import routes from './routes';
import constants from './constant';
import User from './models/User';
import store from './store';

export default {
  routes,
  constants,
  models: [User],
  store
};
