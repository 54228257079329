import ApiService from 'Core/services/api.service';
import AppError from 'Core/services/errors.service';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import User from 'Auth/models/User';
import unitsConstants from 'Units/constant';
import coreConstants from 'Core/constant';

const constants = {...unitsConstants, ...coreConstants};

const ReportsService = {

  async getDeviceGraphData(installationID, deviceID, startDate, rangeType = 'day', graphType = 'confort', timeZone, weather = false, period = undefined) {

    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let url = `${admin}bg/stats/device/${deviceID}?startdate=${startDate}&range=${rangeType}&installationId=${installationID}&graphType=${graphType}`;

    if(weather) {
      url += `&weather=${weather}`;
    }

    if(period) {
      url += `&period=${period}`;
    }


    try {
      const response = await ApiService.get(url);

      let data;

      if(graphType === 'confort') {
        data = cloud2web.getGraphComfortData(response?.data, timeZone);
      } else if(graphType === 'aq') {
        data = cloud2web.getGraphAqData(response?.data, timeZone);
      }

      return data;

    } catch (error){
      console.log(error);
      throw new AppError(error);
    }
  },

  async getMetricsData(installationID, deviceID, startDate, rangeType = 'day') {

    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const url = `${admin}bg/metrics/device/${deviceID}?startdate=${startDate}&range=${rangeType}&installationId=${installationID}`;

    try {
      const response = await ApiService.get(url);

      const data = cloud2web.getMetricsData(response?.data);

      return data;

    } catch (error){
      console.log(error);
      throw new AppError(error);
    }
  },

  async getMonitoringUrl({ installationId, deviceId }) {
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestUrl = `${admin}/devices/${deviceId}/device-monitoring-uri?installationId=${installationId}`;

    try {
      const response = await ApiService.request(requestUrl, {}, 'get', constants.CONNECT.APP.AZCLOUD_API_V2_URL);

      return response?.data;
    } catch (error){
      console.log(error);
      throw new AppError(error);
    }
  },

}

export default ReportsService;
