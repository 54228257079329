import i18n from 'Core/services/language.service';
import { Device } from './DeviceHierarchy';


export class Relay extends Device {
  static entity = 'relay';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   **************************************************************
  * @property {boolean} [relay_sched_available] - si permite programaciones de Rele
  * @property {boolean} [power] - On/Off
  * @property {boolean} [active] - En demanda

  * @property {Array<{_id: string, data?: Object<string, string>}>} [warnings]
  * @property {Array<{_id: string, data?: Object<string, string>}>} [errors]
  ******************************************************************** */

  static fields() {
    return {
      ...super.fields(),

      relay_sched_available: this.boolean(undefined).nullable(), // si permite programaciones de VMC
      isDefaultName: this.boolean(undefined).nullable(),
      name: this.string(''),
      power: this.boolean(undefined).nullable(), // On/Off
      status: this.attr(undefined),
      errors: this.attr(undefined).nullable(),
      warnings: this.attr(undefined).nullable(),
      onlyOnOff: this.boolean(true)

    };
  }



  get getState() {
    if (!this.loadedData) return 'loading';
    if(this.getError) return 'error';
    if (!this.power) return 'off';

    return 'relayActive';
  }

  get getZoneStateString() {
    const zoneState = this.getState;

    if (zoneState === 'error') return '';
    if (!zoneState || zoneState === 'loading') return i18n.global.t('messages.loading');
    if (zoneState === 'off') return i18n.global.t('unit.off');
    if (zoneState === 'success') return i18n.global.t('unit.success');

    let message = '';

    if(typeof this.active === 'boolean'){
      message = `${i18n.global.t(`unit.${zoneState}`)}`
    }

    return message;
  }

  /**
  * Indica si la unidad tiene algún warning en el array
  *
  */
  get hasWarnings() {
    return (this.warnings !== undefined && this.warnings !== null && this.warnings.length > 0);
  }

}


export default Relay;
