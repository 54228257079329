/* eslint no-shadow: 0 */

import { Model } from '@vuex-orm/core';
import AuthService from 'Auth/services/auth.service';
import AuthError from 'Auth/services/errors.service';
import SocketService from 'Core/services/socket.service';
import { hasProps } from 'Core/utils/validate.utils';
import log from 'Core/services/log.service';
import CONSTANTS from 'Core/constant';
import UserSettingsService from 'Auth/services/userSettings.service';
import store from 'Core/store/store';
import StorageService from 'Core/services/storage.service';
// import RollbarService from 'Core/services/Rollbar.service';

export default class User extends Model {
  /**
   * @typedef {Object} User
   * @property {Number} id - identificador dentro de entities del modelo Vuex ORM
   * @property {Number} userID - identificador dentro del sistema
   * @property {String} name - Nombre del usuario
   * @property {String} lastName - Apellidos del usuario
   * @property {String} email - Email del usuario
   * @property {String} avatar - Imagen del usuario
   * @property {Boolean} commercial - Comercial activo o no
   * @property {Boolean} toc - Activo o no
   * @property {Boolean} notification - Notificaciones activas o no
   * @property {Boolean} ampm - Modo AM/PM activo o no
   * @property {Boolean} sundayFirst - Primer día de la semana activo o no
   * @property {Boolean} noHaptic - Sensación háptica en algunas funciones
   * @property {Number} units - Número de unidades asignadas
   * @property {String} lang - Lenguaje del usuario
   * @property {Boolean} admin_mode - Si un usuario con role="admin" tiene activo el modo administrador
   * @property {Array} extra_roles - Array con el tipo de extra_roles asignados al usuario
   * @property {Object} skin - skin OEM aplicada en la interfaz de la APP
   */

  static entity = 'users';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.string(null),
      userID: this.string(''),
      name: this.string(''),
      lastName: this.string(''),
      email: this.string(''),
      avatar: this.string(null),
      commercial: this.boolean(false),
      notification: this.boolean(true),
      ampm: this.boolean(false),
      sundayFirst: this.boolean(false),
      noHaptic: this.boolean(true),
      units: this.string(CONSTANTS.TEMP_UNITS.CELSIUS),
      lang: this.string('en'),
      pendingInstallations: this.number(0),
      hasInstallations: this.boolean(false),
      role: this.string(undefined).nullable(),
      totalInstallations: this.number(0),
      totalAdminInstallation: this.number(0),
      admin_mode: this.boolean(undefined).nullable(),
      integrations: this.attr(undefined).nullable(),
      extra_roles: this.attr(undefined).nullable(),
      userSettings: this.attr(undefined).nullable(),
      skin: this.attr(undefined).nullable(),
      hasSkinsAvail: this.boolean(false),
      isSkinEnabled: this.boolean(false).nullable(),
    };
  }

  /**
  * Devuelve el tipo de unidad de medida en formato texto abreviado: C o F
  */
  get getUnitAbbr() {
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? 'C' : 'F';
  }

  /**
  * Devuelve el valor numérico de la unidad seleccionada por el usuario
  */
  get getUnitsValue() {
    return this.units === CONSTANTS.TEMP_UNITS.CELSIUS ? CONSTANTS.UNITS.CELSIUS : CONSTANTS.UNITS.FARENHEIT;
  }

  /** *************************************************************
   * LIFECYCLE HOOKS
   ************************************************************** */

  static afterCreate(model) {
    //
    // Compruebo si he recibido los datos mínimos del usuario para que la aplicación funcione
    //
    const validate = hasProps(model, ['userID', 'name', 'lastName', 'email']);

    if (validate.length) throw new AuthError('invalidUserData', `Faltan los datos "${validate.join()}" del usuario`);
  }

  /** *************************************************************
   * ACCIONES
   ************************************************************** */

  /**
   * Loguea un usuario
   *
   * @param {String} email - Email del usuario
   * @param {String} password - Contraseña del usuario
   * @throws {AuthError} - Error de Autenticación
   */
  static loginUser = async (email, password) => {
    const user = await AuthService.login({ email, password });
    //
    // Almaceno el usuario en nuestra store (Vuex ORM)
    //
    await this.create({ data: user });
    //
    // Si la recuperación de datos ha tenido éxito conecto el Socket
    //
    // await SocketService.connectUserSocket();
    return true;
  };

  static oauthLogin = async (email, password) => {

    const token = await AuthService.oauthLogin(email, password);

    return token;
  };

  static checkConfirmationOauthToken = async token => {

    await AuthService.checkConfirmationOauthToken(token);

    return true;
  }

  static getOauthAuthorize = async (token, clientId) => {
    const response = await AuthService.getOauthAuthorize(token, clientId);

    return response;
  }

  static oauthAuthorize = async (token, clientId, redirectUri, state, scopes) => {

    const response = await AuthService.oauthAuthorize(token, clientId, redirectUri, state, scopes);

    return response;
  }

  /**
   * Comprueba si un usuario está logueado
   *
   * @param {String} token - Token de la sesión actual
   * @throws {AuthError} - Error de Autenticación
   */
  static isLogin = async token => {
    if(!token) return false;
    const user = await AuthService.isLogin(token);
    //
    // Actualizo el modelo de usuario en Vuex ORM
    //
    await this.create({ data: user });
    //
    // Si la recuperación de datos ha tenido éxito conecto el Socket
    //
    // await SocketService.connectUserSocket();
    return true;
  };



  static clearUser = () => {
    console.log('Desde el clear user');
    AuthService.clearUser();
    SocketService.disconnectSocket();
    return true;
  }

  static hasSocketConnection = () => {
    if(store.getters.getIsDemo) return true;
    return SocketService.hasSocketConnection();
  };

  static connectSocket = async () => {
    if(this.hasSocketConnection()) return true;

    await SocketService.connectUserSocket();
    log.success(`Usuario conectado al socket`);
    return true;
  }

  static pauseSocketUser = () => {
    if (!store.getters.getIsDemo){
      SocketService.disconnectSocket();
    }
    log.info(`Usuario desconectado del socket`);
    return true;
  }

  static refreshListeners = () => {
    // console.log('Refrescando listeners');
    return SocketService.refreshListeners();
  }

  /**
   * Registra un usuario. Recibe los parámetros desde la vista y le pasa al servicio el objeto esperado
   *
   * @param {String} name - Nombre del usuario
   * @param {String} lastName - Apellidos del usuario
   * @param {String} email - Correo del usuario
   * @param {String} password - Password introducido por el usuario
   */
  registerUser = async (user = this) => {
    await AuthService.register(user.name, user.lastName, user.email, user.password, user.lang, user.commercial);
  }

  /**
   * Envía un email al usuario para que pueda validar su cuenta
   *
   * @param {String} email - Email del usuario
   * @return {Promise<Boolean>} - Indica el resultado de enviar el email
   * @throws {AuthError} - Error de Autenticación
   */
  static sendConfirmEmail = email => AuthService.sendConfirmEmail(email);

  /**
   *  Actualiza el usuario en backend
   *
   * @param {String} param - El parámetro que se va a actualizar
   * @param {User} user - Datos de usuario (personales y de configuración)
   */
  editUser = async (param, user = this) => {
    // console.log(user);

    await AuthService.editUser(param, user);
    await user.$update({ where: user.id, data: user }); // La id del usuario dentro del modelo siempre será 1
  };

  /**
   * Actualiza el usuario en el modelo (vista) sin enviar al backend
   *
   * @param {User} user - Datos de usuario (personales y de configuración)
   */
  updateUser = async (user = this) => {
    // console.log(user);
    await user.$update({ where: user.id, data: user }); // La id del usuario dentro del modelo siempre será 1
  };

  /**
   * @param {String} email - Correo del usuario
   * @return {Promise<Boolean>} - Indica el resultado de enviar el email
   */
  static rememberUser = email => AuthService.remember(email);

  /**
   *
   * @param {String} token - El token para confirmar la cuenta de usuario
   * @return {Promise<Boolean>} - Indica el resultado de realizar la confiramción
   */
  static confirmUser = (token, userData = null) => AuthService.confirm(token, userData);

  /**
   *
   * @param {String} token - El token para confirmar la cuenta de usuario
   * @param {String} password - El nuevo password a modificar
   * @return {Promise<Boolean>} - Indica el resultado de actualizar
   */
  static resetPassword = (resetToken, newPassword) => AuthService.resetPassword({ resetToken, newPassword });

  /**
   *
   * @param {String} token - El token para confirmar la cuenta de usuario
   * @return {Promise<Boolean>} - Indica el resultado de actualizar
   */
  static checkConfirmationToken = async confirmationToken => {
    const response = await AuthService.checkConfirmationToken(confirmationToken);

    return response;
  }

  /**
   * @return {Promise<Boolean>} - Indica si se ha borrado con éxito
   */
  static deleteUser = async () => {
    // if(SocketService.disconnectSocket()) log.success('Usuario desconectado del Socket');
    await AuthService.deleteUser();
    this.clearUser();
    await User.deleteAll();
    return true;
  }

  /**
   * @return {Boolean} - Indica si se ha deslogueado con éxito
   */
  logout = async () => {
    if(SocketService.disconnectSocket()) log.success('Usuario desconectado del Socket');
    await AuthService.logout();
    await
    User.deleteAll();
    return true;
  }

  static applySkin = async ({skinId, enabled}) => {
    const response = await AuthService.applySkin({skinId, enabled});

    return response;
  }

  static getSkin = async ({skinId, userId}) => {
    const { data } = await AuthService.getSkin({skinId, userId});

    // StorageService.setItem('skin', JSON.stringify({user_id: userId, ...data}));

    User.update({
      data:{
        id: userId,
        skin: data
      }
    });

    return data;
  }

  removeSkinEnabled = async () => {
    User.update({
      data: {
        id: this.id,
        isSkinEnabled: null
      }
    });
  }

  /**
   *  Indica al Websocket que empezamos a escuchar eventos de la instalación
   */
  static listenInstallation = async installationID => {
    if(store.getters.getIsDemo) {
      SocketService.populateDevicesStatusDemoInstallation(installationID);
      return true;
    }

    await SocketService.listenInstallation(installationID);
    // RollbarService.info(`Listen installation: ${installationID} by websocket`);
    log.info(`Listen installation: ${installationID} by websocket`);

    return true;
  }

  /**
   *  Indica al Websocket que empezamos a escuchar eventos de un webserver
   */
  static listenWebserver = async webserverID => {
    await SocketService.listenWebserver(webserverID);
    log.info(`Listen webserver: ${webserverID} by websocket`);
    return true;
  }

  /**
   *  Indica al Websocket que empezamos a escuchar eventos de actualización de firmware de webserver
   */
  static listenWebserverLink = async webserverID => {
    await SocketService.listenWebserverLink(webserverID);
    log.info(`Listen webserver: ${webserverID} by websocket to upgrade firmware`);
    return true;
  }

  /**
   * Indica al Websocket que dejamos de escuchar los eventos de la instalación, liberando el tráfico de datos
   */
  static clearListeners = async () => {
    await SocketService.clearListeners();
    log.info(`Clear ws listeners from installation`);
    return true;
  }

  /**
   *
   */
  static getUserSettings = async userID => {
    const userSettingsService = new UserSettingsService();
    let userSettings

    try {
      userSettings = await userSettingsService.get(userID);
    } catch (err) {
      console.log(err);
    }

    return userSettings;
  }

  static setUserSettings = async (userID, inputData) => {
    const updatedData = {
      id: userID,
      ...inputData
    }

    const userSettingsService = new UserSettingsService();
    return userSettingsService.set(updatedData);
  }

}
