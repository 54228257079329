/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sensor': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.946 0H21.8a2.585 2.585 0 012.574 2.582v18.845A2.583 2.583 0 0121.799 24H2.946C1.533 24 .363 22.84.363 21.427V2.582A2.587 2.587 0 012.946 0zM21.8 23.094c.917 0 1.667-.75 1.667-1.667V2.582c0-.916-.75-1.666-1.667-1.666H2.946c-.916 0-1.667.75-1.667 1.666v18.845c0 .916.751 1.667 1.667 1.667H21.8zM15.307 11.04c-.497-.429-.975-.662-1.423-.692v-.02h-.107l-.215.04-1.54 1.042a3.44 3.44 0 01-.517.283l-.204.078a1.584 1.584 0 01-.507.088c-.8 0-1.443-.293-1.93-.877a.372.372 0 01.01-.497.28.28 0 01.204-.088c.088 0 .156.039.215.107.37.448.857.663 1.491.663.107 0 .214-.02.341-.059.146-.048.293-.117.458-.214l1.024-.721.507-.341c.253-.146.419-.195.663-.195.604 0 1.238.283 1.89.838a.385.385 0 01.06.497.29.29 0 01-.205.136.299.299 0 01-.215-.068zm-1.423 1.364c.448.03.926.263 1.433.692a.298.298 0 00.214.068.29.29 0 00.205-.136.384.384 0 00-.059-.497c-.653-.556-1.286-.838-1.89-.838h-.137c-.195.02-.351.088-.595.234l-.448.302-1.102.77a2.603 2.603 0 01-.38.175 1.139 1.139 0 01-.341.059c-.634 0-1.121-.215-1.492-.663a.272.272 0 00-.214-.107.28.28 0 00-.205.087.384.384 0 00-.02.497c.488.585 1.132.877 1.931.877a1.6 1.6 0 00.507-.087 2.94 2.94 0 00.302-.127l.41-.234.37-.253 1.15-.78.215-.048h.117l.029.01zm1.423 2.914c-.497-.429-.975-.663-1.423-.692v-.02h-.107l-.215.04-1.54 1.042a3.53 3.53 0 01-.517.283l-.204.078a1.585 1.585 0 01-.507.087c-.8 0-1.443-.292-1.93-.877a.372.372 0 01.01-.497.28.28 0 01.204-.087.27.27 0 01.214.107c.37.448.858.662 1.492.662.107 0 .214-.019.341-.058a2.37 2.37 0 00.458-.214l1.024-.721.507-.341c.243-.147.419-.195.663-.195.604 0 1.238.282 1.89.838a.385.385 0 01.06.497.29.29 0 01-.205.136.285.285 0 01-.215-.068z" _fill="#fff"/>'
  }
})
