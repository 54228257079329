// Módulos
import Core from 'Core';
import Auth from 'Auth';
import Units from 'Units';
import { getStore } from 'Core/services/modules.service';

// Obtengo las rutas, modelos y constantes de los módulos
const modules = [Core, Auth, Units];

const store = getStore(modules);

export default store;
