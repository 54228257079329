/* eslint-disable no-undef */
import axios from 'axios';
// import AppError from 'Core/services/errors.service';


// const apiS3Client = axios.create({});

const S3Service = {

  // /**
  //  * Inicializa el Servicio API
  //  *
  //  * @param {String} baseURL - Url base del API
  //  */
  // init() {

  //   // Configuración base
  //   // apiS3Client.defaults.headers.post['Content-Type'] = 'application/json';
  //   // apiS3Client.defaults.headers.post.Accept = 'application/json';
  //   // apiS3Client.defaults.headers.get['Access-Control-Allow-Origin'] = 'file://';
  //   // apiS3Client.defaults.timeout = 45000;

  //   // Interceptor
  //   /* prettier-ignore */
  //   apiS3Client.interceptors.response.use(
  //     response => response,
  //     // eslint-disable-next-line consistent-return
  //     async error => {

  //       /*
  //        * Sin conexión a internet
  //        * La petición no puede realizarse porque no hay conexión a internet
  //        * redirijo a la página de error
  //        */
  //       if (error.message === 'Network Error') {
  //         throw new AppError('offline');
  //       }

  //       /*
  //        * Tiempo excedido
  //        * La petición es abortada porque ha tardado más del tiempo definido en la variable 'timeout'
  //        */
  //       else if (error.code === 'ECONNABORTED') {
  //         throw new AppError('timeout');
  //       }

  //       /*
  //        * Backend no responde
  //        * La petición no puede realizarse porque el backend no nos responde
  //        * redirijo a la página de error
  //        */
  //       else if (!error.response) {
  //         // Router.push({ name: 'error', query: { reason: 'backendDown' } });
  //         throw new AppError('backendDown');
  //       }

  //       /*
  //        * Ruta no encontrado
  //        * La petición no puede realizarse porque la ruta no se ha encontrado,
  //        */
  //       else if (error.response.status === 404) {
  //         switch(error.response.data._id){
  //           default:
  //             throw new AppError('endPointNotFound');
  //         }
  //       }

  //       // else {
  //       //   throw new Error(error);
  //       // }

  //       return Promise.reject(error);
  //     },
  //   );
  // },


  get(resource, data) {
    if (data) return axios.get(resource, data);
    return axios.get(resource);
  },

  post(resource, data) {
    return apiS3Client.post(resource, data);
  },

  put(resource, data) {
    return apiS3Client.put(resource, data);
  },

  patch(resource, data) {
    return apiS3Client.patch(resource, data);
  },

  delete(resource, data) {
    if (data) return apiS3Client.delete(resource, { data });
    return apiS3Client.delete(resource, data);
  },
}

export default S3Service;
