import AppError from 'Core/services/errors.service';

const mapError = {
  default: ['00', 'Error por defecto, se aplica cuando no se especifica ningún código de error', false],
  userNotExist: ['01', 'No existe ningún usuario con esas credenciales', false],
  userNotConfirmed: ['02', 'El usuario no ha confirmado su cuenta', false],
  sanitazeUserDataError: ['03', 'Los datos recibidos del usuario no son válidos', false],
  invalidUserData: ['04', 'Los datos recibidos del usuario no están completos', false],
  sessionExpired: ['05', 'Tanto el token como el refresh token han caducado', false],
  userConfirmed: ['06', 'El usuario ya había confirmado su cuenta', false],
  userNotAvailable: ['07', 'No puede registrarse el usuario porque ya está registrado', false],
  badParams: ['08', 'Los datos enviados no son válidos', false],
  backendDown: ['09', 'El backend no responde', true],
  tokenNotFound: ['10', 'Las credenciales del usuario han caducado', false],
  sessionClosed: ['11', 'El usuario ha cerrado su sesión desde otro dispositivo', false],
  notEnoughData: ['12', 'No hay suficientes datos para ser confirmado', false],
  oauth2EntityNotExist: ['20', 'There is no entity registered for that client id', false],
  getOauth2Info: ['21', 'error', false],
  deviceNotSupported: ['30', 'Esta zona no está soportada para este dispositivo', false],
  deviceAssociated: ['31', 'Este dispositivo ya está vinculado', false],
  oauthUnlinkedNest: ['32', 'Error de autentificación con la cuenta de Nest', false],
  oauthUnlinkedEcobee: ['33', 'Error de autentificación con la cuenta de Ecobee', false],
  oauthUnlinkedHoneywell: ['34', 'Error de autentificación con la cuenta de Honeywell.', false],
};

/**
 * Errores personalizados dentro de la aplicación
 *
 * @param {String} error - Nombre del error
 */
class AuthError extends AppError {
  constructor(error, customDescription) {
    super(error, 'auth', 'Ax', mapError, customDescription);
  }
}

export default AuthError;
