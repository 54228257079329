import unitConstants from '../constant';

const gradients = unitConstants.GRADIENTS;

function validateState(state) {
  return gradients[state] ? state : 'default';
}

function gradientTransitionFunction(el, binding) {
  const finishMode = validateState(binding.value);

  const finishR1 = gradients[finishMode][0][0];
  const finishG1 = gradients[finishMode][0][1];
  const finishB1 = gradients[finishMode][0][2];
  const finishR2 = gradients[finishMode][1][0];
  const finishG2 = gradients[finishMode][1][1];
  const finishB2 = gradients[finishMode][1][2];

  el.style.backgroundImage = `radial-gradient(circle at 0% 0%, rgb(${finishR1}, ${finishG1}, ${finishB1}), rgb(${finishR2}, ${finishG2}, ${finishB2}))`;

}

const backgroundTransition = {
  mounted(el, binding) {
    gradientTransitionFunction(el, binding);
  },

  updated(el, binding) {
    gradientTransitionFunction(el, binding);
  },
};

export default backgroundTransition;
