import openLink from 'Core/utils/openLink.utils';
import CONSTANTS from 'Core/constant';

/**
 * Redirecciona al store de Android o iOS, el idioma lo
 * obtiene del dispositivo no es necesario pasarle parámetro
 */
const openAppStore = () => {
  const isMobile = CONSTANTS.IS_MOBILE;

  if (isMobile === CONSTANTS.PLATFORM.IOS) {
    // Al usar openLink safariViewController abre App Store y luego al cerrar App Store,
    // se renderizaría en blanco. Window open o location no funciona.
    console.log(CONSTANTS.STORE.IOS)
    openLink(CONSTANTS.STORE.IOS)
    // window.open(encodeURI(CONSTANTS.STORE.IOS), '_blank', 'location=yes,enableViewPortScale=yes')
    // window.cordova.InAppBrowser.open(CONSTANTS.STORE.IOS, '_system', 'location=no');
  } else if (isMobile === CONSTANTS.PLATFORM.ANDROID) {
    console.log(CONSTANTS.STORE.ANDROID)
    openLink(CONSTANTS.STORE.ANDROID)
  }
}

export default openAppStore;
