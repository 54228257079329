// import Vue from 'vue';
import ApiService from 'Core/services/api.service';
// import NavBar from 'Core/components/NavBar.component.vue';
import routes from './routes';
import constants from './constant';
import store from './store';

// Componentes globales

// Librerías
// import 'Core/utils/airzone';
// import 'Core/utils/datetime';
// import 'Core/utils/vant';
import 'Core/utils/fastClick';

// Inicializo el servicio API
//
// Crea una instancia de Axios con la configuración base del entorno seleccionado
ApiService.init();

// Añade los componentes globales
// Vue.component('NavBar', NavBar);

export default {
  routes,
  constants,
  store
};
