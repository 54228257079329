import i18n from 'Core/services/language.service';
import Webserver from 'Units/models/Webserver.model';
import { System, Zone, CCP, Outputs } from 'Units/models/DeviceHierarchy';

const user = require("Public/demo/user.json");
const installations = require("Public/demo/installations.json");
const scenes = require("Public/demo/scenes.json");
const invited = require("Public/demo/invited.json");
const prices = require("Public/demo/prices.json");
const installationDemo = require("Public/demo/installationDemo.json");
const installationDemo1Devices = require("Public/demo/installationDemo1-devices.json");
const installationDemo2Devices = require("Public/demo/installationDemo2-devices.json");
const installationDemo3Devices = require("Public/demo/installationDemo3-devices.json");
const installationDemo4Devices = require("Public/demo/installationDemo4-devices.json");
const installationDemo5Devices = require("Public/demo/installationDemo5-devices.json");
const installationDemo1Weather = require("Public/demo/installationDemo1-weather.json");
const installationDemo2Weather = require("Public/demo/installationDemo2-weather.json");
const installationDemo3Weather = require("Public/demo/installationDemo3-weather.json");
const installationDemo4Weather = require("Public/demo/installationDemo4-weather.json");
const installationDemo5Weather = require("Public/demo/installationDemo5-weather.json");
const thirdPartyLinked = require("Public/demo/thirdPartyLinked.json");
const devicesBLE = require("Public/demo/devicesBLE.json");
const devicesInfoBLE = require("Public/demo/devicesInfoBLE.json");
const wifis = require("Public/demo/wifis.json");

const lang = i18n.global.locale.value;

const DemoService = {
  getUser() {
    user.data.config.lang = lang
    if (lang === 'en') {
      user.data.config.ampm = true
      user.data.config.sundayFirst = true
      user.data.config.units = 1
    }
    return user;
  },

  getInstallations(filterParam = null, filterValue = null ) {
    if(installations.data.installations.length === 0) return [];

    installations.data.installations.forEach( installation => {
      if (typeof installation.name === 'object') {
        installation.name = installation.name[lang];
      }

      if(typeof installation.location_text.city === 'object') {
        installation.city = installation.location_text.city[lang];
      }
    })

    if(filterParam) {
      return { data: { installations: installations.data.installations.filter( installation =>  installation[filterParam].includes(filterValue) ) }};
    }

    return installations;
  },

  getInstallation(id) {
    if (typeof installationDemo[id].data.name === 'object') {
      installationDemo[id].data.name = installationDemo[id].data.name[lang]
    }
    for(let i=0; i<installationDemo[id].data.groups.length; i++) {
      if (installationDemo[id].data.groups[i].name && typeof installationDemo[id].data.groups[i].name === 'object') {
        installationDemo[id].data.groups[i].name = installationDemo[id].data.groups[i].name[lang]
      }
    }
    for(let j=0; j<scenes.data.scenesList.length; j++) {
      if (typeof scenes.data.scenesList[j].meta.name === 'object') {
        scenes.data.scenesList[j].meta.name = scenes.data.scenesList[j].meta.name[lang]
      }
    }
    installationDemo[id].data.scenes = scenes.data.scenesList

    return installationDemo[id];
  },

  getDevices(id) {
    let installationDevices = null
    switch(id) {
      case 'installationDemo1':
        installationDevices = installationDemo1Devices
        break;
      case 'installationDemo2':
        installationDevices = installationDemo2Devices;
        break;
      case 'installationDemo3': {
        installationDevices = installationDemo3Devices;
        break;
      }
      case 'installationDemo4':
        installationDevices = installationDemo4Devices;
        break;
      case 'installationDemo5':
        installationDevices = installationDemo5Devices;
        break;
      default:
    }

    for(let j=0; j<installationDevices.data.length; j++) {
      if (installationDevices.data[j].status.name && typeof installationDevices.data[j].status.name === 'object') {
        installationDevices.data[j].status.name = installationDevices.data[j].status.name[lang]
      }
    }

    return installationDevices.data;
  },

  getStatus(id) {
    return installationDemo[id].data.status
  },

  getSettings(installationID, deviceID) {
    let response = installationDemo[installationID].data.settings
    // Caso especial de un Aidoo en una instalación con webserver Airzone.
    // Devuelvo los ajustes de la instalación 3, que es un Aidoo
    if (installationID === 'installationDemo1' && deviceID === 'DeviceId05') {
      response = installationDemo.installationDemo3.data.settings
    }
    return response
  },

  getSchedules(id) {
    return installationDemo[id].data.schedules
  },

  getScene(id) {
    const sc = scenes.data.scenes.find(scene => scene.data._id === id)
    if (typeof sc.data.meta.name === 'object') {
      sc.data.meta.name = sc.data.meta.name[lang]
    }
    return sc
  },

  getInvited() {
    for(let i=0; i<invited.data.length; i++) {
      invited.data[i].data.name = i18n.global.t('invited')
    }
    return invited
  },

  getPrices(id) {
    return prices[id]
  },

  getWeather(id) {
    let response = null
    switch(id) {
      case 'installationDemo1':
        response = installationDemo1Weather;
        break;
      case 'installationDemo2':
        response = installationDemo2Weather;
        break;
      case 'installationDemo3':
        response = installationDemo3Weather;
        break;
      case 'installationDemo4':
        response = installationDemo4Weather;
        break;
      case 'installationDemo5':
        response = installationDemo5Weather;
        break;
      default:
    }
    return response
  },

  getLocation(id) {
    const locationData = {
      timezone: installationDemo[id].data.timezone,
      countryCode: installationDemo[id].data.status.data.config.country_code,
      city: installationDemo[id].data.location.text.city[lang],
      country: installationDemo[id].data.location.text.country[lang],
      coords: installationDemo[id].data.coords,
    }
    return locationData;
  },

  getEcowatt() {
    const date = new Date();

    const data = {
      "values": [
        {
          "dt": date.toISOString(),
          "value": 0
        },
        {
          "dt": new Date(date.setHours(date.getHours() + 1)).toISOString(),
          "value": 1
        },
        {
          "dt": new Date(date.setHours(date.getHours() + 1)).toISOString(),
          "value": 2
        }
      ]
    }
    return data;
  },

  getThirdPartyLinked() {
    return thirdPartyLinked.linked
  },

  getTariff() {
    return {
      id: 'sampleDataTariff0001',
      name: 'Tarifa 2.0DHA',
      price: 0.15,
      description: 'Tarifa de discriminación horaria',
      currency: {code: 'EUR', symbol: '€'},
      active: true,
      type: 'custom',
      periods: [
        {
          name: 'MyPeriod',
          price: 0.5,
          color: '#1992D4',
          timeSlots: [
            {
              start_hour: 0,
              end_hour: 12,
              days: [0,1,2,3,4]
            },
            {
              start_hour: 16,
              end_hour: 23,
              days: [0,1,2,3]
            }
          ]
        },
        {
          name: 'Weekend',
          price: 0.12,
          color: '#2DCC9A',
          timeSlots: [
            {
              start_hour: 0,
              end_hour: 23,
              days: [5,6]
            }
          ]
        }
      ],
      period_legend: [
        {
          "key": "price.rates.high.title",
          "color": "#1992D4"
        },
        {
          "key": "price.rates.mid.title",
          "color": "#40C3F7"
        },
        {
          "key": "price.rates.low.title",
          "color": "#B3EBFF"
        }
      ]
    }
  },

  getThirdPartyLinkedInstallation() {
    if (typeof thirdPartyLinked.thirdPartyLinkedInstallation.data[0].group_name === 'object') {
      thirdPartyLinked.thirdPartyLinkedInstallation.data[0].group_name = thirdPartyLinked.thirdPartyLinkedInstallation.data[0].group_name[lang]
    }
    if (typeof thirdPartyLinked.thirdPartyLinkedInstallation.data[0].device.name === 'object') {
      thirdPartyLinked.thirdPartyLinkedInstallation.data[0].device.name = thirdPartyLinked.thirdPartyLinkedInstallation.data[0].device.name[lang]
    }
    return thirdPartyLinked.thirdPartyLinkedInstallation
  },

  getBLEDevices() {
    return devicesBLE.data
  },

  getBLEDeviceInfo(macBLE) {
    const data = Object.values(devicesInfoBLE).find(device => { return device.info.macBLE === macBLE });
    Webserver.create({data: data.info})
    if (data.devices?.ccp) {
      CCP.create({data: data.devices.ccp})
    }
    if (data.devices?.system) System.create({data: data.devices.system})
    if (data.devices?.zones?.length > 0) {
      data.devices.zones.map(zone => {
        zone.name = `${i18n.global.t('installations.zone')} ${zone.zone_number}`
        return zone
      })
      Zone.create({data: data.devices.zones})
    }
    if (data.devices?.outputs) {
      Outputs.create({data: data.devices.outputs})
    }
  },

  updateVirtualZones(zone) {
    const trvs =(Outputs.query().where('system_number', zone.system_number).first().outputs.filter(out => (out.type === "trv")));

    if (zone.virtual_zone) {
      if (trvs.filter(trv => trv.linked_zones.includes(zone.zone_number)).length === 0) {
        Zone.delete(z => { return z.zone_number === zone.zone_number });
      } else {
        Zone.update({
          where: zone.id,
          data: {device_heat_stages_conf: 'heat'}
        })
      }
    }

    let newZoneNumber = 0;

    if (trvs.filter(trv => !(trv.linked_zones.length > 0)).length > 0) {
      const usedNumbers = [];
      Zone.query().where('system_number', zone.system_number).get().forEach(z => {
        usedNumbers.push(z.zone_number);
      });
      console.log("INSIDE SEARCH NEW NUMBER");
      newZoneNumber = 1;
      while (usedNumbers.includes(newZoneNumber)) newZoneNumber++;
    }

    System.update({
      where: system => {return system.system_number === zone.system_number},
      data: { new_virtual_zones_allowed: newZoneNumber }
    }).then(value => {console.log(value)})
  },

  getDeviceWifis() {
    return wifis.aplist
  },

  getLocationText() {
    return installations.data.installations[0].location_text.city[i18n.global.locale.value]
  }
}

export default DemoService;
