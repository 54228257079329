/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home_simple': {
    width: 343,
    height: 296,
    viewBox: '0 0 343 296',
    data: '<g clip-path="url(#2w6xj0q0ea)"><path pid="0" d="M338.614 159.288L185.782 5.928c-7.872-7.867-20.692-7.867-28.564 0L4.386 159.289c-5.736 5.815-5.736 15.279 0 21.094 2.699 2.736 6.522 4.333 10.346 4.447h38.461v85.06c0 14.481 11.583 26.225 25.866 26.225H235c3.036 0 5.51-2.509 5.51-5.587 0-3.079-2.474-5.587-5.51-5.587H79.059c-8.21 0-14.845-6.728-14.845-15.051v-90.647c0-3.079-2.474-5.587-5.51-5.587H14.62c-.9 0-1.912-.342-2.587-1.027-1.35-1.482-1.35-3.762 0-5.13L164.977 13.91c3.599-3.648 9.447-3.534 12.933 0l152.832 153.36c.675.685 1.012 1.597 1.124 2.623 0 2.052-1.574 3.649-3.598 3.649h-43.972c-3.036 0-5.51 2.508-5.51 5.587v90.647c0 8.323-6.635 15.051-14.845 15.051h-38.236c-3.036 0-5.51 2.508-5.51 5.587 0 3.078 2.474 5.587 5.51 5.587h38.236c14.282 0 25.866-11.744 25.866-26.225v-85.06h38.461c8.097 0 14.619-6.727 14.619-14.937 0-3.877-1.574-7.754-4.385-10.49h.112z" _fill="#fff"/></g><defs><clipPath id="2w6xj0q0ea"><path pid="1" _fill="#fff" d="M0 0h343v296H0z"/></clipPath></defs>'
  }
})
