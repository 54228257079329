import i18n from 'Core/services/language.service';
import CONSTANTS from 'Core/constant';
import mapTranslations from 'Units/utils/mapTranslations';
import { Device } from './DeviceHierarchy';


export class ACS extends Device {
  static entity = 'acs';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   **************************************************************
  * @property {number} [setpoint] ACS setpoint. Only available if altherma gateway is present
  * @property {boolean} [block_setpoint] - if true it indicates if setpoint change is blocked for the user (this is activated when in powerful mode)
  * @property {number} [tank_temp] - water tank temperature. Only available if altherma gateway is present
  * @property {boolean} [acs_sched_available] - si permite programaciones de ACS
  * @property {boolean} [power] - On/Off
  * @property {boolean} [powerful_mode] - On/Off - only available if altherma gateway is present
  * @property {string} [acs_type] - describes if system is altherma or radiant radio
  * @property {number} [error_code] - error code in altherma
  * @property {number} [range_sp_acs_max]
  * @property {number} [range_sp_acs_min]
  * @property {Array<{_id: string, data?: Object<string, string>}>} [warnings]
  * @property {Array<{_id: string, data?: Object<string, string>}>} [errors]
  ******************************************************************** */

  static fields() {
    return {
      ...super.fields(),

      active: this.boolean(undefined).nullable(), // Indica si está en demanda
      acs_sched_available: this.boolean(undefined).nullable(), // si permite programaciones de ACS
      acs_type: this.string(undefined).nullable(), // describes if system is altherma or radiant radio
      block_setpoint: this.boolean(undefined).nullable(), // if true it indicates if setpoint change is blocked for the user (this is activated when in powerful mode)
      errors: this.attr(undefined).nullable(),
      isDefaultName: this.boolean(undefined).nullable(),
      name: this.string(''),
      power: this.boolean(undefined).nullable(), // On/Off
      powerful_mode: this.boolean(undefined).nullable(), // On/Off - only available if altherma gateway is present
      range_sp_acs_max: this.number(undefined).nullable(),
      range_sp_acs_min: this.number(undefined).nullable(),
      // return_water_temp: this.number(undefined).nullable(),
      setpoint: this.number(undefined).nullable(), // ACS setpoint. Only available if altherma gateway is present
      status: this.attr(undefined),
      step: this.number(undefined).nullable(),
      tank_temp: this.number(undefined).nullable(),
      temp_refrig: this.number(undefined).nullable(),
      temp_water_out_icp: this.number(undefined).nullable(),
      water_flow: this.number(undefined).nullable(), // caudal del agua (Airtool para unidades de agua)
      water_pressure: this.number(undefined).nullable(), // caudal del agua (Airtool para unidades de agua)
      warnings: this.attr(undefined).nullable(),
      units: this.string(undefined).nullable(),
    };
  }

  get hasAidooConfig() {
    //
    // TODO: Implementar lógica para ver si el dispositivo tiene ajustes aidoo
    //
    console.log(this.id);

    const hasConfig = false;

    return hasConfig;
  }

  get hasAidooInfo() {
    return (this.temp_refrig !== undefined && this.temp_refrig !== null) ||
    (this.tank_temp !== undefined && this.tank_temp !== null) ||
    (this.temp_water_out_icp !== undefined && this.temp_water_out_icp !== null) ||
   // (this.return_water_temp !== undefined && this.return_water_temp !== null) ||
    (this.water_flow !== undefined && this.water_flow !== null) ||
    (this.water_pressure !== undefined && this.water_pressure !== null)
  }

  get getSetPoint() {
    return this.setpoint;
  }

  get getAcsTemp() {
    return this.tank_temp;
  }

  get getMaxTemp() {
    return this.range_sp_acs_max;
  }

  get getMinTemp() {
    return this.range_sp_acs_min;
  }

  get getTemp() {
    return this.tank_temp
  }

  get getState() {
    if (!this.loadedData) return 'loading';
    if(this.getError) return 'error';
    if (!this.power) return 'off';
    if(this.active === false) return 'success';
    if(this.powerful_mode) return 'powerful';


    return 'heating';
  }

  get getZoneStateString() {
    const zoneState = this.getState;

    if (zoneState === 'error') return '';
    if (!zoneState || zoneState === 'loading') return i18n.global.t('messages.loading');
    if (zoneState === 'off') return i18n.global.t('unit.off');
    if (zoneState === 'success') return i18n.global.t('unit.success');


    let message = '';

    if(typeof this.active === 'boolean'){
      message = `${i18n.global.t(`unit.${zoneState}`)}`
    }

    return message;
  }

  get onlyOnOff() {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(this.setpoint) || isNaN(this.range_sp_acs_max) || isNaN(this.range_sp_acs_min)
  }

  /**
   * Devuelve el salto de temperatura
   */
  get getStepSize() {
    return this.step;
  }

  /**
  * Devuelve el tipo de unidad de medida en formato texto abreviado: C o F
  */
  get getUnitAbbr() {
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? 'C' : 'F';
  }

   /**
  * @override
  */
  getTemptInUnits(temp) {
    if(temp === null) return null;
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? temp.celsius : temp.fah;
  }


  /**
   * Indica si la unidad tiene alguna función bloqueada
   *
   * @return {Boolean}
   */
  get hasBlock() {
    return this.block_on || this.block_off || this.block_mode || this.block_setpoint;
  }

  /**
  * Indica si la unidad tiene algún warning en el array
  *
  */
  get hasWarnings() {
    return (this.warnings !== undefined && this.warnings !== null && this.warnings.length > 0);
  }



}


export default ACS;
