import { Model }  from '@vuex-orm/core';

export default class Period extends Model {

  static entity = 'periods';

  static fields() {
    return {
      id: this.string(null).nullable(),
      price: this.string(undefined).nullable(), // precio del periodo por defecto
      tariff_id: this.string(null).nullable(),
      color: this.string(undefined).nullable(),
      name: this.string(undefined).nullable(),
      timeSlots: this.attr(undefined).nullable(),
    };
  }
}
