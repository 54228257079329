// COMPONENTS
import Dialog from 'Core/components/Dialog';
import PopupGlobal from 'Core/components/PopupGlobal';
import Toast from 'Core/components/Toast';

// MODELS
import store from 'Core/store/store';
import User from 'Auth/models/User';
import { Zone, Device } from 'Units/models/DeviceHierarchy'
import Installation from 'Units/models/Installation.model'
import Group from 'Units/models/Group.model';
// Sevices
import StorageService from 'Core/services/storage.service';
import i18n from 'Core/services/language.service';
import thirdPartyError from 'Auth/utils/getTypeThirdPartyError'

export default [
  // Auth routes
  {
    path: '/auth',
    component: () => import('./pages/AuthLayout'),
    redirect: {
      name: 'login',
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('./pages/Login'),
        meta: {
          onlyWhenLoggedOut: true,
        },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('./pages/Register.page.vue'),
        beforeEnter: async (to, from, next) => {
          User.clearUser();
          next();
        },
      },
      {
        path: 'register/:token',
        name: 'registerInvited',
        component: () => import('./pages/Register.page.vue'),
        beforeEnter: async (to, from, next) => {
          User.clearUser();
          next();
        },
        props: true
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import('./pages/Forgot.page.vue'),
        beforeEnter: async (to, from, next) => {
          User.clearUser();
          next();
        },
      },

      {
        path: 'confirm/:token',
        name: 'confirm',
        component: () => import('./pages/Confirm.page.vue'),
        beforeEnter: async (to, from, next) => {
          //
          // Antes de confirmar nueva cuenta compruebo que no esté logueado
          // Si estoy logueado, entonces cierro sesión
          //
          //
          const isLoggin = StorageService.getItem('access_token');
          if( isLoggin ) {
            User.clearUser();
          }
          next();
        },
      },
      {
        path: 'reset/:token',
        name: 'reset',
        component: () => import('./pages/ResetPass.page.vue'),
        beforeEnter: async (to, from, next) => {
          User.clearUser();
          next();
        },
      },
    ],
  },

  // Oauth
  {
    path: '/oauth',
    name: 'oauth',
    component: () => import('./pages/Oauth'),
    beforeEnter: async (to, from, next) => {
      User.clearUser();
      next();
    },
  },

  // Association landing
  {
    path: '/association',
    name: 'association',
    component: () => import('./pages/Association'),
    props: true
  },

  // Private routes
  {
    path: 'account',
    name: 'account',
    component: () => import('./pages/Account.page.vue'),
    parent: '/main',
    beforeEnter: async (to, from, next) => {
      //
      // Al acceder a Account conecto el socket de usuario para la edición
      //
      try {

        const hasConnection = User.hasSocketConnection();
        //
        // Si ya estamos conectado al socket limpiamos cualquier conexión anterior
        //
        if(!hasConnection) await User.connectSocket();

        console.log('finalizado Get Installations!');
        next();
      } catch (error) {
        Dialog.error(error);
        next();
      }
    },
  },
  {
    path: '/thirdParty',
    name: 'thirdParty',
    parent: '/main',
    component: () => import('./pages/ThirdParty/ThirdParty.page.vue'),
    beforeEnter: async (to, from, next) => {
      //
      // Al acceder a ThirdParty se piden los dispositivos vinculados del usuario
      //
      try {
        Toast.loading({
          closeOnClick: false
        })
        console.log('Limpio modelo de datos')
        Device.deleteAll()
        Installation.deleteAll()
        Group.deleteAll()

        const user = User.query().first();

        if (store.getters.getIsDemo) {
          await Zone.getDevicesThirdParty('honeywell')
        } else if (user && user.integrations && Object.keys(user.integrations).length !== 0) {
          const promises = [];
          const extConfigURLs = {}
          if (user.integrations.honeywell) {
            // console.log('getDevicesThirdParty Honeywell');
            const honeywellPromise = Zone.getDevicesThirdParty('honeywell')
              .then(resp => {
                extConfigURLs.honeywell = resp
              })
            promises.push(honeywellPromise);
          }

          if (user.integrations.ecobee) {
            // console.log('getDevicesThirdParty Ecobee');
            const ecobeePromise = Zone.getDevicesThirdParty('ecobee')
              .then(resp => {
                extConfigURLs.ecobee = resp
              })
            promises.push(ecobeePromise);
          }

          if (user.integrations.nest) {
            // console.log('getDevicesThirdParty Nest');
            const nestPromise = Zone.getDevicesThirdParty('nest')
              .then(resp => {
                extConfigURLs.nest = resp
              })
            promises.push(nestPromise);
          }

          if(promises.length > 0) {
            await Promise.all(promises);

            store.dispatch('setExtConfigUrl', extConfigURLs);
          }

        }
        next();
        Toast.clear();
      } catch (error) {
        console.log(error);
        const type = thirdPartyError.getTypeThirdPartyError(error)
        // console.log(type)
        if (type) {
          console.log('Popup')
          PopupGlobal.confirm({
            title: i18n.global.t('user.thirdParty.oauthUnlinked.title'),
            message: i18n.global.t('user.thirdParty.oauthUnlinked.message'),
            otherMessage: i18n.global.t('user.thirdParty.oauthUnlinked.otherMessage'),
            acceptBtnText: i18n.global.t('user.thirdParty.oauthUnlinked.relink'),
            cancelBtnText: i18n.global.t('buttons.remove'),
            buttons: true,
          })
          .then(async () => {
            // console.log('Revinculo la cuenta', type, error);
            next({name: 'linkAccount', query: { type } })
          }).catch(async () => {
            // console.log('Borro la cuenta vinculada', type, error);
            next({name: 'deviceLinked', params: { type }, query: { deleteAccount: true } })
          })
          next()
        } else {
          Dialog.error(error);
          next();
        }
      }
    },
  },
  {
    path: '/linkAccount',
    name: 'linkAccount',
    component: () => import('./pages/ThirdParty/LinkAccount/LinkAccount.page.vue'),
    beforeEnter: async (to, from, next) => {
      if(from.name === undefined){
        next({name: 'thirdParty'})
      }
      next()
    }
  },
  {
    path: '/deviceLinked/:type',
    name: 'deviceLinked',
    component: () => import('./pages/ThirdParty/DeviceLinked/DeviceLinked.page.vue'),
    beforeEnter: async (to, from, next) => {
      if(from.name === undefined){
        next({name: 'thirdParty'})
      }
      next()
    }
  },
  {
    path: '/associateZone/:type/:accountID/:zoneID?',
    name: 'associateZone',
    component: () => import('./pages/ThirdParty/AssociateZone/AssociateZone.page.vue'),
    beforeEnter: async (to, from, next) => {
      if(from.name === undefined){
        next({name: 'thirdParty'})
      }
      next()
    }
  },
];
