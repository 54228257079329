
import i18n from 'Core/services/language.service';
import ThirdPartyService from 'Auth/services/thirdParty.service';
import CONSTANTS from 'Core/constant';
import UNITS from 'Units/constant';
import log from 'Core/services/log.service';
// import apiBleMocks from 'Units/mocks/bleApi.mocks';
import { getModeState } from 'Units/utils/mode.utils';
import mapTranslations from 'Units/utils/mapTranslations';
import { hasWarnings } from '../utils/warnings.utils';
import { Device } from './DeviceHierarchy';
import store from 'Core/store/store';

export class Zone extends Device {
  static entity = 'zones';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields(zoneType = UNITS.MODEL_TYPE.ZONE) {
    return {
      ...super.fields(),

      // aidooit: this.boolean(undefined).nullable(),
      zone_type: this.attr(zoneType),
      active: this.boolean(undefined).nullable(),
      air_active: this.boolean(undefined).nullable(),
      antifreeze: this.boolean(undefined).nullable(),
      airq: this.boolean(undefined).nullable(), // boolean. Si true, entonces es AirQ Sensor o AirQ Box; si no está o es false, entonces es central de purificacion
      aq_sensor: this.boolean(undefined).nullable(),
      aq_sensor_fw: this.string(undefined).nullable(),
      aq_box_fw: this.string(undefined).nullable(),
      aq_priority: this.boolean(undefined).nullable(),
      aq_leds_active: this.boolean(undefined).nullable(),
      aq_active: this.boolean(undefined).nullable(),
      aq_high_conf: this.number(undefined).nullable(),
      aq_low_conf: this.number(undefined).nullable(),
      aq_mode_conf: this.string(undefined).nullable(),
      aq_mode_values: this.attr(undefined).nullable(),
      aq_vent_mode_conf: this.string(undefined).nullable(),
      aq_vent_mode_values: this.attr(undefined).nullable(),
      aq_vent_active: this.boolean(undefined).nullable(),
      aq_present: this.boolean(undefined).nullable(),
      aq_quality: this.string(undefined).nullable(),
      aq_temp_quality: this.string(undefined).nullable(),
      aq_hum_quality: this.string(undefined).nullable(),
      aq_co2_quality: this.string(undefined).nullable(),
      aq_pm2_5_quality: this.string(undefined).nullable(),
      aq_pm10_quality: this.string(undefined).nullable(),
      aq_tvoc_quality: this.string(undefined).nullable(),
      aq_index: this.number(undefined).nullable(),
      aq_score: this.number(undefined).nullable(),
      aq_co2: this.number(undefined).nullable(),
      aqpm1_0: this.number(undefined).nullable(),
      aqpm2_5: this.number(undefined).nullable(),
      aqpm10: this.number(undefined).nullable(),
      aq_tvoc: this.number(undefined).nullable(),
      aq_pressure: this.number(undefined).nullable(),
      aq_pressure_values: this.number(undefined).nullable(),
      aq_heat_delta_conf: this.number(undefined).nullable(),
      aq_heat_delta_values: this.attr(undefined).nullable(),
      aq_cool_delta_conf: this.number(undefined).nullable(),
      aq_cool_delta_values: this.attr(undefined).nullable(),
      aq_min_voltage_conf: this.number(undefined).nullable(),
      aq_min_voltage_values: this.attr(undefined).nullable(),
      aq_max_voltage_conf: this.number(undefined).nullable(),
      aq_max_voltage_values: this.attr(undefined).nullable(),
      aq_hum_weight: this.number(undefined).nullable(),
      aq_co2_weight: this.number(undefined).nullable(),
      aq_tvoc_weight: this.number(undefined).nullable(),
      aq_pm10_weight: this.number(undefined).nullable(),
      aq_pm2_5_weight: this.number(undefined).nullable(),
      aq_temp_weight: this.number(undefined).nullable(),
      aq_hum_high_thres_gm: this.number(undefined).nullable(),
      aq_hum_high_thres_mb: this.number(undefined).nullable(),
      aq_hum_low_thres_gm: this.number(undefined).nullable(),
      aq_hum_low_thres_mb: this.number(undefined).nullable(),
      aq_hum_thres_range_max: this.number(undefined).nullable(),
      aq_hum_thres_range_min: this.number(undefined).nullable(),
      aq_hum_thres_range_step: this.number(undefined).nullable(),
      aq_co2_thres_gm: this.number(undefined).nullable(),
      aq_co2_thres_mb: this.number(undefined).nullable(),
      aq_co2_thres_range_max: this.number(undefined).nullable(),
      aq_co2_thres_range_min: this.number(undefined).nullable(),
      aq_co2_thres_range_step: this.number(undefined).nullable(),
      aq_tvoc_thres_gm: this.number(undefined).nullable(),
      aq_tvoc_thres_mb: this.number(undefined).nullable(),
      aq_tvoc_thres_range_max: this.number(undefined).nullable(),
      aq_tvoc_thres_range_min: this.number(undefined).nullable(),
      aq_tvoc_thres_range_step: this.number(undefined).nullable(),
      aq_pm10_thres_gm: this.number(undefined).nullable(),
      aq_pm10_thres_mb: this.number(undefined).nullable(),
      aq_pm10_thres_range_max: this.number(undefined).nullable(),
      aq_pm10_thres_range_min: this.number(undefined).nullable(),
      aq_pm10_thres_range_step: this.number(undefined).nullable(),
      aq_pm2_5_thres_gm: this.number(undefined).nullable(),
      aq_pm2_5_thres_mb: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_max: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_min: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_step: this.number(undefined).nullable(),
      aq_sensor_reset_factory: this.boolean(undefined).nullable(),
      aq_vmc: this.boolean(undefined).nullable(),
      aq_vent_const: this.boolean(undefined).nullable(),
      auto_ovr_mode_enabled:this.boolean(undefined).nullable(),
      autochange_diff_temp_conf: this.number(undefined).nullable(),
      autochange_diff_temp_values: this.attr(undefined).nullable(),
      autochange_time_conf: this.attr(undefined).nullable(),
      autochange_time_values: this.attr(undefined).nullable(),
      auto_mode: this.number(undefined).nullable(),
      basic_mode: this.boolean(undefined).nullable(),
      block_autospeed: this.boolean(undefined).nullable(),
      block_autotemp: this.boolean(undefined).nullable(),
      block_dryspeed: this.boolean(undefined).nullable(),
      block_drytemp: this.boolean(undefined).nullable(),
      block_fantemp: this.boolean(undefined).nullable(),
      block_mode: this.boolean(undefined).nullable(),
      block_off: this.boolean(undefined).nullable(),
      block_on: this.boolean(undefined).nullable(),
      block_setpoint: this.boolean(undefined).nullable(),
      cold_stages: this.number(undefined).nullable(),
      cool_battery_temp: this.number(undefined).nullable(),
      cooling_available: this.boolean(undefined).nullable(),
      cooling_available_readonly: this.boolean(undefined).nullable(),
      connection_date: this.string(undefined).nullable(),
      consumption_ue: this.number(undefined).nullable(), // consumo de la unidad exterior (A) (solo Aidoo)
      dehumidifier_conf: this.boolean(undefined).nullable(),
      dehum_function_active: this.boolean(undefined).nullable(),
      dehum_function_humidity_conf: this.attr(undefined).nullable(),
      dehum_function_humidity_values: this.attr(undefined).nullable(),
      supply_heat_conf: this.boolean(undefined).nullable(),
      device_air_cooling_stage_conf: this.string(undefined).nullable(),
      device_air_cooling_stage_values: this.attr(undefined).nullable(),
      device_air_heating_stage_conf: this.string(undefined).nullable(),
      device_air_heating_stage_values: this.attr(undefined).nullable(),
      device_cooling_stages_conf: this.string(undefined).nullable(),
      device_heat_stages_conf: this.string(undefined).nullable(),
      device_cooling_stages_values: this.attr(undefined).nullable(),
      device_heat_stages_values: this.attr(undefined).nullable(),
      device_rad_cooling_stage_conf: this.string(undefined).nullable(),
      device_rad_cooling_stage_values: this.attr(undefined).nullable(),
      device_rad_heating_stage_conf: this.string(undefined).nullable(),
      device_rad_heating_stage_values: this.attr(undefined).nullable(),
      disch_comp_temp_ue: this.number(undefined).nullable(), // temperatura de descarga del compresor en la unidad exterior (ºC/F) (solo Aidoo)
      disconnection_date: this.string(undefined).nullable(),
      double_sp: this.boolean(undefined).nullable(),
      dualsp_auto_conf: this.boolean(undefined).nullable(),
      eco_conf: this.string(undefined).nullable(),
      eco_values: this.attr(undefined).nullable(),
      eco_sensor_conf: this.string(undefined).nullable(),
      eco_sensor_values: this.attr(undefined).nullable(),
      errors: this.attr(undefined).nullable(),
      exch_heat_temp_ue: this.number(undefined).nullable(), // Temperatura intercambiador de calor unidad exterior (ºC/F) (solo Aidoo)
      exch_heat_temp_iu: this.number(undefined).nullable(), // Temperatura del intercambiador de calor de la unidad interior (ºC/F) (solo Aidoo)
      exp_valv_ui: this.number(undefined).nullable(), // apertura de valvulas de expansion de la unidad interior (solo Aidoo)
      exp_valv_ue: this.number(undefined).nullable(), // apertura de valvulas de expansion de la unidad exterior (solo Aidoo)
      ext_temp: this.number(undefined).nullable(),
      ext_config_url: this.string(undefined).nullable(),
      ext_device_name: this.string(undefined).nullable(),
      ext_device_type: this.string(undefined).nullable(),
      ext_device_units: this.number(undefined).nullable(),
      fallback_active: this.boolean(undefined).nullable(),
      fallback_conf: this.string(undefined).nullable(),
      fallback_values: this.attr(undefined).nullable(),
      fan_speed_real: this.number(undefined).nullable(), // velocidad real de la potencia de ventilación (solo Aidoo)
      gas_pipe_temp_iu: this.number(undefined).nullable(), // Temperatura de la tubería de gas de la unidad interior (ºC/F) (solo Aidoo)
      grille_cooling_angle: this.number(undefined).nullable(),
      grille_heating_angle: this.number(undefined).nullable(),
      grille_cooling_angle_values: this.attr(undefined).nullable(),
      grille_heating_angle_values: this.attr(undefined).nullable(),
      heat_battery_temp: this.number(undefined).nullable(),
      heat_stages: this.number(undefined).nullable(),
      heating_available: this.boolean(undefined).nullable(),
      heating_available_readonly: this.boolean(undefined).nullable(),
      humidity: this.number(undefined).nullable(),
      icon: this.number(undefined).nullable(),
      isChangingMode: this.boolean(undefined).nullable(),
      isDefaultName: this.boolean(undefined).nullable(),
      is_tai_conf: this.boolean(undefined).nullable(), // solo para unidades de agua: Si true, está en TAI (usa tai_temp); sino en TH (usa local_temp)
      iu_model_id: this.string(undefined).nullable(),
      iu_model_name: this.string(undefined).nullable(),
      leds_active: this.boolean(undefined).nullable(),
      leds_off: this.boolean(undefined).nullable(),
      local_module_fw: this.string(undefined).nullable(),
      local_temp: this.number(undefined).nullable(),
      local_vent: this.boolean(undefined).nullable(),
      local_vent_active: this.boolean(undefined).nullable(),
      global_vent_active: this.boolean(undefined).nullable(),
      m010_cold_constant_ventilation : this.boolean(undefined).nullable(),
      m010_heat_constant_ventilation : this.boolean(undefined).nullable(),
      m010_max_voltage_conf: this.number(undefined).nullable(),
      m010_max_voltage_values: this.attr(undefined).nullable(),
      m010_min_voltage_conf: this.number(undefined).nullable(),
      m010_min_voltage_values: this.attr(undefined).nullable(),
      manufacturer_MLI_conf: this.string(undefined).nullable(),
      manufacturer_MLI_values: this.attr(undefined).nullable(),
      master_conf: this.boolean(undefined).nullable(),
      master: this.boolean(undefined).nullable(),
      master_addr: this.number(undefined).nullable(),
      master_addr_values: this.attr(undefined).nullable(),
      max_cool_battery_temperature_conf: this.number(undefined).nullable(),
      min_heat_battery_temperature_conf: this.number(undefined).nullable(),
      min_heat_setpoint: this.attr(undefined).nullable(),
      min_heat_setpoint_values: this.attr(undefined).nullable(),
      max_heat_setpoint: this.attr(undefined).nullable(),
      max_heat_setpoint_values: this.attr(undefined).nullable(),
      min_cold_setpoint: this.attr(undefined).nullable(),
      min_cold_setpoint_values: this.attr(undefined).nullable(),
      modbus_map_code: this.string(undefined).nullable(),
      modbus_version: this.string(undefined).nullable(),
      mode: this.string(undefined).nullable(),
      mode_available: this.attr(undefined).nullable(),
      name: this.string(''),
      offset_env_cool_conf: this.attr(undefined).nullable(),
      offset_env_cool_values: this.attr(undefined).nullable(),
      offset_env_heat_conf: this.attr(undefined).nullable(),
      offset_env_heat_values: this.attr(undefined).nullable(),
      offset_trv_env_heat_conf: this.attr(undefined).nullable(),
      offset_trv_env_heat_values: this.attr(undefined).nullable(),
      outputs_config_avail: this.boolean(undefined).nullable(), // Indica si tiene o no tiene las salidas disponibles
      pc_ue: this.number(undefined).nullable(), // presion del condensador de la unidad exterior (MPa) (solo Aidoo)
      pe_ue: this.number(undefined).nullable(), // presion del evaporador de la unidad exterior (MPa) (solo Aidoo)
      power: this.boolean(undefined).nullable(),
      pspeed: this.number(undefined).nullable(),
      preparing_conf: this.number(undefined).nullable(),
      preparing_values: this.attr(undefined).nullable(),
      presence_sensor_conf: this.string(undefined).nullable(),
      presence_sensor_values: this.attr(undefined).nullable(),
      qadapt: this.number(undefined).nullable(),
      range_air_max: this.number(undefined).nullable(),
      range_air_min: this.number(undefined).nullable(),
      range_aq_high_max: this.number(undefined).nullable(),
      range_aq_high_min: this.number(undefined).nullable(),
      range_aq_low_max: this.number(undefined).nullable(),
      range_aq_low_min: this.number(undefined).nullable(),
      range_sp_auto_air_max: this.number(undefined).nullable(),
      range_sp_auto_air_min: this.number(undefined).nullable(),
      range_sp_cool_air_max: this.number(undefined).nullable(),
      range_sp_cool_air_min: this.number(undefined).nullable(),
      range_sp_dry_air_max: this.number(undefined).nullable(),
      range_sp_dry_air_min: this.number(undefined).nullable(),
      range_sp_emerheat_air_max: this.number(undefined).nullable(),
      range_sp_emerheat_air_min: this.number(undefined).nullable(),
      range_sp_hot_air_max: this.number(undefined).nullable(),
      range_sp_hot_air_min: this.number(undefined).nullable(),
      range_sp_real_cool_max: this.number(undefined).nullable(),
      range_sp_real_cool_min: this.number(undefined).nullable(),
      range_sp_real_heat_max: this.number(undefined).nullable(),
      range_sp_real_heat_min: this.number(undefined).nullable(),
      range_sp_vent_air_max: this.number(undefined).nullable(),
      range_sp_vent_air_min: this.number(undefined).nullable(),
      range_sp_stop_air_max: this.number(undefined).nullable(),
      range_sp_stop_air_min: this.number(undefined).nullable(),
      range_sp_lite_base_max: this.number(undefined).nullable(),
      range_sp_lite_base_min: this.number(undefined).nullable(),
      range_max_cool_battery_temperature_values: this.attr(undefined).nullable(),
      range_min_heat_battery_temperature_values: this.attr(undefined).nullable(),
      range_usermode_sp_comfort_heat_max: this.attr(undefined).nullable(),
      range_usermode_sp_comfort_heat_min: this.attr(undefined).nullable(),
      range_usermode_sp_comfort_cool_max: this.attr(undefined).nullable(),
      range_usermode_sp_comfort_cool_min: this.attr(undefined).nullable(),
      range_usermode_sp_eco_heat_max: this.attr(undefined).nullable(),
      range_usermode_sp_eco_heat_min: this.attr(undefined).nullable(),
      range_usermode_sp_eco_cool_max: this.attr(undefined).nullable(),
      range_usermode_sp_eco_cool_min: this.attr(undefined).nullable(),
      range_usermode_sp_unnocupied_heat_max: this.attr(undefined).nullable(),
      range_usermode_sp_unnocupied_heat_min: this.attr(undefined).nullable(),
      range_usermode_sp_unnocupied_cool_max: this.attr(undefined).nullable(),
      range_usermode_sp_unnocupied_cool_min: this.attr(undefined).nullable(),
      range_usermode_sp_vacation_heat_max: this.attr(undefined).nullable(),
      range_usermode_sp_vacation_heat_min: this.attr(undefined).nullable(),
      range_usermode_sp_vacation_cool_max: this.attr(undefined).nullable(),
      range_usermode_sp_vacation_cool_min: this.attr(undefined).nullable(),
      replicated_zones: this.attr(undefined).nullable(),
      associated_trv_valves: this.attr(undefined).nullable(),
      return_temp: this.number(undefined).nullable(), // return temperature
      return_water_temp: this.number(undefined).nullable(),
      sp_lite_base_conf: this.number(undefined).nullable(),
      setpoint: this.number(undefined).nullable(),
      setpoint_air_auto: this.number(undefined).nullable(),
      setpoint_air_cool: this.number(undefined).nullable(),
      setpoint_air_dry: this.number(undefined).nullable(),
      setpoint_air_emerheat: this.number(undefined).nullable(),
      setpoint_air_heat: this.number(undefined).nullable(),
      setpoint_air_vent: this.number(undefined).nullable(),
      setpoint_air_stop: this.number(undefined).nullable(),
      serialnum_tstat: this.string(undefined).nullable(),
      serialnum_lmb: this.string(undefined).nullable(),
      serialnum_gw: this.string(undefined).nullable(),
      simulator_mode_active: this.boolean(undefined).nullable(),
      slats_h_conf: this.string(undefined).nullable(),
      slats_h_values: this.attr(undefined).nullable(),
      slats_v_conf: this.string(undefined).nullable(),
      slats_v_values: this.attr(undefined).nullable(),
      sleep: this.number(undefined).nullable(),
      sleep_values: this.attr(undefined).nullable(),
      sp_limits_forced: this.boolean(undefined).nullable(),
      speed_values: this.attr(undefined).nullable(),
      speed_conf: this.number(undefined).nullable(),
      speed_type: this.number(undefined).nullable(),
      stat_channel: this.number(undefined).nullable(),
      stat_rssi: this.number(undefined).nullable(),
      stat_ssid: this.string(undefined).nullable(),
      step: this.number(undefined).nullable(),
      system_type: this.string(undefined).nullable(), // Para los aidoo_it (tipo de instalación)
      system_type_values: this.attr(undefined).nullable(), // tipos de instalación
      tai_th: this.boolean(true).nullable(),
      tai_temp: this.number(undefined).nullable(), // Temperatura de impulsión de agua (si zona de aire es con pasarela de agua AIDOO)
      thermostat_fw: this.string(undefined).nullable(),
      thermostat_type: this.string(undefined).nullable(),
      temp_auto_mode_active: this.boolean(undefined).nullable(),
      third_party_allowed: this.boolean(true),
      third_party_id: this.string(undefined).nullable(),
      third_party_name: this.string(undefined).nullable(),
      third_party_type: this.string(undefined).nullable(),
      third_party_compatible: this.attr(undefined).nullable(),
      timer: this.attr(undefined).nullable(),
      timer_values: this.attr(undefined).nullable(),
      two_zones_water: this.boolean(undefined).nullable(),
      units: this.string(undefined).nullable(),
      updated_zoneConfig_ts: this.string(undefined).nullable(),
      user_device_cooling_stages_values: this.attr(undefined),
      user_device_heat_stages_values: this.attr(undefined).nullable(),
      user_device_cooling_stages_conf: this.string(undefined).nullable(),
      user_device_heat_stages_conf: this.string(undefined).nullable(),
      usermode_conf: this.string(undefined).nullable(),
      usermode_values: this.attr(undefined).nullable(),
      usermode_sp_step: this.attr(undefined).nullable(),
      usermode_sp_comfort_heat_conf: this.attr(undefined).nullable(),
      usermode_sp_comfort_cool_conf: this.attr(undefined).nullable(),
      usermode_sp_eco_heat_conf: this.attr(undefined).nullable(),
      usermode_sp_eco_cool_conf: this.attr(undefined).nullable(),
      usermode_sp_unnocupied_heat_conf: this.attr(undefined).nullable(),
      usermode_sp_unnocupied_cool_conf: this.attr(undefined).nullable(),
      usermode_sp_vacation_heat_conf: this.attr(undefined).nullable(),
      usermode_sp_vacation_cool_conf: this.attr(undefined).nullable(),
      version: this.string(undefined).nullable(),
      virtual_zone: this.boolean(undefined).nullable(), // Aplica sólo a las TRV virtuales
      is_virtual: this.boolean(undefined).nullable(), // Indica si la zona representa un termostato virtual (genérico)
      window_sensor_conf: this.string(undefined).nullable(),
      window_sensor_values: this.attr(undefined).nullable(),
      ws_sched_available: this.boolean(undefined).nullable(),
      ws_sched_calendar_available: this.boolean(undefined).nullable(),
      work_temp: this.number(undefined).nullable(),
      work_temp_source: this.string(undefined).nullable(),
      work_temp_source_values: this.attr(undefined).nullable(),
      zone_number: this.number(undefined).nullable(),
      zone_sched_available: this.boolean(undefined).nullable(),
      zone_sched_calendar_available: this.boolean(undefined).nullable(),
      zone_weight_manual: this.boolean(undefined).nullable(),
      zone_weight_percent: this.number(undefined).nullable(),
      zone_weight_manual_readonly: this.boolean(undefined).nullable(),
    };
  }

  //
  // Si la zona es Aidoo, calculamos si tiene configuración o info para Airtools en Aidoo
  //
  get hasAidooConfig() {

    return (this.window_sensor_conf !== undefined && this.window_sensor_conf !== null &&
      Array.isArray(this.window_sensor_values) && this.window_sensor_values.length > 0 )||
      (this.presence_sensor_conf !== undefined && this.presence_sensor_conf !== null &&
      Array.isArray(this.presence_sensor_values) && this.presence_sensor_values.length > 0) ||
      (this.offset_env_cool_conf !== undefined && this.offset_env_cool_conf !== null &&
      Array.isArray(this.offset_env_cool_values) && this.offset_env_cool_values.length > 0) ||
      (this.offset_env_heat_conf !== undefined && this.offset_env_heat_conf !== null &&
      Array.isArray(this.offset_env_heat_values) && this.offset_env_heat_values.length > 0) ||
      (this.eco_sensor_conf !== undefined && this.eco_sensor_values !== null &&
      Array.isArray(this.eco_sensor_values) && this.eco_sensor_values.length > 0) ||
      (this.m010_cold_constant_ventilation !== undefined && this.m010_cold_constant_ventilation !== null) ||
      (this.m010_heat_constant_ventilation !== undefined && this.m010_heat_constant_ventilation !== null) ||
      (this.basic_mode !== undefined && this.basic_mode !== null) ||
      (this.m010_min_voltage_conf !== undefined && this.m010_min_voltage_conf !== null &&
      Array.isArray(this.m010_min_voltage_values) && this.m010_min_voltage_values.length > 0) ||
      (this.m010_max_voltage_conf !== undefined && this.m010_max_voltage_conf !== null &&
      Array.isArray(this.m010_max_voltage_values) && this.m010_max_voltage_values.length > 0) ||
      (this.dualsp_auto_conf !== undefined && this.dualsp_auto_conf !== null) ||
      (this.autochange_diff_temp_conf !== undefined && this.autochange_diff_temp_conf !== null &&
      Array.isArray(this.autochange_diff_temp_values) && this.autochange_diff_temp_values.length > 0) ||
      (this.autochange_time_conf !== undefined && this.autochange_time_conf !== null &&
      Array.isArray(this.autochange_time_values) && this.autochange_time_values.length > 0) ||
      (this.fallback_conf !== undefined && this.fallback_conf !== null &&
      Array.isArray(this.fallback_values) && this.fallback_values.length > 0) ||
      (this.sp_limits_forced !== undefined && this.sp_limits_forced !== null) ||
      (this.min_cold_setpoint !== undefined && this.min_cold_setpoint !== null &&
      Array.isArray(this.min_cold_setpoint_values) && this.min_cold_setpoint_values.length > 0) ||
      (this.heating_available && this.max_heat_setpoint !== undefined && this.max_heat_setpoint !== null &&
      Array.isArray(this.max_heat_setpoint_values) && this.max_heat_setpoint_values.length > 0) ||
      (this.aq_high_conf !== undefined && this.aq_high_conf !== null &&
      this.range_aq_high_min !== undefined && this.range_aq_high_min !== null &&
      this.range_aq_high_max !== undefined && this.range_aq_high_max !== null) ||
      (this.aq_low_conf !== undefined && this.aq_low_conf !== null &&
      this.range_aq_low_min !== undefined && this.range_aq_low_min !== null &&
      this.range_aq_low_max !== undefined && this.range_aq_low_max !== null) ||
      (this.aq_sensor !== undefined && this.aq_sensor !== null && this.aq_sensor !== false)
  }

  get hasAidooInfo() {

    return (this.manufacturer !== undefined && this.manufacturer !== null && this.manufacturer.text) ||
      (this.units !== undefined && this.units !== null) ||
      (this.is_tai_conf !== undefined && this.is_tai_conf !== null) ||
      (this.tai_temp !== undefined && this.tai_temp !== null) ||
      (this.local_temp !== undefined && this.local_temp !== null) ||
      (this.work_temp !== undefined && this.work_temp !== null) ||
      (this.return_temp !== undefined && this.return_temp !== null) ||
      (this.exch_heat_temp_iu !== undefined && this.exch_heat_temp_iu !== null) ||
      (this.gas_pipe_temp_iu !== undefined && this.gas_pipe_temp_iu !== null) ||
      (this.exp_valv_ui !== undefined && this.exp_valv_ui !== null) ||
      (this.cool_battery_temp !== undefined && this.cool_battery_temp !== null) ||
      (this.heat_battery_temp !== undefined && this.heat_battery_temp !== null) ||
      (this.consumption_ue !== undefined && this.consumption_ue !== null) ||
      (this.ext_temp !== undefined && this.ext_temp !== null) ||
      (this.exch_heat_temp_ue !== undefined && this.exch_heat_temp_ue !== null) ||
      (this.disch_comp_temp_ue !== undefined && this.disch_comp_temp_ue !== null) ||
      (this.exp_valv_ue !== undefined && this.exp_valv_ue !== null) ||
      (this.pe_ue !== undefined && this.pe_ue !== null) ||
      (this.pc_ue !== undefined && this.pc_ue !== null)


  }

  //
  // Propiedades cálculo de secciones de Airtools [SECCIONES]
  //
  // Temperatura modo usuario
  get hasUsermodeTemp() {
    return this.usermode_sp_comfort_cool_conf !== undefined && this.usermode_sp_comfort_cool_conf !== null ||
        this.usermode_sp_comfort_heat_conf !== undefined && this.usermode_sp_comfort_heat_conf !== null ||
        this.usermode_sp_eco_cool_conf !== undefined && this.usermode_sp_eco_cool_conf !== null ||
        this.usermode_sp_eco_heat_conf !== undefined && this.usermode_sp_eco_heat_conf !== null ||
        this.usermode_sp_unnocupied_cool_conf !== undefined && this.usermode_sp_unnocupied_cool_conf !== null ||
        this.usermode_sp_unnocupied_heat_conf !== undefined && this.usermode_sp_unnocupied_heat_conf !== null ||
        this.usermode_sp_vacation_cool_conf !== undefined && this.usermode_sp_vacation_cool_conf !== null ||
        this.usermode_sp_vacation_heat_conf !== undefined && this.usermode_sp_vacation_heat_conf !== null
  }

  // Etapas de control (nuevas)
  get hasControlStage() {
    return this.device_air_cooling_stage_conf !== undefined && this.device_air_cooling_stage_conf !== null ||
        this.device_rad_cooling_stage_conf !== undefined && this.device_rad_cooling_stage_conf !== null ||
        this.device_air_heating_stage_conf !== undefined && this.device_air_heating_stage_conf !== null ||
        this.device_rad_heating_stage_conf !== undefined && this.device_rad_heating_stage_conf !== null
  }

  // Sección de zona
  get hasZoneSection() {
    return this.basic_mode !== undefined && this.basic_mode !== null ||
        this.master_conf !== undefined && this.master_conf !== null ||
        this.manufacturer_MLI_conf !== undefined && this.manufacturer_MLI_conf !== null ||
        this.m010_cold_constant_ventilation !== undefined && this.m010_cold_constant_ventilation !== null ||
        this.m010_heat_constant_ventilation !== undefined && this.m010_heat_constant_ventilation !== null ||
        this.hasUsermodeTemp ||
        this.offset_env_cool_conf !== undefined && this.offset_env_cool_conf !== null ||
        this.offset_env_heat_conf !== undefined && this.offset_env_heat_conf !== null ||
        this.offset_trv_env_heat_conf !== undefined && this.offset_trv_env_heat_conf !== null ||
        this.master_addr !== undefined && this.master_addr !== null ||
        this.hasControlStage
  }

  // Etapas frío o calor (antiguas)
  get hasStages() {
    return (this.device_cooling_stages_values !== undefined && this.device_cooling_stages_values !== null ||
        this.device_heat_stages_values !== undefined && this.device_heat_stages_values !== null) &&
        (this.device_heat_stages_values && this.device_heat_stages_values.length > 0 ||
        this.device_cooling_stages_values && this.device_cooling_stages_values.length > 0)
  }

  // Voltajes de ventilador
  get hasVoltageSection() {
    return this.m010_max_voltage_conf !== undefined && this.m010_max_voltage_conf !== null ||
      this.m010_min_voltage_conf !== undefined && this.m010_min_voltage_conf !== null
  }

  // Q-Adapt. Caudal.
  get showWeight() {
     return (this.zone_weight_manual !== undefined
          && this.zone_weight_manual !== null
          && !(this.zone_weight_manual === false
            && this.zone_weight_manual_readonly === true))
  }

  // Entradas
  get hasInputs() {
    return this.window_sensor_conf !== undefined && this.window_sensor_conf !== null ||
    this.presence_sensor_conf !== undefined && this.presence_sensor_conf !== null
  }

  //
  // Total. Si se muestra alguna de las secciones se muestra la pestaña de Ajustes
  //
  get hasAirtoolsSettings() {
    return this.hasZoneSection || this.hasVoltageSection || this.hasStages || this.showWeight || this.hasInputs
  }


  get getTemp() {
    let zoneTemp = null;
    if(this.isTai){
      zoneTemp = this.tai_temp;
    } else {
      zoneTemp =  this.work_temp !== undefined && this.work_temp !== null ? this.work_temp : this.local_temp;
    }
    return zoneTemp;
  }

  get isZoneSchedAvailable () {
    return this.zone_sched_available === true;
  }

  get isCalendarSchedAvailable () {
    return this.zone_sched_calendar_available === true;
  }

  get isPreparing() {
    let isPreparing = false;
    if(this.warnings && this.warnings.length > 0) {
      this.warnings.forEach( warning => {
        if(warning._id === 'fancoil_preparing') {
          isPreparing = true;
        }
      });
    }

    return isPreparing;
  }

  get isTai() {
    return this.is_tai_conf && this.tai_temp !== undefined && this.tai_temp !== null;
  }

  get hasModesAvailables() {
    return this.mode_available?.length > 0;
  }


  get getState() {
    if (!this.loadedData) return 'loading';
    if (this.getError) return 'error';
    if (!this.power) return 'off';
    if (this.isPreparing) return 'preparing';

    return getModeState({
      mode: this.mode,
      active: this.active,
      auto_mode: this.auto_mode,
      block_drytemp: this.block_drytemp,
      local_vent_active: this.local_vent_active || this.global_vent_active,
    });
  }

  get getZoneStateStringComplete() {
    const zoneState = this.getState;
    const setpoint = this.getSetPoint;

    if (zoneState === 'error') return typeof mapTranslations.AZ_DEVICES_STATES.error === 'function' ? mapTranslations.AZ_DEVICES_STATES.error() : '';
    if (zoneState === 'preparing') return typeof mapTranslations.AZ_DEVICES_STATES.preparing === 'function' ? mapTranslations.AZ_DEVICES_STATES.preparing() : '';
    if (!zoneState || zoneState === 'loading') return typeof mapTranslations.AZ_DEVICES_STATES.loading === 'function' ? mapTranslations.AZ_DEVICES_STATES.loading() : '' ;
    if (zoneState === 'stop') return typeof mapTranslations.AZ_DEVICES_STATES.stop === 'function' ? mapTranslations.AZ_DEVICES_STATES.stop() : '';
    if (zoneState === 'off') return typeof mapTranslations.AZ_DEVICES_STATES.off === 'function' ? mapTranslations.AZ_DEVICES_STATES.off() : '';
    if (zoneState === 'success') return typeof mapTranslations.AZ_DEVICES_STATES.success === 'function' ? mapTranslations.AZ_DEVICES_STATES.success() : '';
    if (zoneState === 'drying') return typeof mapTranslations.AZ_DEVICES_STATES.drying === 'function' ? mapTranslations.AZ_DEVICES_STATES.drying() : '';
    if (zoneState === 'airing') return typeof mapTranslations.AZ_DEVICES_STATES.airing === 'function' ? mapTranslations.AZ_DEVICES_STATES.airing() : '';

    let message = '';
    if(zoneState !== 'stop'){
      message = (setpoint !== null) ? `${typeof mapTranslations.AZ_DEVICES_STATES[zoneState] === 'function' ? mapTranslations.AZ_DEVICES_STATES[zoneState]() : ''} ${i18n.global.t('unit.to')} ${setpoint} º` : `${typeof mapTranslations.AZ_DEVICES_STATES[zoneState] === 'function' ? mapTranslations.AZ_DEVICES_STATES[zoneState]() : ''}`
    }

    return message;
  }

  get getZoneStateString() {
    const zoneState = this.getState;

    if (zoneState === 'error') return typeof mapTranslations.AZ_DEVICES_STATES.error === 'function' ? mapTranslations.AZ_DEVICES_STATES.error() : '';
    if (zoneState === 'preparing') return typeof mapTranslations.AZ_DEVICES_STATES.preparing === 'function' ? mapTranslations.AZ_DEVICES_STATES.preparing() : '';
    if (!zoneState || zoneState === 'loading') return typeof mapTranslations.AZ_DEVICES_STATES.loading === 'function' ? mapTranslations.AZ_DEVICES_STATES.loading() : '' ;
    if (zoneState === 'stop') return typeof mapTranslations.AZ_DEVICES_STATES.stop === 'function' ? mapTranslations.AZ_DEVICES_STATES.stop() : '';
    if (zoneState === 'off') return typeof mapTranslations.AZ_DEVICES_STATES.off === 'function' ? mapTranslations.AZ_DEVICES_STATES.off() : '';
    if (zoneState === 'success') return typeof mapTranslations.AZ_DEVICES_STATES.success === 'function' ? mapTranslations.AZ_DEVICES_STATES.success() : '';
    if (zoneState === 'drying') return typeof mapTranslations.AZ_DEVICES_STATES.drying === 'function' ? mapTranslations.AZ_DEVICES_STATES.drying() : '';
    if (zoneState === 'airing') return typeof mapTranslations.AZ_DEVICES_STATES.airing === 'function' ? mapTranslations.AZ_DEVICES_STATES.airing() : '';

    let message = '';
    if(zoneState !== 'stop'){
      message = `${typeof mapTranslations.AZ_DEVICES_STATES[zoneState] === 'function' ? mapTranslations.AZ_DEVICES_STATES[zoneState]() : ''}`;
    }

    return message;
  }



  get getSetPoint() {
    if (this.mode === 'dry' && this.block_drytemp === true) return null;
    if (this.mode === 'fan' && this.block_fantemp === true) return null;
    if (this.mode === 'auto' && this.double_sp === true) {
      return {heat: this[this.getTempProperty.setPoint.heat], cool: this[this.getTempProperty.setPoint.cool]}
    }

    return this[this.getTempProperty.setPoint];
  }


  get getIonizerState() {
    return (this.aq_active !== undefined && this.aq_active !== null) ? this.aq_active : false;
  }

  get getIonizerStateText(){

    switch(this.aq_mode_conf){
      case UNITS.AQ_QUALITY_VALUES.good:
        return 'Good';
      case UNITS.AQ_QUALITY_VALUES.regular:
        return 'Regular';
      case UNITS.AQ_QUALITY_VALUES.bad:
        return 'Bad'
      default:
        return this.aq_mode_values[this.aq_mode_values.indexOf(this.aq_mode_conf)];
    }
  }

  get getMaxTemp() {
    return this[this.getTempProperty.max];
  }

  get getMinTemp() {
    return this[this.getTempProperty.min];
  }

  get getTempProperty() {
    let max = '';
    let min = '';
    let setPoint = '';

    switch (this.mode) {
      case 'heat':
      case 'heat_air':
      case 'heat_radiant':
      case 'heat_comb':
        max = 'range_sp_hot_air_max';
        min = 'range_sp_hot_air_min';
        setPoint = 'setpoint_air_heat';
        break;
      case 'emergencyHeat':
        max = 'range_sp_emerheat_air_max';
        min = 'range_sp_emerheat_air_min';
        setPoint = 'setpoint_air_emerheat';
        break;
      case 'cool':
      case 'cool_air':
      case 'cool_radiant':
      case 'cool_comb':
        max = 'range_sp_cool_air_max';
        min = 'range_sp_cool_air_min';
        setPoint = 'setpoint_air_cool';
        break;
      case 'dry':
        max = 'range_sp_dry_air_max';
        min = 'range_sp_dry_air_min';
        setPoint = 'setpoint_air_dry';
        break;
      case 'fan':
        max = 'range_sp_vent_air_max';
        min = 'range_sp_vent_air_min';
        setPoint = 'setpoint_air_vent';
        break;
      case 'stop':
        max = 'range_sp_stop_air_max';
        min = 'range_sp_stop_air_min';
        setPoint = 'setpoint_air_stop';
        break;
      case 'auto':
        if(this.double_sp){
          max = 'range_sp_cool_air_max';
          min = 'range_sp_hot_air_min';
          setPoint = {heat: 'setpoint_air_heat', cool: 'setpoint_air_cool'}
        } else {
          max = 'range_sp_auto_air_max';
          min = 'range_sp_auto_air_min';
          setPoint = 'setpoint_air_auto';
        }
        break;
      default:
        max = 'range_sp_auto_air_max';
        min = 'range_sp_auto_air_min';
        setPoint = 'setpoint_air_auto';
    }

    return { max, min, setPoint };
  }

  /**
   * Devuelve el salto de temperatura
   */
  get getStepSize() {
    return this.step;
  }

  /**
  * Devuelve el tipo de unidad de medida en formato texto abreviado: C o F
  */
  get getUnitAbbr() {
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? 'C' : 'F';
  }

   /**
  * @override
  */
  getTemptInUnits(temp) {
    if(temp === null) return null;
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? temp.celsius : temp.fah;
  }

  /**
   * Indica si la unidad tiene alguna función bloqueada
   *
   * @return {Boolean}
   */
  get hasBlock() {
    return this.block_on || this.block_off || this.block_setpoint || this.fallback_active;
  }

  /**
  * Indica si la unidad tiene algún warning para pintar al usuario en el array
  *
  */
  get hasWarnings() {
    if ((!Array.isArray(this.warnings) || this.warnings.length === 0) &&
      (!Array.isArray(this.errors) || this.errors.length === 0)) return undefined;

    const warnings = this.warnings?.filter( warning => warning.hidden !== true);
    const errors = this.errors?.filter( error => error.hidden !== true);

    let allowedWarnings = 0
    let allowedErrors = 0

    if (warnings) allowedWarnings = hasWarnings(warnings);

    if (errors) allowedErrors = hasWarnings(errors);

    const allowedWarningsErrors = allowedWarnings + allowedErrors

    return (allowedWarningsErrors !== undefined && allowedWarningsErrors !== null && allowedWarningsErrors > 0);
  }

  /**
   * Obtiene los dispositivos asociados a un tercero
   *
   * @param {string} type - tipo de tercero asociado
   */
  static getDevicesThirdParty = async type => {
    const { extConfigUrl, linkedThirdParty } = await ThirdPartyService.getDevicesThirdParty(type);
    // console.log(response);

    const promises = [];
    linkedThirdParty.forEach( account => {
      // console.log('Creating zone: ', account);
      promises.push(this.insertOrUpdate({ data: account }));
    });
    await Promise.all(promises);

    const devices = linkedThirdParty.filter(device => device.installation_id !== undefined).map(device => ({installation_id: device.installation_id, device_id: device.id}))
    // console.log(devices)
    if (devices.length > 0) {
      const resp = await ThirdPartyService.getInstallationThirdParty(devices);
      // console.log(resp);
      const promisesNames = [];
      devices.forEach(item => {
        // console.log('item: ', item);
        let deviceWithName = resp.find(dev => dev.device.id === item.device_id);

        if(deviceWithName === undefined) {
          deviceWithName = {
            device: {
              id: item.device_id,
              linked_zone_name: i18n.global.t('user.thirdParty.externalLinkDevice')
            }
          }
        }
        promisesNames.push(Device.insertOrUpdate({ data: deviceWithName.device }));
      });

      await Promise.all(promisesNames);
    }
    log.success('GetDevicesThirdParty');
    return extConfigUrl;
  };

  get getZoneIcon() {
    let icon = ''

    switch(this.thermostat_type) {
      case 'blueface':
      case 'bluefacezero':
        icon = 'icon_blueface';
        break;
      case 'literadio':
      case 'litecable':
      case 'lite':
        icon = 'icon_lite';
        break;
      case 'thinkradio':
      case 'thinkcable':
        icon = 'icon_think';
        break;
      default:
    }

    return icon;
  };

  /**
   *
   * @param {string} macBLE - Central or webserver BLE id
   * @param {string} zoneID - Zone number
   * @param {string} systemID - System number
   */
  // static getZoneOutputs = async (macBLE, zoneID, systemID) => {
  //   const zone = Zone.query()
  //     .where('zone_number', zoneID)
  //     .where((_record, query) => {
  //       query.where('system_number', systemID)
  //     }).first();

  //   const mockData = apiBleMocks.get_outputs_available;

  //   await Zone.insertOrUpdate({data: {
  //     id: zone.id,
  //     ...mockData
  //   }});
  // }



}

export default Zone;
