/* eslint-disable camelcase */
import ApiService from 'Core/services/api.service';
import UnitsError from 'Units/services/errors.service';
import Group from 'Units/models/Group.model';
import CONSTANTS from 'Units/constant';
import DemoService from 'Core/services/demo.service';
import User from 'Auth/models/User';
import store from 'Core/store/store';

const InvitedService = {

  async getAll(installationID){
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''

    try {

      const invitedUsers = [];
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getInvited(installationID);
      } else {
        response = await ApiService.get(`${admin}installations/${installationID}/users`);
      }


      response.data.forEach( invitedUserData=> {

        const confirmed = invitedUserData.installation_data.confirmed === undefined;


        const invitedUser = {
          id: invitedUserData.user_id,
          name: invitedUserData.data !== undefined? invitedUserData.data.name : '',
          lastName: invitedUserData.data ? invitedUserData.data.lastName : '',
          email: invitedUserData.email,
          confirmed,
          commercial: invitedUserData.data ? invitedUserData.data.commercial : false,
          toc: invitedUserData.data ? invitedUserData.data.toc : false,
          added_at: invitedUserData.installation_data.added_at,
          type: invitedUserData.installation_data.access_type
        };

        invitedUsers.push(invitedUser);
      });

      return invitedUsers;

    } catch( error ) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async getPermittedDevices(installationID, userID) {
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''

    try{
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getInstallation(`${installationID}`);
      } else {
        response = await ApiService.get(`${admin}installations/${installationID}/user/${userID}`);
      }

      const permittedDevices = response.data.groups;

      return permittedDevices;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Invites a new user to an installation.
   *
   * @param {String} installationID
   */
  async inviteUser(installationID, email, lang, permittedDevices, type = CONSTANTS.USER_TYPE.BASIC, hidden = false){
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''
    //
    // Procesamos los datos en función del type de usuario
    //
    let permitted_devices = []

    if(type === CONSTANTS.USER_TYPE.BASIC){
      //
      // En este caso permittedDevices es un array de objetos con id de grupo y de device
      //
      const result = [];
      try{
        const groups = await Group.all();


        groups.forEach( group => {
          //
          // Primero filtramos los devices con la misma id de grupo
          // Después mapeamos el objeto para devolver sólo las ids de device
          //
          const devicesFromGroup = permittedDevices.filter(dev =>{
            return dev.group_id === group.id
          }).map( item => {
            return item.device_id
          });


          result.push({
            group_id: group.id,
            device_ids: devicesFromGroup
          });
        });

      } catch( error ) {
        console.log(error);
        throw new UnitsError('Error obteniendo grupos');
      }


      //
      // Del resultado, si no tengo zonas en un grupo lo filtro
      //
      try{
        console.log(result);
        permitted_devices = result.filter( item => { return item.device_ids.length > 0});
      }catch(error){
        throw new UnitsError('Error filtrando los datos');
      }

    } else if (type === CONSTANTS.USER_TYPE.ADVANCED) {
      //
      // En este caso permittedDevices traerá un array con ids de grupos
      //
      permittedDevices.forEach( groupID => {
        permitted_devices.push({
          group_id: groupID
        });
      });
    }

    const requestData = {
      email,
      lang,
      type,
      permitted_devices,
      hidden
    }

    console.log(requestData);
    try {
      if (!store.getters.getIsDemo) {
        await ApiService.post(`${admin}installations/${installationID}/users`, requestData);
      }

      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Can be used for changing a user's permissions in an installation (only admin user). It cannot be used in itself
   *
   * @param {String} installationID
   * @param {String} userID
   */
  async editUser(installationID, userID, email, lang, permittedDevices, type = CONSTANTS.USER_TYPE.BASIC, hidden = false){
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''

    //
    // Procesamos los datos en función del type de usuario
    //
    let permitted_devices = []

    if(type === CONSTANTS.USER_TYPE.BASIC){
      //
      // En este caso permittedDevices es un array de objetos con id de grupo y de device
      //
      const result = [];
      try{
        const groups = await Group.all();


        groups.forEach( group => {
          //
          // Primero filtramos los devices con la misma id de grupo
          // Después mapeamos el objeto para devolver sólo las ids de device
          //
          const devicesFromGroup = permittedDevices.filter(dev =>{
            return dev.group_id === group.id
          }).map( item => {
            return item.device_id
          });


          result.push({
            group_id: group.id,
            device_ids: devicesFromGroup
          });
        });

      } catch( error ) {
        console.log(error);
        throw new UnitsError('Error obteniendo grupos');
      }


      //
      // Del resultado, si no tengo zonas en un grupo lo filtro
      //
      try{
        console.log(result);
        permitted_devices = result.filter( item => { return item.device_ids.length > 0});
      }catch(error){
        throw new UnitsError('Error filtrando los datos');
      }

    } else if (type === CONSTANTS.USER_TYPE.ADVANCED) {
      //
      // En este caso permittedDevices traerá un array con ids de grupos
      //
      permittedDevices.forEach( groupID => {
        permitted_devices.push({
          group_id: groupID
        });
      });
    }

    const requestData = {
      email,
      lang,
      type,
      permitted_devices,
      hidden
    }

    console.log(requestData);
    try {
      if (!store.getters.getIsDemo) {
        await ApiService.patch(`${admin}installations/${installationID}/user/${userID}`, requestData);
      }

      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Delete a user from a installation
   *
   * @param {String} installationID
   * @param {String} userID
   */
  async deleteUser(installationID, userID){
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''

    try {
      if (!store.getters.getIsDemo) {
        await ApiService.delete(`${admin}installations/${installationID}/user/${userID}`);
      }

      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Accepts an invitation to an installation.
   *
   * @param {String} installationID
   * @param {String} userID
   */
  async acceptInvitation(installationID, userID){
    try {

      await ApiService.patch(`installations/${installationID}/user/${userID}/invitation`);

      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Remove an invitation to an installation.
   *
   * @param {String} installationID
   * @param {String} userID
   */
  async removeInvitation(installationID, userID){
    const user = User.query().first();

    const admin = user.admin_mode === true ? 'admin/' : ''

    try {
      if (!store.getters.getIsDemo) {
        await ApiService.delete(`${admin}installations/${installationID}/user/${userID}/invitation`);
      }

      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  }



}

export default InvitedService;
