/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'face_id': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_1836_39)" _fill="#3B4E52"><path pid="0" d="M1.646 8.777V5.211c0-2.014 1.551-3.565 3.565-3.565h3.566a.823.823 0 100-1.646H5.211C2.288 0 0 2.288 0 5.211v3.566a.823.823 0 101.646 0zM30.354 8.777V5.211c0-2.014-1.551-3.565-3.565-3.565h-3.566a.823.823 0 110-1.646h3.566C29.712 0 32 2.288 32 5.211v3.566a.823.823 0 11-1.646 0zM1.646 23.223v3.566c0 2.014 1.551 3.565 3.565 3.565h3.566a.823.823 0 110 1.646H5.211C2.288 32 0 29.712 0 26.789v-3.566a.823.823 0 111.646 0zM30.354 23.223v3.566c0 2.014-1.551 3.565-3.565 3.565h-3.566a.823.823 0 100 1.646h3.566C29.712 32 32 29.712 32 26.789v-3.566a.823.823 0 10-1.646 0zM8.702 12.085v2.287c0 .474.358.857.8.857.441 0 .8-.383.8-.857v-2.287c0-.473-.359-.857-.8-.857-.442 0-.8.384-.8.857zM21.895 12.085v2.287c0 .474.358.857.8.857.442 0 .8-.383.8-.857v-2.287c0-.473-.358-.857-.8-.857-.442 0-.8.384-.8.857zM10.373 23.633c1.56 1.457 3.45 2.192 5.627 2.192 2.177 0 4.067-.735 5.627-2.192a.842.842 0 10-1.149-1.231C19.231 23.565 17.753 24.14 16 24.14c-1.753 0-3.232-.575-4.478-1.738a.842.842 0 00-1.15 1.231zM16 12.07v5.895c0 .377-.184.561-.563.561h-.56a.842.842 0 100 1.684h.56c1.308 0 2.247-.937 2.247-2.245V12.07a.842.842 0 00-1.684 0z"/></g><defs><clipPath id="clip0_1836_39"><path pid="1" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
