import { ref } from 'vue';

export const useScroll = () => {
  let scrolled = ref(false);

  const handleScroll = (event) => {
    if(event.target !== undefined) {
      scrolled.value = event.target.scrollTop > 0;
    }
  }

  return { handleScroll, scrolled }
}
