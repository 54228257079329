import { Model } from '@vuex-orm/core';
import InvitedService from 'Units/services/invited.service';

/**
 * @typedef InvitedUser
 *
 */
export default class InvitedUser extends Model {

  static entity = 'invitedusers';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.attr(null).nullable(),
      name: this.string(''),
      lastName: this.string(''),
      lang: this.string('en'),
      email: this.string(''),
      confirmed: this.boolean(null).nullable(),
      toc: this.boolean(null).nullable(),
      added_at: this.string(null).nullable(),
      avatar: this.attr(null).nullable(),
      permited_devices: this.attr(null).nullable(),
      type: this.string(null).nullable(),
      hidden: this.boolean(false)
    };
  }

  static getAll = async installationID => {
    const invitedUsers = await InvitedService.getAll(installationID);
    await this.create({ data: invitedUsers });
    return invitedUsers;
  }

  static getPermittedDevices = async (installationID, userID) => {
    return await InvitedService.getPermittedDevices(installationID, userID);
  }

   /**
   * Actualiza el modelo (sin enviar al servidor)
   *
   * @param {String} param
   * @param {*} value
   */
  updateParam = async (param, value) => {
    const userID = this.id;
    const data = {};
    data[param] = value;

    await InvitedUser.update({
      where: userID,
      data
    });
  }

  static inviteUser = async (installationID, email, lang = 'en', permittedDevices, type) => {
    await InvitedService.inviteUser(installationID, email, lang, permittedDevices, type);
    // Se añade un timeout para que de tiempo a que el servidor procese y solucionar incidencia 8195
    setTimeout(async() => await this.getAll(installationID) , 300);
  }

  static editUser = async (installationID, userID, email, lang = 'en', permittedDevices, type) => {
    await InvitedService.editUser(installationID, userID, email, lang, permittedDevices, type);
    await this.getAll(installationID);
  }

  static acceptInvitation = async (installationID, userID) => {
    await InvitedService.acceptInvitation(installationID,userID);
  }

  static removeInvitation = async (installationID, userID) => {
    await InvitedService.removeInvitation(installationID,userID);
    await InvitedUser.delete(userID);
  }

  static deleteUser = async (installationID, userID) => {
    await InvitedService.deleteUser(installationID,userID);
    await InvitedUser.delete(userID);
  }
}
