import { createI18n } from 'vue-i18n'
import CONSTANTS from 'Core/constant';
import StorageService from 'Core/services/storage.service';
import AppError from 'Core/services/errors.service';
import es from "@/locales/es.json"
import en from "@/locales/en.json"
import fr from "@/locales/fr.json"
import it from "@/locales/it.json"
import pt from "@/locales/pt.json"
import de from "@/locales/de.json"
import pl from "@/locales/pl.json"
import el from "@/locales/el.json"
import tr from "@/locales/tr.json"

/**
 * LANGUAGES
 *
 * @constant {Object} - Listado de idiomas disponibles
 */
const LANGUAGES = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
  pl: 'Polska',
  el: 'Ελληνική',
  tr: 'Türkçe'
};

/**
 * Devuelve un objeto con el listado de idiomas
 * a partir de un string con los códigos de los idiomas
 * separados por comas
 *
 * @param {String} list - Listado de idiomas
 * @return {Object}
 */
const getListLanguages = list => {
  const languagesList = list.split(',');
  const languagesObject = {};

  languagesList.forEach(lang => {
    const language = lang.trim();
    if (LANGUAGES[language]) languagesObject[language] = LANGUAGES[language];
  });

  return languagesObject;
};

/**
 * Inicializa i18n
 *
 * @return {VueI18n}
 */
const getSettingI18n = () => {
  // Obtengo el idioma de la aplicación
  const localStorageLanguage = StorageService.getItem('language');
  const navigatorLanguage = navigator.language.split('-');
  const defaultLanguage = CONSTANTS.LANGUAGE.DEFAULT;
  const locale = localStorageLanguage || navigatorLanguage[0] || defaultLanguage || 'en';

  // Obtengo el idioma por defecto cuando no se encuentre una traducción
  const fallbackLocale = CONSTANTS.LANGUAGE.FALLBACK || 'en';

  // console.log('messages: ', messages);
  return createI18n({ legacy: false, locale, fallbackLocale, fallbackWarn: false, missingWarn: false, warnHtmlMessage: false, messages: {es, en, fr, it, pt, de, pl, el, tr} });
};

const i18n = getSettingI18n();

/**
 * Cambia el idioma de la aplicación
 *
 * @param {String} lang - Código del idioma seleccionado
 * @return {String} - Código del idioma seleccionado
 * @throws {AppError} - langNotFound
 */
const setLanguage = (lang = i18n.global.locale.value) => {
  document.querySelector('html').setAttribute('lang', lang);

  // Si el idioma es el mismo que está seleccionado, no hago nada
  if (i18n.global.locale.value === lang) return lang;

  // Compruebo si el nuevo idioma se encuentra entre los idiomas disponibles
  if (!getListLanguages(CONSTANTS.LANGUAGES)[lang]) {
    throw new AppError('langNotFound');
  }

  // Actualizo el idioma
  i18n.global.locale.value = lang;
  StorageService.setItem('language', lang);

  // Actualizo idioma para el API de Google
  // const scripts = document.getElementsByTagName('script');
  // for (let index = 0; index < scripts.length; index += 1) {
  //   if (scripts[index].src.startsWith('https://maps.googleapis.com')) {
  //     const key = CONSTANTS.CONNECT.GOOGLEMAP.KEY; // eslint-disable-line
  //     const src = `${CONSTANTS.CONNECT.GOOGLEMAP.API_URL}js?key=${key}&language=${lang}&libraries=places`;
  //     scripts[index].src = src;
  //   }
  // }

  return lang;
};

export { LANGUAGES, getListLanguages, setLanguage };

export default i18n;
