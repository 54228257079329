import { Model } from '@vuex-orm/core';

export default class Integration extends Model {

  static entity = 'integrations'

  /** *************************************************************
  * CAMPOS
  ************************************************************** */
   static fields() {
    return {
      id: this.attr(undefined).nullable(),
      description: this.attr(undefined).nullable(),
      meta: this.attr(undefined).nullable(),
      params: this.attr(undefined).nullable()
    }
  }

}
