import { ref, computed, watch, nextTick } from 'vue';
import { COLORS } from '../constant';
import { kebabize } from '../utils/utils';
import { useStore } from 'vuex';
import User from 'Auth/models/User';
import StorageService from 'Core/services/storage.service';
import { detectInsets } from 'Core/utils/utils';


export const useSkins = () => {

  const store = useStore();

  let theme = ref({})

  const logo = computed(() => store.getters.getLogo);

  const setThemeColors = (colors) => {
    theme.value = colors;
  }

  const user = computed(() => User.query().first());

  const hasSkinActive = computed(() => user?.value?.skin?.enabled === true); // Comprueba skin activo a nivel del propio SKIN (globalmente)

  const isSkinEnabled = computed(() => user?.value?.isSkinEnabled === true); // Comprueba skin activo a nivel de usuario

  const isSkinAvailable = computed(() => user?.value && user.value.hasSkinsAvail === true);

  const getLogo = computed(() => logo?.value?.logo || 'airzone.svg');

  const getSidenavLogo = computed(() => logo?.value?.sidenavLogo || logo?.value?.logo || 'airzoneWhite.svg');

  const getAuthLogo = computed(() => logo?.value?.authLogo || logo?.value?.logo || 'logo-airzone-cloud.svg');

  const getByAirzoneLogoColor = computed(() => theme?.value?.byAirzoneDarkLogo === true ? '#52606D' : '#FFFFFF');

  const getUserLocalSkin = () => {
    const skinString = StorageService.getItem('skin');
    return skinString ? JSON.parse(skinString) : null;
  }

  const setUserLocalSkin = ({userId, data, isSkinEnabled} ) => {
    let updateSkinData = {
      user_id: userId,
      ...data
    }
    if(isSkinEnabled !== undefined) updateSkinData.isSkinEnabled = isSkinEnabled;

    StorageService.setItem('skin', JSON.stringify(updateSkinData));
  }

  const clearUserLocalSkin = () => {
    StorageService.removeItem('skin');
  }


  const checkSkins = async () => {
    //
    // Comprobamos que el usuario está logueado
    //
    if(user?.value) {
      //
      // Comprobamos si el usuario tiene un skin activo y lo aplicamos
      //
      if(user?.value?.skin) {
        const data = user.value.skin.data
        if(data && hasSkinActive.value === true && isSkinEnabled.value === true) {
          applySkin(data);
        } else {
          applySkin({});
        }
        setUserLocalSkin({userId: user?.value?.id, data: user?.value?.skin, isSkinEnabled: isSkinAvailable.value});
      } else {
        console.log("No hay skin en user");
        applySkin({});
      }

    //
    // En este caso el usuario no está logueado. Tenemos que comprobar si hay un skin en el storage
    //
    } else {
      //
      // Si no tengo user (no tenemos sesión iniciada) comprobamos si hay un skin en el storage
      //
      const skin = getUserLocalSkin();
      console.log("Skin en storage", skin);
      if(skin?.data && skin?.enabled === true && skin?.isSkinEnabled === true) {
        const { data } = skin;

       applySkin(data);
      } else {
        // Si no hay skin aplicamos el default
        console.log("No hay skin en storage - limpiamos");
        applySkin({});
      }
    }
  }

  const applySkin = ({logo, sidenavLogo, authLogo, navLogo, ...data}) => {

    const logos = {
      logo: logo,
      sidenavLogo: sidenavLogo || logo,
      authLogo: authLogo || logo,
    }

    // primero limpiamos el style de html
    document.documentElement.removeAttribute('style');

    store.commit('SET_LOGO', logos);

    if(data && Object.keys(data).length > 0) {
      theme.value = Object.assign(theme.value, data);

      Object.keys(theme.value).forEach((key) => {
        const kebabKey = kebabize(key);
        document.documentElement.style.setProperty(`--color-${kebabKey}`, theme.value[key]);
      });
    }

    nextTick(() => {
      detectInsets();
    })

  }

  return {
    theme,
    logo,
    getLogo,
    getSidenavLogo,
    getAuthLogo,
    applySkin,
    checkSkins,
    hasSkinActive,
    isSkinAvailable,
    isSkinEnabled,
    getUserLocalSkin,
    setUserLocalSkin,
    clearUserLocalSkin
  }
}
