import { onMounted, onUnmounted } from 'vue';

export const useBackButton = (back, ...args) => {

  const backButton = (e) => {
    e.preventDefault();
    if(typeof back === 'function') {
      console.log("Función back definida, agregada a backbutton");
      back.call(this, ...args);
    } else {
      console.log("No hay función backbutton definida");
    }
  }

  onMounted(() => {
    document.addEventListener('backbutton', backButton, false);
  })

  onUnmounted(() => {
    // console.log("Removing event backbutton");
    document.removeEventListener('backbutton', backButton, false);
  })

  return { backButton }
}
