/**
* @typedef {import('@airzone/data-interface/data-interface').DataInterface} DataInterface
*/
import { DataInterface } from '@airzone/data-interface';


const modelToBle = {

  schedule: (data, schedConf, wsType = 'ws_az', opts = null) => {

    // if(opts !== null) {
    //   // Si tenemos las unitdades en opts, agregamos a schedConf las units de máquina
    //   if(opts.units !== undefined) {
    //     schedConf.units = opts.units;
    //   }
    // }

    console.log("MODEL TO BLE SCHEDULE", data, wsType, schedConf, opts);

    const ifWebserver = DataInterface.Webserver(wsType)

    const startConf = data.start_conf !== undefined ? {...data.start_conf} : null;

    console.log(startConf);

    if(startConf) {

      const notAllowedParams = ['days', 'hour', 'minutes', 'start_month', 'start_day', 'end_month', 'end_day']
      const params = Object.keys(startConf)
      .filter(key => !notAllowedParams.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: startConf[key]
        }
      }, {});

      const conf = Object.keys(startConf)
      .filter(key => notAllowedParams.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: startConf[key]
        }
      }, {});

      // console.log("FILTERED", params);
      console.log("==== MODEL TO BLE. Input DATA INTERFACE ====");
      console.log("params:", params);
      console.log("wsType:",data.type);
      console.log("schedConf", schedConf);
      console.log("opts", opts);

      const resp = ifWebserver.InstallationSchedWeb2BLE(params, data.type, schedConf, opts)

      return {
        params: resp,
        conf
      };
    }

    return null;

  }
}

export default modelToBle
