/*
**
 * MIXIN: Inlcuye los métodos para desabilitar con aria-hidden todos los elementos
 * detrás de un elemento (un modal, por ejemplo).
 *
 * Uso: Al montar el elemento se llama a disableBackgroundEls pasando su ID
 * Al cerrar el elemento hay que liberar los elementos del background con restoreBackgroundEls
 */

export const useAccesibility = () => {

  const disableBackgroundEls = (elementID) => {
    // Obtener el modal
    const modal = document.getElementById(elementID);
    // Deshabilitamos los elementos de detrás del modal y ponemos todos los tabindex < 0
    document.querySelectorAll(`body > *:not(#${elementID})`).forEach(el => {
      el.setAttribute('aria-hidden', true)
      el.querySelectorAll('[tabindex]').forEach(subEl => {
        subEl.setAttribute('tabindex', subEl.getAttribute('tabindex')-1)
      })
    });
    // Habilitar el modal y sus descendientes
    modal.removeAttribute('aria-hidden');
    modal.querySelectorAll('*:not(.force-aria-hidden)').forEach(el => el.removeAttribute("aria-hidden") );
  }

  const restoreBackgroundsEls = (elementID) => {
    // Rehabilitamos los elementos detrás del modal y devolvemos los tabindex a su valor original.
    document.querySelectorAll(`body > *:not(#${elementID})`).forEach(el => {
      el.removeAttribute('aria-hidden')
      el.querySelectorAll('[tabindex]').forEach(subEl => {
        subEl.setAttribute('tabindex', +subEl.getAttribute('tabindex') + +1)
      })
    });
  }

  return { disableBackgroundEls, restoreBackgroundsEls }
}
