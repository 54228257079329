const { default: AppError } = require("./errors.service");

// Definir el servicio para manejar IndexedDB
class IndexedDBService {

  constructor(dbName, version = 1) {
    this.dbName = dbName;
    this.db = null;
    this.version = version;
  }

  // Método para abrir la conexión con la base de datos
  openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version);

      // Si se requiere una actualización de la estructura de datos o la creación de
      request.onupgradeneeded = event => {
        this.db = event.target.result;
        const version = event.oldVersion;
        // Puedes agregar aquí la lógica para crear tablas en caso de necesitar actualizaciones
        switch(version) {
          case 0:
            // versión 0 significa que el cliente no tiene base de datos. inicialización
            resolve(this.db);
            break;
          case 1:
            break;
          default:
        }
      };

      request.onsuccess = event => {
        this.db = event.target.result;

        this.db.onversionchange = () => {
          this.db.close();
          return reject(new AppError('indexedDbVersionOutdated'));
        };


        return resolve(this.db);
      };

      request.onerror = event=> {
        console.log(`Error al abrir la base de datos: ${event.target.error}`)
        reject(event.target.error);
      };
    });
  }

  // Método para cerrar la conexión con la base de datos
  closeDatabase() {
    if (this.db) {
      this.db.close();
      this.db = null;
    }
  }

  // Método para crear una tabla (SI NO EXISTE)
  createTable(tableName, keyPath) {
    console.log("CREANDO TABLA", tableName, keyPath);
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }

      // Verificar si la tabla ya existe
      if (this.db.objectStoreNames.contains(tableName)) {
        resolve(`La tabla ${tableName} ya existe`);
        return;
      }

      const objectStore = this.db.createObjectStore(tableName, { keyPath });

      objectStore.createIndex(`${keyPath}Index`, keyPath, { unique: true });

      resolve(`Tabla ${tableName} creada con éxito`);
    });
  }

  // Método para agregar un registro a una tabla
  addRecord(tableName, record) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }

      const transaction = this.db.transaction([tableName], 'readwrite');
      console.log("TRANSACTION", transaction);
      const objectStore = transaction.objectStore(tableName);
      console.log("OBJECT STORE", objectStore);

      const request = objectStore.add(record);

      request.onsuccess = () => {
        resolve('Registro agregado con éxito');
      };

      request.onerror = event => {
        console.log(`Error al agregar el registro: ${event.target.error}`)
        reject(event.target.error);
      };
    });
  }

  // Método para consultar registros en una tabla
  getRecords(tableName, key) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }
      let objectStore;
      try {
        const transaction = this.db.transaction([tableName], 'readonly');
        objectStore = transaction.objectStore(tableName);
      } catch(err) {
        reject(new AppError('noIndexDbStoreCreated'));
      }

      const request = key ? objectStore.get(key) : objectStore.getAll();
      request.onsuccess = event => {
        resolve(event.target.result);
      };

      request.onerror = event => {
        console.log(`Error al consultar registros: ${event.target.error}`);
        reject(event.target.error);
      };
    });
  }

  // Método para actualizar un registro en una tabla
  updateRecord(tableName, updatedData) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }
      const transaction = this.db.transaction([tableName], 'readwrite');
      const objectStore = transaction.objectStore(tableName);

      // TODO: Revisar si es necesario parsear el objeto. Antes de actualizar a Vue 3 no era necesario. Pero ahora
      // sin parsear, la librería IDBObjectStore lanza el siguiente error:
      // ### DOMException: Failed to execute 'put' on 'IDBObjectStore': #<Object> could not be cloned. ###
      const request = objectStore.put(JSON.parse(JSON.stringify(updatedData)));

      request.onsuccess = event => {
        resolve(event.target.result);
      };

      request.onerror = event => {
        console.log(`Error al actualizar el registro: ${event.target.error}`)
        reject(event.target.error);
      };
    });
  }

  // Método para eliminar un registro de una tabla
  deleteRecord(tableName, key) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }

      const transaction = this.db.transaction([tableName], 'readwrite');
      const objectStore = transaction.objectStore(tableName);

      const request = objectStore.delete(key);

      request.onsuccess = () => {
        resolve('Registro eliminado con éxito');
      };

      request.onerror = event => {
        console.log(`Error al eliminar el registro: ${event.target.error}`)
        reject(event.target.error);
      };
    });
  }

  deleteTable(tableName) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new AppError('noIndexDbOpen'));
        return;
      }


      const request = this.db.deleteObjectStore(tableName);

      request.onsuccess = () => {
        console.log(`Almacén eliminado con éxito: ${tableName}`)
        resolve(true)
      }

      request.onerror = event => {
        console.log(`Error al eliminar el almacén: ${event.target.error}`)
        reject(event.target.error);
      }
    });
  }
}


export default IndexedDBService;
