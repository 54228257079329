import AuthError from 'Auth/services/errors.service';
import ApiService from 'Core/services/api.service';
import DemoService from 'Core/services/demo.service';
import log from 'Core/services/log.service';
import i18n from 'Core/services/language.service';
import thirdPartyType from 'Auth/utils/getTypeThirdPartyError';
import store from 'Core/store/store';

const ThinkPartyService = {
  /**
   * Obtiene las cuentas de terceros vinculadas al usuario
   *
   * @param {String} type - Tipo de dispositivo de tercero (Honeywell, Ecobee, Nest)
   * @return {Promise<string>} - URI para vincular la cuenta del dispositivo de tercero con el usuario
   */
  async getURIThirdParty(type) {
    try {
      let response;
      if (store.getters.getIsDemo) {
        console.log("is demo", store.getters.getIsDemo);
      } else {
        response = await ApiService.get(`/3rdp/${type}/auth_uri`);
      }

      return response.data.redirect_uri
    } catch (error) {
      console.log(error);
      throw new AuthError(error);
    }
  },

  /**
   * Obtiene las cuentas de terceros vinculadas al usuario
   *
   * @param {String} type - Tipo de dispositivo de tercero (Honeywell, Ecobee, Nest)
   * @return {Promise<objecto>} - Objecto con los datos de los dispositivos de terceros vinculados con el usuario
   */
  async getDevicesThirdParty(type) {
    try {
      let response;
      // console.log(type);
      if (store.getters.getIsDemo) {
        response = await DemoService.getThirdPartyLinked();
      } else {
        response = await ApiService.get(`/3rdp/${type}/devices`);
      }

      let extConfigUrl = null
      if (response && response.data && response.data.ext_config_url) {
        extConfigUrl = response.data.ext_config_url
      }
      const linkedThirdParty = []

      if (response && response.data && response.data.devices) {
        response.data.devices.forEach( account => {
          const data = {}
          data.third_party_type = type
          data.third_party_name = account.ext_name
          data.third_party_id = account.ext_device_id
          data.ext_config_url = extConfigUrl

          if (account.link_status) {
            data.installation_id = account.link_status.installation_id
            data.id = account.link_status.device_id
            data.device_type = account.link_status.device_type
            data.webserver_id = account.link_status.ws_id
            data.third_party_allowed = account.link_status.is_allowed
          }
          linkedThirdParty.push(data)
        })
      }

      log.success('thirdPartyService/getDevicesThirdParty');
      // console.log('Formatted response: ', linkedThirdParty);

      return { extConfigUrl, linkedThirdParty }
    } catch (error) {
      // console.log(error.response);

      if (error.response && error.response.status === 403 && error.response.data._id === 'oauthUnlinked') {
        // Set the error by third party type
        // console.log('GET ERROR BY TYPE: ', type)
        const id = thirdPartyType.getErrorThirdPartyType(type);
        throw new AuthError(id);
      } else {
        throw error;
      }
    }
  },

  /**
   * Obtiene los nombres de instalación, grupo y zona vinculada al dispositivo de tercero
   *
   * @param {Object} devices - Objeto con los id de instalación y dispositivo para obtener los nombres de grupo, instalación y zona
   * @return {Promise<Array>} - Array con los datos de instalación, grupo y dispositivo vinculados al dispositivo de tercero
   */
  async getInstallationThirdParty(devices) {
    try {
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getThirdPartyLinkedInstallation();
      } else {
        response = await ApiService.post('/installations/device_names', { devices });
      }

      const array = []
      const position = 0;

      response.data.forEach( item => {
        const data = { device: {}, group: {}, installation: {} }

        data.installation.id = item.installation_id
        data.installation.name = item.name
        data.installation.access_type = item.access_type
        data.group.installation_id = item.installation_id
        data.group.id = item.group_id
        data.group.name = item.group_name && item.group_name !== '' ? item.group_name :  `${i18n.global.t('installations.group')} ${position + 1}`
        data.device.id = item.device.device_id
        data.device.type = 'ZONE'
        data.device.device_type = item.device.type
        data.device.installation_id = item.installation_id
        data.device.group_id = item.group_id
        data.device.webserver_id = item.device.ws_id
        data.device.name = item.device.name && item.device.name !== '' ? item.device.name : `${i18n.global.t('installations.zone')} ${position + 1}`
        data.device.linked_zone_name =  `${data.installation.name} > ${data.group.name} > ${data.device.name}`;
        array.push(data)
      })

      log.success('thirdPartyService/getInstallationThirdParty');
      // console.log('Formatted response: ', array);

      return array
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Obtiene los nombres de instalación, grupo y zona vinculada al dispositivo de tercero
   *
   * @param {String} type - Tipo de dispositivo de tercero (Honeywell, Ecobee, Nest)
   * @return {Promise<Object>} - Objeto con la respuesta del backend
   */
  async deleteThirdPartyAccount(type) {
    try {
      let response
      if (store.getters.getIsDemo) {
        response = true
      } else {
        response = await ApiService.delete(`/3rdp/${type}/account/`);
      }
      return response
    } catch (error) {
      console.log(error);
      throw new AuthError(error);
    }
  },

  /**
   * Borra el vinculo de la zona con el dispositivo de tercero
   *
   * @param {String} type - Tipo de dispositivo de tercero (Honeywell, Ecobee, Nest)
   * @param {String} deviceId - Identificador de la zona a desvincular
   * @return {Promise<Object>} - Objeto con la respuesta del backend
   */
  async removeLinkZone(type, deviceId, installationId) {
    try {
      let response
      if (store.getters.getIsDemo) {
        response = true
      } else {
        response = await ApiService.delete(`/3rdp/${type}/link/${deviceId}?installation_id=${installationId}`);
      }
      return response
    } catch (error) {
      console.log(error);
      throw new AuthError(error);
    }
  },

  /**
   * Asigna la vinculación de la zona al dispositivo de tercero
   *
   * @param {String} type - Tipo de dispositivo de tercero (Honeywell, Ecobee, Nest)
   * @return {Promise<Object>} - Objeto con la respuesta del backend
   */
  async setLinkZone(type, installationID, deviceID, extDeviceId) {
    try {
      let response
      if (store.getters.getIsDemo) {
        response = true
      } else {
        const data = {
          device_id: deviceID,
          ext_device_id: extDeviceId,
          installation_id: installationID,
        }

        response = await ApiService.post(`/3rdp/${type}/link/`, data);
      }
      return response
    } catch (error) {
      console.log(error);
      throw new AuthError(error);
    }
  },
};

export default ThinkPartyService;
