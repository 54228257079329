/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat-lock': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M15.15 5.217c5.478 0 9.933 4.455 9.933 9.933 0 .286-.013.57-.036.85h-1.612c.029-.279.043-.563.043-.85 0-4.592-3.736-8.328-8.328-8.328-4.592 0-8.328 3.736-8.328 8.328 0 4.592 3.736 8.328 8.328 8.328.287 0 .571-.014.85-.043v1.612c-.28.023-.564.036-.85.036-5.478 0-9.933-4.455-9.933-9.933s4.455-9.933 9.933-9.933zm0-1.004a.802.802 0 01-.802-.802V.802a.802.802 0 011.604 0v2.609c0 .443-.36.802-.802.802zm0 26.087a.802.802 0 01-.802-.802v-2.609a.802.802 0 011.604 0v2.609c0 .443-.36.802-.802.802zM9.28 5.786a.801.801 0 01-.696-.401L7.28 3.125a.804.804 0 011.39-.801l1.305 2.259a.803.803 0 01-.695 1.203zm-4.297 4.297a.785.785 0 01-.4-.108l-2.26-1.304a.801.801 0 11.802-1.39l2.26 1.305a.802.802 0 01-.402 1.497zm-1.572 5.87H.802a.802.802 0 010-1.605h2.609a.802.802 0 010 1.604zm26.087 0h-2.609a.802.802 0 010-1.605h2.609a.802.802 0 010 1.604zM2.725 23.125a.801.801 0 01-.403-1.497l2.26-1.304a.803.803 0 01.803 1.39l-2.259 1.303a.794.794 0 01-.401.108zm22.592-13.043a.801.801 0 01-.402-1.497l2.259-1.304a.802.802 0 11.802 1.389l-2.258 1.303a.786.786 0 01-.4.109zM7.977 28.377a.802.802 0 01-.695-1.203l1.304-2.259a.802.802 0 011.39.802l-1.305 2.26a.8.8 0 01-.695.4zM25.703 18c1.854 0 3.358 1.535 3.358 3.42v1.764c.739.002 1.34.54 1.34 1.323v4.63c0 .751-.601 1.363-1.34 1.363H22.34c-.739 0-1.34-.612-1.34-1.363v-4.63c0-.817.601-1.321 1.34-1.321V21.42c0-1.886 1.51-3.421 3.364-3.421zm0 7.083a.833.833 0 00-.834.834V28a.833.833 0 001.667 0v-2.083a.833.833 0 00-.833-.834zm0-5.288c-.804 0-1.459.639-1.459 1.423v1.975h2.917v-1.975c0-.784-.654-1.423-1.458-1.423zM22.725 2.03a.802.802 0 01.294 1.096l-1.305 2.26a.804.804 0 01-1.096.293.802.802 0 01-.293-1.096l1.304-2.26a.8.8 0 011.096-.293z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
