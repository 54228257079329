/**
* Método que devuelve el parámetro de setpoint de una zona asociada a un modo
*/

const getSetpointParam = mode => {

  let setPoint = '';

  switch (mode) {
      case 'heat':
      case 'heat_air':
      case 'heat_radiant':
      case 'heat_comb':
        setPoint = 'setpoint_air_heat';
        break;
      case 'emergencyHeat':

        setPoint = 'setpoint_air_emerheat';
        break;
      case 'cool':
      case 'cool_air':
      case 'cool_radiant':
      case 'cool_comb':
        setPoint = 'setpoint_air_cool';
        break;
      case 'dry':
        setPoint = 'setpoint_air_dry';
        break;
      case 'fan':
        setPoint = 'setpoint_air_vent';
        break;
      case 'stop':
        setPoint = 'setpoint_air_stop';
        break;
      case 'auto':
        // TODO: Doble setpoint
        // if(double_sp){
        //   setPoint = {heat: 'setpoint_air_heat', cool: 'setpoint_air_cool'}
        // } else {
        setPoint = 'setpoint_air_auto';

        break;
      default:
        setPoint = 'setpoint_air_auto';
    }

    return setPoint;
}

export default getSetpointParam;
