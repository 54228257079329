import { parseString, parseBoolean, parseNumber } from 'Core/utils/sanitaze.utils';
import { hasProp } from 'Core/utils/validate.utils';
import CONSTANTS from 'Units/constant';
import bleToModel from 'Units/interfaces/bleToModel.interface';

/**
 * Da formato a los datos de un webserver
 *
 * @param {String} webserverID - Id del grupo
 * @param {Object} webserverData - Datos del webserver
 * @param {String} webserverName - Nombre del webserver
 * @return {WebServer} - Datos formateados de un webserver
 */
const sanitizeWebserver = (webserverID, webserverData, webserverName) => {
  try {
    const webserver = {};

    if(webserverName) {
      webserver.name = webserverName;
      webserver.altherma = CONSTANTS.ALTHERMA.some(prefix => webserverName.toUpperCase().startsWith(prefix));
       // TODO: implementar mejor modo de definir tipo según prefijo
      // const prefix = CONSTANTS.DEVICES_PREFIXES.filter(prefixType => webserver.name.toUpperCase().startsWith(prefixType))
      // webserver.type = webserverName.toUpperCase().startsWith('AZ8CB') ? CONSTANTS.DEVICE_TYPE.az_8cb : CONSTANTS.DEVICE_TYPE.aidoo;
    }


    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'mac')) {
      webserver.id = parseString(webserverData.general.mac);
      webserver.mac = parseString(webserverData.general.mac);
    }
    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'wsver')) {
      webserver.wsver = parseString(webserverData.general.wsver);
    }
    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'mver')) {
      webserver.mver = parseString(webserverData.general.mver);
    }
    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'ble_version')) {
      webserver.ble_version = webserverData.general.ble_version;
    }
    if (hasProp(webserverData, 'network') && hasProp(webserverData.network, 'ip')) {
      webserver.ip = parseString(webserverData.network.ip);
    }

    if (hasProp(webserverData, 'network') && hasProp(webserverData.network, 'dhcp')) {
      webserver.dhcp = parseBoolean(webserverData.network.dhcp);
    }

    if (hasProp(webserverData, 'network') && hasProp(webserverData.network, 'gateway')) {
      webserver.dns = parseString(webserverData.network.dns);
    }

    if (hasProp(webserverData, 'network') && hasProp(webserverData.network, 'mask')) {
      webserver.mask = parseString(webserverData.network.mask);
    }

    if (hasProp(webserverData, 'network') && hasProp(webserverData.network, 'dns')) {
      webserver.gateway = parseString(webserverData.network.gateway);
    }

    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'mstatus')) {
      webserver.isZoneConnectedToUnit = parseBoolean(webserverData.general.mstatus);

      // En los AIDOO antiguos el type lo sabemos por el parámetro mstatus
      webserver.type = CONSTANTS.DEVICE_TYPE.aidoo;
    } else {
      // En los WS antiguos, si no recibimos mstatus sabemos que es airzone
      webserver.type = CONSTANTS.DEVICE_TYPE.az_ws;
    }

    // A partir de BLE_VERSION 2 sabemos el type por el parámetro ws_type. En caso de recibirlo
    // sobreescribimos el parámetro
    if (hasProp(webserverData, 'general') && hasProp(webserverData.general, 'ws_type')) {
      webserver.type = bleToModel.getWebserverType(webserverData.general.ws_type);
    }

    if(webserver.type === CONSTANTS.DEVICE_TYPE.az_8cb) {
      webserver.isUserAllow = false;
    }

    if(hasProp(webserverData, 'wifi')) {
      webserver.isWifi = true;
    }

    if (hasProp(webserverData, 'wifi') && hasProp(webserverData.wifi, 'ssid')) {
      webserver.stat_ssid = decodeURIComponent(webserverData.wifi.ssid);
    }
    if (hasProp(webserverData, 'wifi') && hasProp(webserverData.wifi, 'rssi')) {
      webserver.stat_rssi = parseNumber(webserverData.wifi.rssi);
    }
    if (hasProp(webserverData, 'wifi') && hasProp(webserverData.wifi, 'status')) {
      const status = parseNumber(webserverData.wifi.status);
      webserver.network_status = status;
      webserver.isConnectedToNetwork = status === CONSTANTS.WIFI_STATUS.WIFI_CONNECTED || status === CONSTANTS.WIFI_STATUS.CLOUD_CONNECTED;
      webserver.isConnectedToCloud = status === CONSTANTS.WIFI_STATUS.CLOUD_CONNECTED;
    }

    if(hasProp(webserverData, 'eth')) {
      webserver.isEthernet = true;
    }

    if (hasProp(webserverData, 'eth') && hasProp(webserverData.eth, 'status')) {
      const status = parseNumber(webserverData.eth.status);
      webserver.network_status = status;
      webserver.isConnectedToNetwork = status === CONSTANTS.WIFI_STATUS.WIFI_CONNECTED || status === CONSTANTS.WIFI_STATUS.CLOUD_CONNECTED;
      webserver.isConnectedToCloud = status === CONSTANTS.WIFI_STATUS.CLOUD_CONNECTED;
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'modbusaddr')){
      webserver.modbusaddr = parseNumber(webserverData.extrainfo.modbusaddr);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'modbusspeed')){
      webserver.modbusspeed = parseNumber(webserverData.extrainfo.modbusspeed);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'modbusparity')){
      webserver.modbusparity = parseNumber(webserverData.extrainfo.modbusparity);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'modbusbitstop')){
      webserver.modbusbitstop = parseNumber(webserverData.extrainfo.modbusbitstop);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetid')){
      webserver.bacnetid = parseNumber(webserverData.extrainfo.bacnetid);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetmaxframes')){
      webserver.bacnetmaxframes = parseNumber(webserverData.extrainfo.bacnetmaxframes);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetmaxnum')){
      webserver.bacnetmaxnum = parseNumber(webserverData.extrainfo.bacnetmaxnum);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacneton')){
      webserver.bacneton = parseNumber(webserverData.extrainfo.bacneton);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetmac')){
      webserver.bacnetmac = parseNumber(webserverData.extrainfo.bacnetmac);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetspeed')){
      webserver.bacnetspeed = parseNumber(webserverData.extrainfo.bacnetspeed);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpioalpha')){
      webserver.gpioalpha = parseNumber(webserverData.extrainfo.gpioalpha);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpiobeta')){
      webserver.gpiobeta = parseNumber(webserverData.extrainfo.gpiobeta);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpiofanspeed')){
      webserver.gpiofanspeed = parseNumber(webserverData.extrainfo.gpiofanspeed);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpiominruntime')){
      webserver.gpiominruntime = parseNumber(webserverData.extrainfo.gpiominruntime);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpiostoptime')){
      webserver.gpiostoptime = parseNumber(webserverData.extrainfo.gpiostoptime);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'gpiounit')){
      webserver.gpiounit = parseNumber(webserverData.extrainfo.gpiounit);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'ledsoff')){
      webserver.ledsoff = parseNumber(webserverData.extrainfo.ledsoff);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetipid')){
      webserver.bacnetipid = parseNumber(webserverData.extrainfo.bacnetipid);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'bacnetipport')){
      webserver.bacnetipport = parseNumber(webserverData.extrainfo.bacnetipport);
    }

    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'link_check')){
      webserver.link_check = parseNumber(webserverData.extrainfo.link_check);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'lutronhwqsxstatus')) {
      webserver.lutronhwqsxstatus = parseNumber(webserverData.extrainfo.lutronhwqsxstatus);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'lutronhwqsxmastereveryzone')) {
      webserver.lutronhwqsxmastereveryzone = parseNumber(webserverData.extrainfo.lutronhwqsxmastereveryzone);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'lutronhwqsxip')) {
      webserver.lutronhwqsxip = parseString(webserverData.extrainfo.lutronhwqsxip);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'wizard_compatible')) {
      webserver.wizard_compatible = webserverData.extrainfo.wizard_compatible === 1;
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'inet_enabled')) {
      webserver.inet_enabled = parseNumber(webserverData.extrainfo.inet_enabled);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'lmachinever')) {
      webserver.lmachine = parseString(webserverData.extrainfo.lmachinever);
    }

    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'region')) {
      webserver.region = webserverData.extrainfo.region;
    }
    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'mdbu_maq')) {
      webserver.mdbu_maq = webserverData.extrainfo.mdbu_maq
    }
    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'modbus_type')) {
      webserver.modbus_type = webserverData.extrainfo.modbus_type
    }
    if (hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'test_available')) {
      webserver.test_available = webserverData.extrainfo.test_available;
    }
    if(hasProp(webserverData, 'extrainfo') && hasProp(webserverData.extrainfo, 'slavemodbustcpport')){
      webserver.slavemodbustcpport = parseNumber(webserverData.extrainfo.slavemodbustcpport);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_enable')) {
      webserver.auxheat_enable = parseBoolean(webserverData.params.auxheat_enable);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_ton')) {
      webserver.auxheat_ton = parseNumber(webserverData.params.auxheat_ton);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_toff')) {
      webserver.auxheat_toff = parseNumber(webserverData.params.auxheat_toff);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_tdelay')) {
      webserver.auxheat_tdelay = parseNumber(webserverData.params.auxheat_tdelay);
    }
    if (
      hasProp(webserverData, 'params') &&
      hasProp(webserverData.params, 'auxheat_lockoutenable')
    ) {
      webserver.auxheat_lockoutenable = parseBoolean(webserverData.params.auxheat_lockoutenable);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_lockouttemp')) {
      webserver.auxheat_lockouttemp = parseNumber(webserverData.params.auxheat_lockouttemp);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_lowlock_val')) {
      webserver.auxheat_lowlock_val = parseNumber(webserverData.params.auxheat_lowlock_val);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_lowlock_min')) {
      webserver.auxheat_lowlock_min = parseNumber(webserverData.params.auxheat_lowlock_min);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_lowlock_max')) {
      webserver.auxheat_lowlock_max = parseNumber(webserverData.params.auxheat_lowlock_max);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_lowlock_step')) {
      webserver.auxheat_lowlock_step = parseNumber(webserverData.params.auxheat_lowlock_step);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_highlock_val')) {
      webserver.auxheat_highlock_val = parseNumber(webserverData.params.auxheat_highlock_val);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_highlock_min')) {
      webserver.auxheat_highlock_min = parseNumber(webserverData.params.auxheat_highlock_min);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_highlock_max')) {
      webserver.auxheat_highlock_max = parseNumber(webserverData.params.auxheat_highlock_max);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'auxheat_highlock_step')) {
      webserver.auxheat_highlock_step = parseNumber(webserverData.params.auxheat_highlock_step);
    }
    if (
      hasProp(webserverData, 'params') &&
      hasProp(webserverData.params, 'auxheat_extheatsource')
    ) {
      webserver.auxheat_extheatsource = parseNumber(webserverData.params.auxheat_extheatsource);
    }

    if (
      hasProp(webserverData, 'params') &&
      hasProp(webserverData.params, 'auxheat_emergencyheat')
    ) {
      webserver.auxheat_emergencyheat = parseBoolean(webserverData.params.auxheat_emergencyheat);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 't1t2_enable')) {
      webserver.t1t2_enable = parseNumber(webserverData.params.t1t2_enable);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 't1t2_nc')) {
      webserver.t1t2_nc = parseNumber(webserverData.params.t1t2_nc);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 't1t2_ondelay')) {
      webserver.t1t2_ondelay = parseNumber(webserverData.params.t1t2_ondelay);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 't1t2_offdelay')) {
      webserver.t1t2_offdelay = parseNumber(webserverData.params.t1t2_offdelay);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'rs485_mode')) {
      webserver.rs485_mode = parseNumber(webserverData.params.rs485_mode);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'rs485_availablemodes')) {
      webserver.rs485_availablemodes = webserverData.params.rs485_availablemodes;
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'ecobeesb')) {
      webserver.ecobeesb = parseNumber(webserverData.params.ecobeesb);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'ecobeesbclientid')) {
      webserver.ecobeesbclientid = parseString(webserverData.params.ecobeesbclientid);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'ecobeesbclientsecret')) {
      webserver.ecobeesbclientsecret = parseString(webserverData.params.ecobeesbclientsecret);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'ecobeesbthermostatid')) {
      webserver.ecobeesbthermostatid = parseString(webserverData.params.ecobeesbthermostatid);
    }
    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'ecobeesbstatus')) {
      webserver.ecobeesbstatus = parseNumber(webserverData.params.ecobeesbstatus);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'metering_info') && hasProp(webserverData.params.metering_info , 'meter_cnf_version')) {
      webserver.meterConfigVersion = parseNumber(webserverData.params.metering_info.meter_cnf_version);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'pelicansite')) {
      console.log('pelicansite: ', webserverData.params.pelicansite)
      webserver.pelicansite = parseString(webserverData.params.pelicansite);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'pelicanemail')) {
      console.log('pelicanemail: ', webserverData.params.pelicanemail)
      webserver.pelicanemail = parseString(webserverData.params.pelicanemail);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'pelicanpass')) {
      console.log('pelicanpass: ', webserverData.params.pelicanpass)
      webserver.pelicanpass = parseString(webserverData.params.pelicanpass);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'pelicanserial')) {
      console.log('pelicanserial: ', webserverData.params.pelicanserial)
      webserver.pelicanserial = parseString(webserverData.params.pelicanserial);
    }

    if (hasProp(webserverData, 'params') && hasProp(webserverData.params, 'pelicanstatus')) {
      console.log('pelicanstatus: ', webserverData.params.pelicanstatus)
      webserver.pelicanstatus = parseNumber(webserverData.params.pelicanstatus);
    }



    return webserver;
  } catch (error) {
    console.log('Error al parsear los datos del webserver: ', webserverID);
    throw error;
  }
};

export default sanitizeWebserver;
