import CONSTANTS from 'Core/constant';
import Toast from 'Core/components/Toast';

// MODELS
import User from 'Auth/models/User';

// SERVICES
import StorageService from 'Core/services/storage.service';
import AppManagerService from 'Core/services/app.manager.service'
import { setLanguage } from 'Core/services/language.service';
import packageInfo from '../../../package.json';
import { V1lowerThanV2 } from './utils/utils';

// COMPONENTS

// STORE
import store from 'Core/store/store';

export default [
  {
    path: '/',
    redirect: {
      name: CONSTANTS.SETTINGS.DEFAULT_ROUTE,
    },
  },

  {
    path: '/demo',
    name: 'demo',
    beforeEnter: async (to, from, next) => {
      //
      // Comrpobamos si tenemos un usuario logado
      //
      const token = StorageService.getItem('access_token');

      const isLogin = await User.isLogin(token);

      if (isLogin) {
        const user = User.query().first();

        await user.logout(); // Logout user

        return next({name: 'login',  query: { reason: 'demoMode' }});

      }

      //
      // Si no hay usuario logado comprobamos que no estábamos ya en modo demo
      //
      if(store.getters.getIsDemo) {
        await User.deleteAll();

        store.dispatch('setIsDemo', false);

        return next({name: 'login',  query: { reason: 'demoMode' }});
      }
      //
      // Si no teníamos usuario logado activamos el modo demo
      //
      console.log("DEMO MODE");

      store.dispatch('setIsDemo', true);

      await User.loginUser(); // Se crea el user Demo en el modelo
      // Redirigimos a la página de inicio
      return next({
        name: CONSTANTS.SETTINGS.DEFAULT_ROUTE,
      });
    }
  },

  // OnBoard route
  {
    path: '/onBoard',
    name: 'onBoard',
    props: true,
    component: () => import('./pages/OnBoard.page.vue'),
  },

  // Privates routes
  {
    path: '/main',
    name: 'main',
    component: () => import('./pages/Main.layout.vue'),
    redirect: {
      name: CONSTANTS.SETTINGS.DEFAULT_ROUTE,
    },
    meta: {
      private: true,
    },
    beforeEnter: async (to, from, next) => {
      //
      // Si no venimos de redirección externa a procesar, continuamos con el flujo de la App
      //
      try {
        if(from.name !== 'login') {
          // viene del login, tenemos el usuario ya
          const token = StorageService.getItem('access_token');
          await User.isLogin(token);
        }

        //
        // Si hay versión disponible para actualización de app y
        // el usuario no lo ha visto se muestra popup de actualización
        //
        AppManagerService.hasUpdateApp()

        //
        // Si hay configurado un onBoarding y el usuario no lo ha visto
        // lo redirijo a la vista onBoarding
        //
        const showedOnBoard = StorageService.getItem('showedOnBoard');
        const newsBoardVersion = StorageService.getItem('newsBoardVersion')?.slice(1);

        if (CONSTANTS.SETTINGS.HAS_ON_BOARD === 'true' &&
          to.name !== 'onBoard' &&
          !showedOnBoard &&
          !to.name.includes('oauth') &&
          !to.name.includes('association')) {
            Toast.clear();
            next({ name: 'onBoard' });

        } else if(
          (!newsBoardVersion && to.name !== 'onBoard' && !to.name.includes('oauth') && !to.name.includes('association'))
          || (to.name !== 'onBoard' && V1lowerThanV2(newsBoardVersion, packageInfo.newsVersion) && !to.name.includes('oauth') && !to.name.includes('association'))
        ) {
          Toast.clear();
          next({name: 'onBoard', params: {type: 'news'}})
        }

        const user = User.query().first();
        //
        // Si hemos hecho login conectamos al socket
        //
        //
        // Si ya estamos conectado al socket limpiamos cualquier conexión anterior
        //
        // NOTA: No espero "await" para no ralentizar. No me importa entrar a las zonas sin conexión al socket
        // El estado de las zonas se actualizará en cuanto esté disponible.
        //
        // if (internet === CONSTANTS.NETWORK.CONNECTED_TO_INTERNET)
        User.connectSocket();

        // if (user)
        setLanguage(user.lang);
        // if (internet === CONSTANTS.NETWORK.CONNECTED_TO_INTERNET) {
          return next();
        // }
        // Toast.clear();
        // return next({name: 'error', query:  { reason: 'notConnectedToInternet' } })
      } catch(error) {
        let internet = CONSTANTS.NETWORK.CONNECTED_TO_INTERNET

        if (CONSTANTS.IS_MOBILE) {
          window.addEventListener('deviceready', async () => {
            // Comprobamos: Si ha habido un error en alguna petición por backendDown
            // if (reason === 'backendDown') {
            //   this.$router.push({name: 'error', query: { reason: 'backendDown' } }).catch(() => {})
            // }

            // Check if mobile has output to interntet
            // eslint-disable-next-line no-undef
            WifiWizard2.isConnectedToInternet().then(status => {
              internet = status
            }).catch(err => {
              // NOTA: si no está soportada la función no hacemos nada de intentos de reconexión
              if(err !== 'Not supported' && err !== 'WIFI_NOT_ENABLED') {
                this.$router.push({name: 'error', query:  { reason: 'notConnectedToInternet' } })
              } else {
                console.log("ERROR EN WIFIWIZARD_2 (isConnectedToInternet)", err);
              }
            })
            console.log('En beforeEnter del main => No tiene salida a internet. no envío al login, envío al error', error)
          });
        }

        if (internet !== CONSTANTS.NETWORK.NOT_CONNECTED_TO_INTERNET || error.name === 'WifiWizard2 is not defined') {
          Toast.clear();
          return next({name: 'error', query:  { reason: 'notConnectedToInternet' } })
        }

        if (error.name !== 'backendDown') {
          console.log('Desconecto del socket y envío al login', error)
          User.clearUser();
          return next({ name: 'login' });
        }
        console.log('Limpio loading y sigo la ruta')
        Toast.clear();
        return next()
      }
    },
  },

  // Routes error
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.page.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./pages/NotFound.page.vue'),
  },

  // Routes not found
/*   {
    path: '*',
    redirect: {
      name: 'main',
    },
  }, */

  // Route maintenance
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./pages/Maintenance.page.vue'),
  },

];
