/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recovery-lock': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M21.39 21.84l-4.84-6.08 5.49-6.91h5.74l-2.53 2.53c-.17.17-.27.4-.27.63 0 .23.09.44.26.64.32.32.87.36 1.27 0l4.07-4.07c.16-.18.25-.4.25-.62 0-.17-.05-.33-.16-.5l-4.15-4.19c-.2-.19-.43-.25-.64-.27-.22 0-.44.09-.62.25-.17.16-.27.39-.27.65 0 .24.1.46.27.63l2.53 2.53h-6.15c-.22 0-.45.09-.66.29l-5.56 6.98-5.58-7.01c-.17-.17-.39-.26-.72-.26H3.06l2.53-2.53c.16-.16.25-.37.27-.63 0-.22-.09-.44-.25-.62-.15-.18-.39-.27-.69-.28-.17.02-.39.07-.59.27L.27 7.32l-.07.09c-.13.17-.2.36-.2.55 0 .22.09.44.26.64l4.06 4.06c.32.32.87.36 1.27 0 .17-.17.26-.39.26-.63s-.1-.46-.27-.64L3.05 8.86h5.74l5.49 6.9-4.84 6.08H.9c-.49 0-.9.4-.9.9s.4.89.9.89h8.96c.22 0 .44-.09.66-.29l4.91-6.17 4.93 6.2 1.03-1.54v.01z" _fill="#323F4B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29.99 21.7v-1.85c0-1.98-1.58-3.59-3.52-3.59s-3.53 1.61-3.53 3.59v1.85c-.78 0-1.41.53-1.41 1.39v4.86c0 .79.63 1.43 1.41 1.43h7.05c.78 0 1.41-.64 1.41-1.43v-4.86c0-.82-.63-1.39-1.41-1.39zm-2.64 5.05a.87.87 0 01-1.74 0v-2.19a.87.87 0 011.74 0v2.19zm.65-5.04h-3.06v-2.07c0-.82.69-1.49 1.53-1.49.84 0 1.53.67 1.53 1.49v2.07z" _fill="#323F4B"/>'
  }
})
