import { Device } from './DeviceHierarchy';

export class Clamp extends Device {
  static entity = 'clamps';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),
      ec_fw: this.string(null).nullable(),
      connection_date: this.attr(null),
      disconnection_date: this.attr(null),
      isConnected: this.boolean(false),
      serialnum_meter: this.string(undefined).nullable(),
    };
  }
}

export default Clamp;
