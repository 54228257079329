import { ref, onMounted, onUnmounted } from 'vue';
import { IS_MOBILE, PLATFORM } from 'Core/constant';

export const useResponsive = (width) => {
  // TODO: Implementar lógica de siguientes propiedades
  // const isMobile = ref(false);
  // const isTablet = ref(false);
  // const isDesktop = ref(false);
  // const isWideScreen = ref(false);
  // const isPortrait = ref(false);
  // const isLandscape = ref(false);
  // const isTouch = ref(false);

  let clientWidth = ref(window.innerWidth);
  let clientHeight = ref(window.innerHeight);

  const isMobile = () => {
    return IS_MOBILE === PLATFORM.IOS || IS_MOBILE === PLATFORM.ANDROID;
  }

  const isAndroid = () => {
    return IS_MOBILE === PLATFORM.ANDROID;
  }

  const isIos = () => {
    return IS_MOBILE === PLATFORM.IOS;
  }

  const getResponsiveClass = (baseClass, properties, props) => {
    const breakpoints = ['palm', 'lap', 'small', 'desk', 'large'];
    const response = [];

    breakpoints.forEach(breakpoint => {
      properties.forEach(property => {
        if (breakpoint && props?.[breakpoint]?.[property]) {
          response.push(`${baseClass}--${breakpoint}--${property}-${props[breakpoint][property]}`);
        }
      });
    });

    return response;
  }

  onMounted(() => {
    window.addEventListener('resize', () => {
      clientWidth.value = window.innerWidth;
      clientHeight.value = window.innerHeight;
    });

  });

  onUnmounted(() => {
    window.removeEventListener('resize', () => {
      clientWidth.value = window.innerWidth;
      clientHeight.value = window.innerHeight;
    });

  });

  return {
    clientWidth,
    clientHeight,
    getResponsiveClass,
    isMobile,
    isAndroid,
    isIos
  }

}
