import User from 'Auth/models/User';
import constants from 'Core/constant';

export const useFeedback = () => {
  const emitFeedback = async () => {

    if (await User?.query().first()?.noHaptic) return;

    // Get platform
    const platform = constants.IS_MOBILE;

    // iOs Haptic Feedback  -  Valores posibles: light | medium | heavy
    if (platform === 'ios' && window.TapticEngine) {
      TapticEngine.impact({ style: 'medium' }); // eslint-disable-line
    }

    // Android Haptic Feedback
    if (platform === 'android' && window.plugins.deviceFeedback) {
      const deviceFeedback = window.plugins.deviceFeedback;
      deviceFeedback.haptic(deviceFeedback.VIRTUAL_KEY);
    }

    // Sound Feedback
    // if ((platform === 'iOS' || platform === 'android') && nativeclick) {
    //   nativeclick.trigger(); // eslint-disable-line
    // }
  };

  return { emitFeedback }
}
