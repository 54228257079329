import { createApp, h } from 'vue';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import AzLoading from 'Core/components/Loading/Loading.vue';
import VuePopupPin from 'Units/components/PopupPin';

let popupPinInstance = null;

/**
 * Crea una nueva instancia del componente,
 * si ya existe una devuelve la que había
 */
function createInstance(newProps) {
  const el = document.createElement('div');

  if(popupPinInstance) popupPinInstance.unmount();

  popupPinInstance = createApp({
    render() {
      return h(VuePopupPin, {
        ...newProps,
        callback: (action, code) => {
          if (code !== undefined) {
            if (action === 'confirm') {
              return popupPinInstance?.options.resolve(code)
              popupPinInstance = null
            } else if (action === 'cancel')  {
              return popupPinInstance?.options.reject(action) //action
              popupPinInstance = null
            }
          }
        },
      })
    }
  })

  popupPinInstance.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });
  popupPinInstance.component('AzLoading', AzLoading);
  popupPinInstance.mount(el)

  return popupPinInstance;
}

/**
 * Crea un Objeto PopupPin
 *
 * @param {Object} options - Opciones del PopupPin
 * @param {Object} actions - Objeto para capturar emmiters del componente y ejecutar acciones (ej: {close: () => { ... [function] ... }})
 */
function createPopupPin(options) {
  return new Promise((resolve, reject) => {
    const popup = createInstance(options);
    // Le paso las opciones a la instancia del popupPin
    popup.options = { resolve, reject }
  });
}

/**
 * Creo el objeto PopupPin
 */

const PopupPin = {
  clear: () => {
    if (popupPinInstance) {
      const popupPin = popupPinInstance._instance.ctx
      const parent = popupPin.$el.parentNode;
      if (parent) parent.removeChild(popupPin.$el);
      popupPinInstance.unmount();
      popupPinInstance = null
    }
  },

  create: options => {
    return createPopupPin({
      ...options
    })
  },

  confirm: options => {
    return createPopupPin({
      buttons: true,
      ...options
    })
  },
}

export default PopupPin;
