import AppError from 'Core/services/errors.service';

const mapError = {
  default: ['00', 'Error por defecto, se aplica cuando no se especifica ningún código de error', false],
  invalidInstallationData: ['01', 'Los datos recibidos de la instalación no están completos', false],
  badParams: ['02', 'Los datos enviados no son válidos', false],
  notAuthorized: ['03', 'El usuario no es administrador de la instalación', false],
  backendDown: ['04', 'El backend no responde', true],
  maxPinRetries: ['05', 'Número máximo de intentos', false],
  refreshListeners: ['06', 'Error al refrescar los listeners', false],
  deviceAssociated: ['10','El webserver ya está asociado a una instalación', false],
  deviceNotFound: ['11','El webserver no se encuentra en base de datos', false],
  offline: ['12','El webserver no se encuentra conectado a la Cloud', false],
  userNotIncluded: ['13','El usuario no pertenece a esta instalación', false],
  installationAlreadyAdded: ['14', 'El usuario ya pertenece a esta instalación', false],
  userNotConfirmed: ['15', 'El usuario invitado no ha confirmado su cuenta', false],
  modeNotSupported: ['20', 'El modo seleccionado no está soportado', false],
  tooManySchedules: ['40', 'No se pueden crear más programaciones', false],
  detectSystemsError: ['50', 'Error al detectar sistemas', false],
};

/**
 * Errores personalizados dentro de la aplicación
 *
 * @param {String} name - Nombre del error
 */
class UnitsError extends AppError {
  constructor(error, customDescription) {
    super(error, 'units', 'Ux', mapError, customDescription);
  }
}

export default UnitsError;
