/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electricity-cost': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.44 25.572h-8.782c-.296 0-.493-.197-.493-.493 0-1.579-.395-2.665-1.283-4.737l-.296-.592c-.494-.987-.987-1.776-1.382-2.665-1.086-1.973-2.171-3.947-2.171-6.02C6.033 5.54 10.473 1 16 1c5.526 0 9.967 4.54 9.967 10.066 0 1.677-1.085 3.75-2.072 5.625-.592.987-1.086 2.072-1.48 3.059-1.283 3.454-1.48 5.428-1.48 5.428-.1.296-.297.394-.494.394zm-8.387-.986h7.894c.1-.79.494-2.566 1.48-5.23.395-.988.988-2.073 1.58-3.159.986-1.776 1.973-3.75 1.973-5.131 0-4.934-4.046-8.98-8.98-8.98a8.95 8.95 0 00-8.98 8.98c0 1.875.987 3.552 2.072 5.526.494.888.987 1.776 1.48 2.763l.296.691c.691 1.875 1.086 3.06 1.185 4.54z" _fill="#3B4E52"/><path pid="1" d="M19.849 28.237h-7.796c-.987 0-1.678-.79-1.678-1.678v-.197c0-.987.79-1.678 1.678-1.678h7.796c.987 0 1.677.79 1.677 1.678v.197c0 .888-.69 1.678-1.677 1.678zm-7.796-2.566a.674.674 0 00-.691.69v.198c0 .395.296.691.69.691h7.797c.394 0 .69-.296.69-.69v-.198a.675.675 0 00-.69-.69h-7.796z" _fill="#3B4E52"/><path pid="2" d="M19.158 31h-6.316c-.987 0-1.776-.79-1.776-1.776v-.099c0-.987.79-1.776 1.776-1.776h6.316c.987 0 1.776.79 1.776 1.776v.099c-.099.986-.888 1.776-1.776 1.776zm-6.415-2.566a.778.778 0 00-.79.79v.098c0 .395.297.79.79.79h6.316c.395 0 .79-.296.79-.79v-.098a.778.778 0 00-.79-.79h-6.316zM19.158 13.829l1.875.888c-.69 1.283-1.974 2.467-4.244 2.467-2.467 0-4.44-1.381-5.131-3.651h-.79v-1.184h.593v-.494-.493h-.593v-1.184h.79c.69-2.171 2.664-3.553 5.132-3.553 2.27 0 3.552 1.184 4.243 2.467l-1.875.987c-.395-.79-1.283-1.48-2.369-1.48-1.282 0-2.27.69-2.763 1.677h3.652v1.184h-4.046v.987h4.046v1.185h-3.652c.494.986 1.48 1.677 2.764 1.677 1.085 0 1.973-.69 2.368-1.48z" _fill="#3B4E52"/><path pid="3" d="M14.421 25.375c-.296 0-.493-.197-.493-.493v-9.08c0-.295.197-.493.493-.493s.494.198.494.494v9.079c0 .296-.297.493-.494.493zM18.072 25.375c-.296 0-.493-.197-.493-.493v-9.08c0-.295.197-.493.493-.493s.494.198.494.494v9.079c0 .296-.198.493-.494.493z" _fill="#3B4E52"/>'
  }
})
