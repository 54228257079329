import StorageService from 'Core/services/storage.service';

let userData;

const localUserData = {

  init() {
    const data =JSON.parse(StorageService.getItem('userData'));

    if(!data) {
      userData = []
    } else {
      userData = data;
    }
  },

  getUser(email) {
    return userData.find(item => item.user === email);
  },

  getDevice(mac, devicesList) {
    return devicesList.find(item => item.mac === mac);
  },

  checkUser(email) {

    const user = this.getUser(email);

    if(user === undefined){
      this.addUser(email);
      this.saveData();
    }
    return true;
  },

  addUser(email) {
    const user = {
      user: email
    }
    userData.push(user)
  },

  getData(email, propName) {
    const user = this.getUser(email);

    return user[propName];
  },


  setData(email, {propName, value}) {

    const index = userData.findIndex(item => item.user === email);

    userData[index][propName] = value;

    this.saveData();

  },

  getDevicePincode(email, mac){
    const user = this.getUser(email);

    if(user && user.devices_pincode) {
      const device = this.getDevice(mac, user.devices_pincode);
      if(device) {
        return device.pin;
      }
      return undefined;
    }
    return undefined;
  },

  setPincode(email, deviceID, pincode) {
    const index = userData.findIndex(item => item.user === email);

    if(userData[index].devices_pincode !== undefined) {
      const device = userData[index].devices_pincode.find(item => item.mac === deviceID);

      if(device === undefined) {
        const data = this.getData(email, 'devices_pincode');
        data.push({
          mac: deviceID,
          pin: pincode
        })
        this.setData(email, {propName: 'devices_pincode', value: data})
      } else {
        const pos = userData[index].devices_pincode.findIndex(dev => dev.mac === deviceID);
        userData[index].devices_pincode[pos].pin = pincode;
        this.saveData();
      }
    } else {
      this.setData(email, {
        propName: 'devices_pincode',
        value: [{mac: deviceID, pin: pincode}]
      })

    }

  },

  getShowedVersion(email, version){
    const user = this.getUser(email);

    if(user && user.versions) {
      return user.versions.findIndex(item => item === version) >= 0;
    }
    return false;
  },

  setShowedVersion(email, version) {
    const index = userData.findIndex(item => item.user === email);

    if(userData[index].versions !== undefined) {
      const findedVersion = userData[index].versions.findIndex(item => item === version);

      if(findedVersion === -1) {
        const data = this.getData(email, 'versions');
        data.push(version)
        this.setData(email, {propName: 'versions', value: data})
      }
    } else {
      this.setData(email, {
        propName: 'versions',
        value: [version]
      })
    }
  },

  saveData() {
    StorageService.setItem('userData', JSON.stringify(userData));
  }



}

export default localUserData;
