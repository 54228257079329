import Tariff from "Units/models/Tariff.model";
import User from 'Auth/models/User';
import Installation from "Units/models/Installation.model";
import UnitsError from "./errors.service";
import store from 'Core/store/store';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import ApiService from 'Core/services/api.service';
import CORECONSTANTS from 'Core/constant';
import web2cloud from "Units/interfaces/web2cloud.interface";

const CONSTANTS = {...CORECONSTANTS};

const TariffService = {

  async saveTariff({installationID, params}) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = `${admin}tariffs?installationId=${installationID}`;

    try {
      let response;
      let responseData;

      if(!store.getters.getIsDemo) {

        params = web2cloud.formattedTariffData(params);

        response = await ApiService.request(requestUrl, params, 'post', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);
        responseData = cloud2web.formattedTariffData(response?.data);
      }

      return responseData;
    } catch (err) {
      console.log(err);
    }
  },

  async getTariffData({installationID, tariffId, basicMode = false}) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = `${admin}tariffs/${tariffId}?installationId=${installationID}&basicData=${basicMode}`;

    try {
      let response;
      let responseData;

      if(!store.getters.getIsDemo){
        response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);
        responseData = cloud2web.formattedTariffData(response.data);
      } else {
        responseData = await DemoService.getTariff();
      }

      return responseData;
    } catch(error) {
      throw new UnitsError(error);
    }
  },
}

export default TariffService;
