import { nextTick, ref } from 'vue';

export const useOverlayMask = () => {
  const showMask = ref(false);

  const initOverlaySettings= (elementID) => {

    nextTick(() => {
      // Al iniciarlizar las settings obtenemos el div scrollable y
      // calculamos si hay o no scroll disponible para activar la máscara
      let divSettings;

      if(elementID !== undefined){
        divSettings = document.getElementById(elementID);
      } else {
        divSettings = document.getElementsByClassName('overlay-mask-active')[0];
      }

      const scrollHeight = divSettings?.scrollHeight;
      const clientHeight = divSettings?.clientHeight;

      if(scrollHeight > clientHeight) {
        showMask.value = true;
      }
      if(divSettings) {
        divSettings.addEventListener('scroll', scrollSettingsHandler);
      }

    });
  }

  const scrollSettingsHandler = (e) => {
    // Si la altura de la ventana más el scroll realizado alcanza la altura total del scrollable
    // entonces desactivamos la máscara (hemos llegado al final del div con el scroll) (Incluímos una guarda de 20px)
    if((e.target.clientHeight + e.target.scrollTop) >= e.target.scrollHeight - 20 ) {
      showMask.value = false;
    } else {
      showMask.value = true;
    }
  }

  return { showMask, initOverlaySettings, scrollSettingsHandler }
}
