import PopupPinGlobal from 'Core/components/PopupPinGlobal';
import PopupModal from 'Core/components/PopupModal';
import Toast from 'Core/components/Toast';
import Webserver from 'Units/models/Webserver.model';
import PopupGlobal from 'Core/components/PopupGlobal'
import i18n from 'Core/services/language.service';
import BleUtils from 'Units/utils/ble.utils';
import { createApp, h } from 'vue';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { doc } from 'prettier';
import coreConstants from 'Core/constant';
import unitConstants from 'Units/constant';
import authConstants from 'Auth/constant';


const checkDisabledInternet = async (webserverID, macBle, name) => {
  // eslint-disable-next-line consistent-return
  return new Promise(async (resolve, reject) => {

    try {
      const ws = Webserver.find(webserverID)

      if (ws.inet_enabled === 0) {
        await PopupGlobal.confirm({
          message: i18n.global.t('airtools.hasDisabledInternet'),
          buttons: true,
          acceptBtnText: i18n.global.t('airtools.check'),
          cancelBtnText: i18n.global.t('buttons.close'),
        }).then(async () => {
          PopupGlobal.clear()

          Toast.loading({
            closeOnclick: false
          })
          await BleUtils.getExtraInfoBleModel(webserverID, macBle, name)
          Toast.clear()

          return resolve(await checkDisabledInternet(webserverID, macBle, name))

        }).catch(() => {
          PopupGlobal.clear()
          return reject()
        })
      } else {
        return resolve(true)
      }

    } catch(error) {
      return reject(error);
    }
  })
}

const showPopupPin = async (mac, pinError, maxRetriesPinError, pinCode) => {
  // eslint-disable-next-line consistent-return
  return new Promise(async (resolve, reject) => {

    const popup = PopupPinGlobal.confirm({
      code: pinCode,
      pinError,
      maxRetriesPinError,
    })
    .then(async (code) => {
      Toast.loading({
        closeOnclick: false
      })

      Webserver.checkPin(mac, code)
      .then(() => {
        Toast.clear()

        return resolve(code)
      })
      .catch(async error => {
        if (error.name === 'maxPinRetries') {
          Toast.clear()
          showPopupPin(mac, false, true, code)
          .then(pinCode => {
            return resolve(pinCode)
          }).catch( err => {
            return reject(err);
          })
        } else {
          Toast.clear()
          showPopupPin(mac, true, false, code)
          .then(pinCode => {
            return resolve(pinCode)
          }).catch(err => {
            return reject(err);
          })
        }
      })
    })
    .catch((error) => {
      popup.clear()
      return reject(error)
    })

  })
}

/**
 *
 * @param {VueComponent} component - Component to render in the popup
 * @param {Array} attrs - Attributes to pass to the component
 * @param {VNode} childVNodes - Child nodes to pass to the component
 * @returns Object - Object with the close method and the instance of the popup
 */
const createPopupWrapper = (component, attrs, childVNodes) => {
  const el = document.createElement('div');

  const constants = {...unitConstants, ...coreConstants, ...authConstants};

  document.body.appendChild(el);

  const closePopup = () => {
    popupInstance.unmount();
    el.remove();
  }

  const popupInstance = createApp({
    render() {
      return h(PopupModal,{
        onClose: closePopup
      },{
        nowrapper: () => h(component, attrs, childVNodes)
      })
    }
  });

  popupInstance.provide('i18n', i18n.global);
  popupInstance.provide('constants', constants);
  popupInstance.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });
  popupInstance.mount(el);

  return {
    close: closePopup,
    instance: popupInstance
  }
}


export {
  checkDisabledInternet,
  showPopupPin,
  createPopupWrapper
};
