import CONSTANTS from 'Core/constant';

/**
 *
 * @param {String} value - Unidad de temperatura
 */
const unitTotempunit = value => {
  return value === CONSTANTS.UNITS.CELSIUS ? CONSTANTS.TEMP_UNITS.CELSIUS : CONSTANTS.TEMP_UNITS.FARENHEIT;
};

/**
 *
 * @param {String} value - Unidad de temperatura
 */
const tempunitToUnit = value => {
  return value === CONSTANTS.TEMP_UNITS.CELSIUS ? CONSTANTS.UNITS.CELSIUS : CONSTANTS.UNITS.FARENHEIT;
};

export default { unitTotempunit, tempunitToUnit };
