/* eslint-disable no-undef */
/**
 * NOTA: Usando plugin 'cordova-plugin-fingerprint-aio'
 */
import authConstans from 'Auth/constant';
import coreConstans from 'Core/constant';
import store from 'Core/store/store';
import popup from 'Core/components/PopupGlobal'
import i18n from 'Core/services/language.service'
import User from 'Auth/models/User';
import StorageService from '@/modules/core/services/storage.service';

const CONSTANTS = {...authConstans, ...coreConstans};
const CONFIG_OPTIONAL_PARAMS = {
  title: '',
  disableBackup: true, // always disabled on Android
  cancelButtonTitle: i18n.global.t('buttons.cancel'),
  confirmationRequired: false
}

let biometricType = '';

/**
 *  Registra las credenciales de autenticación usando la biometría
 * @param {Object} data - Objeto con las credenciales de autenticación
 */
const registerBiometricSecret = async data => {
  return new Promise((resolve, reject) => {
    const options = {
      invalidateOnEnrollment: true,
      confirmationRequired: false, // Android
      disableBackup: true, // always disabled on Android
    }
    try {
      const secret = typeof data === 'object' ? JSON.stringify(data) : data

      Fingerprint.registerBiometricSecret({...options, secret }, () => {
        StorageService.setItem('isBiometricActive', true);
        resolve(true);
      }, error => {
        reject(error)
      });
    } catch (err) {
      console.log("No biometric available!");
      reject(err)
    }
  })
}

/**
 *  Obtiene las credenciales de autenticación usando la biometría
 */
const showBiometricSecretAuth = async() => {
  return new Promise((resolve, reject) => {
    try {
      if (store.getters.getIsBiometricAuthOpen) {
        store.dispatch('setBiometricAuthOpen', false);
        return;
      }

      store.dispatch('setBiometricAuthOpen', true);
      const optionalParams = CONFIG_OPTIONAL_PARAMS;

      Fingerprint.loadBiometricSecret( optionalParams, secret => {
        const loginData = {}
        const credentials = JSON.parse(secret);
        if (typeof credentials === 'object' && Object.keys(credentials).length > 0) {
          loginData.email = credentials.email
          loginData.password = credentials.password
        }
        resolve(loginData);
      }, error => {
        console.log(error);
        console.log(`errorBiometricCallback Authentication invalid ${error.message}`);
        reject(error);
      })
    } catch (err) {
      console.log("Error loading secret biometric", err);
      reject(err);
    }
  })
}

/**
 *  Comprueba si el dispositivo tiene la biometría disponible
 */
const checkBiometricAuthAvailable = async() => {
  return new Promise((resolve, reject) => {
    const optionalParams = {};
    try {
      const vm = store;

      Fingerprint.isAvailable(async mode => {
        const isIOSMobile = CONSTANTS.IS_MOBILE === CONSTANTS.PLATFORM.IOS;
        const isAndroidMobile = CONSTANTS.IS_MOBILE === CONSTANTS.PLATFORM.ANDROID;

        if(isIOSMobile) biometricType = mode === 'face' ? 'faceID' : 'touchID';
        if(isAndroidMobile) biometricType = mode === 'face' ? 'face' : 'fingerprint';

        CONFIG_OPTIONAL_PARAMS.title = i18n.global.t('login.biometric.title', { 'biometricMode' : i18n.global.t(`login.biometric.${biometricType}`)});

        const isBiometricActive = JSON.parse(StorageService.getItem('isBiometricActive'));
        vm.dispatch('setBiometricMode', mode);
        vm.dispatch('setBiometricType', biometricType);
        vm.dispatch('setBiometricAvailable', true);

        if(isBiometricActive && JSON.parse(!StorageService.getItem('refresh_token'))) {
          try {
            const { email, password } = await showBiometricSecretAuth();
            const user = await User.loginUser(email, password);

            resolve(user);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }

        return false;
      }, error => {
        reject(error);
        console.log(`is available error ${error.message}`);
      }, optionalParams);
    } catch (err) {
      reject(err);
      console.log("No biometric available!", err);
    }

  })
}

/**
 *  Muestra modal del error si está entre los errores controlados
 * @param {Object} error - Objeto con los datos del error
 */
const showBiometricError = error => {
  const noFootprintRegisteredErrorCode = 11;

  if (CONSTANTS.BIOMETRIC.ERROR_CODES.includes(error?.code)) {
    popup.confirm({
      src: 'unitError.svg',
      subtitle: i18n.global.t('messages.errors.biometricError.title', {biometricMode: i18n.global.t(`login.biometric.${biometricType}`)}),
      message: error.code === noFootprintRegisteredErrorCode? i18n.global.t('messages.errors.biometricError.data_change', {biometricMode: i18n.global.t(`login.biometric.${biometricType}`)}) : error.message,
      otherMessage: error.code === noFootprintRegisteredErrorCode? error.message : i18n.global.t('messages.errors.biometricError.message'),
      closeOnClick: false,
      cancelBtn: false,
    }).then(() => {})
  }
}

export {
  registerBiometricSecret,
  showBiometricSecretAuth,
  checkBiometricAuthAvailable,
  showBiometricError
};
