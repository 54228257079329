import { Model } from '@vuex-orm/core';
import UnitsService from 'Units/services/units.service';
import log from 'Core/services/log.service';

export default class Schedule extends Model {

  static entity = 'schedules';

  /** *************************************************************
  * CAMPOS
  ************************************************************** */
  static fields() {
    return {
      id: this.attr(null).nullable(), // id del modelo para consultas
      device_id: this.attr(null).nullable(), // id del dispositivo asociado a la programación (zona, webserver, etc..)
      device_ids: this.attr(null).nullable(), // id de dispositivos a los que afecta (en programaciones de instalación o calendario)
      type: this.string(undefined).nullable(), // OPCIONAL: tipo de programación: zona, webserver, etc...
      isCalendarSched: this.boolean(undefined).nullable(),
      zone_numbers: this.attr(undefined).nullable(),
      days: this.attr(undefined).nullable(), // Array con días de la semana [0 (lunes) ... 6 (domingo) ]
      name: this.string('').nullable(), // Nombre de la programación
      schedule_number: this.number(undefined).nullable(), // Número asignado dentro de las 24 posibles en un sistema
      start_conf: this.attr(undefined).nullable(),
      end_conf: this.attr(undefined).nullable(),
      prog_enabled: this.boolean(undefined).nullable(),
      units: this.string(undefined).nullable(),
    }
  }

  static saveSchedule = async (installationID, deviceID, scheduleID, data, opts = null) => {
    const response = await UnitsService.saveSchedule(installationID, deviceID, data, opts);

    console.log(response);

    const schedules = response.schedules;

    const updatedSchedules = [];

    if( schedules && schedules.length > 0){
      schedules.forEach( async sched => {
        sched.id = sched.schedule_number;
        sched.device_id = deviceID;
        updatedSchedules.push(sched);
      });

      await Schedule.create({data: updatedSchedules});
    }

    log.info(`setScheduleStatus: ${JSON.stringify(data)}`);

    return response;
  }

  static deleteSchedule = async (installationID, deviceID, scheduleID ) => {

    const response = await UnitsService.deleteSchedule(installationID, deviceID, scheduleID);

    await Schedule.delete(scheduleID);

    log.info(`deleteSchedule: ${scheduleID}`);

    return response;

  }
}
