

const web2cloud = {
  formattedTariffData: inputData => {
    const periods = inputData.periods?.map(period => {
      return {
        price_kWh: period.price,
        tariff_id: period.tariff_id,
        color: period.color,
        name: period.name,
        time_slots: period.timeSlots
      }
    })

    const data = {
      id: inputData?.id,
      installation_id: inputData?.installation_id,
      name: inputData?.name,
      price_kWh: inputData?.price,
      default_price: inputData?.price,
      type: inputData?.type,
      description: inputData?.description,
      currency: inputData?.currency,
      regulated_tariff_id: inputData?.regulated_tariff_id,
      active: inputData?.active,
      periods
    }

    return data;
  }
}

export default web2cloud;
