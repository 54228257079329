import CONSTANTS from 'Core/constant';
import { Device, Zone } from './DeviceHierarchy';


export class System extends Device {
  static entity = 'systems';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),

      air_supply_vent_delay_conf: this.attr(undefined).nullable(),
      air_supply_vent_delay_values: this.attr(undefined).nullable(),
      air_supply_type_conf: this.string(undefined).nullable(),
      air_supply_type_values: this.attr(undefined).nullable(),
      aq_box_fw: this.string(undefined).nullable(),
      airqbox_num_ionizers_conf: this.number(undefined).nullable(),
      airqbox_num_ionizers_values: this.attr(undefined).nullable(),
      airqsensor_missing: this.boolean(undefined).nullable(),
      aq_work_sensor_conf: this.string(undefined).nullable(),
      aq_work_sensor_values: this.attr(undefined).nullable(),
      air_filter_active: this.boolean(undefined).nullable(),
      antifreeze: this.boolean(undefined).nullable(),
      auto_time_enabled: this.boolean(undefined).nullable(),
      speed_values: this.attr(undefined).nullable(),
      sleep_values: this.attr(undefined).nullable(),
      aq_mode_values: this.attr(undefined).nullable(),
      aq_vent_mode_values: this.attr(undefined).nullable(),
      timer_values: this.attr(undefined).nullable(),
      aq_high_conf: this.number(undefined).nullable(),
      aq_low_conf: this.number(undefined).nullable(),
      aq_present: this.boolean(undefined).nullable(),
      aq_quality: this.string(undefined).nullable(),
      aqpm1_0: this.number(undefined).nullable(),
      aqpm2_5: this.number(undefined).nullable(),
      aqpm10: this.number(undefined).nullable(),
      autochange_ovr: this.boolean(undefined).nullable(),
      autochange_ovr_temp_conf: this.attr(undefined).nullable(),
      autochange_ovr_temp_values: this.attr(undefined).nullable(),
      autochange_diff_temp_conf: this.attr(undefined).nullable(),
      autochange_diff_temp_values: this.attr(undefined).nullable(),
      autochange_time_conf: this.attr(undefined).nullable(),
      autochange_time_values: this.attr(undefined).nullable(),
      aux_heat_on: this.boolean(undefined).nullable(),
      aux_heat_num_stages_conf: this.attr(undefined).nullable(),
      aux_heat_num_stages_values: this.attr(undefined).nullable(),
      aux_heat_main_stage_conf: this.attr(undefined).nullable(),
      aux_heat_main_stage_values: this.attr(undefined).nullable(),
      aux_heat_stages_conf: this.attr(undefined).nullable(),
      aux_heat_vent_conf: this.string(undefined).nullable(),
      aux_heat_vent_values: this.attr(undefined).nullable(),
      aux_heat_electric_vent_delay_conf: this.attr(undefined).nullable(),
      aux_heat_electric_vent_delay_values: this.attr(undefined).nullable(),
      away_unnocupied_hist_conf: this.attr(undefined).nullable(),
      away_unnocupied_hist_values: this.attr(undefined).nullable(),
      away_unnocupied_time_conf: this.attr(undefined).nullable(),
      away_unnocupied_time_values: this.attr(undefined).nullable(),
      away_vacation_hist_conf: this.attr(undefined).nullable(),
      away_vacation_hist_values: this.attr(undefined).nullable(),
      away_vacation_time_conf: this.attr(undefined).nullable(),
      away_vacation_time_values: this.attr(undefined).nullable(),
      bf_num: this.attr(undefined).nullable(),
      comb_rele_board_num: this.attr(undefined).nullable(),
      radio_switchboard_fw: this.attr(undefined).nullable(),
      rad_alg_cool_air_deact_diff_conf: this.attr(undefined).nullable(),
      rad_alg_cool_air_deact_diff_values: this.attr(undefined).nullable(),
      rad_alg_cool_air_prep: this.boolean(undefined).nullable(),
      rad_alg_cool_air_diff_conf: this.attr(undefined).nullable(),
      rad_alg_cool_air_diff_values: this.attr(undefined).nullable(),
      collector_temp_1: this.attr(undefined).nullable(),
      collector_temp_2: this.attr(undefined).nullable(),
      collector_temp_3: this.attr(undefined).nullable(),
      collector_temp_4: this.attr(undefined).nullable(),
      combined_cool_available: this.boolean(undefined).nullable(),
      combined_heat_available: this.boolean(undefined).nullable(),
      cool_rele_board_num: this.attr(undefined).nullable(),
      cool_hist_conf: this.attr(undefined).nullable(),
      cool_hist_values: this.attr(undefined).nullable(),
      cool_standby: this.boolean(undefined).nullable(),
      cool_standby_hist_conf: this.attr(undefined).nullable(),
      cool_standby_hist_values: this.attr(undefined).nullable(),
      cooling_available: this.boolean(undefined).nullable(),
      cooling_available_readonly: this.boolean(undefined).nullable(),
      d1_input_conf: this.attr(undefined).nullable(),
      d1_input_values: this.attr(undefined).nullable(),
      dehum_function_active: this.boolean(undefined).nullable(),
      dehum_function_humidity_conf: this.attr(undefined).nullable(),
      dehum_function_humidity_values: this.attr(undefined).nullable(),
      device_id: this.attr(undefined),
      display_localtemp: this.boolean(undefined).nullable(),
      eco_values: this.attr(undefined).nullable(),
      fancoil_gw_available: this.boolean(undefined).nullable(),
      filter_hours_conf: this.number(undefined).nullable(),
      filter_usage_hours: this.number(undefined).nullable(),
      filter_hours_range_max: this.number(undefined).nullable(),
      filter_hours_range_min: this.number(undefined).nullable(),
      global_vent_on: this.boolean(undefined).nullable(),
      global_vent_interval_conf: this.attr(undefined).nullable(),
      global_vent_interval_values: this.attr(undefined).nullable(),
      global_vent_duration_conf: this.attr(undefined).nullable(),
      global_vent_duration_values: this.attr(undefined).nullable(),
      lite_step_conf: this.attr(undefined).nullable(),
      lite_step_conf_values: this.attr(undefined).nullable(),
      group_id: this.attr(undefined),
      gw_duplex_mode: this.boolean(undefined).nullable(),
      gw_drymode_available: this.boolean(undefined).nullable(),
      gw_ventmode_available: this.boolean(undefined).nullable(),
      rad_alg_heat_air_deact_diff_conf: this.attr(undefined).nullable(),
      rad_alg_heat_air_deact_diff_values: this.attr(undefined).nullable(),
      rad_alg_heat_air_prep: this.boolean(undefined).nullable(),
      rad_alg_heat_air_diff_conf: this.attr(undefined).nullable(),
      rad_alg_heat_air_diff_values: this.attr(undefined).nullable(),
      heat_hist_conf: this.attr(undefined).nullable(),
      heat_hist_values: this.attr(undefined).nullable(),
      heat_standby: this.boolean(undefined).nullable(),
      heat_standby_hist_conf: this.attr(undefined).nullable(),
      heat_standby_hist_values: this.attr(undefined).nullable(),
      rad_alg_heat_valv_deact_diff_conf: this.attr(undefined).nullable(),
      rad_alg_heat_valv_deact_diff_values: this.attr(undefined).nullable(),
      heat_rele_board_num: this.attr(undefined).nullable(),
      heat_wireless_valves: this.attr(undefined).nullable(),
      heating_config_temp_gap_conf: this.attr(undefined).nullable(),
      heating_config_temp_gap_values: this.attr(undefined).nullable(),
      heating_available: this.boolean(undefined).nullable(),
      heating_available_readonly: this.boolean(undefined).nullable(),
      hitachi_prot_active: this.boolean(undefined).nullable(),
      installation_id: this.attr(undefined).nullable(),
      knx_gw_available: this.boolean(undefined).nullable(),
      lite_num: this.attr(undefined).nullable(),
      m010_min_voltage_conf: this.attr(undefined).nullable(),
      m010_min_voltage_values: this.attr(undefined).nullable(),
      m010_max_voltage_conf: this.attr(undefined).nullable(),
      m010_max_voltage_values: this.attr(undefined).nullable(),
      main_stage_conf: this.attr(undefined).nullable(),
      main_stage_values: this.attr(undefined).nullable(),
      max_cool_battery_temperature_conf: this.number(undefined).nullable(),
      min_heat_battery_temperature_conf: this.number(undefined).nullable(),
      min_heat_setpoint: this.attr(undefined).nullable(),
      min_heat_setpoint_values: this.attr(undefined).nullable(),
      max_heat_setpoint: this.attr(undefined).nullable(),
      max_heat_setpoint_values: this.attr(undefined).nullable(),
      min_cold_setpoint: this.attr(undefined).nullable(),
      min_cold_setpoint_values: this.attr(undefined).nullable(),
      minimum_air: this.boolean(undefined).nullable(),
      max_cold_setpoint: this.attr(undefined).nullable(),
      max_cold_setpoint_values: this.attr(undefined).nullable(),
      mode_available: this.attr(undefined).nullable(),
      name: this.string('').nullable(),
      new_virtual_zones_allowed: this.number(undefined).nullable(),
      noshow_iaq_0: this.boolean(undefined).nullable(),
      prop_opening: this.attr(undefined).nullable(),
      plenum_mode: this.boolean(undefined).nullable(),
      show_protection_dew_info: this.boolean(undefined).nullable(),
      preparing_conf: this.number(undefined).nullable(),
      preparing_values: this.attr(undefined).nullable(),
      protection_dew_active: this.boolean(undefined).nullable(),
      protection_dew_conf: this.attr(undefined).nullable(),
      protection_dew_values: this.attr(undefined).nullable(),
      protection_temp_cooling_conf: this.attr(undefined).nullable(),
      protection_temp_cooling_values: this.attr(undefined).nullable(),
      protection_temp_heating_conf: this.attr(undefined).nullable(),
      protection_temp_heating_values: this.attr(undefined).nullable(),
      purifier_count_control_available: this.attr(undefined).nullable(),
      qadapt_conf: this.string(undefined).nullable(),
      qadapt_values: this.attr(undefined).nullable(),
      radio_enabled: this.boolean(undefined).nullable(),
      range_max_cool_battery_temperature_values: this.attr(undefined).nullable(),
      range_min_heat_battery_temperature_values: this.attr(undefined).nullable(),
      range_aq_high_max: this.number(undefined).nullable(),
      range_aq_high_min: this.number(undefined).nullable(),
      range_aq_low_max: this.number(undefined).nullable(),
      range_aq_low_min: this.number(undefined).nullable(),
      range_sp_max: this.number(undefined).nullable(), // Máximo temp setpoint común del sistema
      range_sp_min: this.number(undefined).nullable(), // Mínimo temp setpoint común del sistema
      range_air_max: this.number(undefined).nullable(), // Máximo temp setpoint común del sistema NUEVOS
      range_air_min: this.number(undefined).nullable(), // Mínimo temp setpoint común del sistema NUEVOS
      relay_boards_fw: this.attr(undefined).nullable(),
      relay_conf: this.attr(undefined).nullable(),
      relay_conf_values: this.attr(undefined).nullable(),
      relay_o1_conf: this.attr(undefined).nullable(),
      relay_o1_values: this.attr(undefined).nullable(),
      relay_o2_conf: this.attr(undefined).nullable(),
      relay_o2_values: this.attr(undefined).nullable(),
      reset_filter_enabled: this.boolean(undefined).nullable(),
      sp_limits_forced: this.boolean(undefined).nullable(),
      step: this.attr(undefined).nullable(),
      supermaster: this.boolean(undefined).nullable(),
      system_number: this.number(undefined).nullable(),
      system_number_min: this.number(undefined).nullable(),
      system_number_max: this.number(undefined).nullable(),
      system_number_editable: this.boolean(undefined).nullable(),
      system_fw: this.string(undefined).nullable(),
      system_weight_manual: this.boolean(undefined).nullable(),
      system_type: this.attr(undefined).nullable(),
      system_type_values: this.attr(undefined).nullable(),
      temp_auto_mode_active: this.boolean(undefined).nullable(),
      think_r_num: this.string(undefined).nullable(),
      think_w_num: this.string(undefined).nullable(),
      type: this.attr('SYSTEM'),
      units: this.string(undefined).nullable(),
      usermode_values: this.attr(undefined).nullable(),
      user_device_heat_stages_conf: this.string(undefined).nullable(),
      user_device_heat_stages_values: this.attr(undefined).nullable(),
      user_device_cooling_stages_conf: this.string(undefined).nullable(),
      user_device_cooling_stages_values: this.attr(undefined).nullable(),
      VMC_relay_mode_conf: this.attr(undefined).nullable(),
      VMC_relay_mode_values: this.attr(undefined).nullable(),
      webserver_id: this.attr(undefined),
      r32_alarm_active: this.boolean(undefined).nullable(),
      r32_alarm_compat_info: this.attr(undefined).nullable(),
      r32_alarm_readonly: this.boolean(undefined).nullable(),
      range_sp_acs_max: this.number(undefined).nullable(),
      range_sp_acs_min: this.number(undefined).nullable(),
      range_sp_auto_air_max: this.number(undefined).nullable(),
      range_sp_auto_air_min: this.number(undefined).nullable(),
      range_sp_cool_air_max: this.number(undefined).nullable(),
      range_sp_cool_air_min: this.number(undefined).nullable(),
      range_sp_dry_air_max: this.number(undefined).nullable(),
      range_sp_dry_air_min: this.number(undefined).nullable(),
      range_sp_emerheat_air_max: this.number(undefined).nullable(),
      range_sp_emerheat_air_min: this.number(undefined).nullable(),
      range_sp_hot_air_max: this.number(undefined).nullable(),
      range_sp_hot_air_min: this.number(undefined).nullable(),
      range_sp_vent_air_max: this.number(undefined).nullable(),
      range_sp_vent_air_min: this.number(undefined).nullable(),
      range_sp_stop_air_max: this.number(undefined).nullable(),
      range_sp_stop_air_min: this.number(undefined).nullable(),
      range_sp_real_cool_max: this.number(undefined).nullable(),
      range_sp_real_cool_min: this.number(undefined).nullable(),
      range_sp_real_heat_max: this.number(undefined).nullable(),
      range_sp_real_heat_min: this.number(undefined).nullable(),
      serialnum_mainb: this.string(undefined).nullable(),
      serialnum_mainb_gw: this.string(undefined).nullable(),
      serialnum_relayb: this.string(undefined).nullable(),
      serialnum_trvb: this.string(undefined).nullable(),
      serialnum_airqb: this.string(undefined).nullable(),
      serialnum_airqsensor: this.string(undefined).nullable(),
      valves_per_zone_limit: this.number(undefined).nullable()
    };
  }

  get hasAidooConfig() {

    return (this.min_cold_setpoint !== undefined && this.min_cold_setpoint !== null &&
      this.min_cold_setpoint_values && this.min_cold_setpoint_values.length > 0) ||
      (this.heating_available && this.max_heat_setpoint !== undefined && this.max_heat_setpoint !== null &&
      Array.isArray(this.max_heat_setpoint_values) && this.max_heat_setpoint_values.length > 0) ||
      (this.dehum_function_active !== undefined && this.dehum_function_active !== null) ||
      (this.dehum_function_humidity_conf !== undefined && this.dehum_function_humidity_conf !== null &&
      Array.isArray(this.dehum_function_humidity_values) && this.dehum_function_humidity_values.length > 0) ||
      (this.temp_auto_mode_active !== undefined && this.temp_auto_mode_active !== null) ||
      (this.max_cool_battery_temperature_conf !== undefined && this.max_cool_battery_temperature_conf !== null &&
      Array.isArray(this.range_max_cool_battery_temperature_values) && this.range_max_cool_battery_temperature_values.length > 0) ||
      (this.min_heat_battery_temperature_conf !== undefined && this.min_heat_battery_temperature_conf !== null &&
      Array.isArray(this.range_min_heat_battery_temperature_values) && this.range_min_heat_battery_temperature_values.length > 0) ||
      (this.sp_limits_forced !== undefined && this.sp_limits_forced !== null) ||
      (this.aq_high_conf !== undefined && this.aq_high_conf !== null &&
      this.range_aq_high_min !== undefined && this.range_aq_high_min !== null &&
      this.range_aq_high_max !== undefined && this.range_aq_high_max !== null) ||
      (this.aq_low_conf !== undefined && this.aq_low_conf !== null &&
      this.range_aq_low_min !== undefined && this.range_aq_low_min !== null &&
      this.range_aq_low_max !== undefined && this.range_aq_low_max !== null)
  }

  /**
   * AIRTOOLS SETTINGS [Sections]
   */

  get showSystemBlock() {
    return (this.supermaster !== undefined
        && this.supermaster !== null)
      || (this.plenum_mode !== undefined
        && this.plenum_mode !== null)
      || (this.hitachi_prot_active !== undefined
        && this.hitachi_prot_active !== null)
      || (this.protection_dew_active !== undefined
        && this.protection_dew_active !== null)
      || (this.protection_dew_conf !== undefined
        && this.protection_dew_conf !== null)
      || (this.global_vent_on !== undefined
        && this.global_vent_on !== null)
      || (this.aux_heat_on !== undefined
        && this.aux_heat_on !== null)
      || (this.away_vacation_time_conf !== undefined
        && this.away_vacation_time_conf !== null)
      || (this.away_unnocupied_time_conf !== undefined
        && this.away_unnocupied_time_conf !== null)
      || (this.away_unnocupied_hist_conf !== undefined
        && this.away_unnocupied_hist_conf !== null)
      || (this.antifreeze !== undefined
        && this.antifreeze !== null)
      || (this.air_supply_type_conf !== undefined
        && this.air_supply_type_conf !== null
        && this.air_supply_type_values
        && this.air_supply_type_values.length > 0)
      || (this.display_localtemp !== undefined
        && this.display_localtemp !== null)
      || (this.aq_high_conf !== undefined
        && this.aq_high_conf !== null)
      || (this.aq_low_conf !== undefined
        && this.aq_low_conf !== null)
      || (this.radio_enabled !== undefined &&
          this.radio_enabled !== null)
  }

  get showConfigStagesBlock() {
    return (this.cooling_available !== undefined
        && this.cooling_available !== null)
      || (this.heating_available !== undefined
        && this.heating_available !== null)
      || (this.combined_cool_available !== undefined
        && this.combined_cool_available !== null)
      || (this.cool_hist_conf !== undefined
        && this.cool_hist_conf !== null)
      || (this.combined_heat_available !== undefined
        && this.combined_heat_available !== null)
      || (this.heat_hist_conf !== undefined
        && this.heat_hist_conf !== null)
      || (this.main_stage_conf !== undefined
        && this.main_stage_conf !== null
        && this.main_stages_values
        && this.main_stage_values.length > 0
      || this.heating_config_temp_gap_conf !== undefined
        && this.heating_config_temp_gap_conf !== null
        && this.heating_config_temp_gap_values
        && this.heating_config_temp_gap_values.length > 0);
  }

  get showAutoChangeBlock() {
    return this.autochange_ovr !== undefined && this.autochange_ovr !== null
  }

  get showBatteryTempBlock() {
    return this.max_cool_battery_temperature_conf !== undefined ||
     this.min_heat_battery_temperature_conf !== undefined ||
     (this.dehum_function_active !== undefined && this.dehum_function_active !== null) ||
     (this.temp_auto_mode_active !== undefined && this.temp_auto_mode_active !== null)
  }

  get showIOBlock() {
    return this.relay_conf !== undefined && this.relay_conf !== null ||
    this.relay_o1_conf !== undefined && this.relay_o1_conf !== null ||
    this.relay_o2_conf !== undefined && this.relay_o2_conf !== null ||
    this.d1_input_conf !== undefined && this.d1_input_conf !== null
  }

  get hasVMCSelect() {
    return this.relay_conf !== undefined && this.relay_conf !== null
  }

  get hasO1Select() {
    return this.relay_o1_conf !== undefined && this.relay_o1_conf !== null
  }

  get hasO2Select() {
    return this.relay_o2_conf !== undefined && this.relay_o2_conf !== null
  }

  get showProtectionTemp() {
    return (this.protection_temp_heating_conf !== undefined && this.protection_temp_heating_conf !== null) ||
    (this.protection_temp_cooling_conf !== undefined && this.protection_temp_cooling_conf !== null)
  }

  get showQAdaptBlock() {
    return (this.qadapt_conf !== undefined && this.qadapt_conf !== null) ||
    (this.system_weight_manual !== undefined && this.system_weight_manual!== null) ||
    (this.m010_min_voltage_conf !== undefined && this.m010_min_voltage_conf !== null) ||
    (this.m010_max_voltage_conf !== undefined && this.m010_max_voltage_conf !== null) ||
    (this.prop_opening !== undefined && this.prop_opening !== null) ||
    (this.minimum_air !== undefined && this.minimum_air !== null) ||
    (this.cool_standby !== undefined && this.cool_standby !== null) ||
    (this.cool_standby_hist_conf !== undefined && this.cool_standby_hist_conf !== null) ||
    (this.heat_standby !== undefined && this.heat_standby !== null) ||
    (this.heat_standby_hist_conf !== undefined && this.heat_standby_hist_conf !== null)
  }

  get showColdRadiantControlBlock()  {
    return (this.rad_alg_cool_air_prep !== undefined && this.rad_alg_cool_air_prep !== null) ||
    (this.rad_alg_cool_air_diff_conf !== undefined && this.rad_alg_cool_air_diff_conf !== null) ||
    (this.rad_alg_cool_air_deact_diff_conf !== undefined && this.rad_alg_cool_air_deact_diff_conf !== null) ||
    (this.rad_alg_cool_air_deact_diff_values?.length > 0)
  }

  get showHeatRadiantControlBlock()  {
    return (this.rad_alg_heat_air_prep !== undefined && this.rad_alg_heat_air_prep !== null) ||
    (this.rad_alg_heat_air_diff_conf !== undefined && this.rad_alg_heat_air_diff_conf !== null) ||
    (this.rad_alg_heat_air_deact_diff_conf !== undefined && this.rad_alg_heat_air_deact_diff_conf !== null) ||
    (this.rad_alg_heat_air_deact_diff_values?.length > 0) ||
    (this.rad_alg_heat_valv_deact_diff_conf !== undefined && this.rad_alg_heat_valv_deact_diff_conf !== null) ||
    (this.rad_alg_heat_valv_deact_diff_values?.length > 0)
  }

  get hasAirtoolsSettings() {
    return this.showSystemBlock ||
      this.showAutoChangeBlock ||
      this.showBatteryTempBlock ||
      this.showConfigStagesBlock ||
      this.showIOBlock ||
      this.hasO1Select ||
      this.hasO2Select ||
      this.showProtectionTemp ||
      this.showQAdaptBlock ||
      this.showColdRadiantControlBlock ||
      this.showHeatRadiantControlBlock
  }

  get getTotalTrvValves() {
    const zones = Zone.query().where('system_number', this.system_number).get();

    let totalValves = 0;

    // console.log("ZONES IN GET TOTAL TRV VALVES", zones);

    zones.forEach(zone => {
      if(zone.associated_trv_valves !== undefined) {
        totalValves += zone.associated_trv_valves;
      }
    })

    return totalValves;
  }


  /** *************************************************************
   * ACCIONES
   ************************************************************** */

   /**
    * @override
    */
  getTemptInUnits(temp) {
    if (temp === null) return null;
    return (this.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? temp.celsius : temp.fah;
  }
}

export default System ;
