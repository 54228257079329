// import User from 'Auth/models/User';
import ApiService from 'Core/services/api.service';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import DemoService from 'Core/services/demo.service';
import energyApi from 'Units/mocks/energyApi.mock';
import User from 'Auth/models/User';
import UnitsError from './errors.service';
import store from 'Core/store/store';
import CORECONSTANTS from 'Core/constant';
import UNITSCONSTANTS from 'Units/constant';

const CONSTANTS = {...CORECONSTANTS, ...UNITSCONSTANTS};

const WidgetsService = {

  /**
   * Devuelve la información sobre el precio eléctrico y tarifas en una zona horaria
   *
   * @param {String} installationID - Id de la instalación
   * @param {String} countryCode - Código del país
   * @param {String} region - Región
   * @param {String} date - Fecha del precio eléctrico
   * @return {Object} - Información sobre las tarifas y precios por horas
   */
  async getPricesInfo({installationID, countryCode, timezone, region, date, tariffId, regulatedTariffId = null}) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = `${admin}tariffs/${tariffId}/price`;

    if(countryCode || region || date ) requestUrl += "?";

    if(countryCode) requestUrl += `country_code=${countryCode}&`;
    if(region) requestUrl += `region=${region}&`;
    if(date) requestUrl += `date=${date}&`;
    if(timezone) requestUrl += `timezone=${timezone}&`;
    if(regulatedTariffId) requestUrl += `regulatedTariffId=${regulatedTariffId}&`;
    if(installationID) requestUrl += `installationId=${installationID}&`;

    let responseData;
    try {
      let response;

      if(!store.getters.getIsDemo){
        response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);

        if(response === CONSTANTS.PRICE_NOT_FOUND) return;

        responseData = response.data;
      } else {
        responseData = await DemoService.getPrices(installationID);
      }

      return cloud2web.getPriceData(responseData, timezone, region);
    } catch(error) {
      throw new UnitsError(error);
    }
  },

  /**
   *  Devuelve la información meteorológica de una ubicación
   *
   * @param {String} installationID - Id de la instalación
   * @param {String} locationID - ID de Google Place
   * @param {Boolean} aq - Información sobre la calidad de aire
   * @returns
   */
  async getWeatherInfo(installationID, locationID, aq = true){
    let requestUrl = `weather/${locationID}`;

    if(aq === true) {
      requestUrl += '?aq=true';
    }

    try {
      let response;
      let responseData;
      if(!store.getters.getIsDemo){
        response = await ApiService.request(requestUrl);
        responseData = response.data;
      } else {
        responseData = await DemoService.getWeather(installationID);
      }

      const data = cloud2web.getWeatherData(responseData, aq);

      return data;

    } catch(error) {
      throw new UnitsError(error);
    }
  },

  /**
   *  Devuelve la información meteorológica de una ubicación
   *
   * @param {String} startdate - Fecha y hora actual en formato ISO.
   * @returns
   */
  async getEcowattInfo({startdate, query}){
    const requestUrl = `appinfo/rte/ecowatt?startdate=${startdate}&${query}`;

    try {
      let response;
      let responseData;
      if(!store.getters.getIsDemo){
        response = await ApiService.get(requestUrl);
        responseData = response.data;
      } else {
        responseData = await DemoService.getEcowatt();
      }

      return responseData;
    } catch(error) {
      console.log({error})
      throw new UnitsError(error);
    }
  },



  async getConsumptionElectricInfo(installationID, startDate, range = 'day') {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestUrl = `${admin}bg/energy?installation_id=${installationID}&startdate=${startDate}&range=${range}`;
    let responseData;

    if(!store.getters.getIsDemo){
      try {
        const response = await ApiService.get(requestUrl);
        responseData = response.data;

      } catch( error ) {
        throw new UnitsError(error);
      }

    } else {
      switch(range){
        case 'day':
          responseData = energyApi.get24hMockObject(startDate, 2);
          break;
          case 'week':
          responseData = energyApi.getWeekMockObject(startDate, 2, user.sundayFirst);
          break;
        case 'month':
          responseData = energyApi.getMonthMockObject(startDate, 2);
          break;
        case 'year':
          responseData = energyApi.getYearMockObject(startDate, 2);
          break;
        default:
      }
    }

    return responseData;
  },

  async getCoffeeCupPrice({currencyCode}) {
    const requestUrl = `tariffs/reference_price?currency=${currencyCode}`;
    let responseData;

    if(!store.getters.getIsDemo){
      try {
        const response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);
        responseData = response.data;

      } catch( error ) {
        throw new UnitsError(error);
      }

    } else {
      responseData = {
        "type": "coffeeWithMilk",
        "currency": {
          "code": "EUR",
          "symbol": "€"
        },
        "price": 3
      }
    }

    return responseData;
  },

  async getAvailablesTariffs({ installationId }) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();
    const lang = user?.lang || 'es';
    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestUrl = `${admin}tariffs/available_tariffs?installationId=${installationId}&lan=${lang}`;
    let responseData;

    if(!store.getters.getIsDemo){
      try {
        const response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);
        responseData = response.data;

      } catch( error ) {
        throw new UnitsError(error);
      }

    } else {
      responseData = {
        "fixed": true,
        "custom": true,
        "regulated": [
          {
            "_id": "667ac20125c985b06e3fc118",
            "name": "PVPC 2.0 TD España",
            "currency": "EUR",
            "country_code": "ES",
            "period_legend": {
              "1": {
                "key": "price.rates.low.title",
                "color": "#1992D4"
              },
              "2": {
                "key": "price.rates.mid.title",
                "color": "#40C3F7"
              },
              "3": {
                "key": "price.rates.high.title",
                "color": "#B3EBFF"
              }
            }
          }
        ]
      }
    }

    return responseData;
  }

};

export default WidgetsService;
