import i18n from 'Core/services/language.service';

function getExtraData(object) {

  if(typeof object === 'string') {
    return ` ${object}`
  }

  let extraData;

  Object.keys(object).forEach(key => {
    switch(key) {
      case 'ids':
      case 'zones':
      case 'valves':
        extraData = ` (${object[key].join(', ')})`;
        break;
      default:
    }
  });

  return extraData;
}

/**
* Devuelve el listado de textos traducidos de los avisos de zona
* NOTA: El mensaje puede agregar ciertas claves opcionales para mostrar botones, callbacks, etc.
*       - confirm: boolean - Indica si el mensaje permite check de confirmación
*       - buttons: object - Indica si el mensaje tiene botones (aceptar, cancelar, etc.)
*       - acceptCallback: function - Indica si el mensaje realiza una acción al aceptar
*       - cancelCallback: function - Indica si el mensaje realiza una acción al cancelar
*       - confirmCallback - function - Indica si el mensaje realiza una acción al aceptar con el check de confirmación
*       - noshow_param: string - Indica si el mensaje tiene un parámetro de control para no mostrarlo (viene desde backend)
*       - title: string - Indica el título del mensaje
*       - info: string - Indica la información adicional del mensaje
*       - extraData: string - Indica información adicional del mensaje (Suele venir desde backend)
*
* @param {Array} warnings - Lista de (objetos) avisos de la zona
* @return {Array} - Lista de avisos traducidos de la zona
*/

const getWarningTexts = (warnings, deviceID) => {
  const warningsArray = [];

  for(let i=0; i<warnings.length; i++){
    const object = warnings[i];
    let extraData = '';

    const message = {};
    // Si existe información extra, la extraemos.
    if (object?.data) {
      extraData = getExtraData(object.data);
    }
    if(object?.show?.noshow_param) {
      message.noshow_param = object.show.noshow_param;
      message.noshow_check = false;
      message.buttons = {
        accept: true,
      }
    }
    if(deviceID) {
      message.deviceID = deviceID;
    }
    //
    // Si existe la traducción del texto lo incluímos.
    //

    const warning = i18n.global.t(`zones.warnings.${object._id}`);

    if(typeof warning === 'string' && !warning.includes(`zones.warnings.${object._id}`)){
      const title = i18n.global.t(`zones.warnings.${object._id}`);
      // Si el error es de unidad debemos mostrar el código de error
      if(object._id === 'ac_unit_error') {
        message.title = `${title} ${object.data.error_code}`;
      } else {
        message.title = title + extraData;
      }

    } else {

      const title = i18n.global.t(`zones.warnings.${object._id}.title`);
      const info = i18n.global.t(`zones.warnings.${object._id}.info`);

      if(typeof title === 'string' && !title.includes(`zones.warnings.${object._id}.title`)){
        // Si el error es de unidad debemos mostrar el código de error
        if(object._id === 'ac_unit_error') {
          message.title = `${title} ${object.data.error_code}`;
        } else {
          message.title = title + extraData;
        }
      }


      if(typeof info === 'string' && !info.includes(`zones.warnings.${object._id}.info`)){
        message.info = info;
      }
    }

    // Si el mensaje tiene contenido (title, info)
    if(Object.keys(message).length > 0) {
      warningsArray.push(message);
    }

  }

  return warningsArray;
};


const hasWarnings = warnings => {
  const totalWarnings = [];
  for(let i=0; i < warnings.length; i++) {
    const warning = i18n.global.t(`zones.warnings.${warnings[i]._id}`)
    if(typeof warning === 'string' && !i18n.global.t(`zones.warnings.${warnings[i]._id}`).includes('zones.warnings')){
      const object = warnings[i];
      const title = i18n.global.t(`zones.warnings.${object._id}`);
      totalWarnings.push(title);
    } else if(typeof warning === 'object'){

      totalWarnings.push(warning);

    }
  }

  return totalWarnings.length;
}

export { getWarningTexts, hasWarnings };
