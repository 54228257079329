
// VIEWS and ROUTES
import installationsRoutes from './routes/installations.routes';
import addDevicesRoutes from './routes/addDevices.routes';
import airtoolsRoutes from './routes/airtools.routes';
// import airtoolsBLERoutes from './routes/airtoolsBLE.routes';
import configBLERoutes from './routes/configBLE.routes';


export default [
  {
    path: '/',
    name: 'unitsView',
    component: () => import('./pages/Units'),
    parent: '/main',
    redirect: {
      name: 'installations',
    },
    children: [
      //
      // RUTAS de Installations
      //
      installationsRoutes,
      //
      // RUTAS de Add Devices
      //
      addDevicesRoutes,
      //
      // RUTAS de Airtools
      //
      airtoolsRoutes,
      //
      // RUTAS de Configuración BLE (Parámetros Airtools, Programaciones, etc.)
      //
      configBLERoutes
    ]
  },
];
