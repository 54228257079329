/**
 * MIXIN: maneja la escucha del CustomEvent 'devicesMovedGroup' despachado desde socket
 * Este evento se lanzará cuando se mueva una zona de un grupo a otro y el usuario esté
 * conectado al socket mediante 'listen_installation'.
 * Por defecto se recarga la página. Se puede sobreescribir su comportamiento en una vista
 * añadiendo un método 'devicesMovedGroupHandler'
 */
import { onMounted, onUnmounted } from 'vue'
import { reloadPage } from 'Core/utils/utils';

export const useDevicesMovedGroup = () => {

  const deviceMoved = (e) => {
    if(typeof devicesMovedGroupHandler === 'function') {
      devicesMovedGroupHandler(e);
    } else {
      console.log("No hay función definida, recargo la página");
      reloadPage()
    }
  }

  onMounted(() => {
    document.addEventListener('devicesMovedGroup', deviceMoved, false);
  })

  onUnmounted(() => {
    document.removeEventListener('devicesMovedGroup', deviceMoved, false);
  })

  return { deviceMoved }
}
