/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fingerprint': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M23.746 5.96a.612.612 0 01-.306-.08C20.88 4.56 18.666 4 16.014 4c-2.64 0-5.147.626-7.427 1.88a.677.677 0 01-.906-.266.674.674 0 01.266-.906 16.726 16.726 0 018.067-2.042c2.84 0 5.32.627 8.04 2.026a.653.653 0 01.28.894.653.653 0 01-.587.374zm-19.08 7a.665.665 0 01-.546-1.054c1.32-1.866 3-3.334 5-4.36 4.186-2.16 9.546-2.172 13.746-.012 2 1.026 3.68 2.48 5 4.333a.667.667 0 01-.16.934.665.665 0 01-.934-.16c-1.2-1.68-2.72-3-4.52-3.92-3.826-1.96-8.72-1.96-12.533.014-1.814.934-3.334 2.266-4.534 3.946a.581.581 0 01-.52.28l.001-.001zM13 29.054a.623.623 0 01-.466-.2c-1.16-1.16-1.787-1.906-2.68-3.52-.92-1.64-1.4-3.64-1.4-5.787 0-3.96 3.386-7.186 7.546-7.186s7.546 3.226 7.546 7.186a.66.66 0 01-.666.667.66.66 0 01-.666-.666c0-3.227-2.787-5.854-6.214-5.854-3.426 0-6.214 2.626-6.214 5.853 0 1.92.426 3.694 1.24 5.134.854 1.534 1.44 2.186 2.466 3.226a.683.683 0 010 .947.73.73 0 01-.493.2zm9.56-2.466c-1.586 0-2.986-.4-4.134-1.187-1.986-1.346-3.174-3.534-3.174-5.854a.66.66 0 01.667-.666.66.66 0 01.666.666c0 1.88.96 3.654 2.586 4.747.946.64 2.054.946 3.386.946.32 0 .854-.04 1.387-.134a.666.666 0 01.773.546.666.666 0 01-.546.774 9.14 9.14 0 01-1.614.16l.003.002zm-2.68 2.746a.836.836 0 01-.174-.026c-2.12-.587-3.506-1.374-4.96-2.8a9.732 9.732 0 01-2.894-6.96c0-2.16 1.84-3.92 4.107-3.92 2.266 0 4.106 1.76 4.106 3.92 0 1.426 1.24 2.586 2.774 2.586 1.533 0 2.773-1.16 2.773-2.587 0-5.026-4.333-9.106-9.666-9.106-3.786 0-7.254 2.106-8.814 5.374-.52 1.08-.786 2.346-.786 3.734 0 1.04.094 2.68.894 4.814a.651.651 0 01-.386.853A.662.662 0 016 24.83a14.844 14.844 0 01-.974-5.28c0-1.6.306-3.054.906-4.32 1.774-3.72 5.707-6.134 10.014-6.134 6.066 0 11 4.68 11 10.44 0 2.16-1.84 3.92-4.106 3.92s-4.106-1.76-4.106-3.92c0-1.426-1.24-2.586-2.774-2.586s-2.774 1.16-2.774 2.586c0 2.28.88 4.414 2.494 6.014 1.266 1.254 2.48 1.946 4.36 2.466.36.094.56.467.466.814a.65.65 0 01-.626.506v-.002z" _fill="#323F4B"/>'
  }
})
