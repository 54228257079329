import User from '../models/User';
import { computed } from 'vue';
import constants from 'Auth/constant';

export const useUser = () => {
  const user = computed(() => User.query().first());
  const lang = computed(() => user?.value?.lang);
  const sundayFirst = computed(() => user?.value?.sundayFirst);
  const ampm = computed(() => user?.value?.ampm);
  const units = computed(() => user?.value?.units);
  const hasInstallations = computed(() => user?.value?.installations === true);
  const pendingInstallations = computed(() => user?.value?.pendingInstallations);
  const noHaptic = computed(() => user?.value?.noHaptic);
  const totalInstallations = computed(() => user?.value?.totalInstallations);
  const isAdmin = computed(() => user?.value?.role === constants.ROLE.SUPER_ADMIN );

  return {
    ampm,
    hasInstallations,
    isAdmin,
    lang,
    noHaptic,
    pendingInstallations,
    sundayFirst,
    totalInstallations,
    units,
    user,
  }
}
