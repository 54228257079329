import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const store = {
  state,
  mutations,
  getters,
  actions
}

export default store;
