import i18n from 'Core/services/language.service';
import moment from 'moment-timezone';
import store from 'Core/store/store';
// import User from 'Auth/models/User'
/**
 *
 * @param {Array<{}>} hourlyArray
 * @returns
 */
function getCompleteHourlyDay(hourlyArray, timeZone){
  const actualDate = new Date();
  let actualHourIndex = null;
  const options = {
    hour12: false,
    timeZone,
  };

  const [,localHour] = actualDate.toLocaleString('es-ES', options).split(' ');
  // hora local en formato 12:12:12
  for(let i = 0; i < hourlyArray.length; i++) {
    const [, registryHour] = (new Date(hourlyArray[i].dt)).toLocaleString('es-ES',options).split(' '); // fecha UTC de la posición del array;
    // Obtenemos el primer elemento del array de split ":" correspondiente a la hora
    if(localHour.split(":")[0] === registryHour.split(":")[0]){
      actualHourIndex = i;
      break;
    }
  }

  return {
    actualHour: hourlyArray[actualHourIndex],
    nextHours: hourlyArray.slice(actualHourIndex + 1, actualHourIndex + 25)
  }

}

/**
 *
 * @param {Array<{}>} dailyArray
 * @param {string} timeZone
 * @returns
 */
function getActualDate(dailyArray, timeZone) {

  let actualDate;

  if(store.getters.getIsDemo){
    actualDate = new Date(dailyArray[0].dt);
  } else {
    actualDate = new Date();
  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone,
  };

  let actualDayIndex = null;

  const [, localDay] = actualDate.toLocaleString('es-ES', options).split(' ');
  for (let i = 0; i < dailyArray.length; i++) {
    const [, dailyDay] = new Date(dailyArray[i].dt).toLocaleString('es-ES', options).split(' ');
    if (dailyDay === localDay) {
      actualDayIndex = i;
      break;
    }
  }

  return {
    actualDay: dailyArray[actualDayIndex],
    nextDays: dailyArray.slice(actualDayIndex + 1),
  }
}

function getDayString(dailyEntry) {
 // 0 -> doming; 6 -> sabado
 const day = (new Date(dailyEntry)).getUTCDay();

 return i18n.global.t(`server.days.${day}`);
}

function getLocalHour(StringUTC, timeZone) {
  return (new Date(StringUTC)).toLocaleTimeString('es-ES',{timeZone}).split(":")[0]
}

function getLocalTimeAMPM(StringUTC, timeZone, ampm, units) {
  let time = null

  if (timeZone) {
    if (ampm) {
      time = units ? moment(StringUTC).tz(timeZone).format('h:mm A') : moment(StringUTC).tz(timeZone).format('h:mm')
    } else {
      time = units ? `${moment(StringUTC).tz(timeZone).format('HH:mm')}h` : moment(StringUTC).tz(timeZone).format('HH:mm')
    }
  } else if (!timeZone) {
    if (ampm) {
      time = units ? moment(StringUTC).format('h:mm A') : moment(StringUTC).format('h:mm')
    } else {
      time = units ? `${moment(StringUTC).format('HH:mm')}h` : moment(StringUTC).format('HH:mm')
    }
  }

  return time
}

function getLocalHourAMPM(StringUTC, timeZone, ampm, units) {
  let hour = null

  if(!StringUTC) return '--';

  if (timeZone) {
    if (ampm) {
      hour = units ? moment(StringUTC).tz(timeZone).format('h A') : moment(StringUTC).tz(timeZone).format('h')
    } else {
      hour = units ? `${moment(StringUTC).tz(timeZone).format('HH')} h` : moment(StringUTC).tz(timeZone).format('HH')
    }
  } else if (!timeZone) {
    if (ampm) {
      hour = units ? moment(StringUTC).format('h A') : moment(StringUTC).format('h')
    } else {
      hour = units ? `${moment(StringUTC).format('HH')} h` : moment(StringUTC).format('HH')
    }
  }

  return hour
}

function getHourUnits(StringUTC, timeZone, ampm) {
  let units = 'h'

  if (ampm) {
    units = moment(StringUTC).tz(timeZone).format('A')
  }

  return units
}

function addUnit24Hours(ampm) {
  return !ampm ? 'h' : ''
}

function addHour(date, hour) {
  return moment(date).add(hour, 'h')
}

function parseHour2ampm(hour) {
  const time = moment(hour, ['H']).format('h A').split(' ')
  return { hour: time[0], unit: time[1] }
}

function parseAMPM2Hour(hour, ampm) {
  const parsedHour = String(parseInt(hour, 10)) + ampm.toUpperCase();
  return Number(moment(parsedHour, ['hA']).format('H'))
}

export {
  getCompleteHourlyDay,
  getActualDate,
  getDayString,
  getLocalHour,
  getLocalTimeAMPM,
  getLocalHourAMPM,
  addUnit24Hours,
  getHourUnits,
  addHour,
  parseHour2ampm,
  parseAMPM2Hour,
}
