// import "regenerator-runtime";
import { createApp, ref } from 'vue';
import i18n from 'Core/services/language.service';
import store from 'Core/store/store';
import { getRouter, getConstants } from 'Core/services/modules.service';
import backgroundTransition from 'Units/directives/backgroundTransition';

/* COMPAT CONFIGURATION ////////////
import { configureCompat } from 'vue'

configureCompat({
  COMPONENT_V_MODEL: false,
  ATTR_FALSE_VALUE: false,
  RENDER_FUNCTION: false,
  WATCH_ARRAY: false,
})
// COMPAT CONFIGURATION ////////////*/

// Módulos
import Core from 'Core';
import Auth from 'Auth';
import Units from 'Units';

// Aplicación
import log from 'Core/services/log.service';
import Toast from 'Core/components/Toast';
import Dialog from 'Core/components/Dialog';
import PopupGlobal from 'Core/components/PopupGlobal';
// import RollbarService from 'Core/services/Rollbar.service';

// Obtengo las rutas, modelos y constantes de los módulos
const modules = [Core, Auth, Units];

import App from './App.vue';

const app = createApp(App).use(i18n).use(store);
const Router = getRouter(modules);
const Constants = getConstants(modules);

// SVG ICONS
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import '@yzfe/svgicon/lib/svgicon.css';

// Scroll Picker component
import VueScrollPicker from "vue-scroll-picker";
import "vue-scroll-picker/lib/style.css";
app.use(VueScrollPicker);

// Date Picker component
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
app.component('VueDatePicker', VueDatePicker);

// Estilos de la aplicación
import './design/main.scss';
import 'Core/design/main.scss';
import 'Auth/design/main.scss';
import 'Units/design/main.scss';

// Global filters
import { filters } from 'Core/utils';
app.config.globalProperties.$filters = filters;

// Configuro propiedades y métodos globales
app.provide('constants', Constants);
app.provide('log', log);
app.provide('dialog', Dialog);
app.provide('toast', Toast)
app.provide('popup', PopupGlobal);
app.provide('filters', filters);

// Hay que proveer el i18n para poder injectarlo en los descendientes
app.provide('i18n', i18n );
// En movil hay que esperar a que se renderize el dom para proveer el popup
// switchToSetting
const isSwitchToSetting = ref(false);
app.provide('switchToSettings', { isSwitchToSetting, setIsSwitchToSetting: (isSwitchSetting) => isSwitchToSetting.value = isSwitchSetting });

app.directive('background-transition', backgroundTransition);

app.use(Router);
app.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });


// TODO: Eliminar tras pasar completamente a vue 3
app.config.globalProperties.$constants = Constants;
app.config.globalProperties.$log = log;
app.config.globalProperties.$switchToSettings = false;
app.config.globalProperties.$isOnline = true;
app.config.globalProperties.$popup = PopupGlobal
app.config.globalProperties.$toast = Toast
app.config.globalProperties.$dialog = Dialog
app.config.globalProperties.$i18n = i18n.global;

import AzAvatar from 'Core/components/Avatar/Avatar.vue';
import AzButton from 'Core/components/Button/Button.vue';
import AzCell from 'Core/components/Cell/Cell.vue';
import AzCellGroup from 'Core/components/CellGroup/CellGroup.vue';
import AzCheckbox from 'Core/components/Checkbox/Checkbox.vue';
import AzCheckboxGroup from 'Core/components/CheckboxGroup/CheckboxGroup.vue';
import AzCol from 'Core/components/Col/Col.vue';
import AzContainer from 'Core/components/Container/Container.vue';
import AzFeedback from 'Core/components/Feedback/Feedback.vue';
import AzFieldset from 'Core/components/Fieldset/Fieldset.vue';
import AzInput from 'Core/components/Input/Input.vue';
import AzLoading from 'Core/components/Loading/Loading.vue';
import AzRow from 'Core/components/Row/Row.vue';
import AzSwitch from 'Core/components/Switch/Switch.vue';
import AzTabBar from 'Core/components/TabBar/TabBar.vue';
import AzTabBarItem from 'Core/components/TabBarItem/TabBarItem.vue';
import AzSectionInfo from 'Core/components/SectionInfo';
import AzFoldingBox from 'Core/components/FoldingBox';
import AzSelect from 'Core/components/Select';
import AzDotButton from 'Core/components/DotButton';
import AzPill from 'Core/components/Pill';
import AzPillsGroup from 'Core/components/PillsGroup';
import NavBar from 'Core/components/NavBar.component.vue';

app.component('AzAvatar', AzAvatar);
app.component('AzButton', AzButton);
app.component('AzCell', AzCell);
app.component('AzCellGroup', AzCellGroup);
app.component('AzCheckbox', AzCheckbox);
app.component('AzCheckboxGroup', AzCheckboxGroup);
app.component('AzCol', AzCol);
app.component('AzContainer', AzContainer);
app.component('AzFeedback', AzFeedback);
app.component('AzFieldset', AzFieldset);
app.component('AzInput', AzInput);
app.component('AzLoading', AzLoading);
app.component('AzRow', AzRow);
app.component('AzSwitch', AzSwitch);
app.component('AzTabBar', AzTabBar);
app.component('AzTabBarItem', AzTabBarItem);
app.component('AzSectionInfo', AzSectionInfo);
app.component('AzFoldingBox', AzFoldingBox);
app.component('AzSelect', AzSelect);
app.component('AzDotButton', AzDotButton);
app.component('AzPill', AzPill);
app.component('AzPillsGroup', AzPillsGroup);
app.component('NavBar', NavBar);

Router.isReady().then(() => {
  app.mount('#app');
})
