import { createApp, h } from 'vue';
import i18n from 'Core/services/language.service';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import AzLoading from 'Core/components/Loading/Loading.vue';
import VuePopup from 'Core/components/PopupModal';

let popupInstance = null;

/**
 * Crea una nueva instancia del componente,
 * si ya existe una devuelve la que había
 */
function createInstance(newProps) {
  if (popupInstance) popupInstance.unmount();

  const el = document.createElement('div')
  //
  // NOTA: no es necesario añadir en el DOM al body porque el componente PopupModal ya lo hace en el mounted()
  //
  popupInstance = createApp({
    render() {
      return h(VuePopup, {
        ...newProps,
        callback:  action => {
          if (action === 'confirm') {
            popupInstance.options.resolve(action)
          } else {
            popupInstance.options.reject(action)
          }
          popupInstance = null
        },
      })
    }
  });

  popupInstance.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });
  popupInstance.component('AzLoading', AzLoading);
  popupInstance.mount(el)

  return popupInstance;
}

/**
 * Crea un Objeto Dialog
 *
 * @param {Object} options - Opciones del Dialog
 * @param {Object} actions - Objeto para capturar emmiters del componente y ejecutar acciones (ej: {close: () => { ... [function] ... }})
 */
function createPopup(options) {
  return new Promise((resolve, reject) => {
    const popup = createInstance(options);
    // Le paso las opciones a la instancia del dialog
    popup.options = { resolve, reject }
  });
}

/**
 * Creo el objeto Popup
 */

const Popup = {
    create: options => {
      return createPopup({
        ...options
      })
    },
    confirm: options => {
      return createPopup({
        buttons: true,
        ...options
      })
    },

    notification: options => {
      return createPopup({
        buttons: true,
        cancelBtn: false,
        title: i18n.global.t('home.invited_title'),
        ...options
      })
    },

    warning: options => {
      return createPopup({
        subtitle: i18n.global.t('zones.adjusts.adjust_zone.warnings'),
        buttons: true,
        cancelBtn: false,
        src: "unitError.svg",
        ...options
      })
    },
 }

export default Popup;
