/**
 * Parse a value to boolean
 *
 * @param {*} - Value to parse
 * @return {Boolean} - Value parse to boolean
 */
const parseBoolean = value => {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
      return true;
    default:
      return false;
  }
};

/**
 * Parse a value to string
 *
 * @param {*} - Value to parse
 * @return {String} - Value parse to string
 */
const parseString = value => {
  const string = String(value).trim();
  return string === 'null' ? null : string;
};

/**
 * Parse a value to number
 *
 * @param {*} - Value to parse
 * @return {Number} - Value parse to string
 */
const parseNumber = value => {
  return Number(value);
};

/**
 * Comprueba que el valor se encuentra entre dos números,
 * en caso contrario redondea al límite más cercano
 *
 * @param {Number} value - Value to parse
 * @param {Number} min - Value to parse
 * @param {Number} max - Value to parse
 * @return {Number} - Value parse to string
 */
const parseBetween = (value, max, min) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

/**
 * Junta varias cadenas de caracteres en una, añadiendo un espacio entre ellas
 * Si una de las cadenas viene vacía no la tiene en cuenta
 * Si ambas cadenas vienen vacías devuelve una cadena vacía.
 *
 * @param {String} string1 - Cadena 1
 * @param {String} string2 - Cadena 2
 * @return {String} - Cadena resultante
 */
const joinString = (string1, string2) => {
  const strings = [];
  if (string1) strings.push(parseString(string1));
  if (string2) strings.push(parseString(string2));
  if (strings.length <= 0) return '';
  return strings.join(' ');
};

export { parseBoolean, parseString, parseNumber, parseBetween, joinString };
