
export const useNotification = () => {

  const createBubleNotification = (elementHTML, styles) => {
    const bubbleDiv = document.createElement('div');

    if(styles) {
      for( const styleKey in styles) {
        bubbleDiv.style[styleKey] = styles[styleKey];
      }
    } else {
      bubbleDiv.classList.add('bubble-notification');
    }

    if(elementHTML) elementHTML.append(bubbleDiv);
  }

  return {
    createBubleNotification,
  }
}
