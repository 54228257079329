/* eslint-disable no-undef */
import AuthError from 'Auth/services/errors.service';
import ApiService from 'Core/services/api.service';
import DemoService from 'Core/services/demo.service';
import StorageService from 'Core/services/storage.service';
import log from 'Core/services/log.service';
import unitTempUtil from 'Auth/utils/unitTemp.util';
import CONSTANTS from 'Core/constant';
import { setLanguage } from 'Core/services/language.service';
// import RollbarService from 'Core/services/Rollbar.service';
import CryptoService from '@/modules/auth/services/crypto.service';
import store from 'Core/store/store';
import User from 'Auth/models/User';
import IndexedDBService from 'Core/services/indexedDB.service';
import { resolveSkinsState } from 'Auth/utils/skin.utils';

/**
 * TODO: unitTempUtil debería estar deconstruido { unitTotempunit, tempunitToUnit }
 */

 /**
  * @type {Promise<string>}
  */
let refreshPromise = null;
let refreshPending = false;

const AuthService = {
  /**
   * Elimina las credenciales inseguras almacenadas en localStorage del usuario para evitar vulnerabilidades
   */
  deleteInsecureCredentials() {
    if (StorageService.getItem('userRemember')) StorageService.removeItem('userRemember')
    if (StorageService.getItem('userEmail')) StorageService.removeItem('userEmail')
    if (StorageService.getItem('userPassword')) StorageService.removeItem('userPassword')
  },

  /**
   * Loguea al usuario, obtiene sus datos y almacena localmente sus credenciales,
   *
   * @param {String} email - Email del usuario
   * @param {String} password - Contraseña del usuario
   * @return {Promise<import('Auth/models/User').default>} - Datos del usuario parseados y validados
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - Si el usuariono existe o si la contraseña es errónea
   * @throws {userNotConfirmed} - Si el usuario todavía no ha validado su cuenta
   * @throws {sanitazeUserDataError} - Error al validar los datos del usuario
   * @throws {invalidUserData} - No se han recibido los datos mínimos necesarios
   * @throws {default} - Ocurrió otro error
   */
  async login({ email, password }) {
    try {
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getUser();
      } else {
        response = await ApiService.post('auth/login', { email, password });
        //
        // Actualizo las cabeceras de axios con el bearer token
        // y almaceno los token en el local storage para futuras visitas
        //
        ApiService.setHeader(response.data.token);
        StorageService.setItem('access_token', response.data.token);
        StorageService.setItem('refresh_token', response.data.refreshToken);
        // Si hay credenciales inseguras almacenadas en el localStorage se eliminan
        this.deleteInsecureCredentials();
      }

      const skin = response?.data?.config?.skin;
      let actualSkin = null;
      let hasSkinsAvail = false;
      let isSkinEnabled = false; // Indica a nivel de usuario si tiene el skin activo (NOTA: NO CONFUNIDIR CON enabled del propio skin (se obtiene en getSkin))

      console.log("skin en auth", skin);
      // Si el usuario tiene skins disponibles
      if(skin) {
        const skinAvailIds = skin.avail_skin_ids;
        const currentSkinId = skin.current_skin_id;
        isSkinEnabled = skin.enabled;
        const skinState = await resolveSkinsState({skinAvailIds, currentSkinId, userId: response?.data?._id, isSkinEnabled});
        actualSkin = skinState.actualSkin;
        hasSkinsAvail = skinState.hasSkinsAvail;
      } else {
        // Si el usuario no tiene skins disponibles deshabilitamos el skin
        console.log("desactivando skin");
        document.dispatchEvent(new CustomEvent('disableSkin'));
      }



      //
      // Transformo los datos al formato de nuestro modelo
      //
      const user = {
        id: response?.data._id,
        userID: response?.data._id,
        name: response?.data?.data?.name,
        lastName: response?.data?.data?.lastName,
        email: response?.data?.email,
        avatar: response?.data?.data?.avatar,
        commercial: response?.data?.data?.commercial,
        notification: response?.data?.config?.notification,
        ampm: response?.data?.config.ampm,
        sundayFirst: response?.data?.config?.sundayFirst,
        noHaptic: response?.data?.config?.noHaptic,
        units: unitTempUtil.unitTotempunit(response?.data?.config?.units),
        lang: response?.data?.config?.lang,
        role: response?.data?.role,
        admin_mode: response?.data?.config.admin_mode,
        integrations: response?.data?.integrations,
        extra_roles: response?.data?.extra_roles,
        skin: actualSkin,
        hasSkinsAvail,
        isSkinEnabled
      };

      // Actualizamos el usuario en Rollbar
      // RollbarService.configure({
      //   payload: {
      //     person: {
      //       id: user.id,
      //       username: user.email,
      //       email: user.email
      //     }
      //   }
      // });

      // RollbarService.info('Sesión iniciada');

      setLanguage(user.lang);

      log.success('AuthService/login');
      return user;
    } catch (error) {
      console.error(error);
      throw new AuthError(error);
    }
  },

  /**
   * Obtiene el token para identificarse contra Oauth2 (Google, Alexa, etc...)
   * @param {string} email
   * @param {string} password
   * @returns {Promise<string>} token de identificación
   * @throws {badParams}
   * @throws {userNotExist}
   * @throws {userNotConfirmed}
   */
  async oauthLogin(email, password) {
    const userData = {
      email,
      password
    }

    try{

      const response = await ApiService.post('auth/oauth2/login', userData);

      const token = await response.data.token;

      // ApiService.setHeader(token);

      return token;

    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Confirma si un token Oauth es válido y no está caducado
   *
   * @param {string} token
   * @returns {Promise<boolean>}
   * @throws {tokenNotFound} Si el token es inválido o caducado
   */
  async checkConfirmationOauthToken(token) {

    try{

      await ApiService.get(`auth/oauth2/token/${token}`);

      return true;

    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Obtiene la información de la aplicación vinculada con Oauth
   *
   * @param {string} token
   * @param {string} clientId
   * @returns {Object} data
   * @throws {badParams}
   * @throws {tokenNotFound}
   */
  async getOauthAuthorize(token, clientId) {
    try{

      const response = await ApiService.get(`auth/oauth2/authorize?token=${token}&client_id=${clientId}`);

      const data = response.data;

      return data;

    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   *
   * @param {string} token
   * @param {string} client_id
   * @param {string} redirect_uri
   * @param {string} state
   * @param {Array} scopes
   * @returns
   * @throws {badParams}
   * @throws {oauth2EntityNotExist}
   * @throws {authorizeOauthReject}
   * @throws {tokenNotFound}
   * @throws {socketTimeout}
   */
  // eslint-disable-next-line camelcase
  async oauthAuthorize(token, client_id, redirect_uri, state, scopes){

    const oauthData = {
      client_id,
      redirect_uri,
      state,
      scopes
    }

    try{

      const response = await ApiService.post(`auth/oauth2/authorize?token=${token}`, oauthData);

      const redirectUri = response.data.redirect_uri;

      return redirectUri;

    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Comprueba si un usuarios está logueado, obtiene sus datos y almacena sus credenciales
   *
   * @return {User} - Datos del usuario parseados y validados
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - Si el usuariono existe o si la contraseña es errónea
   * @throws {userNotConfirmed} - Si el usuario todavía no ha validado su cuenta
   * @throws {sanitazeUserDataError} - Error al validar los datos del usuario
   * @throws {invalidUserData} - No se han recibido los datos mínimos necesarios
   * @throws {default} - Ocurrió otro error
   */
  async isLogin(token) {
    try {
      ApiService.setHeader(token);

      const response = await ApiService.get('user');

      const skin = response?.data?.config?.skin;
      let actualSkin = null;
      let hasSkinsAvail = false;
      let isSkinEnabled = false; // Indica a nivel de usuario si tiene el skin activo (NOTA: NO CONFUNIDIR CON enabled del propio skin (se obtiene en getSkin))

      // Si el usuario tiene skins disponibles
      if(skin) {
        const skinAvailIds = skin.avail_skin_ids;
        const currentSkinId = skin.current_skin_id;
        isSkinEnabled = skin.enabled;
        const skinState = await resolveSkinsState({skinAvailIds, currentSkinId, userId: response?.data?._id, isSkinEnabled});
        actualSkin = skinState.actualSkin;
        hasSkinsAvail = skinState.hasSkinsAvail;
      } else {
        // Si el usuario no tiene skins disponibles deshabilitamos el skin
        document.dispatchEvent(new CustomEvent('disableSkin'));
      }
      //
      // Preparo los datos para el modelo user
      //
      const user = {
        id: response?.data._id,
        userID: response?.data._id,
        name: response?.data?.data?.name,
        lastName: response?.data?.data?.lastName,
        email: response?.data?.email,
        avatar: response?.data?.data?.avatar,
        commercial: response?.data?.data?.commercial,
        notification: response?.data?.config?.notification,
        ampm: response?.data?.config.ampm,
        sundayFirst: response?.data?.config?.sundayFirst,
        noHaptic: response?.data?.config?.noHaptic,
        units: unitTempUtil.unitTotempunit(response?.data?.config?.units),
        lang: response?.data?.config?.lang,
        role: response?.data?.role,
        admin_mode: response?.data?.config?.admin_mode,
        integrations: response?.data?.integrations,
        extra_roles: response?.data?.extra_roles,
        skin: actualSkin,
        hasSkinsAvail,
        isSkinEnabled
      };


      setLanguage(user.lang);

      // Actualizamos el usuario en Rollbar
      // RollbarService.configure({
      //   payload: {
      //     person: {
      //       id: user.id,
      //       username: user.email,
      //       email: user.email
      //     }
      //   }
      // });

      // RollbarService.info("Recuperando datos de usuario");

      log.success('AuthService/isLogin');
      return user;
    } catch (error) {
      log.error(error);
      if (error?.name !== 'offline') {
        ApiService.removeHeader();
        StorageService.removeItem('access_token');
        StorageService.removeItem('refresh_token');
      }
      throw new AuthError(error);
    }
  },

  /**
   * Obtiene un nuevo token mediante el refreshToken
   *
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {tokenNotFound} - No se encuentra el refreshToken en la Base de Datos
   * @throws {default} - Ocurrió algún error
   */
  refreshToken() {

    if( refreshPending === false) {
      refreshPending = true;
      console.log('Creando pending');
      refreshPromise = new Promise( async (resolve, reject ) => {
        try {
          console.log('Resolviendo pending');
          const localRefreshToken = StorageService.getItem('refresh_token');
          if(!localRefreshToken) {
            throw localRefreshToken
          }

          const response = await ApiService.get(`auth/refreshToken/${localRefreshToken}`);

          if(response && response.data) {
            ApiService.setHeader(response.data.token);
            StorageService.setItem('access_token', response.data.token);
            StorageService.setItem('refresh_token', response.data.refreshToken);

            log.success('AuthService/refreshToken');

            refreshPending = false;
            return resolve (response.data.token);
          }

          return reject(new AuthError('tokenNotFound'));

        } catch (error) {
          try {
            console.log(error)
            // si tengo en localstorage email y pass, hago login
            const userRemember = StorageService.getItem('userRemember');
            const email = StorageService.getItem('userEmail');
            const userPassword = StorageService.getItem('userPassword');

            const remember = userRemember === 'true';
            const password = userPassword ? CryptoService.decrypt(userPassword) : '';

            if (!remember || !email || !password) {
              return reject(error)
            }

            const resp = await ApiService.post('auth/login', { email, password });

            if(resp && resp.data) {
              ApiService.setHeader(resp.data.token);
              StorageService.setItem('access_token', resp.data.token);
              StorageService.setItem('refresh_token', resp.data.refreshToken);

              // Actualizamos el usuario en Rollbar
              // RollbarService.configure({
              //   payload: {
              //     person: {
              //       id: resp.data._id,
              //       username: resp.data.email,
              //       email: resp.data.email
              //     }
              //   }
              // });

              log.success('AuthService/refreshToken autoLogin');
              refreshPending = false;
              return resolve(resp.data.token);
            }

            return reject(error);


          } catch (err) {
            console.log(err)
            refreshPending = false;
            return reject(new AuthError(err));
          }

        }

      })
    } else {
      console.log("Pending creado");
    }

    return refreshPromise;
  },

  /**
   * Registra un usuario
   *
   * @param {String} name - Nombre del usuario
   * @param {String} lastName - Nombre del usuario
   * @param {String} email - Nombre del usuario
   * @param {String} password - Password escogido por el usuario
   * @param {String} language - Lenguaje del sistema (viene de la variable global i18n)
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotAvailable} - Si ya existe un usuario registrado con ese email
   * @throws {userNotConfirmed} - Si el usuario todavía no ha validado su cuenta
   * @throws {default} - Ocurrió algún error
   */
  async register( name, lastName, email, password, language, commercial ) {
    try {
      //
      // Transformo los datos para el backend
      //
      const userData = {
        email,
        password,
        lang: language,
        data: {
          name,
          lastName,
          toc: true,
          commercial,
        },
      };
      //
      // Realizo la petición de registro al backend
      //
      await ApiService.post('auth/signup', userData);
      //
      // Si todo va bien devuelvo true
      //
      log.success('AuthService/register');
      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Envía un email al usuario para que pueda validar su cuenta
   * si el email se envía correctamente devuelve true.
   *
   * @param {String} email - Email del usuario
   * @return {Promise<Boolean>} - Resultado de la petición
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - El usuario no existe
   * @throws {userConfirmed} - El usuario ya había confirmado su cuenta
   * @throws {default} - Ocurrió algún error
   */
  async sendConfirmEmail(email) {
    try {
      await ApiService.get(`auth/resendConfirmationEmail/${email}`);

      log.success('AuthService/sendConfirmEmail');
      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Edita los datos de un usuario
   *
   * @param {String} param - El parámetro que se va a actualizar
   * @param {User} user - Datos del Usuario
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {default} - Ocurrió algún error
   */
  async editUser(param, user) {
    //
    // Preparo los datos para hacer las peticiones esperadas por el backend
    // 1) Información de usuario
    //
    const userData = {
      name: user?.name,
      lastName: user?.lastName,
      commercial: user?.commercial,
    };
    //
    // 2) Configuración de usuario
    //
    const userConfig = {
      admin_mode: user?.admin_mode,
      lang: user?.lang,
      ampm: user?.ampm,
      units: user?.units === CONSTANTS.TEMP_UNITS.CELSIUS ? CONSTANTS.UNITS.CELSIUS : CONSTANTS.UNITS.FARENHEIT,
      noHaptic: user?.noHaptic,
      sundayFirst: user?.sundayFirst,
      notification: user?.notification,
    };

    try {
      //
      // Realizo las petición al bakckend en función del tipo de parámetro
      //
      if (!store.getters.getIsDemo) {
        if(param === 'name' || param === 'lastName' || param === 'commercial'){
          await ApiService.patch('user/data', userData);
        } else {
          await ApiService.patch('user/config', userConfig);
        }
      }
      //
      // Si se ha realizado bien devuelvo "true" al modelo para que pueda actualizarse
      //
      log.success('AuthService/editUser');
      return true;
    } catch (error) {
      console.error(error);
      throw new AuthError(error);
    }
  },

  /**
   * Edita los ajustes de un usuario
   *
   * @param {Object} data - Ajustes del Usuario
   * @return {Boolean} - Resultado de registrar al usuario
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {default} - Ocurrió algún error
   */
  async editSettings(data) {
    try {
      if (!store.getters.getIsDemo) {
        await ApiService.patch('user/config', data);
      }

      log.success('AuthService/editSettings');
      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Elimina un usuario
   *
   * @return {Promise<Boolean>} - Resultado de eliminar al usuario
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {default} - Ocurrió algún error
   */
  async deleteUser() {
    try {
      await ApiService.delete('user');

      ApiService.removeHeader();
      StorageService.removeItem('access_token');
      StorageService.removeItem('refresh_token');

      log.success('AuthService/deleteUser');

      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },



  /**
   * Limpia los datos del usuario locales
   *
   * @return {Boolean} - Resultado de eliminar al usuario
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {default} - Ocurrió algún error
   */
  clearUser() {
    ApiService.removeHeader();
    StorageService.removeItem('access_token');
    StorageService.removeItem('refresh_token');

    log.success('AuthService/clearUser');

    return this;
  },

  /**
   * Envía un email para que el usuario modifique su contraseña
   *
   * @param {Object} email - Email del usuario
   * @return {Promise<Boolean>} - Indica el resultado de enviar el email
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - El usuario no existe
   * @throws {userConfirmed} - El usuario ya había confirmado su cuenta
   * @throws {default} - Ocurrió algún error
   */
  async remember(email) {
    try {
      await ApiService.get(`auth/remember/${email}`);

      log.success('AuthService/remember');
      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  /**
   * Confirma el email de un usuario mediante un token
   *
   * @param {String} token - El token obtenido por la url que recibe el usuario en el email de confirmación
   * @return {Promise<Boolean>} - Indica si la confirmación se ha realizado con éxito
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notEnoughData} - No hay suficientes datos para ser confirmado
   * @throws {tokenNotFound} - el token referenciado no coincide con el registrado en base de datos
   */
  async confirm(token, userData = null) {

    let tokenData = {}
    if(userData === null){
      tokenData = {
        confirmationToken: token,
      };
    } else {
      tokenData = {
        confirmationToken: token,
        password: userData.password,
        lang: userData.lang,
        ampm: userData.ampm,
        noHaptic: userData.noHaptic,
        notification: userData.notification,
        sundayFirst: userData.sundayFirst,
        units: unitTempUtil.tempunitToUnit(userData.units),
        data: {
          name: userData.name,
          lastName: userData.lastName,
          commercial: userData.commercial,
          toc: true
        }
      }
    }

    try {

      await ApiService.post('auth/confirm', tokenData);

      log.success('AuthService/confirm');
      return true;

    } catch (error) {
      console.log(error);
      throw new AuthError(error);
      // throw new Error(error);
    }
  },

  /**
   * Modifica la contraseña de un usuario
   *
   * @param {String} resetToken - El token para confirmar la validez del cambio de contraseña en cuenta existente
   * @param {String} newPassword - El nuevo password del usuario
   * @returns {Promise<Boolean>} Si tiene éxito la actualización
   */
  async resetPassword({ resetToken, newPassword }) {
    const data = {
      password: newPassword,
    };

    try {
      await ApiService.post(`auth/resetPass/${resetToken}`, data);

      log.success('AuthService/resetPassword');
      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  async checkConfirmationToken(confirmationToken) {
    try {
      const response = await ApiService.get(`auth/checkConfirmationToken/${confirmationToken}`);

      log.success('AuthService/confirmationToken');

      return response.data;
    } catch (error) {
      throw new AuthError(error);
      // throw new Error(error);
    }
  },

  /**
   * Cierra sesión del usuario actual y limpia sus datos del navegador
   *
   * @return {Promise<Boolean>} - Indica el resultado cerrar la sesión del usuario
   * @throws {default} - Ocurrió algún error
   */
  async logout() {
    try {
      await ApiService.get('user/logout');

      ApiService.removeHeader();
      StorageService.removeItem('access_token');
      StorageService.removeItem('refresh_token');

      log.success('AuthService/logout');

      // Limpiamos el usuario de Rollbar
      // RollbarService.configure({
      //   payload: {
      //     person: {
      //       id: null
      //     }
      //   }
      // });

      return true;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  async applySkin({skinId, enabled, userId}) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestData = {
      skinId
    }

    if(userId) requestData.userId = userId;
    if(enabled !== undefined) requestData.enabled = enabled;

    try {

      const response = await ApiService.patch(`${admin}user/apply-skin`, requestData);
      // const response = await ApiService.request(`${admin}users/apply-skin`, requestData, 'patch', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);

      console.log("succesfull applied", response);

      log.success('AuthService/applySkin');

      return response.data;
    } catch (error) {
      throw new AuthError(error);
    }
  },

  async getSkin({skinId, userId}) {
    try {
      const response = await ApiService.get(`app-manager/skins/?skinId=${skinId}`);

      log.success('AuthService/getSkin');

      return {
        data: response.data,
        userId
      };

    } catch (error) {
      throw new AuthError(error);
    }
  }
};

export default AuthService;
