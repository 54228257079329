/* eslint-disable camelcase */
import CONSTANTS from 'Units/constant';
import { parseNumber } from 'Core/utils/sanitaze.utils';
import i18n from 'Core/services/language.service';

/**
 * Devuelve el valor del modo de funcionamiento como cadena de texto
 *
 * @param {Integer} mode - Modo de funcionamiento en número
 * @return {String} - Modo de funcionamiento en texto
 */
const getModeStringValue = mode => {
  switch (parseNumber(mode)) {
    case CONSTANTS.MODES.STOP:
      return CONSTANTS.WEB_MODES.STOP;
    case CONSTANTS.MODES.AUTO:
      return CONSTANTS.WEB_MODES.AUTO;
    case CONSTANTS.MODES.COOLING:
      return CONSTANTS.WEB_MODES.COOLING;
    case CONSTANTS.MODES.HEATING:
      return CONSTANTS.WEB_MODES.HEATING;
    case CONSTANTS.MODES.FAN:
      return CONSTANTS.WEB_MODES.FAN;
    case CONSTANTS.MODES.DRY:
      return CONSTANTS.WEB_MODES.DRY;
    case CONSTANTS.MODES.EMERGENCY_HEAT:
      return CONSTANTS.WEB_MODES.EMERGENCY_HEAT;
    case CONSTANTS.MODES.HEAT_AIR:
      return CONSTANTS.WEB_MODES.HEAT_AIR;
    case CONSTANTS.MODES.HEAT_RADIANT:
      return CONSTANTS.WEB_MODES.HEAT_RADIANT;
    case CONSTANTS.MODES.HEAT_COMB:
      return CONSTANTS.WEB_MODES.HEAT_COMB;
    case CONSTANTS.MODES.COOL_AIR:
      return CONSTANTS.WEB_MODES.COOL_AIR;
    case CONSTANTS.MODES.COOL_RADIANT:
      return CONSTANTS.WEB_MODES.COOL_RADIANT;
    case CONSTANTS.MODES.COOL_COMB:
      return CONSTANTS.WEB_MODES.COOL_COMB;
    case CONSTANTS.MODES.RECOVERY:
      return CONSTANTS.WEB_MODES.RECOVERY;
    case CONSTANTS.MODES.BYPASS:
      return CONSTANTS.WEB_MODES.BYPASS;
    case CONSTANTS.MODES.REGULATION_TEMP:
      return CONSTANTS.WEB_MODES.REGULATION_TEMP;
    case CONSTANTS.MODES.PURIFICATION:
        return CONSTANTS.WEB_MODES.PURIFICATION;
    case CONSTANTS.MODES.FAN_PURIFICATION:
      return CONSTANTS.WEB_MODES.FAN_PURIFICATION;
    case CONSTANTS.MODES.FAN_ENERGY:
        return CONSTANTS.WEB_MODES.FAN_ENERGY;

    default:
      return undefined;
  }
};

/**
 * Devuelve el valor del modo de funcionamiento como número
 *
 * @param {String} mode - Modo de funcionamiento
 * @param {Boolean}  ACSMode - Indica si se trata de ACS
 * @return {Integer} - nombre del modo
 */
const getModeValue = mode => {
  switch (mode) {
    case CONSTANTS.WEB_MODES.STOP:
      return CONSTANTS.MODES.STOP;
    case CONSTANTS.WEB_MODES.AUTO:
      return CONSTANTS.MODES.AUTO;
    case CONSTANTS.WEB_MODES.COOLING:
      return CONSTANTS.MODES.COOLING;
    case CONSTANTS.WEB_MODES.HEATING:
      return CONSTANTS.MODES.HEATING;
    case CONSTANTS.WEB_MODES.FAN:
      return CONSTANTS.MODES.FAN;
    case CONSTANTS.WEB_MODES.DRY:
      return CONSTANTS.MODES.DRY;
    case  CONSTANTS.WEB_MODES.EMERGENCY_HEAT:
      return CONSTANTS.MODES.EMERGENCY_HEAT;
    case CONSTANTS.WEB_MODES.HEAT_AIR:
      return CONSTANTS.MODES.HEAT_AIR;
    case CONSTANTS.WEB_MODES.HEAT_RADIANT:
      return CONSTANTS.MODES.HEAT_RADIANT;
    case CONSTANTS.WEB_MODES.HEAT_COMB:
      return CONSTANTS.MODES.HEAT_COMB;
    case CONSTANTS.WEB_MODES.COOL_AIR:
      return CONSTANTS.MODES.COOL_AIR;
    case CONSTANTS.WEB_MODES.COOL_RADIANT:
      return CONSTANTS.MODES.COOL_RADIANT;
    case CONSTANTS.WEB_MODES.COOL_COMB:
      return CONSTANTS.MODES.COOL_COMB;
    case  CONSTANTS.WEB_MODES.RECOVERY:
      return CONSTANTS.MODES.RECOVERY;
    case CONSTANTS.WEB_MODES.BYPASS:
        return CONSTANTS.MODES.BYPASS;
    case CONSTANTS.WEB_MODES.REGULATION_TEMP:
      return CONSTANTS.MODES.REGULATION_TEMP;
    case CONSTANTS.WEB_MODES.PURIFICATION:
        return CONSTANTS.MODES.PURIFICATION;
    case CONSTANTS.WEB_MODES.FAN_PURIFICATION:
      return CONSTANTS.MODES.FAN_PURIFICATION;
    case CONSTANTS.WEB_MODES.FAN_ENERGY:
        return CONSTANTS.MODES.FAN_ENERGY;
    default:
      return CONSTANTS.MODES.AUTO;
  }
};

/**
 * Devuelve el valor del modo de funcionamiento como tecto y traducido
 *
 * @param {String} mode - Modo de funcionamiento
 * @return {Integer} - nombre del modo
 */
const getModeString = mode => {
  switch (parseNumber(mode)) {
    case CONSTANTS.MODES.COOLING:
      return i18n.global.t('units.cool');
    case CONSTANTS.MODES.HEATING:
      return i18n.global.t('units.heat');
    case CONSTANTS.MODES.FAN:
      return i18n.global.t('units.fan');
    case CONSTANTS.MODES.DRY:
      return i18n.global.t('units.dry');
    case CONSTANTS.MODES.EMERGENCY_HEAT:
      return i18n.global.t('units.emergencyHeat');
    default:
      return '';
  }
};

/**
* Devuelve el nombre del icono según el modo de usuario (escena) definida.
*/
const getUsermodeIconValue = usermodeID => {
  switch (usermodeID) {
    case CONSTANTS.USERMODE.STOP:
      return 'stop';
    case CONSTANTS.USERMODE.COMFORT:
      return 'comfort';
    case CONSTANTS.USERMODE.UNOCCUPIED:
      return 'leave';
    case CONSTANTS.USERMODE.NIGHT:
      return 'night';
    case CONSTANTS.USERMODE.ECO:
      return 'eco';
    case CONSTANTS.USERMODE.VACATION:
      return 'holidays';
    case CONSTANTS.USERMODE.VLA_ON:
      return 'fan-on';
    case CONSTANTS.USERMODE.VLA_OFF:
      return 'fan-off';
    default:
      return 'leave';
  }
};

const getModeState = ({mode, active, auto_mode, block_drytemp, local_vent_active}) => {

  let state = 'success';

  if(local_vent_active === true) {
    return 'airing';
  }

  switch (mode) {
    case 'heat':
    case 'heat_air':
    case 'heat_radiant':
    case 'heat_comb':
    case 'emergencyHeat':
      if (active === true) state = 'heating';
      break;
    case 'cool':
    case 'cool_air':
    case 'cool_radiant':
    case 'cool_comb':
      if (active === true) state = 'cooling';
      break;
    case 'dry':
      if (block_drytemp) state = 'drying';
      if (active === true )  state = 'drying';
      // state = 'drying'; // En principio el modo seco no tiene estado de demanda;
      break;
    case 'fan':
      // if (this.active === true ) state = 'airing'; // En principio el modo ventilar no tiene estado de demanda;
      state = 'airing';
      break;
    case 'auto':
      if (active === true)
      {
        state = 'stop';
        if(auto_mode === CONSTANTS.MODES.HEATING) state = 'heating';
        if(auto_mode === CONSTANTS.MODES.COOLING) state = 'cooling';
      }
      break;
    case 'stop':
      // if (this.active === true ) state = 'stop';
      state = 'stop';
      break;
    default:
      state = 'success';
      break;
    }

  return state;
}


export { getModeStringValue, getModeValue, getModeString, getUsermodeIconValue, getModeState };
