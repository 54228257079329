import { Model } from '@vuex-orm/core';
/**
 * @typedef Command
 *
 * @param {string} id
 * @param {string} scene_id - Id de la escena a la que pertenece
 * @param {string} type - Puede ser "delay" o "action"
 * @param {Object} target - Puede ser type "acs" o "zone" y tener "groups" o "devices". También puede tener el parámetro "all: true"
 * @param {Object} actions - Si comando es de tipo "delay" debe ser {delay: integer}, si es "action" puede acumular varias acciones en diferentes params.
 */
export default class Command extends Model {

  static entity = 'commands';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
   static fields() {
    return {
      id: this.string(),
      scene_id: this.string(undefined).nullable(),
      type: this.string(undefined).nullable(),
      target: this.attr(undefined).nullable(),
      actions: this.attr(undefined).nullable(),
      position: this.number(undefined).nullable()
    }
   }
}
