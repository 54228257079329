import AuthService from "Auth/services/auth.service";
import StorageService from "Core/services/storage.service";

const hexToHsla = (hex) => {
  if(typeof hex !== 'string') {
    console.error('El color debe ser una cadena');
    return false;
  }

  // Asegurarse de que el color es un color hex
  if(!hex.startsWith('#')) {
    console.error('Formato de color HEX no válido');
    return false;
  }

  // Asegurarse de que el hex tiene 6 caracteres
  hex = hex.replace('#', '');
  if (hex.length !== 6 && hex.length !== 8) {
    console.error('Formato de color HEX no válido');
    return false;
  }

  // Convierte cada par de caracteres hex a decimal
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;
  let a = hex.length === 8 ? parseInt(hex.substring(6, 8), 16) / 255 : 1;

  // Encuentra los valores mínimo y máximo de r, g y b
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);

  // Luminosidad
  let l = (max + min) / 2;

  let h, s;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `hsla(${h}, ${s}%, ${l}%, ${a.toFixed(2)})`;
}

const isLightColor = color => {
  if(typeof color !== 'string') {
    console.error('El color debe ser una cadena');
    return false;
  }

  let hslaColor;

  // Detectar el formato del color
  if (color.startsWith('#')) {
    // Es un color HEXA
    hslaColor = hexToHsla(color);
  } else if (color.startsWith('hsla')) {
    // Es un color HSLA
    hslaColor = color;
  } else if (color.startsWith('hsl')) {
    // Es un color HSL (sin alpha)
    let hslValues = color.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
    if (hslValues) {
      let h = parseInt(hslValues[1]);
      let s = parseInt(hslValues[2]);
      let l = parseInt(hslValues[3]);
      hslaColor = `hsla(${h}, ${s}%, ${l}%, 1)`;
    } else {
      console.error('Formato de color HSL no válido');
      return false;
    }
  } else {
    console.error('Formato de color no reconocido');
    return false;
  }

  // Extraer valores h, s, l, y a de la cadena hsla
  let hslaValues = hslaColor.match(/hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*(\d+(\.\d+)?)\)/);
  if (!hslaValues) {
    return console.error('Formato de color HSLA no válido')
  }

  let h = parseInt(hslaValues[1]);
  let s = parseInt(hslaValues[2]);
  let l = parseInt(hslaValues[3]);
  let a = parseFloat(hslaValues[4]);

  // Determinar si el color es claro
  return l > 50;
}

const resolveSkinsState = async ({skinAvailIds, currentSkinId, userId, isSkinEnabled}) => {
  let actualSkin = null;
  let hasSkinsAvail = false;

  // Comprobamos que el array de skins disponibles existe
  if(skinAvailIds && Array.isArray(skinAvailIds)) {
    // Si no hay skins disponibles y el usuario tiene una skin aplicada (este caso no debería de llegar desde backend)
    if(skinAvailIds.length === 0 && (currentSkinId !== null && currentSkinId !== undefined) ) {
      // Suponemos que al tener skin aplicada habrá que mostrar un modal para avisarle de la desaparición de la skin
      document.dispatchEvent(new CustomEvent('removeSkinModal', { detail: { skinId: currentSkinId } }));
    }
    // Si no hay skins disponibles y el usuario NO tiene una skin aplicada (comprobamos que hay skin en local storage y que es del usuario)
    if(skinAvailIds.length === 0 && (currentSkinId === null || currentSkinId === undefined) ) {
      const skin = StorageService.getItem('skin');
      // Tendremos que asegurarnos que el usaruio es el dueño de la skin (no debería mostrase aviso a un usuario sin skin)
      if(skin && JSON.parse(skin).user_id === userId) {
        document.dispatchEvent(new CustomEvent('removeSkinModal', { detail: { skinId: currentSkinId } }))
      } else {
        document.dispatchEvent(new CustomEvent('disableSkin', { detail: { skinId: currentSkinId } }));
      }
    }
    // Si hay skins disponibles y el usuario no tiene ninguna aplicada
    if(skinAvailIds.length > 0 && (currentSkinId === null || currentSkinId === undefined) ) {
      // Debería mostrarse el modal de skins disponibles
      document.dispatchEvent(new CustomEvent('showSkinModal', { detail: { skinAvailIds } }))
      hasSkinsAvail = true;
    }
    // Si hay skins disponibles y el usuario tiene una aplicada (aplicamos la skin)
    if(currentSkinId && skinAvailIds.includes(currentSkinId)) {
      hasSkinsAvail = true;
      // Se obtienen los datos de la skin
      const { data } = await AuthService.getSkin({skinId: currentSkinId, userId});
      actualSkin = data;
      // Si la skin está activa
      if(actualSkin?.enabled === true && (isSkinEnabled === undefined || isSkinEnabled === true)) {
        document.dispatchEvent(new CustomEvent('applySkin', { detail: { skin: actualSkin, userId } }));
      } else {
        document.dispatchEvent(new CustomEvent('disableSkin', { detail: { skinId: currentSkinId } }));
      }
    }
  }

  return {actualSkin, hasSkinsAvail, isSkinEnabled: actualSkin?.enabled};
}

export {
  hexToHsla,
  isLightColor,
  resolveSkinsState
}
