const SET_SCENE_STATUS = (state, status) => {
  state.sceneStatus = status
};

const SET_COMMANDS_STATUS = (state, status) => {
  state.commandsStatus = status
};

const SET_MONITORING_URL = (state, status) => {
  state.monitoringUrl = status;
}

export default {
  SET_SCENE_STATUS,
  SET_COMMANDS_STATUS,
  SET_MONITORING_URL
}
