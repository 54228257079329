/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vector-recovery-home': {
    width: 16,
    height: 16,
    viewBox: '0 0 343 245',
    data: '<g _fill="#fff"><path pid="0" d="M113.1 1.6L4.3 108.6c-5.7 5.7-5.7 15.1 0 20.8 2.7 2.7 6.5 4.3 10.4 4.4h38.5v83.8c0 14.3 11.6 25.8 25.9 25.8h38.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5H79c-8.2 0-14.9-6.6-14.9-14.8v-89.3c0-3-2.5-5.5-5.5-5.5H14.5c-.9 0-1.9-.3-2.6-1-1.4-1.5-1.4-3.7 0-5.1l109-107c2.2-2.1 2.2-5.7 0-8s-5.7-2.2-8 0l.2-.1zM338.7 108.6L230.3 1.6c-2.2-2.1-5.7-2.1-8 0-2.2 2.1-2.1 5.7 0 8l108.6 106.9c.7.7 1 1.6 1.1 2.6 0 2-1.6 3.6-3.6 3.6h-44c-3 0-5.5 2.5-5.5 5.5v89.3c0 8.2-6.6 14.8-14.9 14.8h-38.3c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5H264c14.3 0 25.9-11.6 25.9-25.8v-83.8h38.5c8.1 0 14.6-6.6 14.6-14.7 0-3.8-1.6-7.6-4.4-10.3h.1z"/></g>'
  }
})
