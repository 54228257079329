import { inject } from 'vue';
import store from 'Core/store/store';

export const useSidenav = () => {
  const CONSTANTS = inject('constants');
  const isThereNotch = store.getters.getIsThereNotch;

  const toggleIsShowSidenav = (e) => {
    if(CONSTANTS?.IS_MOBILE && !isThereNotch) {
      if(e === undefined || e === true){
        // eslint-disable-next-line no-undef
        StatusBar.styleLightContent()
      }  else {
        // eslint-disable-next-line no-undef
        StatusBar.styleDefault();
      }
    }

    store.commit('TOGGLE_SIDENAV')
  }

  return { toggleIsShowSidenav }
}
