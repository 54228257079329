export default {
  /**
   * DOCS
   *
   * @constant {Object} DOCS - Rutas con los documentos legales
   */
  EXTRA_ROLES: {
    GRAPH_VIEWER: 'feat_graph_viewer'
  },
  ROLE: {
    SUPER_ADMIN: 'admin',
  },
  BIOMETRIC: {
    ERROR_CODES: [
        11, // 'BIOMETRIC_NOT_REGISTERED'
      -100, // 'BIOMETRIC_UNKNOWN_ERROR' AUTHENTICATION INVALID
      -101, // 'BIOMETRIC_UNAVAILABLE'
      -102, // 'BIOMETRIC_AUTHENTICATION_FAILED'
      -103, // 'BIOMETRIC_SDK_NOT_SUPPORTED'
      -104, // 'BIOMETRIC_HARDWARE_NOT_SUPPORTED'
      -105, // 'BIOMETRIC_PERMISSION_NOT_GRANTED'
      -106, // 'BIOMETRIC_NOT_ENROLLED'
      -107, // 'BIOMETRIC_INTERNAL_PLUGIN_ERROR'
      -109, // 'BIOMETRIC_PIN_OR_PATTERN_DISMISSED'
      -110, // 'BIOMETRIC_SCREEN_GUARD_UNSECURED'
      -111, // 'BIOMETRIC_LOCKED_OUT'
      -112, // 'BIOMETRIC_LOCKED_OUT_PERMANENT'
      -113, // 'BIOMETRIC_SECRET_NOT_FOUND'
    ],
  },
  DOCS: {
    MANUAL: {
      DE: process.env.VUE_APP_DOCS_MANUAL_MUL,
      EN: process.env.VUE_APP_DOCS_MANUAL_MUL,
      ES: process.env.VUE_APP_DOCS_MANUAL_MUL,
      FR: process.env.VUE_APP_DOCS_MANUAL_MUL,
      IT: process.env.VUE_APP_DOCS_MANUAL_MUL,
      PT: process.env.VUE_APP_DOCS_MANUAL_MUL,
    },
    VIDEO:{
      DE: process.env.VUE_APP_DOCS_VIDEO_DE,
      EN: process.env.VUE_APP_DOCS_VIDEO_EN,
      ES: process.env.VUE_APP_DOCS_VIDEO_ES,
      FR: process.env.VUE_APP_DOCS_VIDEO_FR,
      IT: process.env.VUE_APP_DOCS_VIDEO_IT,
      PT: process.env.VUE_APP_DOCS_VIDEO_PT,
    },
    LEGAL: {
      DE: process.env.VUE_APP_DOCS_LEGAL_DE,
      EN: process.env.VUE_APP_DOCS_LEGAL_EN,
      ES: process.env.VUE_APP_DOCS_LEGAL_ES,
      FR: process.env.VUE_APP_DOCS_LEGAL_FR,
      IT: process.env.VUE_APP_DOCS_LEGAL_IT,
      PT: process.env.VUE_APP_DOCS_LEGAL_PT,
      PL: process.env.VUE_APP_DOCS_LEGAL_PL,
      EL: process.env.VUE_APP_DOCS_LEGAL_EL,
      TR: process.env.VUE_APP_DOCS_LEGAL_TR,
    },
    PRIVACY: {
      URL: process.env.VUE_APP_DOCS_PRIVACY_URL,
      DE: process.env.VUE_APP_DOCS_PRIVACY_DE,
      EN: process.env.VUE_APP_DOCS_PRIVACY_EN,
      ES: process.env.VUE_APP_DOCS_PRIVACY_ES,
      FR: process.env.VUE_APP_DOCS_PRIVACY_FR,
      IT: process.env.VUE_APP_DOCS_PRIVACY_IT,
      PT: process.env.VUE_APP_DOCS_PRIVACY_PT,
      PL: process.env.VUE_APP_DOCS_PRIVACY_PL,
      EL: process.env.VUE_APP_DOCS_PRIVACY_EL,
      TR: process.env.VUE_APP_DOCS_PRIVACY_TR,
    },
    TERMS: {
      DE: process.env.VUE_APP_DOCS_TERMS_DE,
      EN: process.env.VUE_APP_DOCS_TERMS_EN,
      ES: process.env.VUE_APP_DOCS_TERMS_ES,
      FR: process.env.VUE_APP_DOCS_TERMS_FR,
      IT: process.env.VUE_APP_DOCS_TERMS_IT,
      PT: process.env.VUE_APP_DOCS_TERMS_PT,
      PL: process.env.VUE_APP_DOCS_TERMS_PL,
      EL: process.env.VUE_APP_DOCS_TERMS_EL,
      TR: process.env.VUE_APP_DOCS_TERMS_TR,
    },
    ALEXA: {
      PRIVACY: {
        ES: process.env.VUE_APP_DOCS_ALEXA_PRIVACY_ES,
        EN: process.env.VUE_APP_DOCS_ALEXA_PRIVACY_EN,
        FR: process.env.VUE_APP_DOCS_ALEXA_PRIVACY_FR,
        IT: process.env.VUE_APP_DOCS_ALEXA_PRIVACY_IT,
        DE: process.env.VUE_APP_DOCS_ALEXA_PRIVACY_DE
      },
      TERMS: {
        ES: process.env.VUE_APP_DOCS_ALEXA_TERMS_ES,
        EN: process.env.VUE_APP_DOCS_ALEXA_TERMS_EN,
        FR: process.env.VUE_APP_DOCS_ALEXA_TERMS_FR,
        IT: process.env.VUE_APP_DOCS_ALEXA_TERMS_IT,
        DE: process.env.VUE_APP_DOCS_ALEXA_TERMS_DE
      },
    },
    GOOGLE: {
      PRIVACY: {
        ES: process.env.VUE_APP_DOCS_GOOGLE_PRIVACY_ES,
        EN: process.env.VUE_APP_DOCS_GOOGLE_PRIVACY_EN,
        FR: process.env.VUE_APP_DOCS_GOOGLE_PRIVACY_FR,
        IT: process.env.VUE_APP_DOCS_GOOGLE_PRIVACY_IT,
        DE: process.env.VUE_APP_DOCS_GOOGLE_PRIVACY_DE
      },
      TERMS: {
        ES: process.env.VUE_APP_DOCS_GOOGLE_TERMS_ES,
        EN: process.env.VUE_APP_DOCS_GOOGLE_TERMS_EN,
        FR: process.env.VUE_APP_DOCS_GOOGLE_TERMS_FR,
        IT: process.env.VUE_APP_DOCS_GOOGLE_TERMS_IT,
        DE: process.env.VUE_APP_DOCS_GOOGLE_TERMS_DE
      },
    },
    WIZARD: {
      INFO_ID_VALR: process.env.VUE_APP_DOCS_INFO_ID_VALR,
      INFO_ID_THERMOSTATS: process.env.VUE_APP_DOCS_INFO_ID_THERMOSTATS,
      INFO_CNX_DEVICES: process.env.VUE_APP_DOCS_INFO_CNX_DEVICES
    }
  },
};
