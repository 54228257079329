import store from 'Core/store/store';
import constants from 'Units/constant';
import CONSTANTS from 'Core/constant';

// import moment from 'moment-timezone';
// Services
import BluetoothService from 'Core/services/bluetooth.service';
import DemoService from 'Core/services/demo.service';

// import store from 'Core/store/store';
import BleUtils from 'Units/utils/ble.utils';
import localUserData from 'Auth/services/localUserData.service';
import { showPopupPin } from 'Core/utils/popup.utils'

// COMPONENTS
import Dialog from 'Core/components/Dialog';
import Toast from 'Core/components/Toast';
// import PopupGlobal from 'Core/components/PopupGlobal';
// MODELS
import Installation from 'Units/models/Installation.model';
// import Group from 'Units/models/Group.model';
import User from 'Auth/models/User';
import Webserver from 'Units/models/Webserver.model';
import { Device }  from 'Units/models/DeviceHierarchy'
// Routes
import wizardRoutes from './wizard.routes';

// Ble config
// import configBLERoutes from './configBLE.routes';


const addDevicesRoutes = {
  path: 'addDevices',
  name: 'addDevices',
  component: () => import('../pages/AddDevices'),
  props: true,
  redirect: {
    name: 'searchDevice'
  },
  beforeEnter: async (to, from, next) => {
    // Si vengo de navegación interna, ya estoy permitido y no inicializo
    if (from.name !== 'wizardWebserverConfigBLE' && // Cuando vuelvo de Airtools Webserver Info hacia wizard (addDevice)
      from.name !== 'wizardConfigBLEAidooInfo' && // Cuando vuelvo de Airtools Aidoo Info hacia wizard (addDevice)
      from.name !== 'wizardConfigBLEAidooSettings' && // Cuando vuelvo de Airtools Aidoo Settings hacia wizard (addDevice)
      from.name !== 'wizardConfigBLENetwork' && // Cuando vuelvo de config de red hacia wizard (addDevice)
      from.name !== 'wizardConfigBLEIntegration') { // Cuando vuelvo de integración hacia wizard (addDevice)
      store.dispatch('setIsUserAllowStatus', false);
    }

    // const hasConnection = User.hasSocketConnection();
    //
    // Si ya estamos conectado al socket limpiamos
    // DESCONECTAMOS conexión
    //
    // if(hasConnection) User.pauseSocketUser();

    next();
  },
  children: [
    {
      path: 'searchDevice',
      name: 'searchDevice',
      component: () => import('../pages/AddDevices/SearchDevice'),
      props: true,
    },
    {
      path: 'qrScannerView',
      name: 'qrScannerView',
      component: () => import('../pages/AddDevices/qrScannerView'),
      props: true,
    },
    {
      path: 'wizardConfirmation/:macBLE',
      name: 'wizardConfirmation',
      component: () => import('../pages/AddDevices/WizardConfirmation'),
      props: true,
      meta: {
        isBle: true,
      },
    },
    //
    // RUTAS de Asistente de Configuración
    //
    wizardRoutes,
    {
      path: 'universalModbus',
      name: 'universalModbus',
      component: () => import('Units/pages/AddDevices/BleAssociation/UniversalModbus'),
      props: true,
    },
    {
      path: 'ble/:macBLE/:pin?',
      name: 'bleAssociation',
      component: () => import('../pages/AddDevices/BleAssociation'),
      props: true,
      meta: {
        isBle: true,
        fromAddDevice: true,
      },
      redirect: {
        name: 'deviceInfo'
      },
      beforeEnter: async (to, from, next) => {
        //
        // SI venimos de la confimación del asistente de configuración es
        // porque se ha seleccionado "Añadir dispositivo". Dirigimos diréctamente
        // a la redirección 'deviceInfo' -> next().
        //
        if(from.name !== 'wizardConfirmation' && from.name !== 'wizardEnding' && from.name !== 'wizardConfigMain') {
          if (store.getters.getIsDemo) {
            const deviceName = to?.query?.deviceName
            const macBLE = to?.params?.macBLE
            const params = to.params;
            const query = to.query

            DemoService.getBLEDeviceInfo(macBLE)

            if (deviceName === 'AZW5GR7677') {
              return next({name: 'wizardConfirmation', params, query})
            }
            if (deviceName === 'AZ8CB71EF') {
              return next({name: 'wizard', params, query})
            }
            return next()
          }

          Toast.loading();

          const params = to.params;
          const query = to.query
          const user = User.query().first();
          let isUserAllow = false

          //
          // Registro cambios en Bluetooth
          //
          const res = await BleUtils.getDeviceBleModel(params.macBLE, query.deviceName).catch(error => {
            BluetoothService.disconnectDevice(to.params.macBLE);
            console.error(error);
            Toast.clear();
            Dialog.error(error);
            return next({name: from.name})
          })

          // Fix connection BLE: No se puede conectar con el dispositivo
          if (res === undefined) {
            console.log('res es undefined: No se puede conectar con el dispositivo', res);
            BluetoothService.disconnectDevice(to.params.macBLE);
            Toast.clear();

            return next({name: CONSTANTS.SETTINGS.DEFAULT_ROUTE})
          }

          if(res !== false ) {
            const webserver = Webserver.query().where('macBLE', params.macBLE).first();

            // Si venimos de la configuración avanzada del wizard (airtoolsBLE), no volvemos a asignar la fecha
            if(from.name !== 'wizardWebserverConfigBLE' && from.name !== 'wizardConfigBLEAidooInfo' && from.name !== 'wizardConfigBLEAidooSettings') {
              await BleUtils.setLocalTime(params.macBLE, webserver?.ble_version, webserver?.region)
            }
            const deviceID = res?.type === constants.DEVICE_TYPE.az_8cb ? webserver.id : webserver.mac;
            // Si venimos de lector QR y tenemos el pin lo comprobamos y almacenamos
            if(from?.name === 'qrScannerView' && params?.pin !== undefined) {
              //
              // Comprobamos el pin. Si el pin que hay registrado en el qr no coincide no podemos almacenarlo
              //
              try {
                await Webserver.checkPin(deviceID, params.pin)
              } catch( error ) {
                console.log("Check pin error", error);
                return next({name: 'searchDevice'})
              }


              console.log("Guardamos el pin en storage");
              //
              // Inicializamos el servicio y preparamos el usuario para
              // almacenar las credenciales en local storage
              //
              localUserData.init();
              localUserData.checkUser(user.email);
              localUserData.setPincode(user.email,deviceID, params.pin);
              //
              // Guardamos el pin en el modelo
              //
              await webserver.setParam('pin', params.pin);
              //
              // Hacemos que el usuario esté permitido en VUEX
              //
              await webserver.setParam('isUserAllow',true);
              store.dispatch('setIsUserAllowStatus', true);
            } else { // Si no, comprobamos si lo tenemos ya almacenado en local
              isUserAllow = await webserver.getLocalUserAllow(user.email, deviceID);
              if(isUserAllow === true) {
                webserver.setParam('isUserAllow', true);
                store.dispatch('setIsUserAllowStatus', true);
              }
            }

            try {
              if(res.type === constants.DEVICE_TYPE.az_8cb) {
                if(webserver.wizard_compatible === true) {
                  return next({name: 'wizard', params, query});
                }
                //  else {
                  // Si es de tipo central y no permite wizard redirijimos a airtools
                  // Si no tengo el pin en el localstorage entonces lo pido
                  if (!isUserAllow) {
                    try {
                      Toast.clear()

                      const code = await showPopupPin(webserver.mac, false, false, '')

                      //
                      // Si el device es de tipo CENTRAL entonces guardamos la información del pin en local storage
                      //
                      if (webserver.type === constants.DEVICE_TYPE.az_8cb) {
                        console.log("Es central. Guardamos el pin en storage");
                        //
                        // Inicializamos el servicio y preparamos el usuario para
                        // almacenar las credenciales en local storage
                        //
                        localUserData.init();
                        // const user = User.query().first();
                        localUserData.checkUser(user.email);
                        localUserData.setPincode(user.email, webserver.mac, code);
                      }

                      //
                      // Guardamos el pin en el modelo
                      //
                      await webserver.setParam('pin', code);
                      //
                      // Hacemos que el usuario esté permitido en VUEX
                      //
                      await webserver.setParam('isUserAllow', true);
                      store.dispatch('setIsUserAllowStatus', true);

                    } catch(err) {
                      console.log('Volver', err, from);
                      Toast.clear();
                      return next({ name: from.name, params: from.params, query: from.query })
                    }
                  }
                  params.webserverID = webserver.id;
                  return next({name: 'wizardWebserverConfigBLE', params, query: to.query })
                }
              if( res.type === constants.DEVICE_TYPE.az_ws && webserver.wizard_compatible === true) {
                return next({name: 'wizardConfirmation', params, query})
              }
            } catch( error ) {
              BluetoothService.disconnectDevice(to.params.macBLE);
              console.error(error);
              Toast.clear();
              // Protecting, the error object exists
              if (error) Dialog.error(error);

              return next({name: from.name})
            }

          }

        }

        return next()
      },
      children: [
        {
          path: 'deviceInfo',
          name: 'deviceInfo',
          component: () => import('../pages/AddDevices/BleAssociation/DeviceInfo'),
          props: true,
          beforeEnter: async (to, from, next) => {
            try {
              if (store.getters.getIsDemo) return next();

              const isUserAllow = store.getters.getIsUserAllow

              const ws = Webserver.query().where('macBLE', to.params.macBLE).first();

              if ( (isUserAllow || ws.isUserAllow) === false ) {

                try {
                  Toast.clear()

                  const code = await showPopupPin(ws.mac, false, false, '')
                  //
                  // Guardamos el pin en el modelo
                  //
                  await ws.setParam('pin', code);
                  //
                  // Hacemos que el usuario esté permitido en VUEX
                  //
                  await ws.setParam('isUserAllow', true);
                  store.dispatch('setIsUserAllowStatus', true);
                  // Si es un Aidoo Modbus Universal pido el get_params para que me devuelva toda la información
                  if (ws.mdbu_maq !== undefined) {
                    // Añado al query el universalModbus para controlar que se ha pedido la información de los devices
                    const query = {...to.query, universalModbus: true}
                    Toast.loading();
                    const user = User.query().first();
                    await Webserver.getWebserverStatusBLE(ws.macBLE, ws.id, ws.type, ws.ble_version, ws.wsver, user.email);
                    const devices = Device.query().where('webserver_id', ws.id).get();
                    // No podemos usar forEach si queremos usar await dentro del bucle !
                    for(let i = 0; i < devices.length; i++){
                      // eslint-disable-next-line no-await-in-loop
                      await Device.getBleConfig(devices[i].id);
                    }
                  return next({ name: 'universalModbus', params: to.params, query });
                  }

                  return next()

                } catch(err) {
                  console.log('Volver', err)
                  BluetoothService.disconnectDevice(to.params.macBLE);
                  return next({ name: from.name, params: from.params, query: from.query })
                }
              }
              console.log(ws, from, to)
              // Si es un Aidoo Modbus Universal pido el get_params para que me devuelva toda la información
              if (ws.mdbu_maq !== undefined) {
                // Añado al query el universalModbus para controlar que se ha pedido la información de los devices
                const query = {...to.query, universalModbus: true}
                Toast.loading();
                const user = User.query().first();
                await Webserver.getWebserverStatusBLE(ws.macBLE, ws.id, ws.type, ws.ble_version, ws.wsver, user.email);
                const devices = Device.query().where('webserver_id', ws.id).get();
                // No podemos usar forEach si queremos usar await dentro del bucle !
                for(let i = 0; i < devices.length; i++){
                  // eslint-disable-next-line no-await-in-loop
                  await Device.getBleConfig(devices[i].id);
                }
              return next({ name: 'universalModbus', params: to.params, query });
              }
              return next();
            }
            catch ( error ) {
              BluetoothService.disconnectDevice(to.params.macBLE);
              console.error(error);
              Toast.clear();
              Dialog.error(error);

              return next({name: from.name})
            }
          }
        },
        {
          path: 'searchWifisBLE/:webserverID',
          name: 'searchWifisBLE',
          component: () => import('../pages/AddDevices/BleAssociation/SearchWifisBLE'),
          props: true,
        },
      ]
    },
    {
      path: 'wifi/:mac/:ssid',
      name: 'wifiAssociation',
      component: () => import('../pages/AddDevices/WifiAssociation'),
      props: true,
      redirect: {
        name: 'searchWifis'
      },
      beforeEnter: async (to, from, next) => {

        Webserver.create({
          data: { id: to.params.mac, mac: to.params.mac, stat_ssid: to.params.ssid }
        });
        next();
      },
      children: [
        {
          path: 'search',
          name: 'searchWifis',
          component: () => import('../pages/AddDevices/WifiAssociation/SearchWifis'),
          props: true
        },
        {
          path: 'confirm',
          name: 'confirmAssociation',
          component: () => import('../pages/AddDevices/WifiAssociation/ConfirmAssociation'),
          props: true
        }
      ]
    },
    {
      path: 'manual',
      name: 'manualAssociation',
      component: () => import('../pages/AddDevices/ManualAssociation'),
      props: true,
    },
    {
      path: 'mac/:mac',
      name: 'selectInstallation',
      component: () => import('../pages/AddDevices/SelectInstallation'),
      props: true,
      beforeEnter: async (to, from, next) => {
        try{
          //
          // Obtenemos las instalaciones para listarlas.
          //
          await Installation.getInstallations('access_type', 'admin');

          next();
        } catch (error) {
          Dialog.error(error);
          next();
        }
      }
    },
    {
      path: 'mac/:mac/edit',
      name: 'editInstallation',
      component: () => import('../pages/AddDevices/EditInstallation'),
      props: true
    }
  ]
}

export default addDevicesRoutes;
