import CryptoJS from 'crypto-js';

// Clave de encriptación
const key = 'AzcloudCryptoKey2020';

const CryptoService = {
  /**
   * Encripta una cadena de texto
   *
   * @param {String} password - Texto a encriptar
   */
  encrypt(password) {
    return CryptoJS.AES.encrypt(password, key).toString();
  },

  /**
   * Desencripta una cadena de texto previamente encriptada
   *
   * @param {String} cipher - Texto a desencriptar
   */
  decrypt(cipher) {
    return CryptoJS.AES.decrypt(cipher, key).toString(CryptoJS.enc.Utf8);
  },
};

export default CryptoService;
