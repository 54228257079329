
import BleUtils from 'Units/utils/ble.utils';
import {showPopupPin , checkDisabledInternet} from 'Core/utils/popup.utils'
// import moment from 'moment-timezone';
// Services
import localUserData from 'Auth/services/localUserData.service';
import BluetoothService from 'Core/services/bluetooth.service';
import i18n from 'Core/services/language.service';
import store from 'Core/store/store';
import CONSTANTS from 'Units/constant'
import { V1lowerThanV2, compareVersions } from 'Core/utils/utils';

// COMPONENTS
import Dialog from 'Core/components/Dialog';
import Toast from 'Core/components/Toast';
import PopupGlobal from 'Core/components/PopupGlobal';

// MODELS
import User from 'Auth/models/User';
import Installation from 'Units/models/Installation.model';
import Webserver from 'Units/models/Webserver.model';
import { Device } from 'Units/models/DeviceHierarchy';

const configBLERoutes = {
  path: 'config/:macBLE/:webserverID',
  name: 'airtoolsConfigBLE',
  component: () => import('Units/pages/AirtoolsBLE/AirtoolsBLEAssociation/AirtoolsConfigBLE'),
  redirect: {
    name: 'airtoolsWebserverConfigBLE'
  },
  meta: {
    isBle: true,
    isAirtools: true,
  },
  beforeEnter: async (to, from, next) => {
    try {
      //
      // Registro cambios en Bluetooth
      //
      Toast.loading({
        closeOnclick: false
      });
      const res = await BleUtils.getDeviceBleModel(to.params.macBLE, to.query.deviceName, true)

      // Agregamos el parámetros webserverID desde la información obtenida del ws
      const ws = Webserver.query().where('macBLE', to.params.macBLE).first();
      await BleUtils.setLocalTime(to.params.macBLE, ws?.ble_version, ws?.region);
      to.params.webserverID = ws.id;
      // Agregamos el parámetro para indicar que venimos de Airtools BLE

      if(res.bleConfig === true) {
        let isDeviceAllowed = true
        if (ws.type !== CONSTANTS.DEVICE_TYPE.az_8cb) {
          isDeviceAllowed = ws.isDeviceAssociated
        }

        const user = User.query().first();

        const isUserAllow = store.getters.getIsUserAllow || await ws.getLocalUserAllow(user.email, ws.id);

        if ( (isUserAllow || (ws.isUserAllow && isDeviceAllowed)) === false ) {

          try {
            Toast.clear()

            const code = await showPopupPin(ws.mac, false, false, '')

            //
            // Si el device es de tipo CENTRAL entonces guardamos la información del pin en local storage
            //
            if (ws.type === CONSTANTS.DEVICE_TYPE.az_8cb) {
              console.log("Es central. Guardamos el pin en storage");
              //
              // Inicializamos el servicio y preparamos el usuario para
              // almacenar las credenciales en local storage
              //
              localUserData.init();
              // const user = User.query().first();
              localUserData.checkUser(user.email);
              localUserData.setPincode(user.email, ws.mac, code);
            }

            //
            // Guardamos el pin en el modelo
            //
            await ws.setParam('pin', code);
            //
            // Hacemos que el usuario esté permitido en VUEX
            //
            await ws.setParam('isUserAllow', true);
            store.dispatch('setIsUserAllowStatus', true);

            return next()

          } catch(err) {
            Toast.clear();
            return next({ name: from.name })
          }

        }

        return next();
      }

      Toast.clear();

      PopupGlobal.confirm({
        title: i18n.global.t('home.invited_title'),
        subtitle: i18n.global.t('messages.detectSystemFail.title'),
        message: i18n.global.t('airtools.wsVersion.notCompatible'),
        src: "unitError.svg",
        buttons: true,
        danger: true,
        cancelBtn: false
      }).then(() => {
        return next({name: 'airtoolsBLESearchDevice'})
      })
      .catch(() => {
        return next({name: from.name})
      })

    } catch ( error ) {
      BluetoothService.disconnectDevice(to.params.macBLE);
      console.error(error);
      Toast.clear();
      Dialog.error(error);

      return next({name: from.name})
    }

  },
  props: true,
  children: [
    {
      path: 'airtoolsWS',
      name: 'airtoolsWebserverConfigBLE',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsWebserverConfigBLE'),
      props: true,
      beforeEnter: async (to, from, next) => {

        try {
          Toast.loading({
            closeOnclick: false
          })
          const macBLE = to.params.macBLE;
          const device = Webserver.query().where('macBLE', macBLE).first();
          const user = User.query().first();
          if((from.name === 'airtoolsBLESearchDevice' || from.name === 'searchWifisBLE' || from.name === 'searchDevice' || from.name === 'airtoolsBLEOutputsSetup')){

            await Webserver.getWebserverStatusBLE(macBLE, device.id, device.type, device.ble_version, device.wsver, user.email);

            if(BleUtils.compatibleIntegrations(device)) {
              await Webserver.getWebserverServicesBLE(macBLE, device.id);
            }
          }
          if(device.type === 'aidoo'){
            return next({name: 'airtoolsConfigBLEAidoo', params: { macBLE, webserverID: device.id }, query: to.query });
          }

          Toast.clear()

          return next();

        } catch (error) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          BluetoothService.disconnectDevice(to.params.macBLE);
          return next({name: from.name});
        }
      },
    },
    {
      path: 'airtoolsConfigBLESystem/:deviceID',
      name: 'airtoolsConfigBLESystem',
      component: () => import('Units/pages/Airtools/AirtoolsSystem'),
      props: true,
      beforeEnter: async (to, from, next) => {

        try {
          const system = Device.find(to.params.deviceID);

          // Comprobamos si el sistema tiene pinza asociada
          const clamp = Device.query()
              .where('type', 'CLAMP')
              .where('system_number', system.system_number).first();

          // Si tiene pinza (medidor de consumo), obtenemos sus datos en el modelo
          if(clamp !== null) {
            await Device.getBleConfig(clamp.id);
          }

           // Comprobamos si el sistema tiene pinza asociada
           const dehumidifier = Device.query()
           .where('type', 'DEHUMIDIFIER')
           .where('system_number', system.system_number).first();

          // Si tiene deshumidificador, obtenemos sus datos en el modelo
          if(dehumidifier !== null) {
            await Device.getBleConfig(dehumidifier.id);
          }

          await Device.getBleConfig(to.params.deviceID);
          next();
        } catch( error ) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          next({name: from.name});
        }
      },
      redirect: {
        name: 'airtoolsConfigBLESystemInfo'
      },
      children: [
        {
          path: 'airtoolsInfo',
          name: 'airtoolsConfigBLESystemInfo',
          component: () => import('Units/pages/Airtools/AirtoolsSystemInfo'),
          props: true,
        },
        {
          path: 'airtoolsSettings',
          name: 'airtoolsConfigBLESystemSettings',
          component: () => import('Units/pages/Airtools/AirtoolsSystemSettings'),
          props: true,
        }
      ]
    },
    {
      path: 'airtoolsConfigBLEDevice/:deviceID',
      name: 'airtoolsConfigBLEDevice',
      component: () => import('Units/pages/Airtools/AirtoolsDevice'),
      props: true,
      beforeEnter: async (to, from, next) => {
        const device = Device.find(to.params.deviceID);

        // Si no hay device (P.Ejem: al eliminar una zona virtual y actualizar el modelo) -> Redirigimos a vista Airtools Webserver
        if(device === null) {
          return next({name: 'airtoolsWebserverConfigBLE', params: to.params, query: to.query});
        }

        try {
          //
          // Comprobamos si el sistema tiene salidas TRV asociada
          //
          const outputs = Device.query()
              .where('type', 'OUTPUTS')
              .where('system_number', device.system_number).first();

          if(outputs !== null) {
            await Device.getBleConfig(outputs.id);
          }

          await Device.getBleConfig(to.params.deviceID);


          return next();
        } catch( error ) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          return next({name: from.name});
        }
      },
      redirect: {
        name: 'airtoolsConfigBLEDeviceInfo'
      },
      children: [
        {
          path: 'airtoolsInfo',
          name: 'airtoolsConfigBLEDeviceInfo',
          component: () => import('Units/pages/Airtools/AirtoolsDeviceInfo'),
          props: true,
        },
        {
          path: 'airtoolsSettings',
          name: 'airtoolsConfigBLEDeviceSettings',
          component: () => import('Units/pages/Airtools/AirtoolsDeviceSettings'),
          props: true,
        }
      ]
    },
    {
      path: 'outputsBLESetup',
      name: 'airtoolsBLEOutputsSetup',
      component: () => import('Units/pages/Wizard/WizardConfigZone'),
      props: true,
      beforeEnter: async(to, from, next) => {
        if(to.params.newVirtualZone) {
          const device = await Device.find(to.params.zoneID);
          const outputs = Device.query()
            .where('type', 'OUTPUTS')
            .where('system_number', device.system_number).first();

          if(outputs !== null) {
            await Device.getBleConfig(outputs.id);
          }
        }
        next();
      }
    },
    {
      path: 'airtoolsAidoo',
      name: 'airtoolsConfigBLEAidoo',
      component: () => import('Units/pages/Airtools/Aidoo'),
      props: true,
      beforeEnter: async (to, from, next) => {
        try {
          //
          // Si el webserver es un Aidoo obtenemos diréctamente la información de los dispositivos
          //
          const devices = Device.query().where('webserver_id',to.params.webserverID).get();
          // No podemos usar forEach si queremos usar await dentro del bucle !
          for(let i = 0; i < devices.length; i++){
            // eslint-disable-next-line no-await-in-loop
            await Device.getBleConfig(devices[i].id);

            // Deshabilito el botón de Reset de fabrica para los Aidoo Pro
            if (devices[i].device_type === 'aidoo_it') {
              const webserver = Webserver.find(to.params.webserverID);
              if (V1lowerThanV2(webserver.wsver, '6.10') || compareVersions(webserver.wsver, '7.00') === 0) {
                webserver.setParam('resetDisabled', true);
              }
            }
          }
          next();
        } catch( error ) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          next({name: from.name});
        }
      },
      redirect: {
        name: 'airtoolsConfigBLEAidooInfo'
      },
      children: [
        {
          path: 'airtoolsInfo',
          name: 'airtoolsConfigBLEAidooInfo',
          component: () => import('Units/pages/Airtools/Aidoo/AidooInfo'),
          props: true,
        },
        {
          path: 'airtoolsSettings',
          name: 'airtoolsConfigBLEAidooSettings',
          component: () => import('Units/pages/Airtools/Aidoo/AidooSettings'),
          props: true,
        },
        {
          path: 'airtoolsBLEUniversalModbus',
          name: 'airtoolsBLEUniversalModbus',
          component: () => import('Units/pages/AddDevices/BleAssociation/UniversalModbus'),
          props: true,
        },
      ]
    },
    {
      path:'airtoolsConfigBLECCP/:deviceID',
      name: 'airtoolsConfigBLECCP',
      component: () => import('Units/pages/Airtools/AirtoolsCCPSettings'),
      props: true,
      beforeEnter: async (to, from, next) => {
        if (store.getters.getIsDemo) {
          next();
        } else {
          try {
            await Device.getBleConfig(to.params.deviceID);
            next();
          } catch( error ) {
            console.log(error);
            Toast.clear();
            Dialog.error(error);
            next({name: from.name});
          }
        }
      },
    },
    {
      path: 'airtoolsConfigBLEIntegration',
      name: 'airtoolsConfigBLEIntegration',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLEIntegration'),
      props: true,
    },
    {
      path: 'sensorSettings',
      name: 'sensorSettings',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLEIntegration/SensorSettings'),
      props: true,
    },
    {
      path: 'sensorSettingsAdvanced',
      name: 'sensorSettingsAdvanced',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLEIntegration/SensorSettingsAdvanced'),
      props: true,
    },
    {
      path: 'airtoolsConfigBLENetwork',
      name: 'airtoolsConfigBLENetwork',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsConfigBLENetwork'),
      props: true,
      beforeEnter: async (to, from, next) => {
        try {
          Toast.clear()

          await checkDisabledInternet(to.params.webserverID, to.params.macBLE, to.query.deviceName)

          return next()
        } catch(err) {
          Toast.clear();
          console.log(err)
          return false
        }
      }
    },
    {
      path: 'schedules/:installationID',
      name: 'BleSchedules',
      component: () => import('Units/pages/Schedules'),
      props: true,
      redirect: {
        name: 'BleSchedulesMainView'
      },
      beforeEnter: async (to, from, next) => {

        console.log("LOADING SCHEDULES", to.params)
        try {
          // Segundo param a "true" indica es getSchedulues BLE
          const data = await Installation.getSchedules(to.params.installationID, to.params.macBLE);

          console.log(data);



        } catch (error) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          return next({name: from.name, params: {error: true}});
        }
        return next();
      },
      children: [
        {
          path: '',
          name: 'BleSchedulesMainView',
          component: () => import('Units/pages/Schedules/SchedulesMainView'),
          props: true,
          redirect: {
            name: 'BleListedSchedules'
          },
          children: [
            {
              path: 'week',
              name: 'BleWeekSchedules',
              component: () => import('Units/pages/Schedules/WeekSchedules'),
              props: true
            },
            {
              path: 'listed',
              name: 'BleListedSchedules',
              component: () => import('Units/pages/Schedules/ListedSchedules'),
              props: true
            },
          ]
        },
        {
          path: 'BleEdit/:scheduleID?',
          name: 'BleEditSchedule',
          component: () => import('Units/pages/Schedules/EditSchedule'),
          props: true,
          redirect: {
            name: 'BleEditWeekSchedule',
          },
          children: [
            {
              path: 'weekSchedule',
              name: 'BleEditWeekSchedule',
              component: () => import('Units/pages/Schedules/EditSchedule/EditWeekSchedule'),
              props: true
            },
            {
              path: 'calendarSchedule',
              name: 'BleEditCalendarSchedule',
              component: () => import('Units/pages/Schedules/EditSchedule/EditCalendarSchedule'),
              props: true
            },
            {
              path: 'acsSchedule',
              name: 'BleEditAcsSchedule',
              component: () => import('Units/pages/Schedules/EditSchedule/EditAcsSchedule'),
              props: true
            },
            {
              path: 'vmcSchedule',
              name: 'BleEditVmcSchedule',
              component: () => import('Units/pages/Schedules/EditSchedule/EditVmcSchedule'),
              props: true
            },
            {
              path: 'relaySchedule',
              name: 'BleEditRelaySchedule',
              component: () => import('Units/pages/Schedules/EditSchedule/EditRelaySchedule'),
              props: true
            },
          ]
        },
      ]
    }
  ]
}

export default configBLERoutes;
