/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recovery-bold': {
    width: 33,
    height: 32,
    viewBox: '0 0 33 32',
    data: '<path pid="0" d="M31.07 24.84h-8.74l-5.14-6.46 5.79-7.28h6.83l-3.17 3.17c-.1.1-.16.23-.16.37 0 .14.06.27.15.37.1.1.23.15.37.15.14 0 .27-.06.37-.15l4.06-4.06c.09-.1.15-.23.15-.37 0-.12-.05-.23-.12-.32 0 0-.04-.05-.04-.06l-4.04-4.04c-.1-.1-.22-.14-.37-.16-.14 0-.27.06-.37.15a.523.523 0 00.01.74l3.17 3.17h-7.05c-.14 0-.27.06-.37.15l-5.85 7.35-5.85-7.35c-.1-.1-.23-.15-.37-.15H3.28l3.17-3.17c.1-.1.15-.23.16-.37 0-.14-.06-.27-.15-.37A.497.497 0 006.09 6c-.16.02-.27.06-.37.16l-4.06 4.05-.04.06c-.07.09-.12.2-.12.32 0 .14.06.27.15.37l4.06 4.06c.1.1.23.15.37.15.14 0 .27-.06.37-.15a.523.523 0 00-.01-.74l-3.17-3.17h6.83l5.79 7.28-5.14 6.46H2.02c-.29 0-.52.23-.52.52 0 .29.23.52.52.52h8.96c.14 0 .27-.06.37-.15l5.2-6.54 5.2 6.54c.1.1.23.15.37.15h8.96c.29 0 .52-.23.52-.52 0-.29-.23-.52-.52-.52l-.01-.01z" _fill="#fff"/>'
  }
})
