import { ref, onMounted, onUnmounted } from 'vue';

/**
 * Obtiene las dimensiones de un elemento HTML o de la ventana del navegador
 *
 * @param {*} el referencia al elemento que del que se quiere obtener el tamaño (opcional)
 * @returns
 */
export const useElementSize = (el) => {
  const width = ref(0);
  const height = ref(0);

  const resizeHandler = () => {
    // Si se pasa un elemento como parámetro, se obtienen las dimensiones de ese elemento, sino devuelve las del cliente
    if(el !== undefined) {
      // Si la entrada es un string, se busca el elemento en el DOM con querySelector (ejemplo: '.clase' o '#id')
      if(typeof el === 'string') {
        const auxElem = document.querySelector(el);
        width.value = auxElem.clientWidth;
        height.value = auxElem.clientHeight;
        // Si la entrada es un objeto, suponemos que es una referencia reactiva VUE a un elemento HTML,
      } else if(typeof el === 'object' && el.value !== undefined) {
        width.value = el.value.clientWidth;
        height.value = el.value.clientHeight;
      }
    } else {
      width.value = window.innerWidth;
      height.value = window.innerHeight;
    }
  }


  onMounted(() => {
    resizeHandler(); // Se ejecuta al inicio para obtener las dimensiones iniciales
    window.addEventListener('resize', resizeHandler);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
  });


  return {
    width,
    height
  }

}
