/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bypass-lock': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M26.17 9.09H1.83c-.46 0-.83-.37-.83-.83 0-.46.38-.83.83-.83h24.33L21.13 2.4a.811.811 0 01-.25-.58c-.01-.23.09-.44.24-.59.15-.15.37-.24.58-.24.25.03.43.09.59.25l6.41 6.41.08.1c.11.14.18.31.18.51 0 .23-.09.43-.24.58l-6.44 6.44c-.15.16-.37.25-.58.25-.21 0-.43-.1-.58-.25a.843.843 0 01-.24-.58c0-.21.09-.43.25-.58l5.03-5.03h.01zM18.3 22.95h2c.46 0 .83-.37.83-.83 0-.46-.38-.83-.83-.83H3.81l5.03-5.03c.16-.15.25-.37.25-.58a.791.791 0 00-.24-.59.843.843 0 00-.58-.24c-.25.03-.43.09-.59.25l-6.42 6.42-.08.1c-.11.14-.18.28-.18.51 0 .23.09.43.24.58l6.44 6.44c.15.16.37.25.58.25.21 0 .43-.1.58-.25.15-.15.24-.37.24-.58 0-.21-.09-.43-.25-.58L3.8 22.96h14.5v-.01zM29.59 23.52v-1.85c0-1.98-1.58-3.59-3.52-3.59s-3.53 1.61-3.53 3.59v1.85c-.78 0-1.41.53-1.41 1.39v4.86c0 .79.63 1.43 1.41 1.43h7.05c.78 0 1.41-.64 1.41-1.43v-4.86c0-.82-.63-1.39-1.41-1.39zm-2.65 5.05a.87.87 0 01-1.74 0v-2.19a.87.87 0 011.74 0v2.19zm.66-5.04h-3.06v-2.07c0-.82.69-1.49 1.53-1.49.84 0 1.53.67 1.53 1.49v2.07z" _fill="#323F4B"/>'
  }
})
