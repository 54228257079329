import CONSTANTS from 'Units/constant';
import ApiBleService from 'Core/services/api.ble.service';
import Webserver from 'Units/models/Webserver.model';
import AppError from 'Core/services/errors.service';
import log from 'Core/services/log.service';
import bleToModel from 'Units/interfaces/bleToModel.interface';
import { fragmentArray } from 'Core/utils/utils';
// import apiBleMocks from 'Units/mocks/bleApi.mocks';
// eslint-disable-next-line camelcase
import { AZGlobal_IF } from '@airzone/data-interface';
import Popup from 'Core/components/PopupGlobal';
import i18n from 'Core/services/language.service';
import Toast from 'Core/components/Toast';



const apiBle = new ApiBleService();

const NUM_MAX_RETRYS = CONSTANTS.BLUETOOTH.NUM_MAX_RETRYS;

let blockWriteInteractions = false;

const BluetoothService = {

setBleVersion(bleVersion) {
  apiBle.bleVersion = bleVersion;
},

async setDeviceType(deviceType, deviceID) {
  apiBle.deviceType = deviceType;
  // Si es una central, tras el cmd: info, forzamos la desconexión y volvemos a conectar para hacerlo con requestMtu 512bytes
  if(deviceID && CONSTANTS.BLUETOOTH.DEVICE_TYPES_FORCE_REQUESTMTU_BUFFERSIZE.includes(deviceType)) {
    await apiBle.disconnectDevice(deviceID);
    await apiBle.connectDevice(deviceID);
  }
},

getBleVersion() {
  return apiBle.bleVersion;
},

/*
 * Stops the program flow for a while
 *
 * @param  {Integer} - Number of miliseconds
 * @return {Promise} - Return when timeout is finished
 */
// function sleep(miliseconds) {
//   return new Promise(resolve => setTimeout(resolve, miliseconds));
// }

getDeviceData(deviceID, dataInfo, callback, webserverID = null) {
  return new Promise(async (resolve, reject) => {
    let breakLoop = false;

    const timeout = setTimeout(() => {
      breakLoop = true;
    }, CONSTANTS.BLUETOOTH.MAX_TIME_GET_DATA);

    try {
      const getData = async attempts => {
        let deviceInfo;
        if(CONSTANTS.BLUETOOTH.MAX_TIME_GET_DATA_EXTENDED_CMD.includes(dataInfo)){
          deviceInfo = await apiBle.readData(deviceID, CONSTANTS.BLUETOOTH.MAX_TIME_GET_DATA_EXTENDED)
        } else {
          deviceInfo = await apiBle.readData(deviceID);
        }



        console.log('Bluetooth', dataInfo);
        console.log('intentos: ', attempts);
        console.log('Lectura', deviceInfo);

        if (dataInfo === 'getDeviceInfo' && deviceInfo.general) {
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if (dataInfo === 'resetWebserver' && deviceInfo.reset) {
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if (dataInfo === 'updateNetworkConfig' && deviceInfo) {
          console.log("updeteNetworkConfig response", deviceInfo);
          clearTimeout(timeout);
          // Webserver.createMetric('ble', 'network_ack_read', deviceInfo);

          resolve(deviceInfo);
          return

        }

        if (dataInfo === 'getModbus' && deviceInfo?.extrainfo?.modbusaddr) {
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if (dataInfo === 'sendNextCommand' && deviceInfo) {
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if (dataInfo === 'getDeviceWifis' && deviceInfo.aplist) {
          clearTimeout(timeout);
          resolve(deviceInfo.aplist);
          return;
        }

        if (dataInfo === 'connectDeviceToWifi' && deviceInfo.general) {
          console.log("connect to wifi data", deviceInfo);
          apiBle.clearPackageBuffer();

          callback(deviceInfo, deviceID);
          return resolve(deviceInfo);

          // if(
          //   deviceInfo.wifi.status === CONSTANTS.WIFI_STATUS.DISCONNECTED ||
          //   deviceInfo.wifi.status === CONSTANTS.WIFI_STATUS.ERROR
          // ) {
          //   clearTimeout(timeout);
          //   return reject(new AppError('notConnect'));
          // } else if(deviceInfo.wifi.status === CONSTANTS.WIFI_STATUS.CLOUD_CONNECTED) {
          //   clearTimeout(timeout);
          //   callback(deviceInfo, deviceID);
          //   resolve(true);
          //   // resolve(deviceInfo, deviceID);
          //   return;
          // } else {
          //   clearTimeout(timeout);
          //   callback(deviceInfo, deviceID);
          //   resolve(true);
          //   return;
          //   // resolve(deviceInfo, deviceID);
          // }
        }

        if(dataInfo === 'getTopologyInfo' && deviceInfo){
          console.log("Resp topology",deviceInfo);
          const detectSystemDisabled = deviceInfo.ws_detect_system_disabled === 1;
          const devices = deviceInfo.devices;

          const devicesData = bleToModel.getDevices(deviceID, devices, webserverID);

          clearTimeout(timeout);
          resolve({detectSystemDisabled, devicesData});
          return
        }

        if(dataInfo === 'getWsSchedules' && deviceInfo.sched){
          console.log("Resp getWsSchedules",deviceInfo);
          // const devices = deviceInfo.devices;

          // const devicesData = bleToModel.getDevices(deviceID, devices, webserverID)

          clearTimeout(timeout);
          resolve(deviceInfo);
          return
        }

        if(dataInfo === 'saveWsSchedule' && deviceInfo){
          console.log("Resp getWsSchedules",deviceInfo);
          // const devices = deviceInfo.devices;

          // const devicesData = bleToModel.getDevices(deviceID, devices, webserverID)

          clearTimeout(timeout);
          resolve(deviceInfo);
          return
        }

        if(dataInfo === 'deleteWsSchedule' && deviceInfo) {
          console.log("Resp deleteWsSchedule", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'detectSystems' && deviceInfo.devices){
          console.log("Resp detectSystem",deviceInfo);
          const devices = deviceInfo.devices;

          const devicesData = bleToModel.getDevices(deviceID, devices, webserverID)

          clearTimeout(timeout);
          resolve(devicesData);
          return
        }

        if(dataInfo === 'getParams' && deviceInfo.infoparams){
          console.log("Resp getParams", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'setParams' && deviceInfo.info) {
          console.log("Resp setParams", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'getParamsValues' && deviceInfo.info) {
          console.log("getParamsValues", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'getWsServices' && deviceInfo.services) {
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'setServices' && deviceInfo.services) {
          console.log("Resp setServices", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'setLocalTime' && deviceInfo) {
          console.log("Resp setLocalTime", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'getMetersConf' && deviceInfo) {
          console.log("Resp getMetersConf", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'setMeterConf' && deviceInfo?.set_meters_conf_rsp) {
          console.log("Resp setMetersConf", deviceInfo);

          clearTimeout(timeout);
          resolve(true);
          return;
        }

        if(dataInfo === 'setMdbuConf' && deviceInfo?.set_mdbu_conf_rsp) {
          console.log("Resp setMdbuConf", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo.set_mdbu_conf_rsp);
          return;
        }

        if(dataInfo === 'getMachineReady' && deviceInfo?.get_machine_ready_rsp) {
          console.log("Resp getMachineReady", deviceInfo);

          clearTimeout(timeout);
          resolve(deviceInfo.get_machine_ready_rsp);
          return;
        }

        if(dataInfo === 'getExtraInfo' && deviceInfo?.extrainfo) {
          console.log("Resp getExtraInfo", deviceInfo);
          clearTimeout(timeout);
          resolve(deviceInfo);
          return;
        }

        if(dataInfo === 'getOutputsAvailable' && deviceInfo) {
          console.log("Resp getOutputsAvailable", deviceInfo);

          const outputs = bleToModel.getSystemOutputs(deviceInfo)

          clearTimeout(timeout);
          resolve(outputs);
          return;
        }


        if(dataInfo === 'setTestResult' && deviceInfo?.test_result) {
          console.log("Resp setTestResult", deviceInfo.test_result);

          clearTimeout(timeout);
          resolve(deviceInfo.test_result);
          return;
        }

        if (!breakLoop) {
          await getData(attempts + 1);

          // setTimeout(async () => {
          //   await getData(attempts + 1);
          // }, 2000);
        } else {
          console.error('errorGetDeviceInfo');
          reject(new AppError('errorGetDeviceInfo', 'Timeout when try get device data'));
        }
      };

      await getData(1);

    } catch (error) {
      // clearTimeout(timeout);
      // console.log("Error en GetData", error);
      reject(error);
    }
  });
},

/**
 * Get a list of devices available by Bluetooth.
 *
 * First check if the user has the bluetooth and location enabled.
 * The search for devices via bluetooth launches an event when it finds a new one,
 * we use a callback function to manage each device when it's found.
 *
 * @param  {function} callback      - Callback function when a new device is found
 * @return {array} devices          - List of found devices
 * @throws {bluetoothDisabled}      - Device bluetooth is Disabled
 * @throws {LocationDisabled}       - Device location is Disabled
 * @throws {errorLocationEnabled}   - Error trying to check if the device location is Enabled
 * @throws {LocationNotAvailable}   - Device location is not Available
 * @throws {errorLocationAvailable} - Error trying to check if the device location is Available
 * @throws {errorAskActiveLocation} - Error trying to ask the user if activate the location
 * @throws {errorStartScan}         - Error trying to scan devices by bluetooth
 * @throws {errorStopScan}          - Error trying to stop scan devices by bluetooth
 */
async getDevicesBluetooth(callback, devicePrefixes) {
  await apiBle.isBluetoothEnabled();
  await apiBle.isLocationEnabled();
  await apiBle.isLocationAvailable();
  return apiBle.scanDevicesBluetooth(callback, devicePrefixes);
},

/**
 * Get a list of devices available by Bluetooth.
 *
 * First check if the user has the bluetooth and location enabled.
 * The search for devices via bluetooth launches an event when it finds a new one,
 * we use a callback function to manage each device when it's found.
 *
 * @param  {function} callback      - Callback function when a new device is found
 * @return {array} devices          - List of found devices
 * @throws {bluetoothDisabled}      - Device bluetooth is Disabled
 * @throws {LocationDisabled}       - Device location is Disabled
 * @throws {errorLocationEnabled}   - Error trying to check if the device location is Enabled
 * @throws {LocationNotAvailable}   - Device location is not Available
 * @throws {errorLocationAvailable} - Error trying to check if the device location is Available
 * @throws {errorAskActiveLocation} - Error trying to ask the user if activate the location
 * @throws {errorStartScan}         - Error trying to scan devices by bluetooth
 * @throws {errorStopScan}          - Error trying to stop scan devices by bluetooth
 */
async searchDevice (deviceName, FIND_MAX_RETRYS = 1) {
    let retry = 0;

    return new Promise(async (resolve, reject) => {
      while(retry < FIND_MAX_RETRYS) {

        try {
          /* eslint-disable no-await-in-loop */
          const deviceID = await apiBle.findDevice(deviceName);
          return resolve(deviceID);

        } catch( error) {
          if(error.name === 'unableConnectDevice'){
            // eslint-disable-next-line no-await-in-loop
            await new Promise(res => setTimeout(res, 50));
            retry++;
            console.log("'unableConnectDevice': Reintento conexión num", retry);
          } else {
            return reject(error);
          }

        }
      }
      return reject(new AppError('unableConnectDevice'))
    });
},
/**
 * Funcion para escribir BLE y leer respuesta. Implementa también un
 * número de reintentos si ha habido un error en la conexión o bien
 * leyendo el buffer de datos.
 *
 * @param {*} deviceID - macBLE del dispositivo
 * @param {*} writeCmd - comando de escritura
 * @param {*} readCmd - comando (id) para la lectura (opcional)
 * @param {*} callback - función callback (opcional)
 * @param {*} webserverID - id de ws (opcional)
 * @param {string} cmdName - Nombre del comando a enviar
 * @returns
 */
// eslint-disable-next-line consistent-return
async sendBlePackage(deviceID, writeCmd, readCmd, callback, webserverID = null){
  return new Promise(async (resolve, reject) => {
      try {
        // eslint-disable-next-line no-await-in-loop
        await apiBle.writeToDevice(deviceID, writeCmd);
        //
        // Esperamos antes de leer para dar tiempo a la central BLE a escribir el buffer.
        // Sólo afecta a los comandos incluídos en SLEEP_TIME_WRITE_TO_READ_CDM
        // NOTA: Se decide incluir siempre el retardo inicial en pro de la estabilidad con
        // cualquier comando de central.
        //
        // if(CONSTANTS.BLUETOOTH.SLEEP_TIME_WRITE_TO_READ_CMD.find(cmd => writeCmd.indexOf(cmd) !== -1)){
          // console.log("150ms Delay before read data");
          // eslint-disable-next-line no-await-in-loop
          await new Promise(res => setTimeout(res, 150));
        // }
        let resp = true
        if (readCmd) {
          // eslint-disable-next-line no-await-in-loop
          resp = await this.getDeviceData(deviceID, readCmd, callback, webserverID);
        }
        return resolve(resp)
      } catch (err) {
        return reject(err);
      }
  });
},

/**
 * Get info of a device
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
 async getDeviceInfo(deviceID) {
  // await apiBle.writeToDevice(deviceID, '{"cmd":"info"}');
  // return this.getDeviceData(deviceID, 'getDeviceInfo');
  let response = await this.sendBLE(deviceID, '{"cmd":"info"}', 'getDeviceInfo');

  if (response?.general?.mac) {
    Webserver.createMetric('ble', 'info', null, response.general.mac)
  }

  if (response?.general?.ble_version >= 3) {
    const responseExtraInfo = await this.sendBLE(deviceID, '{"cmd":"get_extrainfo"}', 'getExtraInfo');
    response = {...response, ...responseExtraInfo}
  }

  return response
},

/**
 * Get extra info of a device
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
 async getExtraInfo(deviceID) {
   return this.sendBLE(deviceID, '{"cmd":"get_extrainfo"}', 'getExtraInfo');
},

/**
 * Inicializa un webserver con los valores de fábrica
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async resetWebserver(deviceID) {
  Webserver.createMetric('ble', 'reset')
  return this.sendBLE(deviceID, '{"cmd":"reset"}', 'resetWebserver');
},

/**
 * Actualiza configuración avanzada de red.
 *
 * @param  {string} deviceID     - ID of device
 * @param {object} network       - Información de red {dhcp, ip, mask, gateway, dns}
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async updateNetworkConfig(deviceID, network) {

  const command = `{"network_ack":{"dhcp":${network.dhcp ? 1 : 0},"ip":"${network.ip}","mask":"${network.mask}","gateway":"${network.gateway}","dns":"${network.dns}"}}`; // eslint-disable-line

  console.log("Enviando",command);

  Webserver.createMetric('ble', 'network_ack', `{"dhcp":${network.dhcp ? 1 : 0},"ip":"${network.ip}","mask":"${network.mask}","gateway":"${network.gateway}","dns":"${network.dns}"}`)

  return this.sendBLE(deviceID, command, 'updateNetworkConfig');
},

/**
 * Get services of a webserver
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getWsServices(deviceID) {
  Webserver.createMetric('ble', 'getservices')
  return this.sendBLE(deviceID, '{"cmd":"getservices"}', 'getWsServices');
},

/**
 * Set Modbus port
 *
 * @param  {string} deviceID     - ID of device
 * @param  {string} port         - Modbus Port
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async setModbus(deviceID, port) {
  Webserver.createMetric('ble', 'extrainfo', `{"modbusaddr":${port}}`)
  return this.sendBLE(deviceID, `{"extrainfo":{"modbusaddr":${port}}}`, 'getModbus');
},

/**
 * Set extrainfo params
 *
 * @param  {string} deviceID     - ID of device
 * @param  {string} params         - params
 * @return {object}              - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async setExtraInfo(deviceID, params) {
  Webserver.createMetric('ble', 'extrainfo', `${JSON.stringify(params)}`)

  await apiBle.writeToDevice(deviceID, `{"extrainfo":${JSON.stringify(params)}}`);

  // Pequeño retardo antes de empezar a leer para dejar respirar al buffer tras la escritura
  await new Promise(res => setTimeout(res, 250));

  return this.getDeviceInfo(deviceID);
},

/*
 * Test a device
 *
 * @param  {string} - ID of device
 * @param  {string} - Action command
 * @return {booelan} - Result of apply action
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async testDevice(deviceID, action) {
  Webserver.createMetric('ble', 'extrainfo', `"${action}"`)
  await apiBle.writeToDevice(deviceID, `{"cmd":"${action}"}`);
  return true;
},

/*
 * Get WiFi networks available from a device
 *
 * @param  {string} - ID of device
 * @return {object} - Device data
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getDeviceWifis(deviceID) {
  Webserver.createMetric('ble', 'scan')
  return this.sendBLE(deviceID, '{"cmd":"scan"}', 'getDeviceWifis');
},

/*
 * Connect device to WiFi Network
 *
 * @param  {string}             - ID of device
 * @param  {object}             - WiFi Network data
 * @param  {object}             - Data to connect with WiFi Network
 * @param  {function}           - Callback function when device finish connect
 * @return {object}             - Device data
 * @throws {unableConnectDevice}   - Unable connect to device
 * @throws {errorWriteDevice}   - Error when try write to device
 * @throws {errorStringToBytes} - Error when try pass string to bytes
 * @throws {errorBytesToString} - Error when try pass bytes to string
 * @throws {errorReadDevice}    - Error when try read from device
 * @throws {errorGetDeviceInfo} - Error when try get device info
 */
// prettier-ignore
async connectDeviceToWifi(deviceID, wifi, callback) {
  console.log(callback)
  const wifiSetting = `"wifi":{"ssid":"${encodeURIComponent(wifi.ssid)}","password":"${encodeURIComponent(wifi.password)}"}`; // eslint-disable-line
  const networkSetting = `"network":{"dhcp":${wifi.dhcp ? 1 : 0},"ip":"${wifi.ip}","mask":"${wifi.mask}","gateway":"${wifi.gateway}","dns":"${wifi.dns}"}`; // eslint-disable-line
  const command = `{${wifiSetting},${networkSetting},"cmd":"connect"}`;

  Webserver.createMetric('ble', 'connect', `wifi:{"ssid":"${encodeURIComponent(wifi.ssid)}","password":"${encodeURIComponent(wifi.password)}"},${networkSetting}`)

  return this.sendBLE(deviceID, command, 'connectDeviceToWifi', callback);

},

/**
 * Obtiene la topología de sistemas y dispositivos de un webserver Ble
 *
 * @param {String} deviceID - MAC BLE
 * @param {String} webserverID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getTopologyInfo(deviceID, webserverID, meta) {
  let command = '';

  console.log("get_topology_info", this.getBleVersion(), meta);

  if(meta !== undefined) {
    command = `{"cmd": "get_topology_info", "meta": ${JSON.stringify(meta)}}`;
  }  else {
    command = `{"cmd": "get_topology_info"}`;
  }

  console.log("Obteniendo Topología");

  Webserver.createMetric('ble', 'get_topology_info', null, webserverID)

  return this.sendBLE(deviceID, command, 'getTopologyInfo', null, webserverID);
},

async getOutputsAvailable(deviceID, systemID) {
  const command = `{"get_sys_output_map": {"systemid":${systemID}}}`;

  console.log('Obteniendo salidas disponibles', command);

 Webserver.createMetric('ble', 'get_sys_output_map', `{"systemid":${systemID}}`);

  return this.sendBLE(deviceID, command, 'getOutputsAvailable');
},

/* Obtiene las programaciones de un webserver
 *
 * @param {String} deviceID - MAC BLE
 * @param {String} webserverID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getWsSchedules(deviceID) {
  const command = `{"info_ws_sched": {}}`;

  Webserver.createMetric('ble', 'info_ws_sched')

  return this.sendBLE(deviceID, command, 'getWsSchedules');

},

async saveWsSchedule(deviceID, sched) {
  const command = `{"mod_ws_sched": {"sched": ${JSON.stringify(sched)}}}`;

  Webserver.createMetric('ble', 'mod_ws_sched', `{"sched": ${JSON.stringify(sched)}}`)

  return this.sendBLE(deviceID, command, 'saveWsSchedule');

},

async deleteWsSchedule(deviceID, schedNum) {
  const command = `{"del_ws_sched":{"sched":{"schedule_number":${schedNum}}}}`;

  Webserver.createMetric('ble', 'del_ws_sched', `{"sched":{"schedule_number":${schedNum}}}`)

  return this.sendBLE(deviceID, command, 'deleteWsSchedule');

},

/**
 * Envía información para setear la hora actual en el dispositivo
 *
 * @param {String} deviceID  ID ble del dispositivo
 * @param {String} utcTime tiempo en UTC en formato ISO String
 * @param {String} utcOffset offset que añadir por la zona horaria al tiempo en UTC
 * @param {String} localTime Tiempo en hora local en formato ISO String (Para central o dispositivos con complejidad para calcular el tiempo con el offset)
 * @returns
 */
async setLocalTime(deviceID, utcTime, utcOffset, localTime = "", region) {
  const regionParam = region ? `,"region":"${region}"` : ''
  const command = `{"set_local_time":{"utc_time":"${utcTime}","utc_offset":${utcOffset},"local_time":"${localTime}"${regionParam}}}`

  Webserver.createMetric('ble', 'set_local_time', `{"utc_time":"${utcTime}","utc_offset":${utcOffset},"local_time":"${localTime}"${regionParam}}`)

  return this.sendBLE(deviceID, command, 'setLocalTime');
},

/**
 * Obtiene la información y dispositivos de un webserver Aidoo Ble
 *
 * @param {String} deviceID - MAC BLE
 * @param {String} webserverID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getAidooPROInitialState(deviceID, webserverID) {
  // // Para SYSTEM: system_id = 1, zone_id = 0
  // const dataSystem = await BluetoothService.getParams(macBLE, 1, 0);
  // console.log("Datos system", dataSystem);
  // // Para ZONE: system_id = 1, zone_id = 1
  // const dataZone = await BluetoothService.getParams(macBLE, 1, 1);
  // console.log("Datos zone", dataZone);
  // // Para CCP: system_id = 0, zone_id = 0
  // const dataCcp = await BluetoothService.getParams(macBLE, 0, 0);
  // console.log("Datos CCP", dataCcp);

  return bleToModel.getAidooPROInitialState(deviceID, webserverID)
},

/**
 * Obtiene el nombre de todos los parámetros disponibles de un device
 *
 * @param {String} deviceID
 * @param {Number} systemID
 * @param {Number} zoneID
 * @param {String} deviceType - Tipo de dispositivo para obtener parámetros: az_system, az_zone, az_energy_clamp, etc...
 * @param {String} type - tipo de parámetros de configuración <advanced> o <user>
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getParams(deviceID, systemID = 1, zoneID = 0, deviceType, type = 'advanced') {
  console.log("EN GET PARAMS BLE");

  zoneID = zoneID === undefined || zoneID === null ? 0 : zoneID;

  const command = `{"getparams": { "systemid":${systemID}, "device_type":"${deviceType}", "zoneid":${zoneID}, "type": "${type}"} }`;

  Webserver.createMetric('ble', 'getparams', `{ "systemid":${systemID}, "device_type":"${deviceType}", "zoneid":${zoneID}, "type": "${type}"}`)

  return this.sendBLE(deviceID, command, 'getParams');

},

async getWsParams(deviceID, params) {

  const command = `{"get": { "params":${JSON.stringify(params)}} }`;

  Webserver.createMetric('ble', 'get', `{ "params":${JSON.stringify(params)}}`)

  return this.sendBLE(deviceID, command, 'getParamsValues');

},

/**
 * Obtiene los valores de una serie de parámetros enviados en un array
 *
 * @param {String} deviceID
 * @param {Array<Strings>} params
 * @param {Number} systemID
 * @param {Number} zoneID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getParamsValuesPackage({deviceID, params, systemID, zoneID = 0, deviceType}) {

  const command = `{"get": {"systemid": ${systemID}, "zoneid": ${zoneID}, "device_type": "${deviceType}", "params": ${JSON.stringify(params)}} }`;

  return new Promise( async (resolve, reject) => {
    try {

      log.info(`Launching command: getParamsValues (package)`);

      Webserver.createMetric('ble', 'get', `{"systemid": ${systemID}, "zoneid": ${zoneID}, "params": ${JSON.stringify(params)}}`)

      const result = this.sendBLE(deviceID, command, 'getParamsValues');

      resolve(result);
    } catch ( error ) {
      reject(new AppError('getParamsError', 'Error obteniendo los valores de los parámetros'));
    }
  });

},

/**
 * Envía una actualización de los parámetros indicados con nuevos valores
 *
 * @param {String} deviceID
 * @param {Object} params
 * @param {Number} systemID
 * @param {Number} zoneID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async setParams(deviceID, params, systemID = 1, zoneID = 0, deviceType) {
  zoneID = zoneID === undefined || zoneID === null ? 0 : zoneID;

  const command = `{"set": { "systemid":${systemID}, "zoneid":${zoneID}, "device_type":"${deviceType}", "params": ${JSON.stringify(params)}} }`;

  log.info(`Launching command: setparams ${systemID}, ${zoneID}, ${params}`);

  Webserver.createMetric('ble', 'set', `{ "systemid":${systemID}, "zoneid":${zoneID}, "device_type":"${deviceType}", params": ${JSON.stringify(params)}}`)

  return this.sendBLE(deviceID, command, 'setParams');

},

/**
 * Envía una actualización de los parámetros indicados con nuevos valores
 *
 * @param {String} deviceID
 * @param {Object} params
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async setWsParams(deviceID, params) {

  const command = `{"set": { "params": ${JSON.stringify(params)}} }`;

  log.info(`Launching command: setparams ws`);

  Webserver.createMetric('ble', 'setParams', `{ "params": ${JSON.stringify(params)}}`)

  return this.sendBLE(deviceID, command, 'setParams');

},

/**
 * Envía una actualización de los parámetros indicados con nuevos valores
 *
 * @param {String} deviceID
 * @param {Object} params
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async setWsService(deviceID, params) {

  const command = `{"setservices": { "services": ${JSON.stringify(params)}} }`;

  log.info(`Launching command: setservices ws`);

  Webserver.createMetric('ble', 'setservices', `{ "services": ${JSON.stringify(params)}}`)

  return this.sendBLE(deviceID, command, 'setServices');

},

/**
 * Actualiza la información de la topología del sistema cuando se ha instalado o movido
 * el webserver.
 *
 * @param {String} deviceID
 * @param {String} webserverID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async detectSystems(deviceID, webserverID) {

  const command = `{"cmd": "detect_system"}`;

  log.info(`Launching command: detect_system`);

  Webserver.createMetric('ble', 'detect_system', null, webserverID)

  return this.sendBLE(deviceID, command, 'detectSystems', null, webserverID);

},


/**
 * Procesa una petición de valores de una lista de parámetros. Primero fragmenta
 * el array de parámetros a solicitar en varios paquetes (para no saturar el buffer Ble de escritura)
 * Después va solicitando cada paquete con el método descrito getParamsValuesPackage
 * Finalmente une todos los resultados obtenidos en un único Array y es devuelto.
 *
 * @param {String} deviceID
 * @param {Array<String>} params
 * @param {Number} systemID - Sistem ID de máquina (Ej: CCP = 0, Aidoo = 1, etc... )
 * @param {Number} zoneID
 * @returns
 * @throws  {unableConnectDevice}   - Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 * @throws  {errorStringToBytes} - Error when try pass string to bytes
 * @throws  {errorBytesToString} - Error when try pass bytes to string
 * @throws  {errorReadDevice}    - Error when try read from device
 * @throws  {errorGetDeviceInfo} - Error when try get device info
 */
async getParamsBleValues({deviceID, params, systemID, zoneID = 0, deviceType}) {

  const packagesOfParams = fragmentArray(params, CONSTANTS.UTILS.ITEMS_FOR_FRAGMENT);

  const arrayOfResponses = [];
  // let count = 0;

  console.log("Total packages", packagesOfParams);
  for(let i = 0; i < packagesOfParams.length; i++) {
    try{
      // eslint-disable-next-line no-await-in-loop
      const res = await this.getParamsValuesPackage({deviceID, params: packagesOfParams[i], systemID, zoneID, deviceType});
      console.log( res);
      arrayOfResponses.push(res);
    } catch ( err ) {
      console.log(err);
      throw new AppError('getParamsError', 'Error obteniendo los valores de los parámetros');
    }
  }

  //
  // Preparamos un único objeto con todos los parámetros unidos
  //
  // NOTA: Nos interesan los parámetros de la repuestas. La respuesta
  // es un obteto que tiene {info: {params: {}}
  //
  const resp = {};
  for(let i = 0; i < arrayOfResponses.length; i++) {
    Object.assign(resp, arrayOfResponses[i].info.params )
  }
  return resp;

  // log.info(`Launching command: ${command}`);
},

/*
 * Disconnect bluetooth device
 *
 * @param  {string}                - ID of device
 * @return {boolean}               - Return true if device is disconnected
 * @throws  {errorDisconnectDevice} - Failed to disconnect device
 */
// prettier-ignore
async disconnectDevice(deviceID) {
  console.log("Disconnect device BLE");

  await apiBle.disconnectDevice(deviceID);
  // this.setBleVersion(0); // reiniciamos el BLE versión al desconectar el device

  return true;

},

/**
 * Get info of settings meters
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Meters Integration data
 * @throws  {unableConnectDevice}- Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 */
 async getMetersConf(deviceID) {
  // return this.sendBLE(deviceID, '{"get_meters_conf": {}}', 'getMetersConf');
  return new Promise( async (resolve, reject) => {
    try {
      log.info(`Launching command: get_meters_conf`);

      Webserver.createMetric('ble', 'get_meters_conf', '{}')

      const result = await this.sendBLE(deviceID, '{"get_meters_conf": {}}', 'getMetersConf');

      resolve(result);
    } catch ( error ) {
      console.log(error)
      reject(error);
    }
  });
},

/**
  * Set settings meters
  *
  * @param  {string} deviceID     - ID of device
  * @param  {string} data         - Meters data
  * @return {string}              - "ok" or "fail"
  * @throws  {unableConnectDevice}- Unable connect to device
  * @throws  {errorWriteDevice}   - Error when try write to device
  */
async setMetersConf(deviceID, data) {
  let resp = null;
  const jsonData = {set_meters_conf: data}

  // console.log('jsonData', jsonData)

  return new Promise( async (resolve, reject) => {
    try {
      Webserver.createMetric('ble', 'set_meters_conf', JSON.stringify(jsonData))
      // eslint-disable-next-line no-restricted-syntax

      resp = await this.sendBLE(deviceID, jsonData, 'setMeterConf')

      resolve(resp);
    } catch ( error ) {
      console.log(error)
      reject(error);
    }
  });
},

/**
  * Set modbus map settings in universal modbus Aidoo
  *
  * @param  {string} deviceID     - ID of device
  * @param  {string} data         - Modbus map data
  * @return {string}              - "ok" or "fail"
  * @throws  {unableConnectDevice}- Unable connect to device
  * @throws  {errorWriteDevice}   - Error when try write to device
  */
async setModbusMapConf(deviceID, data) {
  let resp = null;
  const jsonData = {set_mdbu_conf: data}

  // console.log('jsonData', jsonData)

  return new Promise( async (resolve, reject) => {
    try {
      Webserver.createMetric('ble', 'set_mdbu_conf', JSON.stringify(jsonData))
      // eslint-disable-next-line no-restricted-syntax

      resp = await this.sendBLE(deviceID, jsonData, 'setMdbuConf')

      resolve(resp);
    } catch ( error ) {
      console.log(error)
      reject(error);
    }
  });
},

/**
  * Get status of indoor unit
  *
  * @param  {string} data         - Modbus map data
  * @return {string}              - "machine_ready" or "machine_not_ready"
  * @throws  {unableConnectDevice}- Unable connect to device
  * @throws  {errorWriteDevice}   - Error when try write to device
  */
async getMachineReady(deviceID) {
  let resp = null;

  return new Promise( async (resolve, reject) => {
    try {
      Webserver.createMetric('ble', 'get_machine_ready')
      // eslint-disable-next-line no-restricted-syntax

      resp = await this.sendBLE(deviceID, '{"get_machine_ready": {}}', 'getMachineReady')

      resolve(resp);
    } catch ( error ) {
      console.log(error)
      reject(error);
    }
  });
},

async sendBLE(deviceID, jsonData, readCmd, callback, webserverID = null) {

  if(blockWriteInteractions === true) {
    console.log("Bloqueo de interacciones BLE activado");
    return;
  }

  let resp;
  let retry = 0;
  console.log("CMD", readCmd);
  console.log("writing data", jsonData);

  return new Promise(async (resolve, reject) => {


    while(retry < NUM_MAX_RETRYS) {

      blockWriteInteractions = true;

      try {
        if(this.getBleVersion() >= 3) {
          // eslint-disable-next-line no-restricted-syntax
          for (const chunk of AZGlobal_IF.BLEUtils.writeMultipart(jsonData)) {
            const chunkString = JSON.stringify(chunk)
            // console.log('Bytes block: ', chunk, chunkString)

            // Si es el último bloque leo la respuesta
            if (chunk?.f === 1) {
              /* eslint-disable no-await-in-loop */
              console.log("TERMINANDO ENVIO MULTIPART", chunk);
              resp = await this.sendBlePackage(deviceID, chunkString, readCmd, callback, webserverID);
            } else {
              // eslint-disable no-await-in-loop
              console.log("ENVIANDO PARTE", chunk);
              resp = await this.sendBlePackage(deviceID, chunkString);
            }
          }
        } else {
          resp = await this.sendBlePackage(deviceID, jsonData, readCmd, callback, webserverID);
        }

        blockWriteInteractions = false;

        return resolve(resp);
      } catch (err) {

        blockWriteInteractions = false;

        if(err.name === 'noChangesInBuffer' ||
          err.name === 'URImalformed') {
          // eslint-disable-next-line no-await-in-loop
          await new Promise(res => setTimeout(res, 50));
          retry++;
          console.log("Reintento num", retry);
        } else if (err.name === 'unableConnectDevice' ||
          err.name === 'errorGetDeviceInfo') {
          console.log(" ** unableConnectDevice error ** , retrying with disconnect/connect");
          retry++;
          console.log("Reintento num", retry);
          await apiBle.disconnectDevice(deviceID);
          await new Promise(res => setTimeout(res, 50));
          // await apiBle.connectDevice(deviceID);
          // await new Promise(res => setTimeout(res, 50));

        } else if (err.name === 'commandBleNotSupported'){
          console.log("Comando no soportado o error no crítico. Continuamos ejecución");
          return resolve(true);
        } else if(err.name === 'timeout') {
          // En caso de TIMEOUT vamos a mostrar un aviso para que el usuario se aproxime al dispositivo
          // TODO: Lo ideal sería lanzar el error de timeout y cazarlo en cada vista para mostrar el modal. (refact)
          Toast.clear();

          return Popup.create({
            subtitle: i18n.global.t('messages.errors.backendDown.title'),
            message: i18n.global.t('addDevice.searchBluetooth.error'),
            buttons: true,
            cancelBtn: false,
            acceptBtn: true,
            acceptBtnText: i18n.global.t('buttons.back'),
            closeOnClick: true,
          })
          .then(() => {
            reject();
          })
          .catch(() => {
            reject();
          })
        } else {
          console.log("Error no permitido! LANZO ERROR");
          return reject(err);
        }
      }
    }

    console.log("Número máximo de reintentos alcanzado! LANZO ERROR");

    // return reject(new AppError('timeout')); <-- Anteriormente lanzábamos el error a la vista
    //
    // En caso de TIMEOUT vamos a mostrar un aviso para que el usuario se aproxime al dispositivo
    // TODO: Lo ideal sería lanzar el error de timeout y cazarlo en cada vista para mostrar el modal. (refact)
    Toast.clear();

    return Popup.create({
      subtitle: i18n.global.t('messages.errors.backendDown.title'),
      message: i18n.global.t('addDevice.searchBluetooth.error'),
      buttons: true,
      cancelBtn: false,
      acceptBtn: true,
      acceptBtnText: i18n.global.t('buttons.back'),
      closeOnClick: true,
    })
    .then(() => {
      reject();
    })
    .catch(() => {
      reject();
    })


  });
},


/**
 * Get info of settings meters
 *
 * @param  {string} deviceID     - ID of device
 * @return {object}              - Meters Integration data
 * @throws  {unableConnectDevice}- Unable connect to device
 * @throws  {errorWriteDevice}   - Error when try write to device
 */
 async setTest(deviceID, type, data) {
  return new Promise( async (resolve, reject) => {
    try {
      log.info(`Launching command: test`);
      const jsonData = {
        test: {
          test_type: type,
          config: data
        }
      }
      Webserver.createMetric('ble', 'test', JSON.stringify(jsonData))

      const result = await this.sendBLE(deviceID, `${JSON.stringify(jsonData)}}`, 'setTestResult');

      resolve(result);
    } catch ( error ) {
      console.log(error)
      reject(error);
    }
  });
},

};




export default BluetoothService;
