export const useOverrideMainClass = () => {

  const overrideMainClass = () => {
    console.log('Overriding main class');
    const main = document.querySelector('.main');

    main.classList.add('main-override');
    main.classList.remove('main');
  }

  return { overrideMainClass }
}
