import moment from 'moment-timezone';
import BluetoothService from "Core/services/bluetooth.service";
import Webserver from "Units/models/Webserver.model";
import constants from 'Units/constant';
import sanitizeWebserver from 'Units/utils/webserver.utils';
import localUserData from 'Auth/services/localUserData.service';
import UnitsService from 'Units/services/units.service';
import { getRegion } from 'Core/utils/utils'


const checkBluetoothState = macBLE => {
  if (!constants.IS_MOBILE) return;
  // eslint-disable-next-line no-undef
  cordova.plugins.diagnostic.registerBluetoothStateChangeHandler(state => {
    // eslint-disable-next-line no-undef
    if(state === cordova.plugins.diagnostic.bluetoothState.POWERED_OFF) {
      console.log("El bluetooth se ha desactivado");
      BluetoothService.disconnectDevice(macBLE);
    }
    // eslint-disable-next-line no-undef
    if( state === cordova.plugins.diagnostic.bluetoothState.POWERED_ON) {
      console.log("El bluetooth se ha reanudado");
      // BluetoothService.connectDevice(to.params.macBLE);
    }
  })
}

const BleUtils = {
  getExtraInfoBleModel: async (webserverID, macBLE, deviceName) => {
    return new Promise(async (resolve, reject) => {

      try {
        checkBluetoothState(macBLE)
        const ws = await BluetoothService.getExtraInfo(macBLE);

        // Añado campos mínimos para validar datos del webserver
        ws.general = {
          id: webserverID,
          mac: webserverID
        }

        await Webserver.validateWebserver(macBLE, deviceName, ws);

        return resolve(true);
      } catch( error ) {

        return reject(error);
      }

    });
  },

  getDeviceBleModel: async (macBLE, deviceName, isAirtools) => {
    return new Promise(async (resolve, reject) => {

      try {
      checkBluetoothState(macBLE)
      const ws = await BluetoothService.getDeviceInfo(macBLE);
      const data = await Webserver.validateWebserver(macBLE, deviceName, ws);

      if(data.type) {
        await BluetoothService.setDeviceType(data.type, macBLE);
      }
      //
      // Obtenemos la versión de BLE soportada por el webserver
      //
      if(data.ble_version) {
        BluetoothService.setBleVersion(data.ble_version);
      } else if(isAirtools && data.type !== 'aidoo'){
        return resolve({bleConfig: false, type: data.type});
      }
      // console.log("BLE version ", BluetoothService.getBleVersion());

      return resolve({
        bleConfig: true,
        type: data.type});
    } catch( error ) {

      return reject(error);
    }

    });
  },

  async setLocalTime(macBLE, bleVersion, region) {
    // Si es una versión ble mayor que 2, necesitamos sincronizar la hora del webserver con la del móvil (para programaciones)
    if(bleVersion >= 2) {
      const utcOffset = moment().utcOffset();
      const utcTime = moment().utc().toISOString();
      const localTime = moment().toISOString(true); // Pasamos "true" para prevenir conversión UTC en la llamada. Ver docs http://momentjs.com

      // Solo cuando "region" es nulo pedimos el código del país según la geolocalización y se la asignamos en el comando BLE "set_local_time"
      // Nota: los webserver que responden en el extrainfo con este campo region debe tener un código string asignado anteriormente desde la app
      // o tienen un valor nulo para saber que hay que asignarlo. En los demás casos "region" es undefined. Todo esto es para optimizar peticiones
      // a la API de google geocoding y solo hacer uso de esta API para asignar el valor la primera vez (cuando region es nulo).
      if (region === null) {
          region = await getRegion()
          console.log('Get region: ', region)
      }
      await BluetoothService.setLocalTime(macBLE, utcTime, utcOffset, localTime, region);
    }
  },

  /**
   * Indica si el dispositivo permite integración
   *
   * @param {Device} device - Model type Device
   * @returns
   */
  compatibleIntegrations: device => {
    return device.type === constants.DEVICE_TYPE.az_ws || device.ble_version >= 2
  },

  /**
   * Pedimos la configuración de los medidores
   * @param {String} macBLE - Mac Bluetooth del webserver
   * @returns {Object} - Configuración de los medidores
   */
  getMetersConf: async (macBLE, webserverID) => {
    const response = await BluetoothService.getMetersConf(macBLE, webserverID);
    // TODO: Actualizar el modelo con los datos
    // console.log('TODO => Actualizar el modelo con los datos: ', response)
    // Preparamos los datos de los que obtenemos respuesta y actualizamos el modelo
    // const webserverData = sanitizeWebserver(webserverID, response.meters_conf);

    // return {
    //   webserverData
    // }
    return response
  },

  /**
   * Pedimos la configuración de los medidores
   * @param {String} macBLE - Mac Bluetooth del webserver
   * @returns {Object} - Configuración de los medidores
   */
   setTest: async (macBLE, type, data) => {
    const response = await BluetoothService.setTest(macBLE, type, data);
    console.log('datos de la medida: ', response)
    return response
  },

  /**
   * Pedimos parámetros ecobee SB
   * @param {String} macBLE - Mac Bluetooth del webserver
   * @returns {Object} - Valores de las credenciales y estado del Ecobee SB
   */
  getAidooEcobeeSBStatus: async (macBLE, webserverID) => {
    const response = await BluetoothService.getWsParams(macBLE, [
      'ecobeesbclientid',
      'ecobeesbclientsecret',
      'ecobeesbthermostatid',
      'ecobeesbstatus',
    ]);

    // Preparamos los datos de los que obtenemos respuesta y actualizamos el modelo
    const webserverData = sanitizeWebserver(macBLE, response.info);
    webserverData.id = webserverID;

    return {
      webserverData
    }
  },

  /**
   * Pedimos parámetros Pelican
   * @param {String} macBLE - Mac Bluetooth del webserver
   * @returns {Object} - Valores de las credenciales y estado del Pelican
   */
   getAidooPelicanStatus: async (macBLE, webserverID) => {
    const response = await BluetoothService.getWsParams(macBLE, [
      'pelicansite',
      'pelicanemail',
      'pelicanpass',
      'pelicanserial',
      'pelicanstatus',
    ]);

    // Preparamos los datos de los que obtenemos respuesta y actualizamos el modelo
    const webserverData = sanitizeWebserver(macBLE, response.info);
    webserverData.id = webserverID;

    return {
      webserverData
    }
  },

  getAidooWsStatus: async (macBLE, webserverID, bleVersion) => {
    let devicesData;
    let detectSystemDisabled;
    if(bleVersion >= 1) {
      ({ detectSystemDisabled, devicesData } = await BluetoothService.getTopologyInfo(macBLE, webserverID));
    }

    const response = await BluetoothService.getWsParams(macBLE, [
        'auxheat_enable',
        'auxheat_ton',
        'auxheat_toff',
        'auxheat_tdelay',
        'auxheat_lockoutenable',
        'auxheat_lockouttemp',
        'auxheat_extheatsource',
        'auxheat_emergencyheat',
        't1t2_enable',
        't1t2_nc',
        't1t2_ondelay',
        't1t2_offdelay',
        'rs485_mode',
        'rs485_availablemodes',
        'ecobeesb',
        'metering_info',
        'auxheat_lowlock_val',
        'auxheat_lowlock_min',
        'auxheat_lowlock_max',
        'auxheat_lowlock_step',
        'auxheat_highlock_val',
        'auxheat_highlock_min',
        'auxheat_highlock_max',
        'auxheat_highlock_step',
    ]);
    // response.info.params.rs485_availablemodes.push(4)
    // console.log('Mocked response: ', response)
    // Preparamos los datos de los que obtenemos respuesta y actualizamos el modelo
    const webserverData = sanitizeWebserver(macBLE, response.info);
    webserverData.id = webserverID;
    webserverData.type = constants.DEVICE_TYPE.aidoo;
    webserverData.detectSystemDisabled = detectSystemDisabled;

    return {
      devicesData,
      webserverData
    }
  },

  getWsStatus: async (macBLE, webserverID, type, userEmail) => {
    const {detectSystemDisabled, devicesData} = await BluetoothService.getTopologyInfo(macBLE, webserverID)
    const webserverData = {
        id: webserverID,
        type,
      }

    // Si el az_ws es de tipo az_airqsensor, pedimos los parámetros de integración
    if(type === constants.DEVICE_TYPE.az_airqsensor) {
      const response = await BluetoothService.getWsParams(macBLE, [
        'auxheat_enable',
        'auxheat_ton',
        'auxheat_toff',
        'auxheat_tdelay',
        'auxheat_lockoutenable',
        'auxheat_lockouttemp',
        'auxheat_extheatsource',
        'auxheat_emergencyheat',
        't1t2_enable',
        't1t2_nc',
        't1t2_ondelay',
        't1t2_offdelay',
        'rs485_mode',
        'rs485_availablemodes',
        'ecobeesb',
        'metering_info',
        'auxheat_lowlock_val',
        'auxheat_lowlock_min',
        'auxheat_lowlock_max',
        'auxheat_lowlock_step',
        'auxheat_highlock_val',
        'auxheat_highlock_min',
        'auxheat_highlock_max',
        'auxheat_highlock_step',
      ]);

      const auxData = sanitizeWebserver(macBLE, response.info);

      Object.assign(webserverData, auxData);

      webserverData.type = type; // Reasignamos az_airqsensor que auxData sobreescribe con az_ws
    }

    // En central no debemos pedir PIN. El usuario siempre estará permitido
    if(type === constants.DEVICE_TYPE.az_8cb && userEmail !== undefined){
      localUserData.init();
      const pincode = localUserData.getDevicePincode(userEmail, webserverID)

      if(pincode){
        try {

          await UnitsService.checkPin(webserverID, pincode);

          webserverData.isUserAllow = true;
          // Asignamos el pin del localstorage al modelo para poder pedir el alias en la siguiente vista
          webserverData.pin = pincode
        } catch(err) {
          console.log("Usuario no permitido",err)
          webserverData.isUserAllow = false;
        }
      } else {
        webserverData.isUserAllow = false;
      }
    }

    webserverData.detectSystemDisabled = detectSystemDisabled;

    return {
      devicesData,
      webserverData
    }
  },

  /**
   * Pedimos parámetros de medidores
   * @param {String} macBLE - Mac Bluetooth del webserver
   * @returns {Object} - Valores de la integración de medidores
   */
   getMeteringInfo: async (macBLE, webserverID) => {
    const response = await BluetoothService.getMeteringInfo(macBLE);
    console.log(response)

    // Preparamos los datos de los que obtenemos respuesta y actualizamos el modelo
    const webserverData = sanitizeWebserver(macBLE, response.info);
    webserverData.id = webserverID;

    return {
      webserverData
    }
  },
}


export default BleUtils;
