// import CONSTANTS from '../constant';

/**
 * Devuelve los valores comunes de varias zonas
 *
 * @param {String} property - Propiedad con los valores disponibles
 * @param {Array} defaultOptions - Listado de todos los valores disponibles
 * @param {Array} zones - Listado con todas las zonas disponibles
 */
const getAvailablesOption = (property, defaultOptions, zones) => {
  //
  // Compruebo si la opción la tiene al menos una zona
  //
  let availableOption = false;
  //
  // Si no recibo ninguna zona, devuelvo los valores por defecto
  //
  if (!zones.length) return defaultOptions;
  //
  // Recorro todos las zonas y me quedo con los valores comunes
  // Si la zona no tiene opciones diponibles no la tengo en cuenta
  //
  zones.forEach(zone => {
    if (!zone[property] || !zone[property].length) return;
    defaultOptions = defaultOptions.filter(defaultValue => zone[property].findIndex(value => value === defaultValue) >= 0)
    availableOption = true;
  });

  // Filtramos los datos para no tener duplicidades
  // const dataFilter = new Set(defaultOptions);

  // const result = [...dataFilter];

  // console.log("Filtrados", result);

  //
  // Si ningún modo coincide en todos las zonas devuelvo las opciones por defecto más comunes.
  //
  return availableOption ? defaultOptions : null; // si la opción no la tiene al menos una zona devuelvo null
};

const getMinValue = (property, zones) => {
  return Math.max(...zones.map(zone => zone[property]).filter(value => value));
};

const getMaxValue = (property, zones) => {
  return Math.min(...zones.map(zone => zone[property]).filter(value => value));
};

/**
 * Calcula los setpoints máximos y mínimos menos restrictivos entre todas las zonas.
 * NOTA: No se emplea este método. Ahora todo se calcula en "getAvailableCommon"
 *
 * @param {Array<Object>} zones
 * @returns
 */
const getAvailableTempsRanges = zones => {
  let maxAutoTemp = getMinValue('range_sp_auto_air_max', zones);
  let minAutoTemp = getMaxValue('range_sp_auto_air_min', zones);
  const maxCoolTemp = getMinValue('range_sp_cool_air_max', zones);
  const minCoolTemp = getMaxValue('range_sp_cool_air_min', zones);
  const maxDryTemp = getMinValue('range_sp_dry_air_max', zones);
  const minDryTemp = getMaxValue('range_sp_dry_air_min', zones);
  const maxEmerheatTemp = getMinValue('range_sp_emerheat_air_max', zones);
  const minEmerheatTemp = getMaxValue('range_sp_emerheat_air_min', zones);
  const maxHeatTemp = getMinValue('range_sp_hot_air_max', zones);
  const minHeatTemp = getMaxValue('range_sp_hot_air_min', zones);
  const maxFanTemp = getMinValue('range_sp_vent_air_max', zones);
  const minFanTemp = getMaxValue('range_sp_vent_air_min', zones);
  const maxStopTemp = getMinValue('range_sp_stop_air_max', zones);
  const minStopTemp = getMaxValue('range_sp_stop_air_min', zones);

  if (maxAutoTemp === Infinity) maxAutoTemp = maxHeatTemp;
  if (minAutoTemp === Infinity) minAutoTemp = minCoolTemp;

  const tempRanges = {
    default: [34, 14],
    cool: [maxCoolTemp, minCoolTemp],
    heat: [maxHeatTemp, minHeatTemp],
    auto: [maxAutoTemp, minAutoTemp],
    fan: [maxFanTemp, minFanTemp],
    dry: [maxDryTemp, minDryTemp],
    stop: [maxStopTemp, minStopTemp],
    emergencyHeat: [maxEmerheatTemp, minEmerheatTemp]
  };
  return tempRanges;
}

const getAvailableCommon = devices => {
  return new Promise (res => {
    /**
     * @type {Object}
     */
    const availableCommon = {};
    /**
     * @type {number[]}
     */
    const modesAcc = []; // acumulador de todos los modos
    /**
     * @type {number[]}
     */
    const speedAcc = []; // acumulador de todas las velocidades
    /**
     * @type {string[]}
     */
    const speedAcc2 = []; // acumulador de todas las velocidades
    /**
     * @type {string[]}
     */
    const usermodeAcc = []; // acumulador de todos los modos de usuario
    /**
     * @type {string[]}
     */
    const aqmodesAcc = []; // acumulador de todos los modos de inonizador
    /**
     * @type {string[]}
     */
    const aqventmodesAcc = []; // acumulador de todos los modos de inonizador
    /**
     * @type {string[]}
     */
    const ecomodesAcc = []; // acumulador de todos los modos ECO
    /**
     * @type {number[]}
     */
    const sleepAcc = []; // acumulador de todos los modos sleep
    /**
     * @type {number[]}
     */
    const timerAcc = []; // acumulador de todos los timer
    /**
     * @type {number}
     */
    let step; // acumulador de todos los timer

    for(let i = 0; i < devices.length; i++) {
      const device = devices[i];
      // devices.forEach( device => {
        if(device.type === 'ACS'){
          if(device.range_sp_acs_min !== undefined) {
            if(availableCommon.range_sp_acs_min === undefined){
              availableCommon.range_sp_acs_min = device.range_sp_acs_min;
            }
          }

          if(device.range_sp_acs_max !== undefined) {
            if(availableCommon.range_sp_acs_max === undefined){
              availableCommon.range_sp_acs_max = device.range_sp_acs_max;
            }
          }

        } else {
            //
            // Aire. Modos, rangos temp, velocidades, vmc, ionizador
            //
            if(device && device.mode_available !== undefined){
              modesAcc.push(...device.mode_available);
            }
            // velocidades
            if(device && device.speed_values !== undefined){
              speedAcc.push(...device.speed_values);
            }
            if(device && device.speed_values_2 !== undefined){
              speedAcc2.push(...device.speed_values_2);
            }
            // Usermode
            if(device && device.usermode_values !== undefined) {
              usermodeAcc.push(...device.usermode_values);
            }
            // ionizer
            if(device && device.aq_mode_values !== undefined) {
              aqmodesAcc.push(...device.aq_mode_values);
            }
            // mechanic vent
            if(device && device.aq_vent_mode_values !== undefined) {
              aqventmodesAcc.push(...device.aq_vent_mode_values);
            }
            // ECO
            if(device && device.eco_values !== undefined) {
              ecomodesAcc.push(...device.eco_values);
            }
            // Timer
            if(device && device.timer_values !== undefined) {
              timerAcc.push(...device.timer_values);
            }
            // Sleep
            if(device && device.sleep_values !== undefined) {
              sleepAcc.push(...device.sleep_values);
            }

            // Setpoint global (Si no hay modo seleccionado)
            if(device && device.range_air_min !== undefined) {
              if(availableCommon.range_air_min === undefined) {
                availableCommon.range_air_min = device.range_air_min;
              } else if ( device.range_air_min < availableCommon.range_air_min) {
                availableCommon.range_air_min = device.range_air_min;
              }
            }
            if(device && device.range_air_max !== undefined) {
              if(availableCommon.range_air_max === undefined) {
                availableCommon.range_air_max = device.range_air_max;
              } else if ( device.range_air_max > availableCommon.range_air_max) {
                availableCommon.range_air_max = device.range_air_max;
              }
            }

            // rangos temp
            if(device && device.range_sp_cool_air_min !== undefined) {
              if(availableCommon.range_sp_cool_air_min === undefined) {
                if(device.range_sp_real_cool_min !== undefined) {
                  availableCommon.range_sp_cool_air_min = device.range_sp_real_cool_min;
                } else {
                  availableCommon.range_sp_cool_air_min = device.range_sp_cool_air_min;
                }
              } else if(device.range_sp_real_cool_min !== undefined
                && device.range_sp_real_cool_min < availableCommon.range_sp_cool_air_min) {
                  availableCommon.range_sp_cool_air_min = device.range_sp_real_cool_min;
              } else if(device.range_sp_cool_air_min < availableCommon.range_sp_cool_air_min) {
                  availableCommon.range_sp_cool_air_min = device.range_sp_cool_air_min;
              }
            }

            if(device && device.range_sp_cool_air_max !== undefined) {
              if(availableCommon.range_sp_cool_air_max === undefined) {
                if(device.range_sp_real_cool_max !== undefined){
                  availableCommon.range_sp_cool_air_max = device.range_sp_real_cool_max;
                } else {
                  availableCommon.range_sp_cool_air_max = device.range_sp_cool_air_max;
                }
              } else if (device.range_sp_real_cool_max !== undefined
                && device.range_sp_real_cool_max > availableCommon.range_sp_cool_air_max){
                availableCommon.range_sp_cool_air_max = device.range_sp_real_cool_max;
              } else if ( device.range_sp_cool_air_max > availableCommon.range_sp_cool_air_max) {
                availableCommon.range_sp_cool_air_max = device.range_sp_cool_air_max;
              }
            }

            if(device && device.range_sp_hot_air_min !== undefined) {
              if(availableCommon.range_sp_hot_air_min === undefined) {
                if(device.range_sp_real_heat_min !== undefined) {
                  availableCommon.range_sp_hot_air_min = device.range_sp_real_heat_min;
                } else {
                  availableCommon.range_sp_hot_air_min = device.range_sp_hot_air_min;
                }
              } else if( device.range_sp_real_heat_min !== undefined
                && device.range_sp_real_heat_min < availableCommon.range_sp_hot_air_min) {
                availableCommon.range_sp_hot_air_min = device.range_sp_real_heat_min;
              } else if ( device.range_sp_hot_air_min < availableCommon.range_sp_hot_air_min) {
                availableCommon.range_sp_hot_air_min = device.range_sp_hot_air_min;
              }
            }

            if(device && device.range_sp_hot_air_max !== undefined) {
              if(availableCommon.range_sp_hot_air_max === undefined) {
                if(device.range_sp_real_heat_max !== undefined) {
                  availableCommon.range_sp_hot_air_max = device.range_sp_real_heat_max;
                } else {
                  availableCommon.range_sp_hot_air_max = device.range_sp_hot_air_max;
                }
              } else if (device.range_sp_real_heat_max
                && device.range_sp_real_heat_max > availableCommon.range_sp_hot_air_max) {
                availableCommon.range_sp_hot_air_max = device.range_sp_real_heat_max;
              } else if ( device.range_sp_hot_air_max > availableCommon.range_sp_hot_air_max) {
                availableCommon.range_sp_hot_air_max = device.range_sp_hot_air_max;
              }
            }

            if(device && device.range_sp_auto_air_min !== undefined) {
              if(availableCommon.range_sp_auto_air_min === undefined) {
                availableCommon.range_sp_auto_air_min = device.range_sp_auto_air_min;
              } else if ( device.range_sp_auto_air_min < availableCommon.range_sp_auto_air_min) {
                availableCommon.range_sp_auto_air_min = device.range_sp_auto_air_min;
              }
            }

            if(device && device.range_sp_auto_air_max !== undefined) {
              if(availableCommon.range_sp_auto_air_max === undefined) {
                availableCommon.range_sp_auto_air_max = device.range_sp_auto_air_max;
              } else if ( device.range_sp_auto_air_max > availableCommon.range_sp_auto_air_max) {
                availableCommon.range_sp_auto_air_max = device.range_sp_auto_air_max;
              }
            }

            if(device && device.range_sp_dry_air_min !== undefined) {
              if(availableCommon.range_sp_dry_air_min === undefined) {
                if(device.range_sp_real_cool_min !== undefined) {
                  availableCommon.range_sp_dry_air_min = device.range_sp_real_cool_min;
                } else {
                  availableCommon.range_sp_dry_air_min = device.range_sp_dry_air_min;
                }
              } else if (device.range_sp_real_cool_min !== undefined
                && device.range_sp_real_cool_min < availableCommon.range_sp_dry_air_min) {
                availableCommon.range_sp_dry_air_min = device.range_sp_real_cool_min;
              } else if ( device.range_sp_dry_air_min < availableCommon.range_sp_dry_air_min) {
                availableCommon.range_sp_dry_air_min = device.range_sp_dry_air_min;
              }
            }

            if(device && device.range_sp_dry_air_max !== undefined) {
              if(availableCommon.range_sp_dry_air_max === undefined) {
                if(device.range_sp_real_cool_max !== undefined){
                  availableCommon.range_sp_dry_air_max = device.range_sp_real_cool_max;
                } else {
                  availableCommon.range_sp_dry_air_max = device.range_sp_dry_air_max;
                }
              } else if (device.range_sp_real_cool_max !== undefined
                && device.range_sp_real_cool_max > availableCommon.range_sp_dry_air_max) {
                availableCommon.range_sp_dry_air_max = device.range_sp_real_cool_max;
              } else if ( device.range_sp_dry_air_max > availableCommon.range_sp_dry_air_max) {
                availableCommon.range_sp_dry_air_max = device.range_sp_dry_air_max;
              }
            }

            if(device && device.range_sp_vent_air_min !== undefined) {
              if(availableCommon.range_sp_vent_air_min === undefined) {
                if(device.range_sp_real_heat_min !== undefined) {
                  availableCommon.range_sp_vent_air_min = device.range_sp_real_heat_min;
                } else {
                  availableCommon.range_sp_vent_air_min = device.range_sp_vent_air_min;
                }
              } else if (device.range_sp_real_heat_min !== undefined
                && device.range_sp_real_heat_min < availableCommon.range_sp_vent_air_min) {
                availableCommon.range_sp_vent_air_min = device.range_sp_real_heat_min;
              } else if ( device.range_sp_vent_air_min < availableCommon.range_sp_vent_air_min) {
                availableCommon.range_sp_vent_air_min = device.range_sp_vent_air_min;
              }
            }

            if(device && device.range_sp_vent_air_max !== undefined) {
              if(availableCommon.range_sp_vent_air_max === undefined) {
                if(device.range_sp_real_cool_max !== undefined) {
                  availableCommon.range_sp_vent_air_max = device.range_sp_real_cool_max;
                } else {
                  availableCommon.range_sp_vent_air_max = device.range_sp_vent_air_max;
                }
              } else if (device.range_sp_real_cool_max !== undefined
                && device.range_sp_real_cool_max > availableCommon.range_sp_vent_air_max) {
                availableCommon.range_sp_vent_air_max = device.range_sp_real_cool_max;
              } else if ( device.range_sp_vent_air_max > availableCommon.range_sp_vent_air_max) {
                availableCommon.range_sp_vent_air_max = device.range_sp_vent_air_max;
              }
            }

            if(device && device.range_sp_stop_air_min !== undefined) {
              if(availableCommon.range_sp_stop_air_min === undefined) {
                if(device.range_sp_real_heat_min !== undefined) {
                  availableCommon.range_sp_stop_air_min = device.range_sp_real_heat_min;
                } else {
                  availableCommon.range_sp_stop_air_min = device.range_sp_stop_air_min;
                }
              } else if (device.range_sp_real_heat_min !== undefined
                && device.range_sp_real_heat_min < availableCommon.range_sp_stop_air_min) {
                availableCommon.range_sp_stop_air_min = device.range_sp_real_heat_min;
              } else if ( device.range_sp_stop_air_min < availableCommon.range_sp_stop_air_min) {
                availableCommon.range_sp_stop_air_min = device.range_sp_stop_air_min;
              }
            }

            if(device && device.range_sp_stop_air_max !== undefined) {
              if(availableCommon.range_sp_stop_air_max === undefined) {
                if(device.range_sp_real_cool_max !== undefined) {
                  availableCommon.range_sp_stop_air_max = device.range_sp_real_cool_max;
                } else {
                  availableCommon.range_sp_stop_air_max = device.range_sp_stop_air_max;
                }
              } else if ( device.range_sp_real_cool_max !== undefined
                && device.range_sp_real_cool_max > availableCommon.range_sp_stop_air_max) {
                availableCommon.range_sp_stop_air_max = device.range_sp_real_cool_max;
              } else if ( device.range_sp_stop_air_max > availableCommon.range_sp_stop_air_max) {
                availableCommon.range_sp_stop_air_max = device.range_sp_stop_air_max;
              }
            }

            if(device && device.range_sp_emerheat_air_min !== undefined) {
              if(availableCommon.range_sp_emerheat_air_min === undefined) {
                if(device.range_sp_real_heat_min !== undefined) {
                  availableCommon.range_sp_emerheat_air_min = device.range_sp_real_heat_min;
                } else {
                  availableCommon.range_sp_emerheat_air_min = device.range_sp_emerheat_air_min;
                }
              } else if ( device.range_sp_real_heat_min !== undefined
                && device.range_sp_real_heat_min < availableCommon.range_sp_emerheat_air_min) {
                availableCommon.range_sp_emerheat_air_min = device.range_sp_real_heat_min;
              } else if ( device.range_sp_emerheat_air_min < availableCommon.range_sp_emerheat_air_min) {
                availableCommon.range_sp_emerheat_air_min = device.range_sp_emerheat_air_min;
              }
            }

            if(device && device.range_sp_emerheat_air_max !== undefined) {
              if(availableCommon.range_sp_emerheat_air_max === undefined) {
                if( device.range_sp_real_heat_max !== undefined ){
                  availableCommon.range_sp_emerheat_air_max = device.range_sp_real_heat_max;
                } else {
                  availableCommon.range_sp_emerheat_air_max = device.range_sp_emerheat_air_max;
                }
              } else if ( device.range_sp_real_heat_max !== undefined
                && device.range_sp_real_heat_max > availableCommon.range_sp_emerheat_air_max) {
                availableCommon.range_sp_emerheat_air_max = device.range_sp_real_heat_max;
              } else if ( device.range_sp_emerheat_air_max > availableCommon.range_sp_emerheat_air_max) {
                availableCommon.range_sp_emerheat_air_max = device.range_sp_emerheat_air_max;
              }
            }


            // Ionizador todos tienen los mismos valores
            // if(device && device.aq_mode_values !== undefined){
            //   if(availableCommon.aq_mode_values === undefined) {
            //     availableCommon.aq_mode_values = device.aq_mode_values
            //   }
            // }

          }

          // Aplicamos el paso más restrictivo
          if(device && device.step) {
            if(step === undefined || step < device.step) {
              step = device.step
            }
          }

        // });
    }

      const modesSet = new Set(modesAcc);

      // auto
      if(availableCommon.range_sp_auto_air_max === undefined
      && modesSet.has('auto')) {
        availableCommon.range_sp_auto_air_max = availableCommon.range_sp_hot_air_max;
        availableCommon.range_sp_auto_air_min = availableCommon.range_sp_cool_air_min;
      }

      //
      // Unificamos
      //
      availableCommon.mode_available = [...new Set(modesAcc)];
      availableCommon.speed_values = [...new Set(speedAcc)];
      availableCommon.speed_values_2 = [...new Set(speedAcc2)];
      availableCommon.eco_values = [...new Set(ecomodesAcc)];
      availableCommon.usermode_values = [...new Set(usermodeAcc)];
      availableCommon.timer_values = [...new Set(timerAcc)];
      availableCommon.sleep_values = [...new Set(sleepAcc)];
      availableCommon.aq_mode_values = [...new Set(aqmodesAcc)];
      availableCommon.aq_vent_mode_values = [...new Set(aqventmodesAcc)];
      availableCommon.step = step;

      return res(availableCommon);
  });


}

const getSystemAvailableCommon = systems => {
  /**
   * @type {Object}
   */
  const availableCommon = {};
  /**
   * @type {number[]}
   */
  const vmcRelayAcc = []; // acumulador de todos los VMC
  /**
   * @type {number[]}
   */
  const heatStageAcc = []; // acumulador de todas las estapas de calor
  /**
   * @type {number[]}
   */
  const coolStageAcc = []; // acumulador de todas las estapas de calor

  systems.forEach( system => {
    if(system && system.VMC_relay_mode_values) {
      vmcRelayAcc.push(...system.VMC_relay_mode_values)
    }

    if(system && system.user_device_heat_stages_values){
      heatStageAcc.push(...system.user_device_heat_stages_values)
    }

    if(system && system.user_device_cooling_stages_values){
      coolStageAcc.push(...system.user_device_cooling_stages_values)
    }
  });

  //
  // Unificamos
  //
  availableCommon.VMC_relay_mode_values = [...new Set(vmcRelayAcc)];
  availableCommon.user_device_heat_stages_values = [...new Set(heatStageAcc)];
  availableCommon.user_device_cooling_stages_values = [...new Set(coolStageAcc)];

  return availableCommon;

}


export { getAvailablesOption, getMinValue, getMaxValue, getAvailableTempsRanges, getAvailableCommon, getSystemAvailableCommon };
