/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bypass-bold': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.885 7.566H2.63a.622.622 0 010-1.243h18.248l-3.769-3.769a.598.598 0 01-.186-.435.596.596 0 01.178-.444.624.624 0 01.436-.178c.185.024.323.065.444.186l4.81 4.81c.008.008.057.073.057.073a.61.61 0 01-.04.815l-4.827 4.826a.598.598 0 01-.436.186.624.624 0 01-.613-.621.6.6 0 01.185-.437l3.77-3.769zm-.008 10.396h1.501a.622.622 0 000-1.243H4.114l3.77-3.77a.598.598 0 00.185-.435.596.596 0 00-.178-.444.624.624 0 00-.436-.178c-.185.025-.323.065-.444.186l-4.81 4.81a2 2 0 00-.056.073.61.61 0 00.04.815l4.826 4.826a.598.598 0 00.436.186.623.623 0 00.613-.622.598.598 0 00-.185-.435l-3.77-3.77h16.772z" _fill="#7B8794"/>'
  }
})
