import routes from './routes';
import constants from './constant';
import store from './store';
import Installation from './models/Installation.model';
import Group from './models/Group.model';
import Webserver from './models/Webserver.model';
import InvitedUser from './models/InvitedUser.model';
import {Device, System, ACS, VMC, Relay, Zone, Clamp, Purifier, CCP, Outputs, Dehumidifier} from './models/DeviceHierarchy';
import Schedule from './models/Schedule.model';
import ScheduleConf from './models/ScheduleConf.model';
import Command from './models/Command.model';
import Scene from './models/Scene.model';
import Integration from './models/Integration.model';
import Sensor from './models/Sensor.model';
import Tariff from './models/Tariff.model';
import Period from './models/Period.model';

//
// IMPORTANTE: Incluir modelos que hereden de otros DESPUÉS del modelo padre
// Ej: Device tiene que ir antes de System, Zone, ACS, etc...
//
export default {
  routes,
  constants,
  store,
  models: [
    Installation,
    Group,
    Webserver,
    InvitedUser,
    Device,
    System,
    Zone,
    ACS,
    VMC,
    Relay,
    Clamp,
    Purifier,
    CCP,
    Outputs,
    Schedule,
    ScheduleConf,
    Command,
    Scene,
    Integration,
    Sensor,
    Dehumidifier,
    Tariff,
    Period,
  ],
};
