/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto-lock': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.254 20.517a.812.812 0 01-.95-.652 14.563 14.563 0 01-.253-2.66C.051 10.04 5.284 3.979 12.26 2.83L9.68 1.54A.814.814 0 019.317.448c.2-.4.688-.561 1.09-.364l5.245 2.623c.18.09.306.248.376.435a.422.422 0 01.03.072l.007.01.01.018.02.063a.409.409 0 01.002.044l-.003.025-.003.024v.008l.004.012a.315.315 0 01-.014.196l-.002.007-.011.039-.007.028a.76.76 0 01-.311.437l-3.82 3.82a.81.81 0 01-1.149 0 .814.814 0 010-1.15l2.46-2.459c-6.526.702-11.562 6.233-11.562 12.87 0 .766.073 1.54.225 2.363a.813.813 0 01-.65.948zM17.888 31.42v-1.675a12.885 12.885 0 01-13.682-4.882l3.658.997a.814.814 0 00.428-1.57l-5.593-1.522a.783.783 0 00-.48-.036l-.022.007h-.001l-.006.002-.042.01-.007.002a.415.415 0 00-.112.035.284.284 0 00-.063.046c-.02.006-.024.01-.07.037h-.001a.29.29 0 00-.066.072l-.008.01a.76.76 0 00-.07.082.793.793 0 00-.185.457l-.204 5.488a.813.813 0 00.782.843h.03a.814.814 0 00.814-.782l.11-2.937a14.509 14.509 0 0011.535 5.686c1.102 0 2.193-.126 3.255-.37zm11.254-15.682h-1.635a12.985 12.985 0 00-8.555-10.736.815.815 0 01-.495-1.038.816.816 0 011.038-.494 14.61 14.61 0 019.647 12.268zm-17.166 6.137c.076-.514.166-.88.37-1.453l.44-1.253h4l.44 1.252c.144.435.264.92.356 1.457l.155.131h3.02l-.108-.226c-.167-.346-.418-.96-.68-1.665l-3.077-8.24c-.277-.716-.375-1.023-.47-1.435l-.153-.122H13.26l-.154.125c-.082.383-.192.74-.441 1.434l-3.05 8.238c-.292.794-.485 1.249-.704 1.66l-.123.231h3.032l.155-.134zm2.856-8.745l-.054-.155a8.774 8.774 0 01-.053.155l-1.246 3.752h2.613l-1.26-3.752zm15.71 9.344c0-1.979-1.577-3.589-3.523-3.589-1.946 0-3.529 1.61-3.529 3.59v1.851c-.775 0-1.406.53-1.406 1.386v4.858c0 .788.63 1.43 1.406 1.43h7.052a1.42 1.42 0 001.406-1.43v-4.858c0-.82-.63-1.385-1.406-1.388v-1.85zM27.02 20.77c-.843 0-1.53.67-1.53 1.493v2.072h3.06v-2.072c0-.823-.686-1.493-1.53-1.493zm0 5.548a.874.874 0 00-.874.874v2.186a.874.874 0 001.749 0v-2.186a.874.874 0 00-.875-.874z" _fill="#323F4B"/>'
  }
})
