/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vector-recovery-ventilador': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.627 19.581l-3.62-6.347c-.217-.382-.745-.563-1.163-.436-1.419.473-2.564 1.436-3.2 2.71a4.923 4.923 0 00-.219 3.928C8.207 21.6 10.335 23 12.626 23c.582 0 1.164-.073 1.764-.291a2.241 2.241 0 001.4-1.291c.219-.6.164-1.273-.163-1.837zm-1.637 1.055s0 .073-.018.145c-.037.073-.127.164-.2.182a3.513 3.513 0 01-1.146.182c-1.364 0-2.637-.782-3.219-1.982-.764-1.546-.163-3.365 1.346-4.274l3.255 5.674v.055h-.018v.018zM13.935 1.486h-.072C12.59.85 11.026.776 9.68 1.196c-.655.2-1.164.672-1.418 1.29-.255.619-.182 1.292.145 1.855l3.637 6.384a.96.96 0 00.819.454c.109 0 .218 0 .327-.054 2.11-.673 3.564-2.492 3.71-4.62.145-2.09-1.019-4.019-2.983-5h.018v-.019zm.71 6.183l-.146.237a3.399 3.399 0 01-1.182 1.145l-3.2-5.619s-.037-.182 0-.273a.394.394 0 01.2-.182c1.363-.454 2.873-.054 3.8.964a3.236 3.236 0 01.528 3.692v.036zM8.37 7.797a5.739 5.739 0 00-4.073-.291l-.11.036c-1.418.473-2.563 1.437-3.2 2.71C.37 11.47.297 12.87.77 14.18c.218.618.691 1.11 1.328 1.327.272.11.545.146.818.146.364 0 .764-.073 1.091-.273l6.729-3.51a.917.917 0 00.382-1.09C10.662 9.47 9.68 8.414 8.37 7.796zM3.643 9.943a3.655 3.655 0 012.328-.837c.491 0 .964.11 1.437.291l.363.218a3.4 3.4 0 011.164 1.11L3.08 13.762s-.182.036-.291 0c-.11-.037-.164-.11-.182-.182-.455-1.31-.037-2.71 1.036-3.637zM23.283 9.724a2.139 2.139 0 00-1.328-1.327 2.462 2.462 0 00-1.927.127l-6.674 3.474a.926.926 0 00-.455 1.09l.018.11c.8 2.146 2.928 3.491 5.183 3.491.6 0 1.182-.072 1.764-.29 1.419-.473 2.564-1.437 3.2-2.747.6-1.254.655-2.655.219-3.928zm-3.819 4.62c-.473.2-.964.309-1.491.309-1.164 0-2.237-.528-2.91-1.455l5.874-3.037s.164-.037.273 0c.09.036.163.109.182.164.49 1.618-.328 3.31-1.928 4v.019z" _fill="#fff"/>'
  }
})
