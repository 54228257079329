import { computed } from 'vue';
import Installation from 'Units/models/Installation.model';
import { getAllInfoByISO } from 'iso-country-currency';


export const useInstallation = (installationID) => {

  const installation = computed(() => Installation.find(installationID) );

  const getCurrencyFromCountryCode = () => getAllInfoByISO(installation.value.countryCode)?.currency || '';

  const getCurrencySymbolFromCountryCode = () => getAllInfoByISO(installation.value.countryCode)?.symbol || '';

  const isSpanishInstallation = computed(() => installation.value?.countryCode === 'ES' );


  return {
    installation,
    isSpanishInstallation,
    timezone: computed(() => installation.value?.timezone ),
    installation_tariff_id: computed(() => installation.value?.widgets?.price?.tariff_id),
    installation_tariff_type: computed(() => installation.value?.widgets?.price?.tariff_type),
    currency_tariff: computed(() => installation.value.currency_tariff ),

    getCurrencyFromCountryCode,
    getCurrencySymbolFromCountryCode,
  }
}
