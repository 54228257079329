import i18n from 'Core/services/language.service';

const parseLocation = (placeID, address) => {
  const language = i18n.global.locale.value;
  const sublocality = address.find(item => item.types.some(type => type === 'sublocality'));
  const administrative = address.find(item => item.types.some(type => type === 'administrative_area_level_3'));
  const locality = address.find(item => item.types.some(type => type === 'locality'));
  const country = address.find(item => item.types.some(type => type === 'country'));
  const postal = address.find(item => item.types.some(type => type === 'postal_code'));

  // prettier-ignore
  const cityName = locality ? locality.long_name : administrative ? administrative.long_name : sublocality ? sublocality.long_name : ''; // eslint-disable-line
  const location = {
    google_place_id: placeID,
    postal_code: '',
    text: {
      city: {},
      country: {},
    },
  };

  if (postal) location.postal_code = postal.long_name;
  location.text.city[language] = cityName;
  location.text.country[language] = country.long_name;

  return location;
};

export default parseLocation;
