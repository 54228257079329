import { useUser } from 'Auth/composables';
import { getValuesFromRange } from 'Units/utils/utils';
import moment from 'moment';

export const useDate = () => {

  const { lang, sundayFirst, ampm } = useUser();

  /**
   * Get the formatted days of the week based in the user configuration
  *
  * @param {string} format
  * @returns - Array of formatted days of the week
  */
 const getFormattedDaysOfWeek = (format = 'dd') => {
   const result = [];

   // Set the locale and the first day of the week based on the user configuration
   moment.locale(lang.value || 'en');

   moment.updateLocale(lang.value || 'en', {
     week: {
       dow: sundayFirst.value ? 0 : 1
     },
   });

    for (let i = 0; i < 7; i++) {
      result.push(moment().weekday(i).format(format));
    }

    return result;
  }


  const getFormattedHoursOfDay = (format) => {
    const result = [];

    if(format === undefined) {
      if(ampm.value) {
        format = 'h A';
      } else {
        format = 'HH';
      }
    }

    for (let i = 0; i < 24; i++) {
      result.push(moment().hour(i).startOf('hour').format(format));
    }

    return result;
  }

  const getFormattedHour = (hour, minutes) => {
    const format = ampm.value ? (minutes !== undefined ? 'h:mm A' : 'h A') : (minutes !== undefined? 'HH:mm' : 'HH');

    return minutes ? moment().set('hour', hour).set('minute', minutes).format(format) : moment().hour(hour).startOf('hour').format(format);
  }

  const getHoursValues = () => {
    return ampm.value ? getValuesFromRange(1,12,1) : getValuesFromRange(0,23,1);
  }

  const getMinutesValues = () => {
    return getValuesFromRange(0,59,1);
  }

  return {
    getFormattedDaysOfWeek,
    getFormattedHoursOfDay,
    getFormattedHour,
    sundayFirst,
    ampm,
    lang,
    getHoursValues
  }

}
