/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'consumption': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M10.194 2.815h15.242A5.55 5.55 0 0131 8.379v15.242a5.55 5.55 0 01-5.564 5.565H10.194c-2.662 0-4.96-1.936-5.444-4.476-.121-.363.12-.726.484-.726.363-.121.726.12.726.484.363 1.935 2.177 3.387 4.234 3.387h15.242c2.419 0 4.233-1.936 4.233-4.234V8.379c0-2.42-1.935-4.234-4.233-4.234H10.194c-.363 0-.605-.242-.605-.605s.242-.725.604-.725z" _fill="#3B4E52"/><path pid="1" d="M16.363 13.097l2.54 8.467c.242.605.726.726.847.726s.605-.12.847-.604l2.782-6.17c0-.12.121-.12.242-.12h3.387c.363 0 .726-.364.726-.727s-.363-.726-.726-.726h-3.387c-.605 0-1.21.363-1.452.968l-2.177 4.96-.242-.242-2.42-8.226c-.241-.605-.725-.726-.846-.726s-.605 0-.847.605l-2.177 4.96-.847-2.903c-.121-.363-.363-.726-.726-.726s-.726.12-.968.484l-.604.726c0 .12-.242.12-.363.12H1.605c-.363 0-.605.363-.605.726s.242.605.605.726h8.347c.483 0 1.088-.242 1.33-.726l.121-.12.968 3.387c.242.604.726.725.847.725.12 0 .605 0 .847-.605l2.298-4.96z" _fill="#3B4E52"/>'
  }
})
