import { createApp, h } from 'vue';
import i18n from 'Core/services/language.service';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import AzLoading from 'Core/components/Loading';
import VueToast from './Toast.vue';

let toastInstance = null;

/**
 * El componente toast permite un uso simplificado donde unicamente pasamos el mensaje.
 * Cuando se pasa un mensaje como opción, tenemos que parsearlo como un objeto
 *
 * @param {Object,String} title
 */
function parseOptions(title) {
  return typeof title === 'string' ? { title } : title;
}

/**
 * Crea una nueva instancia del componente,
 * si ya existe una devuelve la que había
 */
function createInstance(newProps) {
  if (toastInstance) return toastInstance;

  const el = document.createElement('div')
  toastInstance = createApp({
    render() {
      return h(VueToast, {
        ...newProps
      })
    }
  })
  toastInstance.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });
  toastInstance.component('AzLoading', AzLoading);
  toastInstance.mount(el)

  return toastInstance;
}

/**
 * Crea un Objeto Toast
 *
 * @param {Object} options - Opciones del Toast
 */
function createToast(options) {
  return new Promise((resolve, reject) => {
    const toast = createInstance(options);
    clearTimeout(toast.timer);

    if (options.duration > 0) {
      toast.timer = setTimeout(() => {
        toast.unmount();
      }, options.duration);
    }

    toast.options = { resolve, reject };
  })

}

/**
 * Creo el objeto Toast que contiene los métodos para crear los diferentes tipos de toast
 */
const Toast = {
  clear: () => {
    if (toastInstance) {
      const instanceToast = toastInstance._instance
      // Si existe en el dom lo elimino, sino desmonto la instancia
      if (instanceToast !== null) {
        const toast = instanceToast.ctx
        const parent = toast.$el.parentNode;
        if (parent) parent.removeChild(toast.$el);
        toastInstance.unmount();
        toastInstance = null;
      } else {
        toastInstance.unmount();
        toastInstance = null;
      }
    }
  },
  loading: options =>
    createToast({
      type: 'loading',
      icon: 'loading',
      title: i18n.global.t('messages.loading'),
      duration: 0,
      closeOnClick: false,
      ...parseOptions(options),
    }),

  info: options =>
    createToast({
      type: 'info',
      icon: 'info',
      ...parseOptions(options),
    }),

  success: options =>
    createToast({
      type: 'success',
      icon: 'check',
      title: i18n.global.t('messages.success'),
      duration: 3000,
      ...parseOptions(options),
    }),

  fail: options =>
    createToast({
      type: 'warning',
      icon: 'warning',
      title: i18n.global.t('messages.errors.defaultError.title'),
      message: i18n.global.t('messages.errors.defaultError.message'),
      ...parseOptions(options),
    }),

  error: error => {
    // const title = `${error.module}.${error.name}.title`;
    // const message = `${error.module}.${error.name}.message`;
    const title = error.name && i18n.global.te(error.name)? `messages.errors.${error.name}.title` : 'messages.errors.defaultError.title';
    const message = error.name && i18n.global.te(error.name)? `messages.errors.${error.name}.message`: 'messages.errors.defaultError.message';
    const titleString = i18n.global.t(title)
    const messageString = i18n.global.t(message);

    createToast({
      type: error.isBlocking ? 'error' : 'warning',
      icon: error.icon || 'warning',
      title: titleString,
      message: messageString,
      errorCode: error.code || '',
      errorName: error.name || '',
      errorDescription: error.description || '',
      errorInfo: error.info || null,
      support: error.support || '',
      ...parseOptions(error),
    });
  },
};

/**
 * Le añado un método para eliminar el toast
 */
// Toast.clear = () => {
//   console.log('Elimina la instancia del modal', toastInstance, VueToast.methods.clear)
//   if(toastInstance) {
//     // clearTimeout(toast.timer);
//     toastInstance = null;
//     // console.log(VueToast.clear())
//     VueToast.methods.clear
//     // const parent = toast.$el.parentNode;
//     // if (parent) parent.removeChild(toast.$el);
//     // toastInstance.clear();
//   }
// }

// Toast.install = () => {
//   Vue.use(VueToast);
// };

export default Toast;
