
/**
 *
 * @param {String} error - Error de autentificación de la cuenta con tercero
 * @return {String} - Tipo de cuenta con tercero con el error de autentificación
 */
const getTypeThirdPartyError = error => {
  let type;
  console.log('Error in utils: ', error)
  if (error.name === 'oauthUnlinkedNest') {
    type = 'nest'
  } else if (error.name === 'oauthUnlinkedHoneywell') {
    type = 'honeywell'
  } else if (error.name === 'oauthUnlinkedEcobee') {
    type = 'ecobee'
  }

  return type
};

/**
 *
 * @param {String} type - Tipo de dispositivo de la cuenta con tercero
 * @return {String} - Tipo de error de autentificación con la cuenta de tercero
 */
const getErrorThirdPartyType = type => {
  let error;
  if (type === 'nest') {
    error = 'oauthUnlinkedNest'
  } else if (type === 'honeywell') {
    error = 'oauthUnlinkedHoneywell'
  } else if (type === 'ecobee') {
    error = 'oauthUnlinkedEcobee'
  }

  return error
};

export default { getTypeThirdPartyError, getErrorThirdPartyType };
