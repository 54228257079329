import { Model } from '@vuex-orm/core';
import log from 'Core/services/log.service';
import UnitsService from 'Units/services/units.service';

// import UnitsService from 'Units/services/units.service';
/**
 * @typedef {Object} ScheduleConf
 * @property {string} id - id de la programacion
 * @property {string} device_id - id del dispositivo o sistema (en configuraciones de instalación)
 * @property {string} installation_id - id de la instalación
 * @property {Array<string>} aq_mode_values - modos de ionizador disponibles
 * @property {Array<string>} aq_vent_mode_values - modos de ionizador disponibles
 * @property {number} free_sched_num - SÓLO en programaciones de zona. Siguiente número de programación disponible entre 0 - 24
 * @property {boolean} prog_enabled - Programación activa/desactivada
 * @property {Array<string>} usermode_values - Modos de usuario disponibles
 * @property {Array<number>} mode_available - Modos disponibles (frío, calor, auto, etc...)
 * @property {Array<number>} speed_values - Velocidades disponibles
 * @property {number} speed_type - 0 es velocidades normales; 1 es Airflow (Vaf/DZK)
 * @property {boolean} speed_mode_available - Indica si está disponible programación de velocidad en MODOS
 * @property {boolean} speed_temp_available - Indica si está disponible programación de velocidad en TEMPERATURAS
 * @property {boolean} speed_power_available - Indica si está disponible programación de velocidad en POWER
 * @property {boolean} speed_usermode_vla_available - Si permite programaciones de escena VLA + ventilación
 */
export default class ScheduleConf extends Model {

  static entity = 'schedules_conf';

   /** *************************************************************
   * CAMPOS
   ************************************************************** */
   static fields() {
    return {
      id: this.attr(null).nullable(), // id del modelo para consultas
      device_id: this.attr(null).nullable(), // Id del dispositivo o systema que tiene esta config
      installation_id: this.string(undefined).nullable(),
      aq_mode_values: this.attr(undefined).nullable(),
      aq_vent_mode_values: this.attr(undefined).nullable(),
      free_sched_num: this.number(undefined).nullable(),
      prog_enabled: this.boolean(undefined).nullable(), // si están habilitadas o no. Si no está, es que no se puede deshabilitar
      usermode_values: this.attr(undefined).nullable(), // si permite programación de escenas, las escenas que tiene disponible (solo VAF/DZK)
      mode_available: this.attr(undefined).nullable(), // si permite programación de modo, los modos que tiene disponible
      speed_values: this.attr(undefined).nullable(), // si permite ventilación, los valores disponibles
      speed_type: this.number(undefined).nullable(), // 0 es velocidades normales; 1 es Airflow (Vaf/DZK)
      speed_mode_available: this.boolean(undefined).nullable(), // si permite programaciones de modo + ventilación
      speed_temp_available: this.boolean(undefined).nullable(), // si permite programaciones de temp + ventilación
      speed_power_available: this.boolean(undefined).nullable(), // si permite programaciones de power + ventilación
      speed_usermode_vla_available: this.boolean(undefined).nullable(), // si permite programaciones de escena VLA + ventilación
      enable_individual: this.boolean(undefined).nullable(), // si permite programaciones a nivel de sistema
      end_conf_available: this.boolean(undefined).nullable(), // si permite conf de fin de programacion
      double_sp: this.boolean(undefined).nullable(), // si permite conf de programaciones con doble setpoint
      multi_zone_sched_available: this.boolean(undefined).nullable(), // si permite programaciones a nivel de sistema
      autochange_diff_temp_conf: this.number(undefined).nullable(), // Si es VAF/DZK, el diferencial minimo entre consigna de frio y calor
      step: this.number(undefined).nullable(), // paso para la configuración de temperatura
      range_sp_air_min: this.number(undefined).nullable(), // lower setpoint limit for schedule
      range_sp_air_max: this.number(undefined).nullable(), // upper setpoint limit for schedule
      range_sp_auto_air_min: this.number(undefined).nullable(), // lower setpoint limit for VAF/DZK schedule
      range_sp_auto_air_max: this.number(undefined).nullable(), // lower setpoint limit for VAF/DZK schedule
      range_sp_cool_air_min: this.number(undefined).nullable(), // lower setpoint limit for VAF/DZK schedule
      range_sp_cool_air_max: this.number(undefined).nullable(), // upper setpoint limit for VAF/DZK schedule
      range_sp_hot_air_min: this.number(undefined).nullable(), // lower setpoint limit for VAF/DZK schedule
      range_sp_hot_air_max: this.number(undefined).nullable(), // upper setpoint limit for VAF/DZK schedule
      range_sp_dry_air_max: this.number(undefined).nullable(),
      range_sp_dry_air_min: this.number(undefined).nullable(),
      range_sp_stop_air_max: this.number(undefined).nullable(),
      range_sp_stop_air_min: this.number(undefined).nullable(),
      range_sp_vent_air_max: this.number(undefined).nullable(),
      range_sp_vent_air_min: this.number(undefined).nullable(),
      range_sp_acs_max: this.number(undefined).nullable(),
      range_sp_acs_min: this.number(undefined).nullable(),
      VMC_relay_mode_values: this.attr(undefined).nullable(),
      units: this.number(undefined).nullable() // unidades de máquina (machine_units)
    }
   }

   /**
   * Actualiza un parámetro de un dispositivo
   *
   * @todo Actualizar los parámetros nuevos
   * @param {String} param - El parámetro a actualizar
   * @param {String|Number} value - El nuevo valor {param: value}
   */
  setParam = async (param, value) => {
    // const installationID = this.installation_id;
    const deviceID = this.device_id;

    //
    // Actualizo el modelo
    //
    const deviceData = {};
    deviceData[param] = value;

    await ScheduleConf.update({
      where: sched => sched.device_id === deviceID,
      data: deviceData,
    });

    log.info(`setScheduleConfStatus: ${JSON.stringify(deviceData)}`);
  }

  /**
   * Actualiza el "free_sched_num"
   *
   * @param {number} newSchedNumber El nuevo Schedule Number disponible
   */
  updateScheduleNumber = async newSchedNumber => {

    await this.setParam('free_sched_num',newSchedNumber);

    log.info(`schedule_number updated to: ${newSchedNumber}`);

  }

  /**
   *
   * @param {boolean} enabled
   */
  setProgEnabled = async enabled => {
    const deviceID = this.device_id;
    const installationID = this.installation_id;
    const param = 'prog_enabled';

    await UnitsService.setDeviceStatus(deviceID, installationID, param, enabled);

    const data = {
      id: this.id,
      prog_enabled: enabled
    };

    await ScheduleConf.update({data});

    log.info(`setDeviceStatus ${deviceID}: ${JSON.stringify(data)}`);
  }

}
