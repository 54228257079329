import { createApp, h } from 'vue';
import i18n from 'Core/services/language.service';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';
import VueDialog from './Dialog.vue';

let dialogInstance = null;

/**
 * El componente dialog permite un uso simplificado donde unicamente pasamos el mensaje.
 * Cuando se pasa un mensaje como opción, tenemos que parsearlo como un objeto
 *
 * @param {Object,String} title
 */
function parseOptions(title) {
  return typeof title === 'string' ? { title } : title;
}

/**
 * Crea una nueva instancia del componente,
 * si ya existe una devuelve la que había
 */
function createInstance(newProps) {
  if (dialogInstance) dialogInstance.unmount();

  const el = document.createElement('div')
  //
  // NOTA: no es necesario añadir en el DOM al body porque el componente Doalog ya lo hace en el mounted()
  //
  dialogInstance = createApp({
    render() {
      return h(VueDialog, {
        ...newProps,
        callback: action => {
          if (action === 'confirm') {
            dialogInstance?.options?.resolve(action)
          } else {
            dialogInstance?.options?.reject(action)
          }
          dialogInstance = null
        },
      })
    }
  });
  dialogInstance.use(VueSvgIconPlugin, { tagName: 'az-icon', classPrefix: 'az' });
  dialogInstance.mount(el)

  return dialogInstance;
}

/**
 * Crea un Objeto Dialog
 *
 * @param {Object} options - Opciones del Dialog
 */
function createDialog(options) {
  return new Promise((resolve, reject) => {
    const dialog = createInstance(options);
    // Le paso las opciones a la instancia del dialog
    dialog.options = { resolve, reject }
  });
}

/**
 * Creo el objeto Dialog que contiene los métodos para crear los diferentes tipos de dialog
 */
const Dialog = {
  confirm: options => {
    return createDialog({
      type: 'warning',
      icon: 'warning',
      confirm: true,
      ...parseOptions(options),
    })
  },


  info: options => {
    return createDialog({
      type: 'info',
      icon: 'info',
      ...parseOptions(options),
    })
  },

  success: options => {
    return createDialog({
      type: 'success',
      icon: 'check',
      title: i18n.global.t('messages.success'),
      ...parseOptions(options),
    })
  },

  fail: options => {
    return createDialog({
      type: 'warning',
      icon: 'warning',
      title: i18n.global.t('messages.errors.defaultError.title'),
      message: i18n.global.t('messages.errors.defaultError.message'),
      ...parseOptions(options),
    })
  },

  error: error => {
    // const title = `${error.module}.${error.name}.title`; // Estructura de proyecto antigua, traducciones por módulos. Mantenemos por si hubiese que recuperarla
    // const message = `${error.module}.${error.name}.message`;
    if(error?.name === 'TypeError')  return error;

    const title = error?.name && i18n.global.t(error?.name) ? `messages.errors.${error.name}.title` : 'messages.errors.defaultError.title';
    const message = error?.name && i18n.global.t(error?.name) ? `messages.errors.${error.name}.message`: 'messages.errors.defaultError.message';

    const titleString = i18n.global.t(title).includes('messages.errors.') ? i18n.global.t('messages.errors.defaultError.title') : i18n.global.t(title);
    const messageString = i18n.global.t(message).includes('messages.errors.') ? i18n.global.t('messages.errors.defaultError.message') : i18n.global.t(message);

    return createDialog({
      type: error?.isBlocking ? 'error' : 'warning',
      icon: error?.icon || 'warning',
      title: titleString,
      message: messageString,
      errorCode: error?.code || '',
      errorName: error?.name || '',
      errorDescription: error?.description || '',
      errorInfo: error?.info || null,
      support: error?.support || '',
      ...parseOptions(error),
    });
  },
};

export default Dialog;
