import ApiService from 'Core/services/api.service';
import AppError from 'Core/services/errors.service';
import store from 'Core/services/storage.service';
import i18n from 'Core/services/language.service';
import { Loader } from "@googlemaps/js-api-loader"
import constants from 'Core/constant';
import log from 'Core/services/log.service';
import parseLocation from 'Core/utils/location.utils';

const LocationService = {
  /**
   * Obtiene los datos de la localización del Backend
   *
   * @param {String} locationID - ID de la localizacion
   * @returns {Object} Datos de la localización
   */
  async getBackendLocation(locationID) {
    // Request
    const resource = `installations/location/${locationID}`;
    const response = await ApiService.get(resource);

    return response.data;
  },

  /**
   * Obtiene los datos de la localización del API de Google
   *
   * @param {String} latlng
   * @returns {Object} Datos de la localización
   */
  async getGoogleLocation(latlng) {
    const loader = new Loader({
      apiKey: constants.CONNECT.GOOGLEMAP.KEY,
      version: "weekly",
      libraries: ["places"],
    })

    return new Promise(resolve => {
      loader.load().then((google) => {
        const geocoder = new google.maps.Geocoder(); // eslint-disable-line

        const language = store.getItem('language') ? store.getItem('language') : i18n.global.locale.value;

        geocoder.geocode({ location: latlng, region: language }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const address = results[0].address_components;
              const placeID = results[0].place_id;
              const location = parseLocation(placeID, address);
              resolve(location);
            } else {
              log.error('getGoogleLocation - No results found');
            }
          } else {
            log.error(`getGoogleLocation - Geocoder failed due to: ${status}`);
          }
        });
      });
    });
  },

  /**
   * Obtiene el código iso 3611 alpha2 del país con el geocoding del API de Google
   *
   * @param {String} latlng - Latitud de la ubicación
   * @param {String} latlng - Longitud de la ubicación
   * @returns {String} Código ISO 3611 alpha2 del país según la localización
   */
  async getGoogleCountryCode(latitude, longitude) {
    const loader = new Loader({
      apiKey: constants.CONNECT.GOOGLEMAP.KEY,
      version: "weekly",
      libraries: ["places"],
    })

    return new Promise(resolve => {
      loader.load().then((google) => {
        const geocoder = new google.maps.Geocoder(); // eslint-disable-line

        const latlng = {
          lat: latitude,
          lng: longitude,
        }

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const address = results[0].address_components;
              // console.log('address: ', address)
              const country = address.find(item => item.types.some(type => type === 'country'));
              resolve(country?.short_name);
            } else {
              log.error('getGoogleCountryCode - No results found');
            }
          } else {
            log.error(`getGoogleCountryCode - Geocoder failed due to: ${status}`);
          }
        });
      });
    });
  },

  /**
   * Obtiene la localización de un grupo
   *
   * Si el grupo tiene una localización, pido los datos de la localización al backend
   * Si el backend no devuelve una localización, o no la tiene en ese idioma,
   * pido los datos al API de Google Map y actualizo los datos en el backend.
   * Devuelvo los datos de la localización
   *
   * @param {Object} group - Datos del grupo
   * @return {Object} Datos de la localización
   */
  async getLocation(group) {
    if (store.getters.getIsDemo) return null;
    if (!group.latitude || !group.longitude) return null;

    const language = store.getItem('language') ? store.getItem('language') : i18n.global.locale.value;

    let location = {};

    // Compruebo si el grupo ya tiene una localización
    // y pido los datos al backen
    if (group.locationID) {
      location = await this.getBackendLocation(group.locationID);
    }

    // Si no tengo datos de la localización o no los tengo en el idioma definido
    // los pido al API de Google mediante las coordenadas y los guardo en el backend
    if (!location.text || !location.text.city || !location.text.city[language]) {
      const latlng = {
        lat: group.latitude,
        lng: group.longitude,
      };
      const groupID = group.id;

      location = await this.getGoogleLocation(latlng);

      if (group.type !== 'basic') {
        await this.setLocation(groupID, { location });
      }
    }

    return location;
  },

  /**
   * Obtiene los detalles de una localización
   *
   * @param {HTML Element} map
   * @param {String} placeId
   * @returns {Object} - Devuelve la localización con formato para el backend y las coordenadas.
   */
  async getLocationDetails(map, placeId, sessionToken, language) {
    const loader = new Loader({
      apiKey: constants.CONNECT.GOOGLEMAP.KEY,
      version: "weekly",
      libraries: ["places"],
      language
    })

    return new Promise((resolve, reject) => {
      loader.load().then((google) => {
        const placeService = new google.maps.places.PlacesService(map); // eslint-disable-line

        const request = {
          placeId,
          fields: ['address_components', 'geometry', 'place_id'],
          sessionToken,
        };

        placeService.getDetails(request, (place, status) => {
          // eslint-disable-next-line
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const address = place.address_components;
            const placeID = place.place_id;
            const location = parseLocation(placeID, address);
            const coords = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            resolve({
              location,
              coords,
            });
          } else {
            reject(new AppError('placeService.getDetails', status));
          }
        });
      });
    });
  },

  /**
   * Obtiene un listado con las localidades sugeridas
   *
   * @param {String} query - String que queremos consultar
   * @returns {Array} - Listado de localidades sugeridas
   */
  getAutocompleteList(query, sessionToken, language) {
    const loader = new Loader({
      apiKey: constants.CONNECT.GOOGLEMAP.KEY,
      version: "weekly",
      libraries: ["places"],
      language
    })

    return new Promise(resolve => {
      if (!query) {
        resolve([]);
        return;
      }

      loader.load().then((google) => {
        const service = new google.maps.places.AutocompleteService(); // eslint-disable-line

        const request = {
          input: query,
          types: ['(cities)'],
          sessionToken,
        };

        service.getPlacePredictions(request, (predictions, status) => {
          if (
            status !== google.maps.places.PlacesServiceStatus.OK || // eslint-disable-line
            status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS // eslint-disable-line
          ) {
            log.error('getPlacePredictions', status);
            resolve([]);
          } else {
            resolve(predictions);
          }
        });
      });
    });
  },

  /**
   * Guarda/Actualiza los datos de la localización en el backend
   *
   * @param {String} groupID - ID del grupo al que pertenece la localización
   * @param {Object} location - Datos parseados de la localización
   * @returns {String} ID de la localización
   */
  async setLocation(groupID, location) {
    // Almaceno la localización en el backend
    const resource = `installations/${groupID}/location/aidoo`; // ${CONNECT.SCOPE}`;
    const response = await ApiService.patch(resource, location);

    // Devuelvo el ID de la localización
    return response.location_id;
  },
};

export default LocationService;
