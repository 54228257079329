import { Model } from '@vuex-orm/core';

export default class Sensor extends Model {

  static entity = 'sensors'

  /** *************************************************************
  * CAMPOS
  ************************************************************** */
   static fields() {
    return {
      id: this.attr(null).nullable(), // id del modelo para consultas
      alias: this.string(undefined).nullable(),
      modbus_id: this.number(undefined).nullable(),
      modbus_conf: this.attr(undefined).nullable(),
      show_adv_settings: this.boolean(false),
      manufacturer: this.string(undefined).nullable(),
      meta: this.attr(undefined).nullable(),
      model: this.string(undefined).nullable(),
      measurements: this.attr(undefined).nullable(),
      conf_version: this.number(undefined).nullable()
    }
  }
}
