import BluetoothService from 'Core/services/bluetooth.service';
import { Device } from './DeviceHierarchy';

export class Outputs extends Device {
  static entity = 'outputs';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {

    return {
      ...super.fields(),

      outputs: this.attr(undefined).nullable(),
    }

  }
  /** ********************************************************
   * DENTRO DE UN CAMPO TIPO OUTPUTS TENDREMOS LOS PARÁMETROS:
   ********************************************************** */
  // output_id: this.string(undefined).nullable(),
  // linked_zones: this.attr(undefined).nullable(),
  // type: this.string(undefined).nullable(),
  // stages: this.attr(undefined).nullable(),
  // stage_setup: this.string(undefined).nullable(),
  // stage_setup_cool_values: this.attr(undefined).nullable(),
  // stage_setup_heat_values: this.attr(undefined).nullable()

  /**
   *
   * @param {number} outputID
   * @param {Boolean} value - true: test activo; false: test inactivo
   * @param {String} type - Indicar si es test de output
   * @returns
   */
  async setOutputTest(outputID, value, type = 'air_output') {
    const config = {
      systemid: this.system_number,
      outputid: outputID,
      value
    }

    return BluetoothService.setTest(this.macBLE, type, config);
  }

}

export default Outputs;
