import { Model } from '@vuex-orm/core';
import UnitsService from 'Units/services/units.service';
import Command  from './Command.model';


/**
 * @typedef Scene
 *
 * @param {string} id - identificador único
 * @param {string} name - Nombre de la escena, puesto por el usuario
 * @param {number} icon - Icono de la escena, escogido por el usuario
 * @param {number} total_time - Tiempo duración ejecución de la escena (en min.)
 * @param {Array<Object>} commands - Array de comandos de ejecución. Consultar API backend de instalación
 */
export default class Scene extends Model {

  static entity = 'scenes';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.string(undefined).nullable(),
      installation_id: this.string(undefined).nullable(),
      name: this.string(undefined).nullable(),
      icon: this.number(undefined).nullable(),
      total_time: this.number(undefined).nullable(),
      commands: this.hasMany(Command, 'scene_id'),
      running: this.attr(undefined).nullable(),
      start_time: this.string(undefined).nullable(),
      position: this.number(undefined).nullable(),
    }
  }

  static async getScene(installationID, sceneID) {
    const { scene, commands } = await UnitsService.getScene(installationID, sceneID);
    Scene.update({data: scene});
    Command.insertOrUpdate({data: commands});
  }


  async launchScene(action = "start") {
    await UnitsService.launchScene(this.installation_id, this.id, action);
  }

  // STATICS methods
  static async setPositionScene(installationID, sceneID, position) {
    await UnitsService.setPositionScene(installationID, sceneID, position);
  }

  static async updateScene(installationID, sceneID, data) {

    const {scene, commands} = await UnitsService.updateScene(installationID, sceneID, data);

    Scene.update({
      data: scene
    });

    Command.update({
      data: commands
    })

    return {scene, commands};



  }

  static async createScene(installationID, data) {


    const {scene, commands} = await UnitsService.createScene(installationID, data);

    Scene.insert({
      data: scene
    });

    Command.insert({
      data: commands
    })

    return {scene, commands};
  }

  static async deleteScene(installationID, sceneID) {
    await UnitsService.deleteScene(installationID, sceneID);
  }


}
