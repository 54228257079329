import { Device } from './DeviceHierarchy';

export class CCP extends Device {
  static entity = 'ccp';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),

      acs_active: this.boolean(undefined).nullable(),
      ccp_fw: this.string(undefined).nullable(),
      gw_manufacturer: this.attr(undefined).nullable(),
      gw_fw: this.string(undefined).nullable(),
      activation_delay_conf: this.attr(undefined).nullable(),
      activation_delay_values: this.attr(undefined).nullable(),
      cold_mixing_v_conf: this.string(undefined).nullable(),
      cold_mixing_v_values: this.attr(undefined).nullable(),
      installation_type_conf: this.string(undefined).nullable(),
      installation_type_values: this.attr(undefined).nullable(),
      range_sp_lwt_cool_air_max: this.attr(undefined).nullable(),
      range_sp_lwt_cool_air_min: this.attr(undefined).nullable(),
      range_sp_lwt_cool_radiant_max: this.attr(undefined).nullable(),
      range_sp_lwt_cool_radiant_min: this.attr(undefined).nullable(),
      range_sp_lwt_heat_air_max: this.attr(undefined).nullable(),
      range_sp_lwt_heat_air_min: this.attr(undefined).nullable(),
      range_sp_lwt_heat_radiant_max: this.attr(undefined).nullable(),
      range_sp_lwt_heat_radiant_min: this.attr(undefined).nullable(),
      setpoint_lwt_cool_air: this.attr(undefined).nullable(),
      setpoint_lwt_cool_radiant: this.attr(undefined).nullable(),
      setpoint_lwt_heat_air: this.attr(undefined).nullable(),
      setpoint_lwt_heat_radiant: this.attr(undefined).nullable(),
      serialnum_ccp: this.string(undefined).nullable(),
      serialnum_ccp_gw: this.string(undefined).nullable(),
      step: this.attr(undefined).nullable(),
      system_number: this.attr(undefined).nullable(),
      units: this.string(undefined).nullable(),
      name: this.string(''),
      status: this.attr(null),
    };
  }

  get hasAidooConfig() {
    return this.installation_type_conf !== undefined && this.installation_type_conf !== null &&
      this.installation_type_values && this.installation_type_values.length > 0;
  }

  get hasConfig() {
    return this.cold_mixing_v_conf !== undefined && this.cold_mixing_v_conf !== null ||
      this.cold_mixing_v_values !== undefined && this.cold_mixing_v_values !== null ||
      this.installation_type_conf !== undefined && this.installation_type_conf !== null ||
      this.installation_type_values !== undefined && this.installation_type_values !== null ||
      this.activation_delay_conf !== undefined && this.activation_delay_conf !== null ||
      this.activation_delay_values !== undefined && this.activation_delay_values !== null ||
      this.acs_active !== undefined && this.acs_active !== null ||
      this.range_sp_lwt_heat_air_max !== undefined && this.range_sp_lwt_heat_air_max !== null ||
      this.range_sp_lwt_heat_air_min !== undefined && this.range_sp_lwt_heat_air_min !== null ||
      this.range_sp_lwt_heat_radiant_max !== undefined && this.range_sp_lwt_heat_radiant_max !== null ||
      this.range_sp_lwt_heat_radiant_min !== undefined && this.range_sp_lwt_heat_radiant_min !== null ||
      this.range_sp_lwt_cool_air_max !== undefined && this.range_sp_lwt_cool_air_max !== null ||
      this.range_sp_lwt_cool_air_min !== undefined && this.range_sp_lwt_cool_air_min !== null ||
      this.range_sp_lwt_cool_radiant_max !== undefined && this.range_sp_lwt_cool_radiant_max !== null ||
      this.range_sp_lwt_cool_radiant_min !== undefined && this.range_sp_lwt_cool_radiant_min !== null ||
      this.setpoint_lwt_cool_air !== undefined && this.setpoint_lwt_cool_air !== null ||
      this.setpoint_lwt_cool_radiant !== undefined && this.setpoint_lwt_cool_radiant !== null ||
      this.setpoint_lwt_heat_air !== undefined && this.setpoint_lwt_heat_air !== null ||
      this.setpoint_lwt_heat_radiant !== undefined && this.setpoint_lwt_heat_radiant !== null
  }
}

export default CCP;
