import { Device } from './DeviceHierarchy';

export class Dehumidifier extends Device {
  static entity = 'dehumidifiers';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),
      dehumidifier_fw: this.string(undefined).nullable(),
      humidity_control: this.boolean(undefined).nullable(),
      max_humidity_conf: this.attr(undefined).nullable(),
      max_humidity_values: this.attr(undefined).nullable(),
    };
  }
}

export default Dehumidifier;
