// COMPONENTS
import Dialog from 'Core/components/Dialog';
import Toast from 'Core/components/Toast';
// MODELS
import Installation from 'Units/models/Installation.model';
// import Group from 'Units/models/Group.model';
// import IntegrationsService from 'Units/services/integrations.service';
import User from 'Auth/models/User';
import Webserver from 'Units/models/Webserver.model';
import { Device } from 'Units/models/DeviceHierarchy';
import store from 'Core/store/store';
import unitsContants from 'Units/constant';

const airtoolsRoutes = {
  path: '/airtools',
  name: 'airtools',
  component: () => import('Units/pages/Airtools'),
  props: true,
  redirect: {
    name: 'airtoolsInstallations'
  },
  beforeEnter: async (to, from, next) => {
    const user = User.query().first();

    if(user.admin_mode === true && !store.getters.getIsDemo) {
      next();
    } else{
      // const hasInstallations = await Installation.all().length > 0;
      try {
        // if(!hasInstallations) await Installation.getInstallations();
        // const regex = /^\/installations(?:\/(?=$))?$/i;
        // const fromIntallationRoute = from.matched.length > 2 ? regex.test(from.matched[2].path) : false;
        // console.log("From installation", fromIntallationRoute);



        if(to.name === 'airtoolsInstallations'){
          if(to.params.page){
            await Installation.getInstallations('access_type', 'admin', null, null, to.params.page);
          } else {
            await Installation.getInstallations('access_type', 'admin');
          }
        }

        next();
      } catch (error) {
        Dialog.error(error);
        next({name: 'installations'});
      }
    }
  },
  children: [
    {
      path: 'airtoolsBLESearchDevice',
      name: 'airtoolsBLESearchDevice',
      component: () => import('Units/pages/AirtoolsBLE/AirtoolsBLESearchDevice'),
      props: true,

    },
    {
      path: 'installations/:page?',
      name: 'airtoolsInstallations',
      component: () => import('Units/pages/Airtools/AirtoolsInstallations'),
      props: true,
      beforeEnter: async (to, from, next) => {
        const user = User.query().first();

        if(user.admin_mode === true) {
          if(from.name !== 'adminAirtoolsInstallations'){
            next({name: 'adminAirtoolsInstallations', params: {role: user.role}})
          } else {
            next({name: 'adminAirtoolsInstallations', params: {role: user.role}});
            Toast.clear();
          }

        } else {
          const regex = /^\/installations(?:\/(?=$))?$/i;
          const fromIntallationRoute = from.matched.length > 2 ? regex.test(from.matched[2].path) : false;

          if(from.name !== null
            && from.name !== 'airtools'
            && !fromIntallationRoute) {
            if(to.params.page){
              await Installation.getInstallations('access_type', 'admin', null, null, to.params.page);
            } else {
              await Installation.getInstallations('access_type', 'admin');
            }
          }

          /* try{
            /* const hasOneInstallation = Installation.query().where('isAdmin',true).get().length === 1;
            if( hasOneInstallation && user.pendingInstallations === 0){
              console.log("Sólo una instalación en Airtools");
              // console.log("From", from.name);
              if(from.name === 'airtoolsWebserversList' ||
                from.name === 'airtoolsWebserverInfo'){
                next({name: 'installations'});
              } else {
                const installationID = Installation.query().first().id;
                console.log("Redirigiendo a installation",installationID);
                next({name: 'airtoolsWebservers', params: {installationID}})
              }

            } else { */
              next();
            /* }


          } catch( error ){

            Toast.clear();
            Dialog.error(error);
            next({name: from.name});
          } */
        }

      }
    },
    {
      path: 'list/:role?/:page?/:filterParam?/:filter?',
      name: 'adminAirtoolsInstallations',
      component: () => import('Units/pages/Admin/AdminAirtoolsInstallations'),
      props: true,
      beforeEnter: async (to, from, next) => {
        try {
          Toast.loading();

          if(to.params.filter) {
            await Installation.getInstallations(to.params.filterParam, to.params.filter, null, null, to.params.page, to.params.role);
          } else if(to.params.page){
            await Installation.getInstallations(null, null, null, null, to.params.page, to.params.role);
          }

          next();

          Toast.clear();

        } catch (error) {
          Dialog.error(error);
          Toast.clear();
          next();
        }
      }
    },
    {
      path: 'wwss/:installationID/:page?',
      name: 'airtoolsWebservers',
      component: () => import('Units/pages/Airtools/AirtoolsWebservers'),
      beforeEnter: async (to, from, next) => {
        const user = User.query().first();
        const installationID = to.params.installationID;
        try {
          // Si no from.name es porque se ha actualizado el navegador en web, pido las instalaciones y webservers
          if(!from.name || to.name === 'airtoolsWebserversList')
          {
            if(!user.admin_mode) await Installation.getInstallations(null, null, null, null, from.params.page);
            await Webserver.getWebservers(installationID, to.params.page);
          }

          // Comenzamos a escuchar los eventos del socket
          //
          // await User.listenInstallation(to.params.installationID);

          next();
        } catch (error) {
          console.log(error);
          Toast.clear();
          Dialog.error(error);
          next({name: from.name});
        }
      },
      redirect: {
        name: 'airtoolsWebserversList'
      },
      props: true,
      children: [
        {
          path: '',
          name: 'airtoolsWebserversList',
          component: () => import('Units/pages/Airtools/AirtoolsWebserversList'),
          props: true,
          beforeEnter: async (to, from, next) => {
            const installationID = to.params.installationID;
            try {
              // Si sólo tengo un webserver salto la vista de listar webserver
              const totalWebservers = await Installation.find(installationID)?.totalWebservers || 0;

              if(totalWebservers === 1){
                const webserverID = await Webserver.query().first().id;
                if(from.name === 'airtoolsWebserverInfo' || from.name === 'airtoolsAidooInfo'){
                  next({
                    name: 'airtools'
                  })
                } else{
                  next({
                    name: 'airtoolsWebserver',
                    params: {installationID, webserverID}
                  })
                }
              } else {
                next();
              }

              // Comenzamos a escuchar los eventos del socket
              //
              // await User.listenInstallation(to.params.installationID);
            } catch (error) {
              Toast.clear();
              Dialog.error(error);
              next({name: from.name});
            }
          }
        },
        {
          path: 'ws/:webserverID',
          name: 'airtoolsWebserver',
          component: () => import('Units/pages/Airtools/AirtoolsWebserver'),
          props: true,
          redirect: {
            name: 'airtoolsWebserverInfo'
          },
          beforeEnter: async (to, from, next) => {
            try {
              const hasConnection = User.hasSocketConnection();
              if(!hasConnection && !store.getters.getIsDemo) {
                console.log('No hay conexión con el socket. Conecto');
                await User.connectSocket();
              }

              // Recuperamos toda la información del webserver
              if(to.name === 'airtoolsWebserverInfo' || from.name === undefined){
                await Webserver.getWebserverStatus(to.params.installationID, to.params.webserverID, true);
              }

              // Iniciamos listeners del socket en la instalación a la que vamos
              await User.listenWebserver(to.params.webserverID);
              next();
              } catch (error) {
                Dialog.error(error)
                Toast.clear();
                next({name: from.name });
              }
            },
            children: [
              {
                path: '',
                name: 'airtoolsWebserverInfo',
                component: () => import('Units/pages/Airtools/AirtoolsWebserverInfo'),
                props: true,
                beforeEnter: async (to, from, next) => {
                  // Recuperamos toda la información del webserver
                  // const response = await Webserver.getWebserverStatus(to.params.installationID, to.params.webserverID, true);
                  //
                  // Si el webserver es un Aidoo vamos directamente a la INFO/SETTINGS de Aidoo
                  const ws = Webserver.find(to.params.webserverID);

                  if(ws.type === 'ws_aidoo'){
                    const installationID = to.params.installationID;
                    const webserverID = to.params.webserverID;

                    next({name: 'airtoolsAidoo', params: {installationID, webserverID, page: to.params.page} })
                  } else {
                    next();
                  }
                }
              },
              {
                path: 'aidoo',
                name: 'airtoolsAidoo',
                component: () => import('Units/pages/Airtools/Aidoo'),
                props: true,
                beforeEnter: async (to, from, next) => {
                  try{
                    // Compruebo si viene de medidas de consumo para no volver a pedir la información
                    if (from.name !== 'consumptionMeasurements') {
                      // Si el webserver es un Aidoo obtenemos diréctamente la información de los dispositivos
                      const devices = Device.query().where('webserver_id',to.params.webserverID).get();
                      // No podemos usar forEach si queremos usar await dentro del bucle !
                      for(let i = 0; i < devices.length; i++){
                        // eslint-disable-next-line no-await-in-loop
                        await Device.getConfig(devices[i].id, to.params.installationID);
                      }

                      // Consulto el total de aidoos que hay en la instalación para mostrar el enlace o no de copiar configuración
                      // NOTA: Guardo los aidoos totales en totalWebservers del modelo Installation, despúes hay que volver a restablecer su valor
                      await Webserver.getWebserversFilters(to.params.installationID, 'ws_aidoo')
                    }
                    next();
                  } catch( error ){
                    console.log(error);
                    // next({name: from.name})
                    // Si el error que obtenemos es dispositivo desconectado
                    // vamos a la vista de información donde se mostrará ese dato entre otros
                    if(error.name === 'deviceNotConnected'){
                      next();
                    } else {
                      Dialog.error(error);
                      next({name:'installations'});
                    }
                  }
                },
                redirect: {
                  name: 'airtoolsAidooInfo'
                },
                children: [
                  {
                    path: 'info',
                    name: 'airtoolsAidooInfo',
                    component: () => import('Units/pages/Airtools/Aidoo/AidooInfo'),
                    props: true
                  },
                  {
                    path: 'settings',
                    name: 'airtoolsAidooSettings',
                    component: () => import('Units/pages/Airtools/Aidoo/AidooSettings'),
                    props: true,
                  }
                ]
              },
              {
                path: 'share/:deviceID/:actualPage?/:pages?/:filterParam?/:filter?',
                name: 'aidooSettingsShare',
                component: () => import('Units/pages/Airtools/Aidoo/AidooSettingsShare'),
                props: true,
              },
              {
                path: 'consumptionMeasurements',
                name: 'consumptionMeasurements',
                component: () => import('Units/pages/Airtools/Aidoo/AidooConsumptionMeasurements'),
                props: true,
                beforeEnter: async(to, from, next) => {
                  try{
                    // Si los dispositivos no tienen medidas, entonces vuelvo a pedir la información
                    // Nota: Esto ocurre cuando se actualiza la página en web y en VUE 2 no pasa por el beforeEnter de la ruta padre
                    const devices = Device.query().where('webserver_id',to.params.webserverID).where('type', 'ZONE').get();
                    // No podemos usar forEach si queremos usar await dentro del bucle !
                    for(let i = 0; i < devices.length; i++){
                      if (!devices[i].hasConsumptionMeasurements()) {
                        // eslint-disable-next-line no-await-in-loop
                        await Device.getConfig(devices[i].id, to.params.installationID);
                      }
                    }
                    next();
                  } catch( error ){
                    console.log(error);
                    // next({name: from.name})
                    // Si el error que obtenemos es dispositivo desconectado
                    // vamos a la vista de información donde se mostrará ese dato entre otros
                    if(error.name === 'deviceNotConnected'){
                      next();
                    } else {
                      Dialog.error(error);
                      next({name:'installations'});
                    }
                  }
                }
              },
              {
                path: 'device/:deviceID/',
                name: 'airtoolsDevice',
                component: () => import('Units/pages/Airtools/AirtoolsDevice'),
                props: true,
                beforeEnter: async (to, from, next) => {

                  const zone = Device.find(to.params.deviceID);

                  try {

                    //
                    // Comprobamos si el sistema tiene salidas TRV asociada
                    //
                    const outputs = Device.query()
                        .where('type', 'OUTPUTS')
                        .where('system_number', zone.system_number).first();
                    //
                    // Si tiene pinza (medidor de consumo), obtenemos sus datos en el modelo
                    //
                    let p1;
                    if(outputs !== null && zone.type === unitsContants.MODEL_TYPE.ZONE) {
                      p1 = Device.getConfig(outputs.id, to.params.installationID);
                    }


                    const p2 = Device.getConfig(to.params.deviceID, to.params.installationID);

                    //
                    // Resolvemos las dos peticiones en paralelo
                    //
                    await Promise.all([p1,p2]);

                    next();
                  } catch (error) {
                    Toast.clear();
                    Dialog.error(error);
                    // next({name: from.name})
                    next({name:'installations'});
                  }

                },
                redirect: {
                  name: 'airtoolsDeviceInfo'
                },
                children: [
                  {
                    path: 'info',
                    name: 'airtoolsDeviceInfo',
                    component: () => import('Units/pages/Airtools/AirtoolsDeviceInfo'),
                    props: true,
                  },
                  {
                    path: 'settings',
                    name: 'airtoolsDeviceSettings',
                    component: () => import('Units/pages/Airtools/AirtoolsDeviceSettings'),
                    props: true,
                  }
                ]
              },
              {
                path: 'outputsSetup/:zoneID',
                name: 'airtoolsOutputsSetup',
                component: () => import('Units/pages/Wizard/WizardConfigZone'),
                props: true,
                beforeEnter: async(to, from, next) => {
                  try{
                    const zone = Device.find(to.params.zoneID);
                    //
                    // Comprobamos si el sistema tiene salidas TRV asociada
                    //
                    const outputs = Device.query()
                    .where('type', 'OUTPUTS')
                    .where('system_number', zone.system_number).first();

                    // Si no tengo salidas, entonces vuelvo a pedir la información y asigno el systemID
                    // Nota: Esto ocurre cuando se actualiza la página en web
                    if(outputs !== null && zone.type === unitsContants.MODEL_TYPE.ZONE) {
                      // console.log('Actualiza la página web, se pide config de las salidas', zone)
                      const system = Device.query()
                      .where('type', 'SYSTEM')
                      .where('system_number', zone.system_number).first();
                      to.params.systemID = system.id
                      await Device.getConfig(outputs.id, to.params.installationID);
                    }

                    next();
                  } catch( error ){
                    console.log(error);
                    Dialog.error(error);
                    next({name:'airtools'});
                  }
                }
              },
              {
                path: 'system/:deviceID/',
                name: 'airtoolsSystem',
                component: () => import('Units/pages/Airtools/AirtoolsSystem'),
                props: true,
                beforeEnter: async (to, from, next) => {
                  try {
                    const system = Device.find(to.params.deviceID);
                    console.log('All devices: ', Device.all())
                    //
                    // Comprobamos si el sistema tiene pinza asociada
                    //
                    const clamp = Device.query()
                        .where('type', 'CLAMP')
                        .where('system_number', system.system_number).first();
                    //
                    // Comprobamos si el sistema tiene módulo deshumectador
                    //
                    const dehumidifier = Device.query()
                        .where('type', 'DEHUMIDIFIER')
                        .where('system_number', system.system_number).first();
                    //
                    // Si tiene pinza (medidor de consumo), obtenemos sus datos en el modelo
                    //
                    let p1;
                    if(clamp !== null) {
                      p1 = Device.getConfig(clamp.id, to.params.installationID);
                    }
                    //
                    // Si tiene deshumectador, obtenemos sus datos en el modelo
                    //
                    let p3;
                    if (dehumidifier !== null) {
                      p3 = Device.getConfig(dehumidifier.id, to.params.installationID);
                    }

                    const p2 = Device.getConfig(to.params.deviceID, to.params.installationID);
                    //
                    // Resolvemos las peticiones en paralelo
                    //
                    await Promise.all([p1, p2, p3]);


                    next();
                  } catch (error) {
                    Toast.clear();
                    Dialog.error(error);
                    next({name: from.name});
                  }
                },
                redirect: {
                  name: 'airtoolsSystemInfo'
                },
                children: [
                  {
                    path: 'info',
                    name: 'airtoolsSystemInfo',
                    component: () => import('Units/pages/Airtools/AirtoolsSystemInfo'),
                    props: true,
                  },
                  {
                    path: 'settings',
                    name: 'airtoolsSystemSettings',
                    component: () => import('Units/pages/Airtools/AirtoolsSystemSettings'),
                    props: true,
                  }
                ]
              },
              {
                path: 'ccp/:deviceID/',
                name: 'airtoolsCCP',
                component: () => import('Units/pages/Airtools/AirtoolsCCPSettings'),
                props: true,
                beforeEnter: async (to, from, next) => {
                  try {
                    await Device.getConfig(to.params.deviceID, to.params.installationID);
                    next();
                  } catch (error) {
                    Toast.clear();
                    Dialog.error(error);
                    next({name: from.name});
                  }
                },
              }
            ]
        },
      ],
    },

  ],

}

export default airtoolsRoutes;
