import log from 'Core/services/log.service';

const mapErrorCore = {
  default: ['00', 'Error por defecto, se aplica cuando no se especifica ningún código de error', false],
  offline: ['01', 'No hay conexión de internet', true],
  backendDown: ['02', 'El backend no responde', true],
  timeout: ['03', 'La petición se ha cancelado por tardar demasiado tiempo', false],
  endPointNotFound: ['04', 'La ruta de la petición no se encuentra', false],
  socketConnectError:['05','Websocket: No se ha podido conectar con el socket',false],
  tooManyConnections:['06','Websocket: Ya está escuchando otra instalación. Debe llamar a "clearListeners" primero', false],
  notAuthorized:['07','Websocket: No tiene permisos para ver los cambios de esta instalación', false],
  ReferenceError: ['08', 'Connection is not defined', true],
  networkError: ['09', 'Error de red', true],
  generalError: ['10', 'Lo sentimos, algo ha fallado, inténtalo de nuevo más tarde.'],
  langNotFound: ['20', 'El idioma seleccionado no se encuentra entre los idiomas disponibles', false],
  maxAttempsExceded: ['30', 'Max attemps exceded in polling function', false],
  bluetoothDisabled: ['50', 'El bluetooth está desactivado', false],
  locationDisabled: ['51', 'La localización está desactivada', false],
  locationNotAvailable: ['52', 'La localización no está habilitada', false],
  errorLocationAvailable: ['53','errorLocationAvailable', 'Error al intentar comprobar si el móvil tiene la localización disponible', false],
  errorAskActiveLocation: ['54','errorAskActiveLocation', 'Error al intentar preguntar al usuario que permita el uso de la localización', false],
  errorLocationEnabled: ['55', 'errorLocationEnabled', 'Error al intentar comprobar si el móvil tiene activado la localización', false],
  errorStartScan: ['60', 'errorStartScan', 'Error al intentar buscar webservers por bluetooth', false],
  errorStopScan: ['61', 'errorStopScan', false],
  unableConnectDevice: ['62', 'No se puede conectar con el dispositivo', false],
  errorGetDeviceInfo: ['63', 'No se puede obtener información del dispositivo', false],
  notConnect: ['64', 'Hubo un error al intentar conectar con la red Wi-Fi', false],
  notWifi: ['65', 'No se ha encontrado ninguna red Wi-Fi', false],
  commandBleNotSupported: ['66', 'Comando no soportado en el dispositivo', false],
  errorWriteDevice: ['67', 'Error escritura en dispositivo', false],
  getParamsError: ['70', 'Error obteniendo los valores de los parámetros', false],
  noIndexDbOpen: ['80', 'La base de datos no está abierta', false],
  indexedDbVersionOutdated: ['81', 'La base de datos está desactualizada, es necesario recargar la página', false],
  noIndexDbStoreCreated: ['82', 'La tabla no existe', false]
};

/**
 * Errores personalizados dentro de la aplicación
 *
 * @param {String} error - Nombre del error
 * @param {String} module - Módulo donde se produce el error para buscar los textos de los errores
 * @param {String} prefix - Prefijo para el código de error
 * @param {Object} mapError - Listado de errores
 */
class AppError extends Error {
  constructor(error, module, prefix, mapError, customDescription) {
    super();

    if (error.code) {
      /**
       * Error personalizado de la aplicación, lo vuelvo a reenviar
       */
      this.module = error.module;
      this.prefix = error.prefix;
      this.mapError = error.mapError;
      this.code = error.code;
      this.name = error.name;
      this.description = customDescription || error.description;
      this.isBlocking = error.isBlocking;
    } else {
      this.module = module || 'core';
      this.prefix = prefix || 'Cx';
      this.mapError = mapError || mapErrorCore;

      if (typeof error === 'string') {
        /**
        * Nuevo error generado en la aplicación
        */
        this.getErrorData(error, customDescription);
      } else if (error.response && error.response.data && error.response.data._id) {
        /**
        * Nuevo error recibido del backend
        */
        const errorName = error.response.data._id;
        this.getErrorData(errorName, customDescription);

        // Si recibimos más información del error, lo almacenamos
        if (errorName === 'badParams') {
          this.info = {
            param: error.response.data.data.argument,
            value: error.response.data.data.cause,
          };
        }
      }
    }
  }

  /**
   * Obtiene los datos del error del listado de errores
   */
  getErrorData(name, customDescription) {
    const errorItem = this.mapError[name];
    const errorDefault = this.mapError.default;

    this.code = errorItem ? this.prefix + errorItem[0] : this.prefix + errorDefault[0];
    this.name = name;
    this.isBlocking = errorItem && errorItem[2] ? errorItem[2] : false;
    if (customDescription) {
      this.description = customDescription;
    } else {
      this.description = errorItem ? errorItem[1] : errorDefault[1];
    }

    log.error(`${this.name} (${this.code}) - ${this.description}`);
  }
}

export default AppError;
