/* eslint-disable no-undef */
import UnitsError from 'Units/services/errors.service';
import Constants from 'Core/constant';
import AppError from 'Core/services/errors.service';
import Router from 'Core/router';
import LocationService from 'Core/services/location.service';
import store from 'Core/store/store';
// import RollbarService from 'Core/services/Rollbar.service';

/**
 * Convierte un string cualquiera al mismo con la primera letra mayúscula y el resto minúsculas
 *
 * @param {String} s
 * @returns string
 */

const capitalize = s => {
  if (typeof s !== 'string') return ''
  const lowerString = s.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1)
}

/**
 * Rellena un número con ceros a la izquierda. Por ejemplo, para formatear horas o fechas
 *
 * @param {number} number - Número a complentar con ceros
 * @param {number} width - número de dígitos final que se espera
 * @returns
 */
const zfill = (number, width) => {
  const numberOutput = Math.abs(number); /* Valor absoluto del número */
  const length = number.toString().length; /* Largo del número */
  const zero = "0"; /* String de cero */

  if (width <= length) {
    if (number < 0) {
          return (`-${  numberOutput.toString()}`);
    }
    return numberOutput.toString();
  }

  if (number < 0) {
    return (`-${  zero.repeat(width - length)  }${numberOutput.toString()}`);
  }

  return ((zero.repeat(width - length)) + numberOutput.toString());
}

/**
 *  Convierte un string en formato kebab-case a camelCase
 * @param {*} str
 * @returns
 */
const camelize = str =>  str.replace(/-./g, x=>x[1].toUpperCase());

/**
 *  Convierte un string en formato camelCase a kebab-case
 * @param {*} str
 * @returns
 */
const kebabize = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

/**
 * Comprueba si una cada es un ASCII válido
 *
 * @param {string} str
 * @returns
 */
function isASCII(str) {
    // /^[\x00-\x7F]*$/  <- ASCII sin acentos
    // eslint-disable-next-line no-control-regex
    return /^[\x00-\x7FÀ-ÖØ-öø-ÿ€¡¿·ºª]*$/.test(str);
    // return /^[\x00-\x7FÀ-ÖØ-öø-ÿ]*$/.test(str);
};

function isValidSSID(str) {
  // eslint-disable-next-line no-useless-escape
  return /^[!#;].|[+\[\]/"\t\s].*$/.test(str);
};

/**
 * Típica función "debounce" que impide una ejecución continuada de una función sin dejar un espacio de guarda entre cada una
 *
 * @param {function} func - función a ejecutar con el efecto aplicado
 * @param {Number} wait - Espera en milisegundos antes de ejecutar de nuevo la función
 * @param {*} immediate - Indica si la primera ejecución es inmediata o si espera el tiempo de rebote
 * @param {Number} maxTime - Tiempo (en milisegundos). Asegura que la función se ejecutará al menos una vez cada maxTime (si no para de recibir llamadas sin agotar tiempo de espeara "wait").
 * @returns
 */
function debounce(func, wait, immediate, maxTime) {
	let timeout;
  let startTime;

  // eslint-disable-next-line func-names
  return function() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    // eslint-disable-next-line func-names
    const later = function() {
        timeout = null;
        if (!immediate || (maxTime && Date.now() - startTime >= maxTime)) {
          func.apply(context, args);
        }
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);

    // Establece el tiempo de inicio en la primera llamada
    if (!startTime) startTime = Date.now();

    timeout = setTimeout(later, wait);

    // Si se ha alcanzado el tiempo máximo, llama a la función inmediatamente
    if (maxTime && Date.now() - startTime >= maxTime) {
        clearTimeout(timeout);
        func.apply(context, args);
        startTime = Date.now(); // Restablecer el tiempo de inicio
    } else if (callNow) {
        func.apply(context, args);
    }
  };
};

/**
 * Función "throttle". Introduce un intervalo de espera
 * @param {function} func - función a ejecutar
 * @param {Number} wait  - intervalo de ejecución en ms
 * @returns
 */
function throttle(func, wait) {
  let context; let args; let result;
  let timeout = null;
  let previous = 0;

  // eslint-disable-next-line func-names
  const later = function () {
    previous = Date.now();
    timeout = null;
    result = func.apply(context, args);
    // eslint-disable-next-line no-multi-assign
    if(!timeout) context = args = null;
  };

  // eslint-disable-next-line func-names
  return function() {
    const now = Date.now();
    if(!previous) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if( remaining <= 0 || remaining > wait) {
      if(timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      if( !timeout ) context = args = null;
    } else if (!timeout ){
      timeout = setTimeout(later, remaining);
    }
    return result;
  }
}

const poll = ({fn, validate, interval, maxAttemps}) => {

  // eslint-disable-next-line no-unused-vars
  let attemps = 0;

  // eslint-disable-next-line consistent-return
  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attemps++;

    if(validate(result)) {
      return resolve(result);
    }

    if(maxAttemps && attemps === maxAttemps) {
      // return reject(new AppError('maxAttempsExceded'));
      return reject(new AppError('timeout'));
    }

    setTimeout(executePoll, interval, resolve, reject);
  };

  return new Promise(executePoll)
}

/**
 * Función que recibe un array de entrada y lo fragmenta en otros más pequeños según una longitud dada
 *
 * @param {Array} input Array a fragmentar
 * @param {number} itemsEachFragment Items que tendrá como máximo cada fragmento
 * @returns Array que contiene los arrays más pequeños.
 */
const fragmentArray = (input, itemsEachFragment) => {

  const arrayOfFragments = [];

  for (let i = 0; i < input.length; i+= itemsEachFragment) {
    const fragment = input.slice(i, i + itemsEachFragment);
    arrayOfFragments.push(fragment);
  }

  return arrayOfFragments;
}

const getGeolocation = () => {
  return new Promise (( resolve, reject ) => {
    console.log('Entra en getGeolocation')
    navigator.geolocation.getCurrentPosition(
      position => {
        store.dispatch('setIsLocationActive', true);
        resolve(position);
      },
      error => {
        console.log(error);
        console.log('Ponemos locationActive a undefined')
        // Asignamos a undefined para diferenciarlo de false y así, saber que hemos pasado por aquí
        store.dispatch('setIsLocationActive', undefined);
        if (error.code === 1 && error.PERMISSION_DENIED === 1) {
          reject(new AppError('locationNotAvailable', 'Device location isn´t Available'));
        } else {
          reject(error)
        }
      });
  })
}

const getRegion = async () => {
  try {
    let region = null
    const position = await getGeolocation()
    // console.log('position: ', position)
    if (position?.coords?.latitude !== undefined && position?.coords?.longitude !== undefined) {
      // console.log('lat, lon: ', position.coords.latitude, position.coords.longitude)
      region = await LocationService.getGoogleCountryCode(position.coords.latitude, position.coords.longitude)
      // console.log('region: ', region)
    }
    return region
  } catch(err) {
    console.log('Error getting region: ', err)
    return null
  }
}

const requestLocationAuthorization = () => {
  console.log('Entra en requestLocationAuthorization')
  if (!Constants.IS_MOBILE) return null;
  return new Promise ((resolve, reject) => {
    cordova.plugins.diagnostic.requestLocationAuthorization( async status => {
      console.log("Geolocation activa", status);
      switch(status){
        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
          try {
            await getGeolocation()
            resolve(status);
          } catch ( error ) {
            reject(error);
          }
          console.log("Permission not requested");
          break;
        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
          console.log("Permission denied");
          store.dispatch('setIsLocationActive', false);
          break;
          case cordova.plugins.diagnostic.permissionStatus.GRANTED:
            console.log("Permission granted always");
            store.dispatch('setIsLocationActive', true);
            break;
            case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              console.log("Permission granted only when in use");
              store.dispatch('setIsLocationActive', true);
          break;
        case 'not_determined':
          console.log("Permission not determined");
          store.dispatch('setIsLocationActive', false);
          break;
          default:
          }
          resolve(status);
        },
        error => {
          console.log("Debemos activar geolocation",error);
          store.dispatch('setIsLocationActive', false);
          reject(error);
    });
  });
}

const getCurrentNetwork = async () => {
  let network = ''
  try {
    network = await WifiWizard2.getConnectedSSID()
  } catch( error ) {
    console.log('Unnable to get network ssid', error)
  }

  return network;
}

const getNetworkState = () => {

  const states = {};
  let networkState;

  // Test de 5G en USA. Vamos a deshabilitar temporalmente la obtención de la conexión
  // a través de 'cordova-plugin-network-information'.
  //
  // Obtendremos el estado de la red en cualquier caso a través de navigator.onLine
  //
  // Añadimos monitorización por rollbar del estado de red
  if(Constants.IS_MOBILE) {
    networkState = navigator.connection.type;


    states[Connection.UNKNOWN]  = Constants.NETWORK.UNKNOWN;
    states[Connection.ETHERNET] = Constants.NETWORK.ETHERNET;
    states[Connection.WIFI]     = Constants.NETWORK.WIFI;
    states[Connection.CELL_2G]  = Constants.NETWORK.CELL_2G;
    states[Connection.CELL_3G]  = Constants.NETWORK.CELL_3G;
    states[Connection.CELL_4G]  = Constants.NETWORK.CELL_4G;
    states[Connection.CELL_5G]  = Constants.NETWORK.CELL_5G;
    states[Connection.CELL]     = Constants.NETWORK.CELL;
    states[Connection.NONE]     = Constants.NETWORK.NONE;

    // RollbarService.info(`getNetworkState [Cordova plugin mobile]. navigator.connection.type: ${networkState} - STATE: ${states[networkState]}`);
  }
  else {
    networkState = navigator.onLine.toString();

    states.true = Constants.NETWORK.ONLINE;
    states.false = Constants.NETWORK.NONE;

    // RollbarService.info(`getNetworkState [web]. navigator.onLine: ${networkState} - STATE: ${states[networkState]}`);

  }

  console.log("Estado de la red", states[networkState]);

  return states[networkState];
}

const getReconnectNetworkStatus = () => {
  return new Promise( (resolve, reject) => {
    let status = false;
    poll({
      fn: () => {
        status = getNetworkState();
      },
      validate: () => {
        if(status !== Constants.NETWORK.NONE) {
          return true;
        }
        return false;
      },
      interval: 1000,
      maxAttemps: 90
    })
    .then( () => {
      console.log("Estado de red: ", status)
      resolve(true);
    })
    .catch(err => {
      reject(new UnitsError(err));
    });
  });
}

const getReconnectToWifi = () => {
  // return new Promise( (resolve, reject) => {
  return new Promise( resolve => {
    let status = false;
    poll({
      fn: () => {
        status = getNetworkState();
      },
      validate: () => {
        if(status === Constants.NETWORK.WIFI) {
          return true;
        }
        return false;
      },
      interval: 1000,
      maxAttemps: 45
    })
    .then( () => {
      console.log("Estado de red: ", status)
      resolve(true);
    })
    .catch( () => {
      //
      // Si es un C3 o C6 no nos devolverá un estado de wifi conectado.
      // Con el timeout de 45s tiene tiempo suficiente para poder
      // continuar el proceso
      //
      console.log("TIMEOUT. Posible C3 o C6. Estado de red: ", status);
      resolve(true)
      // reject(new UnitsError(err));
    });
  });
}

const sanitizeMacAddress = mac => {
  const pairs = mac.split(':');

  for(let i = 0; i < pairs.length; i++){
    if(pairs[i].length < 2){
      pairs[i] = `0${pairs[i]}`
    }
  }

  return pairs.join(':');
};

const getLocalTimeString = (StringUTCdate, timezone) => {
  return (new Date(StringUTCdate)).toLocaleString({timezone});
};

const getParamURI = (pageURL, sParam) => {
  const splitPageURL = pageURL.split("?");
  const sPageURL = splitPageURL[1];
  if(sPageURL !== undefined){
    const sURLVariables = sPageURL.split("&");
    let sParameterName = null;

    for (let i = 0; i < sURLVariables.length; i += 1) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  } else if(splitPageURL.length === 1) {
    const sURLVariables = splitPageURL[0].split("&");
    let sParameterName = null;

    for (let i = 0; i < sURLVariables.length; i += 1) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  return null;
};

const isValidParamURI = (pageURL, sParam) => {
  const splitPageURL = pageURL.split("?");
  let isValid = false;

  if(splitPageURL && splitPageURL.length >= 1){
    for(let i=0; i < splitPageURL.length; i++){

      const sURLVariables = splitPageURL[i].split("&")
      let sParameterName = null;

      console.log(sURLVariables, sParam);


      for(let j=0; j < sURLVariables.length; j++){
        console.log(sURLVariables[j]);
        if(sURLVariables[j] && sURLVariables[j].includes("=")){
          sParameterName = sURLVariables[j].split("=");

          if(sParameterName[0] === sParam){
            isValid = true;
            break;
          }
        }

      }


      if(isValid) {
        break;
      }


    }

  }

  return isValid;
};

const isMyScriptLoaded = url => {
  const scripts = document.getElementsByTagName('script');

  for (let index = 0; index < scripts.length; index += 1) {
    if (scripts[index].src.startsWith(url)) return true;
  }

  return false;
};

/**
 * Detecta si dos elementos del DOM han colisionado entre sí con un offset (margen o borde)
 * @param {HTMLDomElement} elem1 - primer elemento del DOM
 * @param {HTMLDomElement} elem1 - elemento del DOM contra el que se comprueba colisión
 * @param {number} offset - incremento que se suma a los extremos del elemento a detectar para amplicar el borde de detección
 * @param {function} success - callback si la colisión se produce
 * @param {function} fail - callback si la colisión no se ha producido
 */
const detectDOMCollision = (elem1, elem2, offset, success, fail) => {
  if(!elem1 || !elem2) return false;
  // Obtener las posiciones y tamaños de los elementos
  const elem1Rect = elem1?.getBoundingClientRect();
  const elem2Rect = elem2?.getBoundingClientRect();

  // Comprobar si hay una colisión entre los elementos (aplicamos 5 pixeles de margen sobre el borde del elemento en los lados)
  if (elem1Rect?.left < elem2Rect?.right + offset &&
      elem1Rect?.right + offset > elem2Rect?.left &&
      elem1Rect?.top < elem2Rect?.bottom + offset &&
      elem1Rect?.bottom + offset > elem2Rect?.top )
  {
    // Hay una colisión
    if(success !== undefined && typeof success === 'function') {
      return success();
    }
    return true;
  }

  // No hay una colisión
  if(fail !== undefined && typeof fail === 'function')
  {
    return fail();
  }
  return false;
}

const reloadPage = () => {
  // En $router.go() existe un bug que hace que no funcione en Safari
  if(Constants.IS_MOBILE === Constants.PLATFORM.IOS) {
    window.location.reload();
  } else {
    Router.go();
  }
}

/**
 * This function compares twor version number in string format.
 * @param {string} version1
 * @param {string} version2
 * @returns -1 -> v1<v2, 0-> equals, 1-> v2<v1
 */
const compareVersions = (version1, version2) => {
  const parts1 = version1.split('.').map(part => parseInt(part, 16));
  const parts2 = version2.split('.').map(part => parseInt(part, 16));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 < part2) {
      return -1;
    } if (part1 > part2) {
      return 1;
    }
  }

  return 0;
}

/**
 *
 * @param {string} v1
 * @param {string} v2
 * @returns
 */
const V1lowerThanV2 = (v1, v2) => {
  return compareVersions(v1, v2) === -1;
}

/**
 * Detecta si el dispositivo es tablet
 */
const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const tablet = /(ipad|macintosh|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  // Si la resolución del ancho de pantalla es muy elevada, podemos permitir el giro de pantalla. De esta forma contemplamos futuras tablets y disp. que no
  // estén incluído en el regex del userAgent.
  const width = window.screen.availWidth * window.devicePixelRatio
  const height = window.screen.availHeight * window.devicePixelRatio
  let validSize = null

  if (width > height) {
  validSize = height
  } else {
  validSize = width
  }

  const highRes = validSize >= 1480;

  return tablet || highRes
}

const isSafariBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') <= -1;
}

/**
 * Detecta los espacios reservados en cada lado de la pantalla y los asigna a las variables
 * Note: Call this on resize or orientationchange, but *after* the deviceready event
 */
const detectInsets = () => {

  if (window.AndroidNotch) {

    const style = document.documentElement.style;

    console.log("style", style);

    // Apply insets as css variables

    window.AndroidNotch.getInsetTop(async px => {
        let safeTopPadding = 0;
        //
        // Si no tiene notch tendremos 0 píxeles. Tenemos que fijar
        // los estilos del status bar y cambiar el overlayWebView
        //
        // Timeout de 4000 (lo que dura el splash screen), para solventar error de status bar al lanzarse cuando se está ejecutando splash screen
        setTimeout(async () => {
          if(px === 0){
            store.dispatch('setIsWithoutNotch', true);
            StatusBar.styleLightContent();
            safeTopPadding = 30;
          } else {
            StatusBar.overlaysWebView(true);
          }

          style.setProperty("--notch-inset-top", `${px + safeTopPadding}px`);
        }, 100);
    }, err => console.error("Failed to get insets top:", err));

    window.AndroidNotch.getInsetRight(px => {
        style.setProperty("--notch-inset-right", `${px}px`);
    }, err => console.error("Failed to get insets right:", err));

    window.AndroidNotch.getInsetBottom(async px => {
        // Espacio de guardia para la navegación nativa de Android
        let safePadding = 0;
        // Si no tengo modo de navegación por gestos activados, introduzco un espacio de guarda para salvar la barra de navegación Android
        await TransparentNavigationBar.getNavigationBarInteractionMode(async mode => {
            // mode 0: Classic three-button navigation
            // mode 1: Two-button navigation
            // mode 2: Full screen gesture mode (introduced with Android Q)
            if(mode < 2) {
              safePadding = 54;
              style.setProperty("--notch-inset-bottom", `${px + safePadding}px`);

            } else {
              safePadding = 4;
              style.setProperty("--notch-inset-bottom", `${safePadding}vh`); // Se ha añadadio un padding de guarda relativo al viewport para garantizar mostrar los botones en todos los dispositivos
            }
        },
        err => {
          console.log("ERROR obteniendo 'NavigationBarInteractionMode' con SDK: ", err);
          // safePadding = 54; // Agrego el espacio de guarda por si el móvil tiene navegación
        });

        console.log(style);
    }, err => console.error("Failed to get insets bottom:", err));

    window.AndroidNotch.getInsetLeft(px => {
        style.setProperty("--notch-inset-left", `${px}px`);
    }, err => console.error("Failed to get insets left:", err));
  }
}

/**
 * Detecta si el elemento HTML pasado está visible en el viewport.
 * @param {HTMLAnchorElement} elementHtml Elemento que se quiere comprobar si está visible en el viewport.
 * @param {HTMLAnchorElement} successCallback Método que se ejecutará en caso de pasarlo y esté el elemento visible en el viewport.
 * @param {HTMLAnchorElement} failCallback Método que se ejecutará en caso de pasarlo y esté el elemento visible en el viewport.
 * @returns { boolean } Devuelve true o false en función de si está o no el elemento en el viewport
 */
const checkIsViewInViewport = (elementHtml, successCallback = null, failCallback = null) => {
  if(!elementHtml) return false;
  // Se obtiene el rectángulo del elemento HTML.
  const rect = elementHtml.getBoundingClientRect();

  // Comprueba si el elemento está fuera del viewport por cualquiera de los lados.
  const isInView = (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight)) &&
    rect.right <= (window.innerWidth || document.documentElement .clientWidth)
  );

  if(successCallback && isInView) successCallback();
  if(failCallback && !isInView) failCallback();

  return isInView;
}

/**
 * Detecta si el objeso que se pasa está vacío.
 * @param {object} obj Objeto a comprobar.
 * @returns { boolean } Devuelve true o false en función de si el objeto que se pasa está vacío.
 */
const isEmptyObj = object => {
  if(typeof object !== 'object') return false;

  return Object.keys(object).length === 0;
}

/**
 * Comprueba si el dato pasado es un objeto.
 * @param {object} item Valor a comprobar.
 * @returns { Boolean } Devuelve true si el dato pasado es un objeto.
 */
function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeArrays(targetArray, sourceArray) {
  const resultArray = [...targetArray];

  sourceArray.forEach(sourceItem => {
    if (isObject(sourceItem)) {
      const targetIndex = resultArray.findIndex(item => isObject(item) && item.id === sourceItem.id);
      if (targetIndex >= 0) {
        resultArray[targetIndex] = mergeObjectsDeep(resultArray[targetIndex], sourceItem);
      } else {
        resultArray.push(sourceItem);
      }
    } else {
      resultArray.push(sourceItem);
    }
  });

  return resultArray;
}

/**
 * Mergea un objeto dentro de otro, reemplazando las keys que ya existen y añadiendo las que no.
 * @param {object} target Objeto destino.
 * @param {object} source Objeto de entrada.
 * @returns { object } Devuelve un objeto con el resultado tras mergear varios objetos.
 */
const mergeObjectsDeep = (target, source) => {
  // Verificar si los dos argumentos son objetos
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeObjectsDeep(target[key], source[key]);
      } else if (Array.isArray(source[key])) {
        if (!Array.isArray(target[key])) target[key] = [];

        // Fusionar arrays de objetos
        target[key] = mergeArrays(target[key], source[key]);
      } else {
        // Si no, simplemente asignamos el valor del source al target
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

const limitPriceLength = ({ value, maxIntegers = 0, maxDecimals = 0, separator = null}) => {
  if(value === '') return value;

  if(value && typeof value !== 'string') value = String(value);

  value = value.replace(/[^0-9.,]/g, '');

  if(!separator) {
    separator = value.includes('.')? '.' : value.includes(',')? ',' : '.';
  }
  // Limitar los decimales y enteros
  let [integerPart, decimalPart] = value.split(separator);

  if (integerPart && integerPart.length > maxIntegers) {
    integerPart = integerPart.slice(0, maxIntegers);
  }

  if (decimalPart && decimalPart.length > maxDecimals) {
    decimalPart = decimalPart.slice(0, maxDecimals);
  }

  return decimalPart !== undefined ? `${integerPart}${separator}${decimalPart}` : integerPart;
}



export {
  capitalize,
  checkIsViewInViewport,
  compareVersions,
  debounce,
  detectDOMCollision,
  detectInsets,
  fragmentArray,
  getCurrentNetwork,
  getGeolocation,
  getLocalTimeString,
  getNetworkState,
  getParamURI,
  getReconnectNetworkStatus,
  getReconnectToWifi,
  getRegion,
  isASCII,
  isEmptyObj,
  isMyScriptLoaded,
  isSafariBrowser,
  isTablet,
  isValidParamURI,
  isValidSSID,
  limitPriceLength,
  mergeObjectsDeep,
  poll,
  reloadPage,
  requestLocationAuthorization,
  sanitizeMacAddress,
  throttle,
  V1lowerThanV2,
  zfill,
  camelize,
  kebabize
};
