
import IndexedDBService from "Core/services/indexedDB.service";

const defaultDbName = 'UserSettingsDb'; // Extraer de una variable de entorno
const defaultVersion = 1;
const tableName = 'users';

class UserSettingsService {

  constructor(dbName = defaultDbName, version = defaultVersion) {
    this.dbName = dbName;
    this.version = version;
    this.db = null;
  }

  /**
   * Inicializa la base de datos con la tabla de usuarios
   */
  async init() {
    this.db = new IndexedDBService(this.dbName, this.version);
    await this.db.openDatabase();
    await this.db.createTable(tableName, 'id');
  }

  /**
   *
   * @param {*} id - Identificador único para el registro de la tabla
   * @returns
   */
  async get(id) {
    if(this.db === null) {
      await this.init()
    }
    let records;
    try {
      records = await this.db.getRecords(tableName, id);
    } catch(err) {
      if(err === 'noIndexDbStoreCreated') {
        await this.init();
        records = await this.db.getRecords(tableName, id);
      } else {
        throw err;
      }
    }
    return records;
  }

  /**
   *
   * @param {*} id - identificador del usuario
   * @param {*} updatedData - valor nuevo a almacenar
   * @returns
   */
  async set(updatedData) {
    if(this.db === null) {
      await this.init()
    }

    return (await this.db).updateRecord(tableName, updatedData);
  }

  /**
   *
   * @param {*} id - identificador del registro a eliminar
   * @returns
   */
  async delete(id) {
    if(this.db === null) {
      await this.init()
    }

    return (await this.db).deleteRecord(tableName, id);
  }

  /**
   *
   * @returns
   */
  async clear() {
    if(this.db === null) {
      await this.init()
    }

    return (await this.db).deleteTable(tableName);
  }



  // async initUser() {
  //   const user = {
  //     id:
  //   }
  // }




}

export default UserSettingsService;
