const setSceneStatus = (context, status) => {
  context.commit('SET_SCENE_STATUS', status)
};

const setCommandsStatus = (context, status) => {
  context.commit('SET_COMMANDS_STATUS', status)
};

const setMonitoringUrl = (context, status) => {
  context.commit('SET_MONITORING_URL', status)
};

export default {
  setSceneStatus,
  setCommandsStatus,
  setMonitoringUrl
}
