/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recovery': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M31.13 24.87l-8.74-.03-4.84-6.08 5.49-6.91h5.74l-2.53 2.53c-.17.17-.27.4-.27.63 0 .23.09.44.26.64.32.32.87.36 1.27 0l4.07-4.07c.16-.18.25-.4.25-.62 0-.17-.05-.33-.16-.5l-4.15-4.19c-.2-.19-.43-.25-.64-.27-.22 0-.44.09-.62.25-.17.16-.27.39-.27.65 0 .24.1.46.27.63l2.53 2.53h-6.15c-.22 0-.45.09-.66.29l-5.56 6.98-5.58-7.01c-.17-.17-.39-.26-.72-.26H4.06l2.53-2.53c.16-.16.25-.37.27-.63 0-.22-.09-.44-.25-.62-.15-.18-.39-.27-.69-.28-.17.02-.39.07-.59.27l-4.06 4.05-.07.09c-.13.17-.2.36-.2.55 0 .22.09.44.26.64l4.06 4.06c.32.32.87.36 1.27 0 .17-.17.26-.39.26-.63s-.1-.46-.27-.64l-2.53-2.53h5.74l5.49 6.9-4.84 6.08H1.9c-.49 0-.9.4-.9.9s.4.89.9.89h8.96c.22 0 .44-.09.66-.29l4.91-6.17 4.93 6.2c.17.17.39.26.64.26h8.96c.49 0 .89-.4.89-.89a.9.9 0 00-.72-.88v.01z" _fill="#323F4B"/>'
  }
})
