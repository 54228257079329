/* eslint-disable no-undef */
import axios from 'axios';
import Router from 'Core/router';
import AuthService from 'Auth/services/auth.service';
import AppError from 'Core/services/errors.service';
import StorageService from 'Core/services/storage.service';
import CORECONSTANTS from 'Core/constant';
import UNITSCONSTANTS from 'Units/constant';
import { getNetworkState } from 'Core/utils/utils';
import SocketService from './socket.service';

const CONSTANTS = {...CORECONSTANTS, ...UNITSCONSTANTS};
const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

const apiClient = axios.create({
  baseURL
});

const ApiService = {
  /**
   * Inicializa el Servicio API
   *
   * @param {String} baseURL - Url base del API`
   */
  init() {


    // Configuración base
    apiClient.defaults.baseURL = `${baseURL}`;
    apiClient.defaults.headers.post['Content-Type'] = 'application/json';
    apiClient.defaults.headers.post.Accept = 'application/json';
    apiClient.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    apiClient.defaults.timeout = 45000;

    // Interceptor
    /* prettier-ignore */
    apiClient.interceptors.response.use(
      response => response,
      // eslint-disable-next-line consistent-return
      async error => {
        // console.log('Error completo',error,error.response);
        // console.log('Error message', error.message);
        /*
         * Sin conexión a internet
         * La petición no puede realizarse porque no hay conexión a internet
         * redirijo a la página de error
         */
        if (error.message === 'Network Error') {

          const networkState = getNetworkState();

          if (networkState === CONSTANTS.NETWORK.NONE) {
            //
            // Si estamos en proceso de asociación Wifi vamos a perder conexión
            // No manejamos el error lanzado.
            //
            if(Router.currentRoute.value.path.includes('addDevices/wifi')){
              return false;
            }

            Router.push({ name: 'error', query: { reason: 'offline' } }).catch(() => {})
            throw new AppError('offline');
          } else {
            //
            // Si estamos en proceso de asociación Wifi vamos a perder conexión
            // No manejamos el error lanzado.
            //
            if(Router.currentRoute.value.path.includes('addDevices/wifi')){
              return false;
            }

            // Check if mobile has output to interntet
            if (CONSTANTS.IS_MOBILE) {
              // eslint-disable-next-line no-undef
              const resp = await WifiWizard2.isConnectedToInternet().then(() => {
                console.log('Tiene internet, seguimos la ejecución')
                // Sino tenemos el servidor caído
                Router.push({ name: 'error', query: { reason: 'backendDown' } }).catch(() => {});
                throw new AppError('backendDown');
              }).catch( err => {
                console.log('En api.service.js => No tiene salida a internet. no lanzo error porque en móvil se maneja desde los eventos', err)
                // Router.push({name: 'error', query:  { reason: 'notConnectedToInternet' } })
                return false
              })
              console.log(resp)
              // Si resp es false es porque no hay salida a internet, entonces no lanzo error para que no envíe al login
              if (resp === false) {
                return resp
              }
            } else {
              // Sino tenemos el servidor caído
              Router.push({ name: 'error', query: { reason: 'backendDown' } }).catch(() => {});
              throw new AppError('backendDown');
            }
          }
        }

        /*
         * Tiempo excedido
         * La petición es abortada porque ha tardado más del tiempo definido en la variable 'timeout'
         */
        else if (error.code === 'ECONNABORTED') {
          Router.push({ name: 'error', query: { reason: 'backendDown' } }).catch(() => {});
          throw new AppError('backendDown');
        }

        /*
         * Backend no responde
         * La petición no puede realizarse porque el backend no nos responde
         * redirijo a la página de error
         */
        else if (!error.response) {
          Router.push({ name: 'error', query: { reason: 'backendDown' } }).catch(() => {});
          throw new AppError('backendDown');
        }

        /*
         * Sesión expirada
         * La sesión del usuario ha expirado, intentamos obtener un nuevo token con el refreshToken,
         * y volvemos realizar la petición.
         */
        else if (error.response.status === 401) {
          const token = await AuthService.refreshToken();
          if(token === false) return false;
          error.config.headers.Authorization = `Bearer ${token}`;
          //
          // Reconectamos el socket de usuario
          //
          await SocketService.connectUserSocket(token);
          return axios(error.config);
        }

        /*
         * El refresh token ha caducado
         * Cierro la sesión y redirijo al login
         */
        else if (error.response.data._id === 'tokenNotFound' &&
                error.response.config.url.includes('auth/') &&
                !error.response.config.url.includes('confirm') &&
                !error.response.config.url.includes('refreshToken') &&
                !error.response.config.url.includes('oauth2')) {
          this.cleanData();
          Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
          throw new AppError('sessionExpired');
        }

        /**
         * El usuario no está permitido en la instalación
         * Redirigimos a "main" (sacamos al user de la instalación)
         */
        else if(error.response.data._id === 'userNotIncluded') {
          Router.push({name: CONSTANTS.SETTINGS.DEFAULT_ROUTE, query: {reason: 'userNotIncluded'}}).catch(() => {});

          // throw new AppError('userNotIncluded');
        }

        /*
         * Sesión cerrada
         * La petición no puede realizarse porque el usuario no tiene ninguna sesión abierta,
         */
        else if (error.response.status === 403 &&
                error.response.config.url.includes('auth/') &&
                !error.response.config.url.includes('confirm') &&
                !error.response.config.url.includes('refreshToken') &&
                !error.response.config.url.includes('oauth2')) {
          this.cleanData();
          Router.push({ name: 'login', query: { reason: 'sessionClosed' } }).catch(() => {});
          // throw new AppError('sessionClosed');
        }
        /*
         * Ruta no encontrado
         * La petición no puede realizarse porque la ruta no se ha encontrado,
         */
        else if (error.response.status === 404) {
          switch(error.response.data._id){
            case CONSTANTS.PRICE_NOT_FOUND:
              return CONSTANTS.PRICE_NOT_FOUND;
            case 'locationNotValid':
            case 'noEnergyMeter':
            case 'passwordNotValid':
              throw error;
            case 'locationNotFound':
              // Devuelvo false para que siga iterando el poll en updateWeather del unist.service
              return false
            default:
              throw new AppError('endPointNotFound');
          }
        }

        else if (error?.response?.status === 503 && error?.response?.data?._id === 'maintenanceModeOn') {
          Router.push({ name: 'maintenance' }).catch(() => {});
        }

        // else {
        //   throw new Error(error);
        // }

        return Promise.reject(error);
      },
    );
  },

  setBaseURL() {
    apiClient.defaults.baseURL = `${baseURL}`;
    this.removeHeader();
  },

  request(url, data, method = 'get', baseUrl = baseURL) {
    const request = {
      method,
      url,
      baseURL: baseUrl
    }

    if(data) request.data = data

    return apiClient(request);

  },

  setHeader(token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  removeHeader() {
    apiClient.defaults.headers.common = {};
  },

  /**
   * Limpia los datos del usuario almacenados en el navegador
   */
  cleanData() {
    // Reset data
    this.removeHeader();
    StorageService.removeItem('access_token');
    StorageService.removeItem('refresh_token');
  },

  get(resource, data) {
    if (data) return apiClient.get(resource, data);
    return apiClient.get(resource);
  },

  post(resource, data) {
    return apiClient.post(resource, data);
  },

  put(resource, data) {
    return apiClient.put(resource, data);
  },

  patch(resource, data) {
    return apiClient.patch(resource, data);
  },

  delete(resource, data) {
    if (data) return apiClient.delete(resource, { data });
    return apiClient.delete(resource, data);
  },
};

export default ApiService;
