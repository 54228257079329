/* eslint-disable no-undef */
import axios from 'axios';
import AppError from 'Core/services/errors.service';
import CONSTANTS from 'Core/constant';


const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

const api3rdpClient = axios.create({
  baseURL
});

const ThirdPartyApiService = {

  /**
   * Inicializa el Servicio API
   *
   * @param {String} baseURL - Url base del API
   */
  init() {

    // Configuración base
    api3rdpClient.defaults.baseURL = `${baseURL}`;
    api3rdpClient.defaults.headers.post['Content-Type'] = 'application/json';
    api3rdpClient.defaults.headers.post.Accept = 'application/json';
    api3rdpClient.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    api3rdpClient.defaults.timeout = 45000;

    // Interceptor
    /* prettier-ignore */
    api3rdpClient.interceptors.response.use(
      response => response,
      // eslint-disable-next-line consistent-return
      async error => {

        /*
         * Sin conexión a internet
         * La petición no puede realizarse porque no hay conexión a internet
         * redirijo a la página de error
         */
        if (error.message === 'Network Error') {
          throw new AppError('offline');
        }

        /*
         * Tiempo excedido
         * La petición es abortada porque ha tardado más del tiempo definido en la variable 'timeout'
         */
        else if (error.code === 'ECONNABORTED') {
          throw new AppError('timeout');
        }

        /*
         * Backend no responde
         * La petición no puede realizarse porque el backend no nos responde
         * redirijo a la página de error
         */
        else if (!error.response) {
          // Router.push({ name: 'error', query: { reason: 'backendDown' } });
          throw new AppError('backendDown');
        }

        /*
         * Ruta no encontrado
         * La petición no puede realizarse porque la ruta no se ha encontrado,
         */
        else if (error.response.status === 404) {
          switch(error.response.data._id){
            default:
              throw new AppError('endPointNotFound');
          }
        }

        // else {
        //   throw new Error(error);
        // }

        return Promise.reject(error);
      },
    );
  },

  setHeader(token) {
    api3rdpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  removeHeader() {
    api3rdpClient.defaults.headers.common = {};
  },

  get(resource, data) {
    if (data) return api3rdpClient.get(resource, data);
    return api3rdpClient.get(resource);
  },

  post(resource, data) {
    return api3rdpClient.post(resource, data);
  },

  put(resource, data) {
    return api3rdpClient.put(resource, data);
  },

  patch(resource, data) {
    return api3rdpClient.patch(resource, data);
  },

  delete(resource, data) {
    if (data) return api3rdpClient.delete(resource, { data });
    return api3rdpClient.delete(resource, data);
  },
}

export default ThirdPartyApiService;
