/* eslint func-names: 0 */

import castArray from 'lodash/castArray';
import filter from 'lodash/filter';
import first from 'lodash/first';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export default {
  install(components) {
    const { Model } = components;

    /**
     * Inicializo la propiedad Validation
     */
    Model.validation = () => {};

    /**
     * Valida un campo del modelo y añade los errores al campo $error si los hay.
     *
     * @param {String} field - Nombre del campo a validar
     * @param {*} value - Valor del campo a validar
     * @returns {boolean} - Indica el resultado de la validación.
     */
    Model.validateField = function (field, value) {
      const rules = castArray(get(this.validation(), field, []));
      const tasks = rules.map(rule => {
        const result = rule(value, field, this);
        console.log(result);
        return result;
      });

      return Promise.all(tasks).then(errors => {
        // Saco los errores anidados
        if (isArray(errors) && isArray(first(errors))) {
          errors = first(errors);
        }

        // Los errores siempre deben ser un mensaje o un objeto con los errores
        errors = filter(errors, e => isString(e) || isObject(e));

        return errors;
      });
    };

    /**
     * Valida todos los campos
     *
     * @param {Object} fileds - Objeto con los parámetros que hay que validar.
     * @returns {Promise} - Devuelve todos los errores encontrados
     */
    Model.validate = function (fields) {
      const $errors = {};

      const tasks = Object.keys(fields).map(field => {
        return this.validateField(field, fields[field]).then(errors => {
          if (!isEmpty(errors)) {
            $errors[field] = errors;
          }
        });
      });

      return Promise.all(tasks).then(() => {
        if (isEmpty($errors)) return Promise.resolve(true);
        const validateError = { validate: $errors };
        return Promise.reject(validateError);
      });
    };
  },
};
