import { Device } from './DeviceHierarchy';

export class Purifier extends Device {
  static entity = 'purifiers';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),
      status: this.attr(null),
    };
  }
}

export default Purifier;
